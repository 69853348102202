:host {
  display: block;
}

.sold-address-illustration > svg {
  width: 8rem;
  height: 8rem;
}

/* using bg-primary messes up the opacity on the image overlay */
.not-for-sale {
  background-color: rgb(228, 9, 32);
}
