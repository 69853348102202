export function buildCaseRedirectUrl(
  caseID: string,
  baseUrl = '/',
  googleAnalyticsParams?: string
): string {
  if (!baseUrl.endsWith('/')) {
    baseUrl += '/';
  }

  return withGoogleAnalyticsParams(
    `${baseUrl}viderestilling/${caseID}`,
    googleAnalyticsParams
  );
}

export function buildCaseOpenHouseRedirectUrl(
  caseID: string,
  baseUrl = '/',
  googleAnalyticsParams?: string
): string {
  if (!baseUrl.endsWith('/')) {
    baseUrl += '/';
  }

  return withGoogleAnalyticsParams(
    `${baseUrl}viderestillingaabenthus/${caseID}`,
    googleAnalyticsParams
  );
}

export function buildHighlightedCaseRedirectUrl(
  caseID: string,
  baseUrl = '/',
  googleAnalyticsParams?: string
): string {
  if (!baseUrl.endsWith('/')) {
    baseUrl += '/';
  }

  return withGoogleAnalyticsParams(
    `${baseUrl}viderestillingfremhaevet/${caseID}`,
    googleAnalyticsParams
  );
}

function withGoogleAnalyticsParams(endpoint: string, params?: string): string {
  return params ? `${endpoint}?${params}` : endpoint;
}
