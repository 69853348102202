import { getFromDataLayer } from './get-from-datalayer';

export function getGoogleAnalyticsParams(): string | undefined {
  const clientId = getFromDataLayer('client_id');
  const sessionId = getFromDataLayer('session_id');

  return clientId && sessionId
    ? [`ga_clientId=${clientId}`, `ga_sessionId=${sessionId}`].join('&')
    : undefined;
}
