export enum FlashMessageType {
  Info = 'info',
  Warning = 'warning',
  Success = 'success',
}

export interface Toast {
  id?: string;
  title: string;
  message: string;
  type: FlashMessageType;
}

type ToastKeys =
  | 'FavoritesSaveSuccess'
  | 'FavoritesSaveError'
  | 'FavoritesUnSaveSuccess'
  | 'FavoritesUnSaveError'
  | 'SearchSaveSuccess'
  | 'SearchSaveError'
  | 'SavedSearchesSaveSuccess'
  | 'SavedSearchesSaveError'
  | 'SavedSearchesDeleteSuccess'
  | 'SavedSearchesDeleteError'
  | 'SavedSearchesUpdateSuccess'
  | 'SavedSearchesUpdateError'
  | 'InfoUpdateSuccess'
  | 'InfoUpdateError'
  | 'UserDeleteSuccess'
  | 'UserDeleteError'
  | 'UserPasswordUpdateSuccess'
  | 'UserPasswordUpdateError'
  | 'PointsOfInterestAddSuccess'
  | 'PointsOfInterestAddError'
  | 'PointsOfInterestUpdateSuccess'
  | 'PointsOfInterestUpdateError'
  | 'PointsOfInterestDeleteSuccess'
  | 'PointsOfInterestDeleteError';

export const ToastType: { [key in ToastKeys]: Toast } = {
  FavoritesSaveSuccess: {
    title: 'Succes',
    message: 'Boligen er gemt',
    type: FlashMessageType.Success,
  },
  FavoritesSaveError: {
    title: 'Der skete en fejl',
    message: 'Boligen kunne ikke gemmes på grund af en teknisk fejl. Prøv igen',
    type: FlashMessageType.Warning,
  },
  FavoritesUnSaveSuccess: {
    title: 'Succes',
    message: 'Boligen er nu ikke længere gemt',
    type: FlashMessageType.Success,
  },
  FavoritesUnSaveError: {
    title: 'Der skete en fejl',
    message:
      'Boligen kunne ikke fjernes på grund af en teknisk fejl. Prøv igen',
    type: FlashMessageType.Warning,
  },
  SearchSaveSuccess: {
    title: 'Succes',
    message: 'Søgning gemt',
    type: FlashMessageType.Success,
  },
  SearchSaveError: {
    title: 'Der skete en fejl',
    message: 'Fejl under gem søgning',
    type: FlashMessageType.Warning,
  },
  SavedSearchesSaveSuccess: {
    title: 'Succes',
    message: 'Søgning gemt',
    type: FlashMessageType.Success,
  },
  SavedSearchesSaveError: {
    title: 'Der skete en fejl',
    message: 'Fejl under gem søgning',
    type: FlashMessageType.Warning,
  },
  SavedSearchesDeleteSuccess: {
    title: 'Succes',
    message: 'Søgning ikke længere gemt',
    type: FlashMessageType.Success,
  },
  SavedSearchesDeleteError: {
    title: 'Der skete en fejl',
    message:
      'Søgningen kunne ikke fjernes på grund af en teknisk fejl. Prøv igen',
    type: FlashMessageType.Warning,
  },
  SavedSearchesUpdateSuccess: {
    title: 'Succes',
    message: 'Søgning opdateret',
    type: FlashMessageType.Success,
  },
  SavedSearchesUpdateError: {
    title: 'Der skete en fejl',
    message:
      'Søgningen kunne ikke opdateres på grund af en teknisk fejl. Prøv igen',
    type: FlashMessageType.Warning,
  },
  InfoUpdateSuccess: {
    title: 'Succes',
    message: 'Dine informationer er opdateret',
    type: FlashMessageType.Success,
  },
  InfoUpdateError: {
    title: 'Der skete en fejl',
    message:
      'Dine informationer kunne ikke opdateres på grund af en teknisk fejl. Prøv igen',
    type: FlashMessageType.Warning,
  },
  UserDeleteSuccess: {
    title: 'Succes',
    message: 'Din bruger er slettet',
    type: FlashMessageType.Success,
  },
  UserDeleteError: {
    title: 'Der skete en fejl',
    message:
      'Din bruger kunne ikke slettes på grund af en teknisk fejl. Prøv igen',
    type: FlashMessageType.Warning,
  },
  UserPasswordUpdateSuccess: {
    title: 'Kodeord opdateret',
    message: 'Dit kodeord, er nu opdateret',
    type: FlashMessageType.Success,
  },
  UserPasswordUpdateError: {
    title: 'Kodeord blev ikke opdateret',
    message: 'Dit kodeord, blev desværre ikke opdateret, Prøv igen',
    type: FlashMessageType.Warning,
  },
  PointsOfInterestAddSuccess: {
    title: 'Succes',
    message: 'Dit sted er blevet gemt',
    type: FlashMessageType.Success,
  },
  PointsOfInterestAddError: {
    title: 'Der skete en fejl',
    message: 'Stedet kunne ikke gemmes på grund af en teknisk fejl. Prøv igen',
    type: FlashMessageType.Warning,
  },
  PointsOfInterestUpdateSuccess: {
    title: 'Succes',
    message: 'Dit sted er blevet opdateret',
    type: FlashMessageType.Success,
  },
  PointsOfInterestUpdateError: {
    title: 'Der skete en fejl',
    message:
      'Stedet kunne ikke opdateres på grund af en teknisk fejl. Prøv igen',
    type: FlashMessageType.Warning,
  },
  PointsOfInterestDeleteSuccess: {
    title: 'Succes',
    message: 'Dit sted er blevet slettet',
    type: FlashMessageType.Success,
  },
  PointsOfInterestDeleteError: {
    title: 'Der skete en fejl',
    message: 'Stedet kunne ikke slettes på grund af en teknisk fejl. Prøv igen',
    type: FlashMessageType.Warning,
  },
};
