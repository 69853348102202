export enum PageViewEvent {
  TrackVp = 'track-vp', // virtual page view tracking
  TrackVpSrp = 'track-vp-srp', // virtual page view of search results page
  TrackVpDv = 'track-vp-dv', // virtual page view of detail view (aka presentation page)
}

export enum TrackingEvent {
  UserInteraction = 'user_interaction',
  UserEngagement = 'user_engagement',
  InView = 'in_view',
  Exit = 'exit',
  Calculator = 'calculator',
  UserData = 'user_data',
  LoginModal = 'login_modal',
  Login = 'login',
  Logout = 'logout',
  Signup = 'signup',
  ViewSearchResult = 'view_search_results',
  SearchTool = 'search_tool',
  ViewProperty = 'view_property',
  FeaturedPropertyCarousel = 'featured_property_carousel',
  NewsletterSignup = 'newsletter_signup',
  ViewPodcast = 'view_podcast',
  ViewSavedSearch = 'view_saved_search',
  ViewFavorite = 'view_favorite',
  ViewSavedSearchList = 'view_saved_search_list',
  ViewSavedItemList = 'view_saved_item_list',
  ViewProfileIndex = 'view_profile_index',
  ViewSpecialPick = 'view_special_pick',
  ViewNews = 'view_news',
  ViewGuide = 'view_guide',
  ViewPropertyClass = 'view_property_class',
  ViewDrilldown = 'view_drilldown',
  ViewAccount = 'view_account',
  NavigationClick = 'navigation_click',
  ViewEmailsAndNotifications = 'view_emails_and_notifications',
  ViewMyPlaces = 'view_my_places_list',
}

export type TrackingParameters = Record<string, string | undefined>;

export interface CommonPageViewParams {
  event?: PageViewEvent;
  virtualPagePath?: string;
  virtualPageTitle?: string;
  pageGroup?: string;
}

export interface SearchLocationParams {
  searchStreetName?: string;
  searchPostCode?: string;
  searchCommune?: string;
  searchRegion?: string;
  searchLocation?: string;
  searchPlace?: string;
}

export interface SearchPageViewParams
  extends SearchLocationParams,
    CommonPageViewParams {
  searchType?: string;
}

export interface DetailPageViewParams extends CommonPageViewParams {
  detailRealtorAgency?: string;
  detailAddress?: string;
  detailCommune?: string;
  detailCommuneCode?: string;
  detailType?: string;
  detailPropertyClass?: string;
  detailPropertyPrice?: string;
  detailPropertyEnergyTimeOnMarket?: string;
  detailMetaData?: string;
  detailMoreData1to5?: string;
  detailMoreData6toEnd?: string;
}

export interface GenericPageViewParams extends CommonPageViewParams {
  userStatus?: string;
  userId?: string;
}

export type PageViewParams =
  | GenericPageViewParams
  | DetailPageViewParams
  | SearchPageViewParams;

export interface TrackingSearchParams extends SearchLocationParams {
  event?: TrackingEvent;
  searchTerm?: string;
  searchType?: string;
  searchFilterRentAndSold?: string;
  searchFilterHousingType?: string;
  searchFilterPriceAndSize?: string;
  searchFilterDetails?: string;
  searchFilterInclude?: string;
  searchFilterFreeText?: string;
  searchResults?: string;
}

export interface TrackingDataLayer {
  dataLayer?: Record<string, string>;
}

export interface Trackable {
  parentComponent?: string;
}
