import { Component, h, Prop } from '@stencil/core';

@Component({
  tag: 'bsdk-action-button',
  styleUrl: 'action-button.css',
  shadow: true,
})
export class ActionButton {
  @Prop() text: string;

  render() {
    return (
      <button class="text-center relative py-2 bg-transition-to-l from-white to-red bg-white border border-red rounded text-gray-900 text-sm sm:text-base min-w-full">
        <slot />
      </button>
    );
  }
}
