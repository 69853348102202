import {
  Address as ApiAddress,
  AddressType,
  CasesApi,
  CasesApiSearchCasesRequest,
  EnergyLabel,
  SearchCasesResult,
  SearchCasesSortBy,
} from '@boligsiden/app-api-client';
import { Component, h, Listen, Prop, State } from '@stencil/core';
import {
  AddressCardBreakpoint,
  createConfiguration,
  getAddressCardBreakpoint,
} from '../../../utils';

const PAGE_DEFAULT = 1;
const PER_PAGE_DEFAULT = 10;
const SCROLL_OFFSET = 24; // when scrolling to the top of the search result, we want to scroll a bit further up

@Component({
  tag: 'bsdk-search',
  styleUrl: 'search.css',
  shadow: true,
})
export class Search {
  @Prop() addressTypes: string;
  @Prop() areaMax: number;
  @Prop() areaMin: number;
  @Prop() basementAreaMax: number;
  @Prop() basementAreaMin: number;
  @Prop() cities: string;
  @Prop() costMax: number;
  @Prop() costMin: number;
  @Prop() customAreas: string;
  @Prop() energyLabels: string;
  @Prop() floorMax: number;
  @Prop() floorMin: number;
  @Prop() filterBasement: boolean;
  @Prop() filterOpenHouse: boolean;
  @Prop() filterPriceDrop: boolean;
  @Prop() freeText: string;
  @Prop() highlighted: boolean;
  @Prop() lastPriceDropAtMax: number;
  @Prop() levelsMax: number;
  @Prop() levelsMin: number;
  @Prop() lotAreaMax: number;
  @Prop() lotAreaMin: number;
  @Prop() monthlyExpenseMax: number;
  @Prop() monthlyExpenseMin: number;
  @Prop() municipalities: string;
  @Prop() numberOfRoomsMax: number;
  @Prop() numberOfRoomsMin: number;
  @Prop() page: number = PAGE_DEFAULT;
  @Prop() perPage: number = PER_PAGE_DEFAULT;
  @Prop() placeNames: string;
  @Prop() polygon: string;
  @Prop() presale: boolean;
  @Prop() priceMax: number;
  @Prop() priceMin: number;
  @Prop() provinces: string;
  @Prop() radius: string;
  @Prop() realtorBranchName: string;
  @Prop() realtorID: string;
  @Prop() roads: string;
  @Prop() sortAscending: boolean = true; // default to true
  @Prop() sortBy: string = SearchCasesSortBy.TimeOnMarket; // default to timeOnMarket
  @Prop() sortByDistanceCenter: string;
  @Prop() timeOnMarketMax: number;
  @Prop() timeOnMarketMin: number;
  @Prop() yearBuiltFrom: number;
  @Prop() yearBuiltTo: number;
  @Prop() yearSoldMax: string;
  @Prop() yearSoldMin: string;
  @Prop() zipCodes: string;

  @State()
  searchResult: ApiAddress[];
  @State()
  error: boolean = false;

  @State()
  totalHits: number;

  ref: HTMLDivElement;
  headerHeight: number;
  paginatorPage: number;

  constructor() {
    this.handlePageChanged = this.handlePageChanged.bind(this);
    // find a sticky header and get its height (for scrolling)
    const header = document.getElementsByTagName('header')[0];
    this.headerHeight =
      header &&
      ['fixed', 'static', 'sticky'].includes(
        getComputedStyle(header).getPropertyValue('position')
      )
        ? header.offsetHeight
        : 0;
  }

  private async handlePageChanged(event: CustomEvent<number>) {
    this.paginatorPage = event.detail;
    await this.getSearchResult();
  }

  private scrollFunction(height: number) {
    window.scrollBy({ top: height, behavior: 'smooth' });
  }

  private async getSearchResult(): Promise<SearchCasesResult> {
    this.error = false;
    const api = new CasesApi(createConfiguration());

    const request: CasesApiSearchCasesRequest = {
      addressTypes: this.getSetFromPropString<AddressType>(this.addressTypes),
      areaMax: this.areaMax,
      areaMin: this.areaMin,
      basementAreaMax: this.basementAreaMax,
      basementAreaMin: this.basementAreaMin,
      cities: this.getSetFromPropString(this.cities),
      costMax: this.costMax,
      costMin: this.costMin,
      customAreas: this.getSetFromPropString(this.customAreas),
      energyLabels: this.getSetFromPropString<EnergyLabel>(this.energyLabels),
      floorMax: this.floorMax,
      floorMin: this.floorMin,
      filterBasement: this.filterBasement,
      filterOpenHouse: this.filterOpenHouse,
      filterPriceDrop: this.filterPriceDrop,
      freeText: this.freeText,
      highlighted: this.highlighted,
      lastPriceDropAtMax: this.lastPriceDropAtMax,
      levelsMax: this.levelsMax,
      levelsMin: this.levelsMin,
      lotAreaMax: this.lotAreaMax,
      lotAreaMin: this.lotAreaMin,
      monthlyExpenseMax: this.monthlyExpenseMax,
      monthlyExpenseMin: this.monthlyExpenseMin,
      municipalities: this.getSetFromPropString(this.municipalities),
      numberOfRoomsMax: this.numberOfRoomsMax,
      numberOfRoomsMin: this.numberOfRoomsMin,
      page: this.paginatorPage ?? this.page,
      perPage: this.perPage,
      placeNames: this.getSetFromPropString(this.placeNames),
      polygon: this.polygon,
      presale: this.presale,
      priceMax: this.priceMax,
      priceMin: this.priceMin,
      provinces: this.getSetFromPropString(this.provinces),
      radius: this.radius,
      realtorBranchName: this.realtorBranchName,
      realtorID: this.realtorID,
      roads: this.getSetFromPropString(this.roads),
      sortAscending: this.sortAscending,
      sortBy: this.sortBy as SearchCasesSortBy,
      sortByDistanceCenter: this.getSetFromPropString<number>(
        this.sortByDistanceCenter
      ), // TODO: This is probably not correct
      timeOnMarketMax: this.timeOnMarketMax,
      timeOnMarketMin: this.timeOnMarketMin,
      yearBuiltFrom: this.yearBuiltFrom,
      yearBuiltTo: this.yearBuiltTo,
      yearSoldMax: this.yearSoldMax,
      yearSoldMin: this.yearSoldMin,
      zipCodes: this.getSetFromPropString(this.zipCodes),
    };

    const result: { data: SearchCasesResult } = await api
      .searchCases(request)
      .catch((error) => {
        console.error(error);
        this.error = true;
        return null;
      });
    const data = await result?.data;
    this.searchResult = data.cases.map(({ address, ...rest }) => ({
      ...address,
      cases: [{ ...rest, address }],
    }));
    this.error = !data;
    this.totalHits = data.totalHits;
    return null;
  }

  connectedCallback() {
    this.getSearchResult();
  }

  @State()
  breakpoint: AddressCardBreakpoint;

  @Listen('resize', { target: 'window' })
  handleResize() {
    if (this.ref?.offsetWidth) {
      this.breakpoint = getAddressCardBreakpoint(this.ref.offsetWidth);
    }
  }

  componentDidRender() {
    this.handleResize();
  }

  componentDidUpdate() {
    if (this.ref) {
      const rect = this.ref.getBoundingClientRect();
      // if updated from paginator, scroll to top of the list
      this.paginatorPage &&
        this.scrollFunction(rect.top - SCROLL_OFFSET - this.headerHeight);
    }
  }

  render() {
    if (!this.searchResult) {
      return (
        <div class="flex h-full items-center justify-center font-sans">
          Indlæser...
        </div>
      );
    }

    return (
      <div class="flex flex-col gap-4" ref={(el) => (this.ref = el)}>
        {this.searchResult.map((address) => (
          <bsdk-address-card
            key={address.addressID}
            address={address}
            breakpoint={this.breakpoint}
          ></bsdk-address-card>
        ))}
        <bsdk-paginator
          page={this.page}
          perPage={this.perPage}
          totalHits={this.totalHits}
          onPageChanged={this.handlePageChanged}
        ></bsdk-paginator>
      </div>
    );
  }

  private getSetFromPropString<T = string>(prop: string) {
    return (
      prop && new Set(prop.split(',').map<T>((at) => at.trim() as unknown as T))
    );
  }
}
