import {
  AddressType as ApiAddressType,
  CaseStatisticAddressType as ApiCaseStatisticAddressType,
} from '@boligsiden/app-api-client';

export enum AddressType {
  Villa = 'Villa',
  TerracedHouse = 'Rækkehus',
  Condo = 'Ejerlejlighed',
  HolidayHouse = 'Fritidsbolig',
  Allotment = 'Kolonihave',
  Cooperative = 'Andelsbolig',
  Farm = 'Landejendom',
  FullYearPlot = 'Helårsgrund',
  HolidayPlot = 'Fritidsgrund',
  VillaApartment = 'Villalejlighed',
  CattleFarm = 'Kvæggård/-jord',
  PigFarm = 'Svinegård/-jord',
  PlantFarm = 'Planteavlsgård/-jord',
  Forest = 'Skovejendom',
  Special = 'Specialejendom',
  Houseboat = 'Husbåd',
}

enum AddressTypeStatistic {
  VillaAndTerracedHouse = 'Villa/Rækkehus',
}

export const CaseStatisticAddressType = {
  ...AddressType,
  ...AddressTypeStatistic,
};

export interface AddressTypeOption {
  text: string;
  value: string;
}

export enum AddressTypePlural {
  Villa = 'Villaer',
  TerracedHouse = 'Rækkehuse',
  Condo = 'Ejerlejligheder',
  HolidayHouse = 'Fritidsboliger',
  Allotment = 'Kolonihaver',
  Cooperative = 'Andelsboliger',
  Farm = 'Landejendomme',
  FullYearPlot = 'Helårsgrunde',
  HolidayPlot = 'Fritidsgrunde',
  VillaApartment = 'Villalejligheder',
  CattleFarm = 'Kvæggårde/-jorde',
  PigFarm = 'Svinegårde/-jorde',
  PlantFarm = 'Planteavlsgårde/-jorde',
  Forest = 'Skovejendomme',
  Special = 'Specialejendomme',
  Houseboat = 'Husbåde',
}

enum AddressTypeStatisticPlural {
  VillaAndTerracedHouse = 'Villaer/Rækkehuse',
}

export const CaseStatisticAddressTypePlural = {
  ...AddressTypePlural,
  ...AddressTypeStatisticPlural,
};

export const addressTypes: AddressTypeOption[] = [
  /* 0 */ { text: 'Villa', value: 'villa' },
  /* 1 */ { text: 'Ejerlejlighed', value: 'ejerlejlighed' },
  /* 2 */ { text: 'Rækkehus', value: 'raekkehus' },
  /* 3 */ { text: 'Fritidsbolig', value: 'fritidsbolig' },
  /* 4 */ { text: 'Andelsbolig', value: 'andelsbolig' },
  /* 5 */ { text: 'Landejendom', value: 'landejendom' },
  /* 6 */ { text: 'Helårsgrund', value: 'helaarsgrund' },
  /* 7 */ { text: 'Villalejlighed', value: 'villalejlighed' },
  /* 8 */ { text: 'Fritidsgrund', value: 'fritidsgrund' },
  /* 9 */ { text: 'Husbåd', value: 'husbaad' },
];

export const caseStatisticsAddressTypes: AddressTypeOption[] = [
  ...addressTypes,
  { text: 'Villa/Rækkehus', value: 'villa/raekkehus' },
];

export const rentalsAddressTypes: AddressTypeOption[] = [
  /* 0 */ { text: 'Villa', value: 'villa' },
  /* 1 */ { text: 'Ejerlejlighed', value: 'ejerlejlighed' },
  /* 2 */ { text: 'Fritidsbolig', value: 'fritidsbolig' },
  /* 3 */ { text: 'Villalejlighed', value: 'villalejlighed' },
  /* 4 */ { text: 'Rækkehus', value: 'raekkehus' },
  /* 5 */ { text: 'Landejendom', value: 'landejendom' },
];

export const soldAddressTypes: AddressTypeOption[] = [
  /* 0 */ { text: 'Villa', value: 'villa' },
  /* 1 */ { text: 'Ejerlejlighed', value: 'ejerlejlighed' },
  /* 2 */ { text: 'Rækkehus', value: 'raekkehus' },
  /* 3 */ { text: 'Fritidsbolig', value: 'fritidsbolig' },
  /* 5 */ { text: 'Landejendom', value: 'landejendom' },
];

export const farmAddressTypes: AddressTypeOption[] = [
  /* 0 */ { text: 'Kvæggård/-jord', value: 'kvaeggaard' },
  /* 1 */ { text: 'Svinegård/-jord', value: 'svinegaard' },
  /* 2 */ { text: 'Planteavlsgård/-jord', value: 'planteavlsgaard' },
  /* 3 */ { text: 'Skovejendom', value: 'skovejendom' },
  /* 4 */ { text: 'Landejendom', value: 'landejendom' },
  /* 5 */ { text: 'Specialejendom', value: 'specialejendom' },
];

// Main farm address types are all farm address types
// without HobbyFarm, i.e. "Lystejendom"
export const mainFarmAddressTypes: AddressTypeOption[] =
  farmAddressTypes.filter(({ value }) => value !== 'lystejendom');

export const farmAddressTypesDisjoint = farmAddressTypes.slice(4, 5);

// Address types specific to "Landbrugssiden"
const LandbrugssidenAddressTypes: [string, ApiAddressType][] = [
  [farmAddressTypes[0].value, ApiAddressType.CattleFarm],
  [farmAddressTypes[1].value, ApiAddressType.PigFarm],
  [farmAddressTypes[2].value, ApiAddressType.PlantFarm],
  [farmAddressTypes[3].value, ApiAddressType.Forest],
  [farmAddressTypes[4].value, ApiAddressType.HobbyFarm],
  [farmAddressTypes[5].value, ApiAddressType.Special],
];

const addressTypeMaps: [string, ApiAddressType][] = [
  [addressTypes[0].value, ApiAddressType.Villa],
  [addressTypes[1].value, ApiAddressType.Condo],
  [addressTypes[2].value, ApiAddressType.TerracedHouse],
  [addressTypes[3].value, ApiAddressType.HolidayHouse],
  [addressTypes[4].value, ApiAddressType.Cooperative],
  [addressTypes[5].value, ApiAddressType.Farm],
  [addressTypes[6].value, ApiAddressType.FullYearPlot],
  [addressTypes[7].value, ApiAddressType.VillaApartment],
  [addressTypes[8].value, ApiAddressType.HolidayPlot],
  [addressTypes[9].value, ApiAddressType.Houseboat],
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ...(process.env.NEXT_PUBLIC_APP_NAME === 'landbrugssiden'
    ? LandbrugssidenAddressTypes
    : []),
];
export const addressTypesMap: Map<string, ApiAddressType> = new Map(
  addressTypeMaps
);

export const addressTypesReverseMap: Map<ApiAddressType, string> = new Map(
  addressTypeMaps.map(([key, value]) => [value, key])
);

export const caseStatisticAddressTypeMap: Map<
  string,
  ApiCaseStatisticAddressType
> = new Map([
  [caseStatisticsAddressTypes[0].value, ApiCaseStatisticAddressType.Villa],
  [caseStatisticsAddressTypes[1].value, ApiCaseStatisticAddressType.Condo],
  [
    caseStatisticsAddressTypes[2].value,
    ApiCaseStatisticAddressType.TerracedHouse,
  ],
  [
    caseStatisticsAddressTypes[3].value,
    ApiCaseStatisticAddressType.HolidayHouse,
  ],
  [
    caseStatisticsAddressTypes[4].value,
    ApiCaseStatisticAddressType.Cooperative,
  ],
  [caseStatisticsAddressTypes[5].value, ApiCaseStatisticAddressType.Farm],
  [
    caseStatisticsAddressTypes[6].value,
    ApiCaseStatisticAddressType.FullYearPlot,
  ],
  [
    caseStatisticsAddressTypes[7].value,
    ApiCaseStatisticAddressType.VillaApartment,
  ],
  [
    caseStatisticsAddressTypes[8].value,
    ApiCaseStatisticAddressType.HolidayPlot,
  ],
  [caseStatisticsAddressTypes[9].value, ApiCaseStatisticAddressType.Houseboat],
  [
    caseStatisticsAddressTypes[10].value,
    ApiCaseStatisticAddressType.VillaAndTerracedHouse,
  ],
]);

export const addressTypeSynonymMap: Map<
  ApiAddressType,
  { singular: string; plural: string }
> = new Map([
  [ApiAddressType.Villa, { singular: 'Hus', plural: 'Huse' }],
  [ApiAddressType.Condo, { singular: 'Lejlighed', plural: 'Lejligheder' }],
  [
    ApiAddressType.HolidayHouse,
    { singular: 'Sommerhus', plural: 'Sommerhuse' },
  ],
  [
    ApiAddressType.FullYearPlot,
    { singular: 'Byggegrund', plural: 'Byggegrunde' },
  ],
  [
    ApiAddressType.VillaApartment,
    { singular: 'Villalejlighed', plural: 'Villalejligheder' },
  ],
  [
    ApiAddressType.HolidayPlot,
    { singular: 'Sommerhusgrund', plural: 'Sommerhusgrunde' },
  ],
]);

export const mobileAvailableAddressTypes: {
  text: AddressType;
  type: ApiAddressType;
}[] = [
  { text: AddressType.Villa, type: ApiAddressType.Villa },
  {
    text: AddressType.Condo,
    type: ApiAddressType.Condo,
  },
  {
    text: AddressType.TerracedHouse,
    type: ApiAddressType.TerracedHouse,
  },
  {
    text: AddressType.Cooperative,
    type: ApiAddressType.Cooperative,
  },
  {
    text: AddressType.VillaApartment,
    type: ApiAddressType.VillaApartment,
  },
  {
    text: AddressType.Farm,
    type: ApiAddressType.Farm,
  },
  {
    text: AddressType.FullYearPlot,
    type: ApiAddressType.FullYearPlot,
  },
  {
    text: AddressType.HolidayPlot,
    type: ApiAddressType.HolidayPlot,
  },
  {
    text: AddressType.HolidayHouse,
    type: ApiAddressType.HolidayHouse,
  },
  {
    text: AddressType.Houseboat,
    type: ApiAddressType.Houseboat,
  },
];

export const allDefaultAddressTypes = [
  ApiAddressType.Villa,
  ApiAddressType.Condo,
  ApiAddressType.TerracedHouse,
  ApiAddressType.HolidayHouse,
  ApiAddressType.Cooperative,
  ApiAddressType.Farm,
  ApiAddressType.HobbyFarm,
  ApiAddressType.FullYearPlot,
  ApiAddressType.VillaApartment,
  ApiAddressType.HolidayPlot,
  ApiAddressType.Houseboat,
];

export function toAddressTypeOptions(
  addressTypes: ApiAddressType[]
): AddressTypeOption[] {
  return (addressTypes ?? [])
    .map((t) => toAddressTypeOption(t))
    .filter((t): t is AddressTypeOption => !!t);
}

export function toAddressTypeOption(
  addressType: ApiAddressType
): AddressTypeOption | undefined {
  const unique = Array.from(addressTypesMap.keys()).reduce(
    (acc, curr) => (acc.includes(curr) ? acc : [...acc, curr]),
    [] as string[]
  );

  const mappings = unique.map((key) => [key, addressTypesMap.get(key)]);

  const entry = mappings.find(([, value]) => value === addressType);
  if (!entry) {
    return undefined;
  }

  const [key] = entry;

  return [...addressTypes, ...farmAddressTypes].find(
    (option) => option.value === key
  );
}

export function toCaseStatisticAddressTypeOption(
  addressType: ApiCaseStatisticAddressType
): AddressTypeOption | undefined {
  const unique = Array.from(caseStatisticAddressTypeMap.keys()).reduce(
    (acc, curr) => (acc.includes(curr) ? acc : [...acc, curr]),
    [] as string[]
  );

  const mappings = unique.map((key) => [
    key,
    caseStatisticAddressTypeMap.get(key),
  ]);

  const entry = mappings.find(([, value]) => value === addressType);
  if (!entry) {
    return undefined;
  }

  const [key] = entry;

  return caseStatisticsAddressTypes.find((option) => option.value === key);
}

export function toApiAddressType(value: string): ApiAddressType | undefined {
  return addressTypesMap.get(value);
}

export function toApiCaseStatisticAddressType(
  value: string
): ApiCaseStatisticAddressType | undefined {
  return caseStatisticAddressTypeMap.get(value);
}

/**
 * Due to merge of `Farm` and `HobbyFarm`, if `Farm` is among `addressTypes`,
 * `HobbyFarm` will be also included in `addressTypes` and returned
 */
export function includeHobbyFarm(
  addressTypes: ApiAddressType[]
): ApiAddressType[] {
  return addressTypes.find((addressType) => addressType === ApiAddressType.Farm)
    ? [...addressTypes, ApiAddressType.HobbyFarm]
    : addressTypes;
}
