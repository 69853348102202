import { Component, Event, EventEmitter, h, Prop, State } from '@stencil/core';

@Component({
  tag: 'bsdk-slide-toggle',
  styleUrl: 'slide-toggle.css',
  shadow: true,
})
export class SlideToggleComponent {
  @State() checked: boolean = false;
  @State() toggled: boolean = false;

  @Prop() label: string;

  @Event() toggle: EventEmitter<boolean>;

  constructor() {
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(_event: CustomEvent) {
    this.toggled = !this.toggled;
    this.toggle.emit(this.toggled);
  }

  render() {
    return (
      <label class="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          checked={this.toggled}
          class="sr-only peer"
          onChange={this.handleChange}
        />
        <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-900 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-400"></div>
        <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
          {this.label}
        </span>
      </label>
    );
  }
}
