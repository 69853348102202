export enum AddressTimelineEventTitle {
  Open = 'Udbudt til salg på boligsiden',
  Closed = 'Fjernet fra boligsiden',
  Built = 'Bygget',
  Renovated = 'Ombygning',
  PriceDrop = 'Prisfald',
  PriceIncrease = 'Prisstigning',
  SoldNormal = 'Solgt, alm. frit salg',
  SoldFamily = 'Solgt, familiehandel',
  SoldAuction = 'Solgt, auktion',
  SoldOther = 'Solgt, øvrigt',
  Unknown = '',
}

export enum AddressTimelineEventLabel {
  Open = 'På markedet',
  Closed = 'Af markedet',
  Built = 'Byggeår',
  Renovated = 'Ombygningsår',
  PriceDrop = 'Prisfald',
  PriceIncrease = 'Prisstigning',
  SoldNormal = 'Fri handel',
  SoldFamily = 'Familiehandel',
  SoldAuction = 'Auktion',
  SoldOther = 'Øvrigt',
  Unknown = '',
}
