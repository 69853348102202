import { Component, h, State } from '@stencil/core';

@Component({
  tag: 'bsdk-demo',
  styleUrl: 'demo.css',
  shadow: true,
})
export class Demo {
  @State() width: string = '850';
  @State() gstkvhx: string = '02652104179b_______';
  @State() unit: string = 'px';

  handleWidthChange(event) {
    this.width = event.target.value;
  }

  handleGstkvhxChange(event) {
    this.gstkvhx = event.target.value;
  }

  handleUnitChange(event: CustomEvent<boolean>) {
    this.unit = event.detail ? 'rem' : 'px';
  }

  render() {
    return (
      <div class="font-sans">
        <div class="flex">
          <div class="p-4">
            <label>Width:</label>
            <input
              class="form-input py-3 px-4 block w-20 leading-5 rounded-md transition duration-150 ease-in-out text-gray-900 bg-white border border-gray-300 placeholder-gray-400 focus:outline-none focus:outline-blue-400 focus:border-blue-300"
              type="text"
              value={this.width}
              onInput={(event) => this.handleWidthChange(event)}
            />
          </div>
          <div class="p-4">
            <label>gstkvhx:</label>
            <input
              class="form-input py-3 px-4 block w-80 leading-5 rounded-md transition duration-150 ease-in-out text-gray-900 bg-white border border-gray-300 placeholder-gray-400 focus:outline-none focus:outline-blue-400 focus:border-blue-300"
              type="text"
              value={this.gstkvhx}
              onInput={(event) => this.handleGstkvhxChange(event)}
            />
          </div>
          <div class="p-4 flex items-end">
            <bsdk-slide-toggle
              label={this.unit}
              onToggle={(event) => this.handleUnitChange(event)}
            ></bsdk-slide-toggle>
          </div>
        </div>
        <h1 class="font-mono mt-2">{'<script>'} example</h1>
        <pre class="p-4 border text-xs">
          {`<script type="module" src="${process.env.BS_WC_URL}/web-components.esm.js"></script>`}
        </pre>
        <h1 class="font-mono mt-2">{'<bsdk-address>'} example</h1>
        <pre class="p-4 border text-xs">
          {`<bsdk-address gstkvhx="${this.gstkvhx}"></bsdk-address>`}
        </pre>
        <div class="flex grow items-center justify-center p-4 mt-4 border">
          <div
            class="flex"
            style={{
              width: `${this.width}${this.unit}`,
            }}
          >
            <bsdk-address gstkvhx={this.gstkvhx}></bsdk-address>
          </div>
        </div>

        <hr />
        <h1 class="font-mono mt-2">{'<bsdk-search>'} example</h1>
        <pre class="p-4 border text-xs">
          {`<bsdk-search
  addressTypes="condo, full year plot"
  priceMin="4000000"
  perPage="5"
  page="62"
></bsdk-search>`}
        </pre>
        <div class="flex  grow items-center justify-center p-4 mt-4 border">
          <div
            class="flex"
            style={{
              width: `${this.width}${this.unit}`,
            }}
          >
            <bsdk-search
              addressTypes="condo, full year plot"
              priceMin={4000000}
              perPage={5}
              page={62}
            ></bsdk-search>
          </div>
        </div>
      </div>
    );
  }
}
