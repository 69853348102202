import { Component, Event, EventEmitter, h, Prop } from '@stencil/core';
import clsx from 'clsx';

@Component({
  tag: 'bsdk-paginator',
  styleUrl: 'paginator.css',
  shadow: true,
})
export class PaginationComponent {
  @Prop() perPage: number;
  @Prop() page: number;
  @Prop() totalHits: number;
  @Event() pageChanged: EventEmitter;

  handlePageChange(nextPage: number) {
    this.page = nextPage;
    this.pageChanged.emit(nextPage);
  }

  private getPageNumbers(currentPage: number, totalPages: number): number[] {
    const firstPages = [1, 2];
    const adjacantPages = [
      currentPage - 1,
      currentPage,
      currentPage + 1,
    ].filter((p) => p > 1 && p < totalPages);
    const lastPages = [totalPages - 1, totalPages];
    const pageNumbers = Array.from(
      new Set([...firstPages, ...adjacantPages, ...lastPages])
    );
    return pageNumbers.reduce((acc, curr, idx, src) => {
      if (idx > 0 && curr - src[idx - 1] !== 1) {
        acc.push(-1);
      }
      acc.push(curr);
      return acc;
    }, []);
  }

  render() {
    const totalPages = Math.ceil(this.totalHits / this.perPage);
    const pageNumbers = this.getPageNumbers(this.page, totalPages);
    const basePageClass =
      'w-6 h-6 sm:w-10 sm:h-10 border rounded-md border-primary flex items-center justify-center transition-colors duration-500';
    const pageClass = clsx(
      basePageClass,
      'text-xs sm:text-sm leading-10 sm:leading-10 hover:text-primary hover:bg-gray-25'
    );
    const breakClass = clsx(
      basePageClass,
      'text-xs sm:text-sm leading-10 sm:leading-10 border-none -mx-2 sm:-mx-5'
    );
    const prevNextClass = clsx(
      basePageClass,
      'bg-white text-xl sm:text-2xl sm:leading-10 font-thin items-end'
    );
    const prevClass = clsx(prevNextClass, {
      'text-gray-700 hover:text-primary hover:bg-gray-25': this.page > 1,
      'text-gray-150 border-gray-150 hover:none': this.page === 1,
    });
    const nextClass = clsx(prevNextClass, {
      'text-gray-700 hover:text-primary hover:bg-gray-25':
        this.page < totalPages,
      'text-gray-150 border-gray-150 hover:none': this.page === totalPages,
    });

    const activeClass = clsx(
      pageClass,
      'flex bg-gray-25 border-gray-150 cursor-not-allowed hover:none pointer-events-none'
    );

    const pageButtons = pageNumbers.map((pageNumber) => (
      <div>
        {pageNumber !== -1 && (
          <button
            class={pageNumber === this.page ? activeClass : pageClass}
            onClick={() => this.handlePageChange(pageNumber)}
          >
            {pageNumber}
          </button>
        )}
        {pageNumber === -1 && <div class={breakClass}>···</div>}
      </div>
    ));

    return (
      <div class="flex flex-row gap-2 sm:gap-4">
        <button
          class={prevClass}
          onClick={() => this.handlePageChange(this.page - 1)}
          disabled={this.page === 1}
        >
          <span class="mb-[0.25rem]">&lsaquo;</span>
        </button>
        {pageButtons}
        <button
          class={nextClass}
          onClick={() => this.handlePageChange(this.page + 1)}
          disabled={this.page === totalPages}
        >
          <span class="mb-[0.25rem]">&rsaquo;</span>
        </button>
      </div>
    );
  }
}
