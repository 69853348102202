type NumberNotation = 'compact' | 'standard' | 'scientific' | 'engineering';

interface NumberFormatOptions extends Intl.NumberFormatOptions {
  notation: NumberNotation;
}

export function formatNumber(
  value: number,
  locale = 'da-DK',
  options?: NumberFormatOptions
): string {
  return new Intl.NumberFormat(locale, options).format(value);
}

export function formatCurrency(
  amount: number,
  notation: NumberNotation = 'compact'
) {
  return formatNumber(amount, 'da-DK', {
    maximumSignificantDigits: 3,
    notation,
  });
}

export function toNumber(value: string) {
  return Number(value.replace(/\D/g, ''));
}

export function formatTravelTime(seconds: number): string {
  if (seconds <= 0 || isNaN(seconds)) {
    return '-';
  }
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  return `${hours > 0 ? hours + 't ' : ''}${minutes % 60}m`;
}

export function formatDistance(value: number): string {
  if (!value) {
    return '-';
  }
  return value < 1000
    ? `${formatNumber(value, 'da-DK', {
        notation: 'standard',
        maximumFractionDigits: 0,
      })} m`
    : `${formatNumber(value / 1000, 'da-DK', {
        notation: 'standard',
        maximumFractionDigits: 2,
      })} km`;
}
