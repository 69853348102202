import { reverseRecord } from '../helpers';

// TODO: this is a temporary solution, until backend will be able to provide
export const customAreaNameToSlug: Record<string, string> = {
  Als: 'als',
  Amager: 'amager',
  'Amager Øst': 'amager-oest',
  'Amager Vest': 'amager-vest',
  Bispebjerg: 'bispebjerg',
  'Ejer Bjerge': 'ejer-bjerge',
  Falster: 'falster',
  'Islands Brygge': 'islands-brygge',
  Kartoffelrækkerne: 'kartoffelraekkerne',
  Lolland: 'lolland',
  Nørrebro: 'noerrebro',
  Ørestad: 'oerestad',
  Christianshavn: 'christianshavn',
  Østerbro: 'oesterbro',
  Vesterbro: 'vesterbro',
  Sjælland: 'sjaelland',
  Jylland: 'jylland',
  Djursland: 'djursland',
  Møn: 'moen',
  'København By': 'koebenhavn-by',
};

export const customAreaSlugToName = reverseRecord(customAreaNameToSlug);
