import {
  CaseStatisticAddressType,
  CaseStatisticLocationType,
  CaseStatisticType,
} from '@boligsiden/app-api-client';
import {
  AddressTypeOption,
  caseStatisticsAddressTypes,
  StatisticLocation,
} from '../models';

export const MARKET_INDEX_DATE_FORMAT = 'yyyy-MM-dd';

export const MARKET_INDEX_DEFAULT_LOOKBACK = 36;

export const MARKET_INDEX_DEFAULT_LOCATION: StatisticLocation = {
  locationType: CaseStatisticLocationType.Country,
  locationName: 'Danmark',
  locationSlug: 'danmark',
};

export const MARKET_INDEX_SUPPORTED_ADDRESS_TYPE_OPTIONS_SOLD_CASES: string[] =
  ['villa/raekkehus', 'ejerlejlighed', 'fritidsbolig'];

export const MARKET_INDEX_SUPPORTED_ADDRESS_TYPE_OPTIONS_OPEN_CASES: string[] =
  ['villa', 'raekkehus', 'ejerlejlighed', 'fritidsbolig'];

export const MARKET_INDEX_ADDRESS_TYPES_OPTIONS_SOLD_CASES: AddressTypeOption[] =
  caseStatisticsAddressTypes.filter(({ value }) =>
    MARKET_INDEX_SUPPORTED_ADDRESS_TYPE_OPTIONS_SOLD_CASES.includes(value)
  );

export const MARKET_INDEX_ADDRESS_TYPES_OPTIONS_OPEN_CASES: AddressTypeOption[] =
  caseStatisticsAddressTypes.filter(({ value }) =>
    MARKET_INDEX_SUPPORTED_ADDRESS_TYPE_OPTIONS_OPEN_CASES.includes(value)
  );

export const MARKET_INDEX_DEFAULT_ADDRESS_TYPES_SOLD_CASES: CaseStatisticAddressType[] =
  [CaseStatisticAddressType.VillaAndTerracedHouse];

export const MARKET_INDEX_DEFAULT_ADDRESS_TYPES_OPEN_CASES: CaseStatisticAddressType[] =
  [CaseStatisticAddressType.Villa];

export const MARKET_INDEX_DEFAULT_PARAMETER_SOLD_CASES: CaseStatisticType =
  CaseStatisticType.SoldPerAreaPrice;

export const MARKET_INDEX_DEFAULT_PARAMETER_OPEN_CASES: CaseStatisticType =
  CaseStatisticType.PerAreaPrice;

export const MARKET_INDEX_SUPPORTED_ZIP_CODES: number[][] = [
  // Villa/Terraced house (aka villa/raekkehus)
  [
    8700, 8600, 6000, 4000, 7100, 4700, 7400, 7000, 8800, 5700, 7500, 2650,
    2670, 4600, 9000, 9800, 3400, 4200, 2770, 4100, 4300, 6710, 8660, 2300,
    4800, 6100, 5500, 3700, 7700, 5250, 6800, 6400, 6200, 9900, 2610, 2791,
    2700, 8920, 2680, 4400, 3600, 8300, 4690, 3460, 9500, 8240, 9700, 8270,
    2920, 3000, 2840, 7800, 3650, 3200, 6700, 2800, 5270, 5600, 2820, 3300,
    3500, 8464, 5000, 3450, 5260, 7430, 5210, 6600, 2860, 5800, 8500, 2900,
    4180, 2500, 4900, 8382, 8960, 7080, 2630, 2750, 5230, 2970, 7120, 9400,
    2830, 8370, 2600, 9530, 4760, 2730, 6950, 9600, 6740, 6760, 3660, 9440,
    3060, 3390, 9240, 8260, 7200, 7600, 4040, 2640, 9990, 5750, 4220, 6900,
    7620, 9230, 2690, 9310, 3250, 3480, 2880, 9200, 8400, 6500, 8930, 9300,
    2950, 3520, 2720, 8680, 3550, 9210, 8520, 6715, 2605, 7900, 8850, 5450,
    9560, 8250, 3230, 2740, 5792, 6705, 7323, 8210, 8620, 8541, 4780, 8450,
    6230, 8722, 8381, 2765, 8940, 4684,
  ],

  // Condo (aka ejerlejlighed)
  [
    2300, 8000, 2100, 2000, 9000, 1000, 2200, 2500, 1500, 2450, 5000, 1800,
    4000, 2400, 2720, 2630, 2610, 2800, 2860, 3000, 2920, 6700, 2900, 9400,
    2700, 8200, 3400, 7100, 2650, 4600, 4700, 2770, 2820, 2600, 2150, 7400,
    8700, 2880, 3460, 8240, 2830, 2665, 8900, 8260, 2635, 2870, 2970, 3050,
    6000, 3520, 8600, 2840, 4200, 2680, 8660, 4300, 8800, 8230, 7000, 8920,
    1400,
  ],

  // Holiday house (aka fritidsbolig)
  [
    4500, 8400, 4873, 3630, 3250, 3210, 6950, 6857, 9370, 6960, 4573, 9480,
    3300, 9560, 4736, 4200, 4560, 4583, 9492, 4540, 3100, 6720, 4300, 8585,
    4970, 8420, 7130, 5450, 8300, 6893, 3230, 6990, 3120, 3370, 7700, 6792,
    4400, 3310, 4070, 4050, 3390, 6854, 6830, 4581, 3220, 9800, 4593, 6100,
    3730, 4305,
  ],
];

export const MARKET_INDEX_ADDRESS_TYPES_ZIP_CODES_ASSOCIATION: Record<
  string,
  number[]
> = {
  // Sold Cases
  [CaseStatisticAddressType.VillaAndTerracedHouse]:
    MARKET_INDEX_SUPPORTED_ZIP_CODES[0],
  // Open Cases
  [CaseStatisticAddressType.Villa]: MARKET_INDEX_SUPPORTED_ZIP_CODES[0],
  [CaseStatisticAddressType.TerracedHouse]: MARKET_INDEX_SUPPORTED_ZIP_CODES[0],
  // Both Sold and Open Cases
  [CaseStatisticAddressType.Condo]: MARKET_INDEX_SUPPORTED_ZIP_CODES[1],
  [CaseStatisticAddressType.HolidayHouse]: MARKET_INDEX_SUPPORTED_ZIP_CODES[2],
};

export const MARKET_INDEX_ZIP_CODES_ADDRESS_TYPES_ASSOCIATION: Record<
  number,
  string[]
> = Object.entries(MARKET_INDEX_ADDRESS_TYPES_ZIP_CODES_ASSOCIATION).reduce(
  (acc, [addressType, zipCodes]) => {
    zipCodes.forEach((zipCode) => {
      if (!acc[zipCode]) {
        acc[zipCode] = [];
      }

      acc[zipCode].push(addressType);
    });

    return acc;
  },
  {} as Record<number, string[]>
);

export const getZipCodeSupportedAddressTypes = (
  zipCode: number
): CaseStatisticAddressType[] => {
  return MARKET_INDEX_ZIP_CODES_ADDRESS_TYPES_ASSOCIATION[
    zipCode
  ] as unknown[] as CaseStatisticAddressType[];
};

export const isZipCodeSupportedByAddressType = (
  zipCode: number,
  addressType: CaseStatisticAddressType
): boolean => {
  const addressTypes = getZipCodeSupportedAddressTypes(zipCode);

  return (addressTypes ?? []).includes(addressType);
};
