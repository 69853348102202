export const reverseRecord = <T extends PropertyKey, U extends PropertyKey>(
  input: Record<T, U>
) => {
  return Object.entries<U>(input).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [value]: key,
    }),
    {} as Record<U, T>
  );
};
