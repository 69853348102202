import {
  CaseStatisticAddressType,
  CaseStatisticLocationType,
  CaseStatisticType,
} from '@boligsiden/app-api-client';

export enum MarketIndexScope {
  SoldCases = 'sold',
  OpenCases = 'open',
}

export interface StatisticDataPoint {
  type: CaseStatisticType;
  location: string;
  addressType: CaseStatisticAddressType;
  value: number;
  period: string;
}

export interface StatisticDataRow {
  location: string;
  addressType: string;
  monthlyChange: number;
  yearlyChange: number;
}

export interface StatisticData {
  chart: StatisticDataPoint[];
  table?: StatisticDataRow[];
}

export interface StatisticLocation {
  locationType: CaseStatisticLocationType;
  locationName: string;
  locationSlug?: string;
}

export interface MarketIndexStatisticFilters extends StatisticLocation {
  statScope: MarketIndexScope;
  statType: CaseStatisticType;
  addressType: CaseStatisticAddressType;
}
