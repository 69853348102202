import {
  Address as ApiAddress,
  AddressesApi,
} from '@boligsiden/app-api-client';
import { Component, h, Listen, Prop, State, Watch } from '@stencil/core';
import {
  AddressCardBreakpoint,
  createConfiguration,
  getAddressCardBreakpoint,
} from '../../../utils';

@Component({
  tag: 'bsdk-address',
  styleUrl: 'address.css',
  shadow: true,
})
export class Address {
  /**
   * gstkvhx
   */
  @Prop() gstkvhx: string;

  @State()
  address: ApiAddress;

  @State()
  error: boolean = false;

  @Watch('gstkvhx')
  private async getAddress(): Promise<Address> {
    this.error = false;
    const api = new AddressesApi(createConfiguration());
    if (this.gstkvhx && this.gstkvhx.length === 19) {
      const address = await api
        .addressGstkvhx({
          gstkvhx: this.gstkvhx,
        })
        .catch((error) => {
          console.error(error);
          this.error = true;
          return null;
        });
      const data = await address?.data;
      this.address = data;
      this.error = !data;
    }
    return null;
  }

  connectedCallback() {
    this.getAddress();
  }

  ref: HTMLBsdkAddressCardElement;

  @State()
  breakpoint: AddressCardBreakpoint;

  @Listen('resize', { target: 'window' })
  handleResize() {
    if (this.ref?.offsetWidth) {
      this.breakpoint = getAddressCardBreakpoint(this.ref.offsetWidth);
    }
  }

  componentDidRender() {
    this.handleResize();
  }

  render() {
    if (!this.address)
      return (
        <div class="flex h-full items-center justify-center font-sans">
          Boligvisningsfejl.
          <br />
          <a href="https://www.boligsiden.dk/information/kontakt-boligsiden">
            Kontakt Boligsiden
          </a>
          &nbsp; for hjælp til at løse fejlen.
        </div>
      );
    return (
      <bsdk-address-card
        ref={(el) => (this.ref = el)}
        address={this.address}
        breakpoint={this.breakpoint}
      ></bsdk-address-card>
    );
  }
}
