export const LOCATION_TYPES_MAP: Record<string, string> = {
  // DA => EN
  landsdel: 'provinces',
  kommune: 'municipalities',
  by: 'cities',
  vej: 'roads',
  sted: 'places',
  postnummer: 'zipCodes',
  omraade: 'customAreas',
  // EN => DA
  provinces: 'landsdel',
  municipalities: 'kommune',
  cities: 'by',
  roads: 'vej',
  places: 'sted',
  zipCodes: 'postnummer',
  customAreas: 'omraade',
};

export const TAG_TYPES_MAP: Record<string, string> = {
  ...LOCATION_TYPES_MAP,
  radius: 'radius',
  polygon: 'polygon',
};
