import { EnergyLabel as ApiEnergyLabel } from '@boligsiden/app-api-client';
import { Component, h, Prop } from '@stencil/core';
import * as EnergyLabels from '../../../assets/energy-labels';

const energyLabelMap = {
  [ApiEnergyLabel.A]: EnergyLabels.A,
  [ApiEnergyLabel.A1]: EnergyLabels.A1,
  [ApiEnergyLabel.A2]: EnergyLabels.A2,
  [ApiEnergyLabel.A2010]: EnergyLabels.A2010,
  [ApiEnergyLabel.A2015]: EnergyLabels.A2015,
  [ApiEnergyLabel.A2020]: EnergyLabels.A2020,
  [ApiEnergyLabel.B]: EnergyLabels.B,
  [ApiEnergyLabel.C]: EnergyLabels.C,
  [ApiEnergyLabel.D]: EnergyLabels.D,
  [ApiEnergyLabel.E]: EnergyLabels.E,
  [ApiEnergyLabel.F]: EnergyLabels.F,
  [ApiEnergyLabel.G]: EnergyLabels.G,
};

@Component({
  tag: 'bsdk-energy-label',
  styleUrl: 'energy-label.css',
  shadow: true,
})
export class EnergyLabel {
  @Prop() energyLabel: ApiEnergyLabel | null;
  @Prop() BBRVal: string;
  @Prop() clazz = '';
  @Prop() size: 'xs' | 'sm' | 'md' | 'lg' = 'lg';
  @Prop() link = false;

  render() {
    const normalizedEnergyLabel = `${this.energyLabel ?? ''}`.toUpperCase();
    const EnergyLabelIcon =
      normalizedEnergyLabel && energyLabelMap[normalizedEnergyLabel]
        ? energyLabelMap[normalizedEnergyLabel]
        : EnergyLabels.None;
    const icon = (
      <div
        innerHTML={EnergyLabelIcon}
        title={
          !normalizedEnergyLabel
            ? 'Intet energimærke'
            : 'Energimærke ' + normalizedEnergyLabel
        }
        class={{
          'w-5 h-5': this.size === 'xs',
          'w-7 h-7': this.size === 'sm',
          'w-8 h-8': this.size === 'md',
          'w-10 h-10': this.size === 'lg',
          [this.clazz]: this.link === false,
        }}
        onClick={(e) => e.stopPropagation()}
      />
    );

    if (this.link === false) {
      return icon;
    }

    if (!this.energyLabel) {
      // TODO(if): no energy label should have a popover with explanation
      return icon;
    }

    const href = `https://sparenergi.dk/forbruger/vaerktoejer/find-dit-energimaerke?bbrval=${this.BBRVal}`;
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={'Energimærke ' + this.energyLabel}
        class={this.clazz}
      >
        {icon}
      </a>
    );
  }
}
