import { ImageResource } from '@boligsiden/app-api-client';
import { caseImagePlaceholder, caseImageSizes } from '../constants';
import { filterImagesBySize } from './utils';

export enum DeviceType {
  Mobile,
  Tablet,
  Desktop,
}

export function toPresentationPageImages(
  images: ImageResource[],
  deviceType = DeviceType.Desktop
): ImageResource[] {
  const size =
    deviceType === DeviceType.Mobile
      ? caseImageSizesMap['600x400']
      : caseImageSizesMap['1440x960'];

  return filterImagesBySize(images, size, caseImagePlaceholder);
}

export function toHighlightedCasesImages(
  images: ImageResource[],
  deviceType = DeviceType.Desktop
): ImageResource[] {
  const size =
    deviceType === DeviceType.Mobile
      ? caseImageSizesMap['300x200']
      : caseImageSizesMap['600x600'];

  return filterImagesBySize(images, size, caseImagePlaceholder);
}

export function toCaseCardImages(
  images: ImageResource[],
  deviceType = DeviceType.Desktop
): ImageResource[] {
  const size = {
    [DeviceType.Mobile]: caseImageSizesMap['300x200'],
    [DeviceType.Tablet]: caseImageSizesMap['600x600'],
    [DeviceType.Desktop]: caseImageSizesMap['600x400'],
  }[deviceType];

  return filterImagesBySize(images, size, caseImagePlaceholder);
}

export function toCaseExcerptImages(
  images: ImageResource[],
  deviceType = DeviceType.Desktop
): ImageResource[] {
  const size =
    deviceType === DeviceType.Desktop
      ? caseImageSizesMap['300x200']
      : caseImageSizesMap['100x80'];

  return filterImagesBySize(images, size, caseImagePlaceholder);
}

const caseImageSizesMap = {
  '100x80': caseImageSizes[0],
  '143x200': caseImageSizes[1],
  '300x200': caseImageSizes[2],
  '600x400': caseImageSizes[3],
  '600x600': caseImageSizes[4],
  '1440x960': caseImageSizes[5],
};
