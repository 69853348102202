import {
  ImageResource,
  ImageSource,
  ImageSize,
} from '@boligsiden/app-api-client';

export function filterImagesBySize(
  images: ImageResource[],
  size: ImageSize,
  placeholder: string
): ImageResource[] {
  const isSameSize = (s: ImageSize): boolean =>
    s.width === size.width && s.height === size.height;

  const filteredImages = (images || []).map((imageResource: ImageResource) => ({
    imageSources: imageResource?.imageSources.filter(
      (imageSource: ImageSource) => isSameSize(imageSource.size)
    ),
  }));

  const hasImagesForSize =
    filteredImages.length > 0 &&
    filteredImages.every(
      (imageResource: ImageResource) => imageResource.imageSources?.length > 0
    );

  if (hasImagesForSize) {
    return filteredImages;
  }

  return [
    {
      imageSources: [
        {
          url: placeholder,
          size,
        } as ImageSource,
      ],
    } as ImageResource,
  ];
}
