export const MAP_CONSTANTS = {
  MAX_RADIUS_METERS: 250_000,
  MAX_POLYGON_POINTS: 24,

  MAX_PINS_ON_MAP: 500,

  RADIUS_VIEW_PADDING_Y: 10,
  RADIUS_VIEW_PADDING_X: 50,

  POLYGON_VIEW_PADDING_Y: 100,
  POLYGON_VIEW_PADDING_X: 250,

  POPUP_PADDING: {
    TOP: 120,
    BOTTOM: 50,
    LEFT: 20,
    RIGHT: 20,
  },

  // Used in URL filters
  // http://wiki.gis.com/wiki/index.php/Decimal_degrees
  DECIMAL_PRECISION: 6,

  DEFAULT_VIEW_STATE: {
    latitude: 56.23675,
    longitude: 11.55529,
    zoom: 6.19559,
  },
};

export enum MapToolbarWidgetType {
  Cadastre = 'cadastre',
  SoldHomes = 'soldHomes',
  SoilPollution = 'soilPollution',
  Transport = 'transport',
  Flood = 'flood',
  Area = 'area',
  SunPath = 'sunPath',
}
