declare const window: any;
export function getFromDataLayer(paramName: string): string | undefined {
  if (typeof window === 'undefined' || !window.google_tag_manager) {
    return;
  }

  const dataLayer = window.google_tag_manager[process.env.BS_GTM_ID].dataLayer;
  if (!dataLayer || !dataLayer.get) {
    return;
  }

  const gtagApiResult = dataLayer.get('gtagApiResult');
  if (!gtagApiResult) {
    return;
  }

  return gtagApiResult[paramName];
}
