export enum AddressCardBreakpoint {
  sm,
  md,
  lg,
}

/** Breakpoint used to determine the size of the address card
 * @param width - The width of the element
 * @returns The breakpoint
 * @example
 * ```ts
 * const breakpoint = getAddressCardBreakpoint(ref.offsetWidth);
 * ```
 * */
export const getAddressCardBreakpoint = (
  width: number
): AddressCardBreakpoint => {
  if (width < 768) {
    return AddressCardBreakpoint.sm;
  }
  if (width < 1200) {
    return AddressCardBreakpoint.md;
  }
  return AddressCardBreakpoint.lg;
};
