import { Address } from '@boligsiden/app-api-client';
import {
  buildCaseRedirectUrl,
  DeviceType,
  toCaseCardImages,
} from '@boligsiden/core';
import {
  formatAddressMultiLine,
  formatAddressType,
  formatCurrency,
} from '@boligsiden/ui-formatters';
import { Component, h, Prop } from '@stencil/core';
import {
  CreateClip,
  Expenses,
  PropertyOnMarket,
  Settings,
  SoldAddressIllustration,
  Squaremeters,
  YearOfConstrucion,
} from '../../../assets';

import {
  AddressCardBreakpoint,
  formatAddress,
  getGoogleAnalyticsParams,
} from '../../../utils';
@Component({
  tag: 'bsdk-address-card',
  styleUrl: 'address-card.css',
  shadow: true,
})
export class AddressCard {
  /**
   * address
   */
  @Prop()
  address: Address;

  @Prop()
  breakpoint: AddressCardBreakpoint;

  render() {
    if (!this.address) return null;
    const [addressCase] = this.address?.cases ?? [];
    const defaultImage = addressCase?.defaultImage;
    const imgUrl = defaultImage
      ? toCaseCardImages([defaultImage], DeviceType.Desktop)[0]?.imageSources[0]
          ?.url
      : `${process.env.BS_URL}/images/placeholders/case.jpeg`;
    const imgAltText = this.address && formatAddress(this.address);
    const [addressLineOne, addressLineTwo] = this.address
      ? formatAddressMultiLine(this.address)
      : [];
    const presentationHref = `${process.env.BS_URL}/adresse/${this.address.slug}`;
    const fullTextLabels = this.breakpoint > AddressCardBreakpoint.md;
    const timeOnMarket = addressCase?.timeOnMarket?.current?.days;
    const timeOnMarketText = `${timeOnMarket} dag${
      !timeOnMarket || timeOnMarket > 1 ? 'e' : ''
    }`;
    return (
      <div class="overflow-hidden relative shadow-card rounded-sm border border-gray-100 grid grid-cols-1 grid-rows-1 sm:grid-cols-12 bg-background font-sans">
        <div class="relative sm:min-h-56 sm:col-span-5 w-full">
          <a href={presentationHref} target="_blank">
            {!addressCase && (
              <div
                class="sold-address-illustration flex items-center justify-center bg-salmon-200 min-h-56 min-w-full p-4"
                innerHTML={SoldAddressIllustration}
              ></div>
            )}
            {addressCase && (
              <img
                class="object-cover min-h-56 aspect-[6/4]"
                src={imgUrl}
                alt={imgAltText}
              />
            )}
            <div class="absolute flex flex-row justify-between bg-black bg-opacity-50 text-white bottom-0 inset-x-0 z-10 px-4 py-2 md:py-3">
              <div class="flex flex-col overflow-hidden">
                <span class="font-black text-sm md:text-base whitespace-nowrap overflow-hidden text-ellipsis mr-2">
                  {addressLineOne}
                </span>
                <span class="font-normal text-xs whitespace-nowrap overflow-hidden text-ellipsis mr-2">
                  {addressLineTwo}
                </span>
              </div>
              {addressCase && (
                <div class="flex flex-row items-end">
                  <span class="font-normal text-xs pr-2 mr-2">
                    {formatAddressType(addressCase.addressType)}
                  </span>
                  <bsdk-energy-label
                    size="sm"
                    energy-label={addressCase?.energyLabel}
                  />
                </div>
              )}
            </div>
          </a>
        </div>
        {addressCase && (
          <div class="bg-white sm:col-span-7">
            <div class="h-full flex flex-col justify-between">
              <div class="flex flex-col pt-3 px-4 sm:pt-5 sm:px-5 flex-1">
                <div>
                  <span class="text-blue-900 font-bold text-lg sm:text-2xl pr-2">
                    {addressCase?.priceCash
                      ? formatCurrency(addressCase?.priceCash, 'standard')
                      : '-'}{' '}
                    kr.
                  </span>
                </div>
                <div class="text-sm font-normal text-gray-600">
                  M²-pris {formatCurrency(addressCase.perAreaPrice, 'standard')}{' '}
                  kr.
                </div>
              </div>

              <div class="py-4 pl-3 pr-0">
                <div class="grid grid-cols-8 grid-rows-2">
                  {/* squarementers */}
                  <div class="flex flex-row select-none mt-2 items-center col-span-2">
                    <div
                      class="w-4 h-4 mr-2 ml-2 flex justify-center items-center"
                      innerHTML={Squaremeters}
                    ></div>
                    <span class="text-gray-800 whitespace-nowrap border-dotted text-sm">
                      {fullTextLabels ? 'Areal' : ''}{' '}
                      {addressCase?.housingArea ?? 0} m²
                    </span>
                  </div>
                  {/* monthly expenses */}
                  <div class="flex flex-row select-none mt-2 items-center col-span-3">
                    <div
                      class="w-4 h-4 mr-2 ml-2 flex justify-center items-center"
                      innerHTML={Expenses}
                    ></div>
                    <span class="text-gray-800 whitespace-nowrap border-dotted text-sm">
                      {fullTextLabels ? 'Ejerudg.' : ''}{' '}
                      {addressCase?.monthlyExpense} kr./md
                    </span>
                  </div>
                  {/* lot area */}
                  <div class="flex flex-row select-none mt-2 items-center col-span-3">
                    <div
                      class="w-4 h-4 mr-2 ml-2 flex justify-center items-center"
                      innerHTML={Settings}
                    ></div>
                    <span class="text-gray-800 whitespace-nowrap border-dotted text-sm">
                      {fullTextLabels ? 'Grund.' : ''}{' '}
                      {addressCase?.lotArea ?? '-'} m²
                    </span>
                  </div>
                  {/* rooms */}
                  <div class="flex flex-row select-none mt-2 items-center col-span-2">
                    <div
                      class="w-4 h-4 mr-2 ml-2 flex justify-center items-center"
                      innerHTML={CreateClip}
                    ></div>
                    <span class="text-gray-800 whitespace-nowrap border-dotted text-sm">
                      {addressCase?.numberOfRooms ?? 0} Vær.
                    </span>
                  </div>
                  {/* time on market */}
                  <div class="flex flex-row select-none mt-2 items-center col-span-3">
                    <div
                      class="w-4 h-4 mr-2 ml-2 flex justify-center items-center"
                      innerHTML={PropertyOnMarket}
                    ></div>
                    <span class="text-gray-800 whitespace-nowrap border-dotted text-sm">
                      {fullTextLabels ? 'Liggetid' : ''} {timeOnMarketText}
                    </span>
                  </div>
                  {/* year of construction */}
                  <div class="flex flex-row select-none mt-2 items-center col-span-3">
                    <div
                      class="w-4 h-4 mr-2 ml-2 flex justify-center items-center"
                      innerHTML={YearOfConstrucion}
                    ></div>
                    <span class="text-gray-800 whitespace-nowrap border-dotted text-sm">
                      {fullTextLabels ? 'Opført' : ''}{' '}
                      {addressCase?.yearBuilt ?? '-'}
                    </span>
                  </div>
                </div>
              </div>
              <div class="flex flex-row border-t border-t-gray-100 justify-between">
                <div class="p-3 border-r w-44">
                  <a href={presentationHref} target="_blank">
                    <bsdk-action-button>Se bolig</bsdk-action-button>
                  </a>
                </div>
                <div class="flex grow justify-end items-center px-5">
                  <a
                    href={buildCaseRedirectUrl(
                      addressCase.caseID,
                      process.env.BS_URL,
                      getGoogleAnalyticsParams()
                    )}
                    target="_blank"
                    rel="nofollow"
                    class="flex flex-row justify-center items-center"
                  >
                    {addressCase.realtor?.realtorBranch?.logo && (
                      <img
                        src={
                          addressCase.realtor.realtorBranch.logo.imageSources[0]
                            .url
                        }
                        alt={addressCase.realtor.name}
                        width="60"
                        height="40"
                      />
                    )}
                    <span class="pl-4 text-sm sm:text-base text-center text-red">
                      Se hos mægler
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        {!addressCase && (
          <div class="flex flex-col justify-between bg-white sm:col-span-7">
            <div class="flex grow items-center justify-center p-8">
              <span class="not-for-sale text-white sm:text-2xl font-bold rotate-12 p-2 sm:p-4">
                Ikke til salg
              </span>
            </div>
            <div class="flex flex-row border-t border-t-gray-100 justify-start">
              <div class="p-3 border-r w-44">
                <a
                  href={`${process.env.BS_URL}${this.address._links.self.href}`}
                >
                  <bsdk-action-button>Se bolig</bsdk-action-button>
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
