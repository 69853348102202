import { AddressType } from '@boligsiden/app-api-client';

export const datafordelerUsername = 'VOOGOSMRWN';
export const datafordelerPassword = 'yOdWVAgMsd1x-dXttcpp';

export enum WaterHardnessValue {
  verySoft = 'Meget blødt',
  soft = 'Blødt',
  medium = 'Middel',
  hard = 'Hårdt',
  veryHard = 'Meget hårdt',
}

export const waterHardnessValue = [
  WaterHardnessValue.verySoft,
  WaterHardnessValue.soft,
  WaterHardnessValue.medium,
  WaterHardnessValue.hard,
  WaterHardnessValue.veryHard,
];

export enum RiskValues {
  veryLow = 'Meget lav',
  low = 'Lav',
  medium = 'Middel',
  high = 'Høj',
  veryHigh = 'Meget høj',
}

export const riskValues = [
  RiskValues.veryLow,
  RiskValues.low,
  RiskValues.medium,
  RiskValues.high,
  RiskValues.veryHigh,
];

export enum SearchCasesSortBy {
  Address = 'address',
  LivingArea = 'livingArea',
  LotArea = 'lotArea',
  Price = 'price',
  TimeOnMarket = 'timeOnMarket',
  PerAreaPrice = 'perAreaPrice',
  ZipCode = 'zipCode',
  NumRooms = 'numRooms',
  MonthlyExpense = 'monthlyExpense',
  PriceChange = 'priceChange',
  LastPriceDropAt = 'lastPriceDropAt',
  Distance = 'distance',
  Random = 'random',
}

export const defaultSearchCasesSortBy = SearchCasesSortBy.TimeOnMarket;

export enum SearchSoldAddressesSortBy {
  Address = 'address',
  Price = 'soldPrice',
  SaleDate = 'soldDate',
}

export const defaultSearchSoldAddressesSortBy =
  SearchSoldAddressesSortBy.SaleDate;

export enum SearchRentalsSortBy {
  Address = 'address',
  LivingArea = 'livingArea',
  LotArea = 'lotArea',
  Price = 'price',
  TimeOnMarket = 'timeOnMarket',
  PerAreaPrice = 'perAreaPrice',
  ZipCode = 'zipCode',
  NumRooms = 'numRooms',
  MonthlyExpense = 'monthlyExpense',
  PriceChange = 'priceChange',
}

export const defaultSearchRentalsSortBy = SearchRentalsSortBy.Address;

export enum SearchAddressesSortBy {
  Road = 'road',
  LivingArea = 'livingArea',
  LotArea = 'lotArea',
  Price = 'price',
  TimeOnMarket = 'timeOnMarket',
  PerAreaPrice = 'perAreaPrice',
  ZipCode = 'zipCode',
  PriceChange = 'priceChange',
  NumRooms = 'numRooms',
  MonthlyExpense = 'monthlyExpense',
  SoldPrice = 'soldPrice',
  SoldDate = 'soldDate',
}

export const searchUrlScopeNames = [
  'tilsalg',
  'solgte',
  'alle',
  'leje',
  'landbrug',
];

export const scopesMap: Map<string, string> = new Map([
  [searchUrlScopeNames[0], 'on_market'],
  [searchUrlScopeNames[1], 'sold'],
  [searchUrlScopeNames[2], 'all'],
  [searchUrlScopeNames[3], 'rent'],
  [searchUrlScopeNames[4], 'farm'],
  // Reverse mapping
  ['on_market', searchUrlScopeNames[0]],
  ['sold', searchUrlScopeNames[1]],
  ['all', searchUrlScopeNames[2]],
  ['rent', searchUrlScopeNames[3]],
  ['farm', searchUrlScopeNames[4]],
]);

export const locationPaths = {
  provinces: '/landsdel',
  municipalities: '/kommune',
  cities: '/by',
  zipCodes: '/postnummer',
  places: '/sted',
  roads: '/vej',
};

export const addressTypeClasses = {
  [AddressType.Villa]: 'text-red',
  [AddressType.TerracedHouse]: 'text-purple',
  [AddressType.Condo]: 'text-blue',
  [AddressType.HolidayHouse]: 'text-blue-300',
  [AddressType.Allotment]: 'text-red',
  [AddressType.Cooperative]: 'text-turquoise',
  [AddressType.Farm]: 'text-brown',
  [AddressType.FullYearPlot]: 'text-darkgreen',
  [AddressType.HolidayPlot]: 'text-green',
  [AddressType.VillaApartment]: 'text-salmon',
  [AddressType.CattleFarm]: 'text-red',
  [AddressType.PigFarm]: 'text-red',
  [AddressType.PlantFarm]: 'text-red',
  [AddressType.Forest]: 'text-red',
  [AddressType.HobbyFarm]: 'text-red',
  [AddressType.Special]: 'text-red',
  // TODO: missing => Lejebolig: 'text-yellow',
};

export const addressTypeIcons = {
  [AddressType.Villa]: '100_single',
  [AddressType.TerracedHouse]: '200_single',
  [AddressType.Condo]: '300_single',
  [AddressType.HolidayHouse]: '400_single',
  [AddressType.Allotment]: '100_single',
  [AddressType.Cooperative]: '500_single',
  [AddressType.Farm]: '600_single',
  [AddressType.FullYearPlot]: '700_single',
  [AddressType.HolidayPlot]: '800_single',
  [AddressType.VillaApartment]: '900_single',
  [AddressType.CattleFarm]: 'grey_single',
  [AddressType.PigFarm]: '900_single',
  [AddressType.PlantFarm]: '800_single',
  [AddressType.Forest]: '700_single',
  [AddressType.HobbyFarm]: '600_single',
  [AddressType.Special]: '500_single',
  // TODO: missing => Lejebolig: '1400_single',
};

export enum PresentationModalTabKeys {
  Map = 'map',
  Images = 'images',
}

export const caseImageSizes = [
  { width: 100, height: 80 },
  { width: 143, height: 118 },
  { width: 300, height: 200 },
  { width: 600, height: 400 },
  { width: 600, height: 600 },
  { width: 1440, height: 960 },
];

export const realtorImageSizes = [{ width: 1440, height: 960 }];

export const caseImagePlaceholder = '/images/placeholders/case.jpeg';

export const realtorImagePlaceholder = '/images/placeholders/case.jpeg';

export const calcMortgageMinimumDownPaymentRate = 0.05; // = 5%

export const defaultCaseMaxPrice = 25_000_000;

export const defaultRentalsMaxRent = 100_000;

export const defaultRentalsMaxDeposit = 70_000;

export const defaultCaseMaxHousingArea = 300;

export const defaultNumberOfRoomsMax = 10;

export const defaultLotAreaMax = 10_000;

export const defaultFarmLotAreaMax = 10_000_000; // 1000 ha (Hectares)

export const defaultYearBuiltFrom = 1900;
export const defaultYearBuiltTo = new Date().getFullYear();

export const defaultCostMax = 70_000;

export const defaultTimeOnMarketMax = 350;

export const defaultFloorMax = 6;

export const defaultLevelsMax = 4;

export const defaultYearSoldFrom = new Date().getFullYear() - 1;
export const defaultYearSoldTo = new Date().getFullYear();
export const minYearSoldFrom = 1992;
export const maxYearSoldTo = new Date().getFullYear();

export const defaultBasementAreaMax = 300;

export const defaultMonthlyExpenseMax = 100_000;

export const toastDuration = 3500;

export const searchCasesPerPage = 50;

export const searchCasesMaxPerPage = 1000;

export const energyLabels = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];

export * from './commercials';
export * from './custom-areas';
export * from './highlighted-cases';
export * from './map';
export * from './marketindex';
export * from './navigation';
export * from './predefined-search-options';
export * from './promotions';
export * from './statistics';
export * from './user-searches';
