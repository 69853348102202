import {
  Configuration,
  ConfigurationParameters,
} from '@boligsiden/app-api-client';

export function createConfiguration(): Configuration {
  return new Configuration({
    basePath: process.env.BS_APP_API_BASE_PATH,
    baseOptions: {
      headers: {
        'X-API-Key': process.env.BS_APP_API_BASE_PATH,
      },
    },
  } as ConfigurationParameters);
}
