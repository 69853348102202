import {
  AddressType as ApiAddressType,
  CaseStatisticAddressType as ApiCaseStatisticAddressType,
} from '@boligsiden/app-api-client';
import {
  AddressType,
  AddressTypeOption,
  AddressTypePlural,
  addressTypeSynonymMap,
  CaseStatisticAddressType,
  CaseStatisticAddressTypePlural,
} from '@boligsiden/core';
import { capitalize } from './string-formatters';

const mappings: [string, string][] = [
  [ApiAddressType.Villa, AddressType.Villa],
  [ApiAddressType.TerracedHouse, AddressType.TerracedHouse],
  [ApiAddressType.Condo, AddressType.Condo],
  [ApiAddressType.HolidayHouse, AddressType.HolidayHouse],
  [ApiAddressType.Allotment, AddressType.Allotment],
  [ApiAddressType.Cooperative, AddressType.Cooperative],
  [ApiAddressType.Farm, AddressType.Farm],
  [ApiAddressType.FullYearPlot, AddressType.FullYearPlot],
  [ApiAddressType.HolidayPlot, AddressType.HolidayPlot],
  [ApiAddressType.VillaApartment, AddressType.VillaApartment],
  [ApiAddressType.CattleFarm, AddressType.CattleFarm],
  [ApiAddressType.PigFarm, AddressType.PigFarm],
  [ApiAddressType.PlantFarm, AddressType.PlantFarm],
  [ApiAddressType.Forest, AddressType.Forest],
  // NOTE: due to merge of `Farm` and `HobbyFarm`, `HobbyFarm` is mapped to `Farm`
  [ApiAddressType.HobbyFarm, AddressType.Farm],
  [ApiAddressType.Special, AddressType.Special],
  [ApiAddressType.Houseboat, AddressType.Houseboat],
];

const mapping: Map<string, string> = new Map(mappings);

const statisticMapping = new Map([
  ...mappings,
  [
    ApiCaseStatisticAddressType.VillaAndTerracedHouse,
    CaseStatisticAddressType.VillaAndTerracedHouse,
  ],
]);

const pluralMappings: [string, string][] = [
  [AddressType.Villa, AddressTypePlural.Villa],
  [AddressType.TerracedHouse, AddressTypePlural.TerracedHouse],
  [AddressType.Condo, AddressTypePlural.Condo],
  [AddressType.HolidayHouse, AddressTypePlural.HolidayHouse],
  [AddressType.Allotment, AddressTypePlural.Allotment],
  [AddressType.Cooperative, AddressTypePlural.Cooperative],
  [AddressType.Farm, AddressTypePlural.Farm],
  [AddressType.FullYearPlot, AddressTypePlural.FullYearPlot],
  [AddressType.HolidayPlot, AddressTypePlural.HolidayPlot],
  [AddressType.VillaApartment, AddressTypePlural.VillaApartment],
  [AddressType.CattleFarm, AddressTypePlural.CattleFarm],
  [AddressType.PigFarm, AddressTypePlural.PigFarm],
  [AddressType.PlantFarm, AddressTypePlural.PlantFarm],
  [AddressType.Forest, AddressTypePlural.Forest],
  [AddressType.Special, AddressTypePlural.Special],
  [AddressType.Houseboat, AddressTypePlural.Houseboat],
];

const plurals: Map<string, string> = new Map(pluralMappings);

const statisticMappingPlural = new Map([
  ...pluralMappings,
  [
    CaseStatisticAddressType.VillaAndTerracedHouse,
    CaseStatisticAddressTypePlural.VillaAndTerracedHouse,
  ],
]);

export function formatAddressType(
  addressType: ApiAddressType | ApiCaseStatisticAddressType,
  casing: 'capitalize' | 'lower' = 'capitalize',
  plural = false,
  statisticsAddressType = false
): string {
  if (!addressType) {
    return '';
  }

  const addressTypeMapping = statisticsAddressType ? statisticMapping : mapping;
  const addressTypeMappingPlural = statisticsAddressType
    ? statisticMappingPlural
    : plurals;

  let result = addressTypeMapping.get(addressType) ?? '';

  if (plural) {
    result = addressTypeMappingPlural.get(result) ?? '';
  }

  if (casing === 'lower') {
    result = result.toLowerCase();
  }

  if (casing === 'capitalize') {
    result = capitalize(result);
  }

  return result;
}

export function formatAddressTypes(
  addressType: ApiAddressType,
  secondaryAddressType?: ApiAddressType
): string {
  if (!secondaryAddressType) return formatAddressType(addressType);

  return `${formatAddressType(addressType)}/${formatAddressType(
    secondaryAddressType
  )}`;
}

export function formatAddressTypeOptions(
  options: AddressTypeOption[],
  fallback = 'Boligtype'
): string {
  if (!!options && options.length > 1) {
    return `${options[0].text} +${options.length - 1}`;
  } else if (!!options && options.length === 1) {
    return options[0].text;
  }

  return fallback;
}

export function formatAddressTypeSynonym(
  addressType: ApiAddressType,
  casing: 'capitalize' | 'lower' = 'lower'
): string {
  const formatSynonym = (synonym: string | undefined): string =>
    casing === 'capitalize'
      ? capitalize(synonym || '')
      : (synonym || '').toLocaleLowerCase();

  return addressTypeSynonymMap.has(addressType)
    ? formatSynonym(addressTypeSynonymMap.get(addressType)?.plural)
    : formatAddressType(addressType, casing, true);
}
