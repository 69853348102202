/* tslint:disable */
/* eslint-disable */
/**
 * Boligsiden API
 * The Boligsiden API exposes various entities and concepts related to the Boligsiden domain
 *
 * The version of the OpenAPI document: 0.1.1
 * Contact: tech@boligsiden.dk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * See https://danmarksadresser.dk/om-adresser/supplerende-bynavn
 * @export
 * @interface AdditionalCityName
 */
export interface AdditionalCityName {
    /**
     * 
     * @type {number}
     * @memberof AdditionalCityName
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AdditionalCityName
     */
    name: string;
    /**
     * 
     * @type {GisPoint}
     * @memberof AdditionalCityName
     */
    coordinates: GisPoint;
    /**
     * 
     * @type {Set<number>}
     * @memberof AdditionalCityName
     */
    bbox: Set<number>;
    /**
     * URL safe string
     * @type {string}
     * @memberof AdditionalCityName
     */
    slug: string;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {ResourceLinks}
     * @memberof Address
     */
    _links: ResourceLinks;
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof Address
     */
    addressID: string;
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof Address
     */
    entryAddressID?: string;
    /**
     * URL safe string
     * @type {string}
     * @memberof Address
     */
    slug: string;
    /**
     * Deprecated; prefer `address.road` object instead.
     * @type {string}
     * @memberof Address
     * @deprecated
     */
    roadName: string;
    /**
     * 
     * @type {AddressType}
     * @memberof Address
     */
    addressType?: AddressType;
    /**
     * If `address.isPublic` is `false` then `address.road` will be a `CustomRoad`. In all other cases, `address.road` will be a `Road`. 
     * @type {Road | CustomRoad}
     * @memberof Address
     */
    road: Road | CustomRoad;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    houseNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    floor?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    door?: string;
    /**
     * Deprecated; prefer `address.zip` object instead.
     * @type {number}
     * @memberof Address
     * @deprecated
     */
    zipCode: number;
    /**
     * 
     * @type {ZipCode}
     * @memberof Address
     */
    zip: ZipCode;
    /**
     * The city to use as part of the formal address
     * @type {string}
     * @memberof Address
     */
    cityName: string;
    /**
     * 
     * @type {AdditionalCityName}
     * @memberof Address
     */
    place?: AdditionalCityName;
    /**
     * Deprecated; prefer `address.place` object instead.
     * @type {string}
     * @memberof Address
     * @deprecated
     */
    placeName?: string;
    /**
     * 
     * @type {Municipality}
     * @memberof Address
     */
    municipality: Municipality;
    /**
     * 
     * @type {GisPoint}
     * @memberof Address
     */
    coordinates?: GisPoint;
    /**
     * 
     * @type {AddressDaysOnMarket}
     * @memberof Address
     * @deprecated
     */
    daysOnMarket: AddressDaysOnMarket;
    /**
     * 
     * @type {Array<Building>}
     * @memberof Address
     */
    buildings?: Array<Building>;
    /**
     * 
     * @type {Array<Case>}
     * @memberof Address
     */
    cases?: Array<Case>;
    /**
     * 
     * @type {Array<Registration>}
     * @memberof Address
     */
    registrations?: Array<Registration>;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    propertyNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    gstkvhx?: string;
    /**
     * 
     * @type {EnergyLabelImprovement}
     * @memberof Address
     * @deprecated
     */
    energyLabelImprovement?: EnergyLabelImprovement;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    livingArea?: number;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    weightedArea?: number;
    /**
     * Whether or not the address exists in _Danmarks Adresseregister_ (DAR). See https://danmarksadresser.dk/om-adresser/danmarks-adresseregister-dar 
     * @type {boolean}
     * @memberof Address
     */
    isPublic: boolean;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    latestValuation?: number;
    /**
     * 
     * @type {Province}
     * @memberof Address
     */
    province: Province;
    /**
     * 
     * @type {City}
     * @memberof Address
     */
    city: City;
    /**
     * 
     * @type {AddressLatestSoldCaseDescription}
     * @memberof Address
     */
    latestSoldCaseDescription?: AddressLatestSoldCaseDescription;
}
/**
 * 
 * @export
 * @interface AddressAllOf
 */
export interface AddressAllOf {
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof AddressAllOf
     */
    addressID: string;
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof AddressAllOf
     */
    entryAddressID?: string;
    /**
     * URL safe string
     * @type {string}
     * @memberof AddressAllOf
     */
    slug: string;
    /**
     * Deprecated; prefer `address.road` object instead.
     * @type {string}
     * @memberof AddressAllOf
     * @deprecated
     */
    roadName: string;
    /**
     * 
     * @type {AddressType}
     * @memberof AddressAllOf
     */
    addressType?: AddressType;
    /**
     * If `address.isPublic` is `false` then `address.road` will be a `CustomRoad`. In all other cases, `address.road` will be a `Road`. 
     * @type {Road | CustomRoad}
     * @memberof AddressAllOf
     */
    road: Road | CustomRoad;
    /**
     * 
     * @type {string}
     * @memberof AddressAllOf
     */
    houseNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressAllOf
     */
    floor?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressAllOf
     */
    door?: string;
    /**
     * Deprecated; prefer `address.zip` object instead.
     * @type {number}
     * @memberof AddressAllOf
     * @deprecated
     */
    zipCode: number;
    /**
     * 
     * @type {ZipCode}
     * @memberof AddressAllOf
     */
    zip: ZipCode;
    /**
     * The city to use as part of the formal address
     * @type {string}
     * @memberof AddressAllOf
     */
    cityName: string;
    /**
     * 
     * @type {AdditionalCityName}
     * @memberof AddressAllOf
     */
    place?: AdditionalCityName;
    /**
     * Deprecated; prefer `address.place` object instead.
     * @type {string}
     * @memberof AddressAllOf
     * @deprecated
     */
    placeName?: string;
    /**
     * 
     * @type {Municipality}
     * @memberof AddressAllOf
     */
    municipality: Municipality;
    /**
     * 
     * @type {GisPoint}
     * @memberof AddressAllOf
     */
    coordinates?: GisPoint;
    /**
     * 
     * @type {AddressDaysOnMarket}
     * @memberof AddressAllOf
     * @deprecated
     */
    daysOnMarket: AddressDaysOnMarket;
    /**
     * 
     * @type {Array<Building>}
     * @memberof AddressAllOf
     */
    buildings?: Array<Building>;
    /**
     * 
     * @type {Array<Case>}
     * @memberof AddressAllOf
     */
    cases?: Array<Case>;
    /**
     * 
     * @type {Array<Registration>}
     * @memberof AddressAllOf
     */
    registrations?: Array<Registration>;
    /**
     * 
     * @type {number}
     * @memberof AddressAllOf
     */
    propertyNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressAllOf
     */
    gstkvhx?: string;
    /**
     * 
     * @type {EnergyLabelImprovement}
     * @memberof AddressAllOf
     * @deprecated
     */
    energyLabelImprovement?: EnergyLabelImprovement;
    /**
     * 
     * @type {number}
     * @memberof AddressAllOf
     */
    livingArea?: number;
    /**
     * 
     * @type {number}
     * @memberof AddressAllOf
     */
    weightedArea?: number;
    /**
     * Whether or not the address exists in _Danmarks Adresseregister_ (DAR). See https://danmarksadresser.dk/om-adresser/danmarks-adresseregister-dar 
     * @type {boolean}
     * @memberof AddressAllOf
     */
    isPublic: boolean;
    /**
     * 
     * @type {number}
     * @memberof AddressAllOf
     */
    latestValuation?: number;
    /**
     * 
     * @type {Province}
     * @memberof AddressAllOf
     */
    province: Province;
    /**
     * 
     * @type {City}
     * @memberof AddressAllOf
     */
    city: City;
    /**
     * 
     * @type {AddressLatestSoldCaseDescription}
     * @memberof AddressAllOf
     */
    latestSoldCaseDescription?: AddressLatestSoldCaseDescription;
}
/**
 * Deprecated; prefer `case.timeOnMarket.total` instead.
 * @export
 * @interface AddressDaysOnMarket
 */
export interface AddressDaysOnMarket {
    /**
     * 
     * @type {number}
     * @memberof AddressDaysOnMarket
     * @deprecated
     */
    total?: number;
    /**
     * 
     * @type {Array<AddressRealtorDaysOnMarket>}
     * @memberof AddressDaysOnMarket
     * @deprecated
     */
    realtors: Array<AddressRealtorDaysOnMarket>;
}
/**
 * 
 * @export
 * @interface AddressLatestSoldCaseDescription
 */
export interface AddressLatestSoldCaseDescription {
    /**
     * 
     * @type {string}
     * @memberof AddressLatestSoldCaseDescription
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressLatestSoldCaseDescription
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressLatestSoldCaseDescription
     */
    body?: string;
}
/**
 * 
 * @export
 * @interface AddressPin
 */
export interface AddressPin {
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof AddressPin
     */
    addressID: string;
    /**
     * 
     * @type {AddressType}
     * @memberof AddressPin
     */
    addressType?: AddressType;
    /**
     * 
     * @type {GisPoint}
     * @memberof AddressPin
     */
    coordinates: GisPoint;
}
/**
 * 
 * @export
 * @interface AddressRealtorDaysOnMarket
 */
export interface AddressRealtorDaysOnMarket {
    /**
     * 
     * @type {string}
     * @memberof AddressRealtorDaysOnMarket
     * @deprecated
     */
    realtorId: string;
    /**
     * 
     * @type {string}
     * @memberof AddressRealtorDaysOnMarket
     * @deprecated
     */
    realtorName?: string;
    /**
     * 
     * @type {number}
     * @memberof AddressRealtorDaysOnMarket
     * @deprecated
     */
    daysOnMarket: number;
}
/**
 * 
 * @export
 * @interface AddressTimelineEvent
 */
export interface AddressTimelineEvent {
    /**
     * 
     * @type {string}
     * @memberof AddressTimelineEvent
     */
    at: string;
    /**
     * 
     * @type {string}
     * @memberof AddressTimelineEvent
     */
    type: AddressTimelineEventTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AddressTimelineEvent
     */
    price?: number;
    /**
     * 
     * @type {object}
     * @memberof AddressTimelineEvent
     */
    aux?: object;
}

/**
    * @export
    * @enum {string}
    */
export enum AddressTimelineEventTypeEnum {
    Open = 'open',
    Closed = 'closed',
    Sold = 'sold',
    Built = 'built',
    Renovated = 'renovated',
    PriceChange = 'price_change'
}

/**
 * The type of an Address
 * @export
 * @enum {string}
 */
export enum AddressType {
    Villa = 'villa',
    TerracedHouse = 'terraced house',
    Condo = 'condo',
    HolidayHouse = 'holiday house',
    Allotment = 'allotment',
    Cooperative = 'cooperative',
    Farm = 'farm',
    FullYearPlot = 'full year plot',
    HolidayPlot = 'holiday plot',
    VillaApartment = 'villa apartment',
    CattleFarm = 'cattle farm',
    PigFarm = 'pig farm',
    PlantFarm = 'plant farm',
    Forest = 'forest',
    HobbyFarm = 'hobby farm',
    Special = 'special',
    Houseboat = 'houseboat'
}

/**
 * 
 * @export
 * @interface Bank
 */
export interface Bank {
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    logo: string;
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    redirectLinkUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    redirectLinkLabel: string;
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    counsellingLinkUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    counsellingLinkLabel: string;
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    advisorLinkUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    advisorLinkLabel: string | null;
    /**
     * 
     * @type {number}
     * @memberof Bank
     */
    downPaymentRate: number;
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    advisorBody: string | null;
}
/**
 * 
 * @export
 * @interface Building
 */
export interface Building {
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    buildingName?: string;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    buildingNumber: string;
    /**
     * The year that the building was last renovated
     * @type {number}
     * @memberof Building
     */
    yearRenovated?: number;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    yearBuilt?: number;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    housingArea?: number;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    businessArea?: number;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    otherArea?: number;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    totalArea?: number;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    numberOfFloors?: number;
    /**
     * See https://teknik.bbr.dk/kodelister/0/1/0/Varmeinstallation
     * @type {string}
     * @memberof Building
     */
    heatingInstallation?: string;
    /**
     * See https://teknik.bbr.dk/kodelister/0/1/0/Varmeinstallation
     * @type {string}
     * @memberof Building
     */
    supplementaryHeating?: string;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    numberOfKitchens?: number;
    /**
     * See https://instruks.bbr.dk/koekkenforhold/0/30
     * @type {string}
     * @memberof Building
     */
    kitchenCondition?: string;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    numberOfToilets?: number;
    /**
     * See https://instruks.bbr.dk/toiletforhold/0/30
     * @type {string}
     * @memberof Building
     */
    toiletCondition?: string;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    numberOfBathrooms?: number;
    /**
     * See https://instruks.bbr.dk/badeforhold/0/30
     * @type {string}
     * @memberof Building
     */
    bathroomCondition?: string;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    basementArea?: number;
    /**
     * See https://teknik.bbr.dk/kodelister/0/1/0/YdervaeggenesMateriale
     * @type {string}
     * @memberof Building
     */
    externalWallMaterial?: string;
    /**
     * See https://teknik.bbr.dk/kodelister/0/1/0/YdervaeggenesMateriale
     * @type {string}
     * @memberof Building
     */
    supplementaryExternalWallMaterial?: string;
    /**
     * See https://teknik.bbr.dk/kodelister/0/1/0/Tagdaekningsmateriale
     * @type {string}
     * @memberof Building
     */
    roofingMaterial?: string;
    /**
     * See https://teknik.bbr.dk/kodelister/0/1/0/Tagdaekningsmateriale
     * @type {string}
     * @memberof Building
     */
    supplementaryRoofingMaterial?: string;
    /**
     * See https://teknik.bbr.dk/kodelister/0/1/0/AsbestholdigtMateriale
     * @type {string}
     * @memberof Building
     */
    asbestosContainingMaterial?: string;
}
/**
 * 
 * @export
 * @interface Case
 */
export interface Case {
    /**
     * 
     * @type {ResourceLinks}
     * @memberof Case
     */
    _links: ResourceLinks;
    /**
     * 
     * @type {Realtor}
     * @memberof Case
     */
    realtor: Realtor;
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof Case
     */
    caseID: string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    providerCaseID: string;
    /**
     * URL safe string
     * @type {string}
     * @memberof Case
     */
    slug: string;
    /**
     * 
     * @type {AddressType}
     * @memberof Case
     */
    addressType: AddressType;
    /**
     * The definition of price cash differs based on the distinction of the case: * For real estate cases, price cash is the full purchase price of the case * For cooperative cases, price cash is the deposit (indskud in Danish) * For rental cases, price cash is the deposit 
     * @type {number}
     * @memberof Case
     */
    priceCash: number;
    /**
     * The price change in percent since the case came into market, an increase in price will lead to a positive number, and a decrease in price will lead to a negative number 
     * @type {number}
     * @memberof Case
     */
    priceChangePercentage?: number;
    /**
     * The definition of monthly expense differs based on the distinction of the case: * For real estate cases, monthly expense is the ownership cost (ejerudgift in Danish) * For cooperative cases, monthly expense is the housing allowance (boligydelse in Danish) * For rental cases, monthly expense is the rent 
     * @type {number}
     * @memberof Case
     */
    monthlyExpense?: number;
    /**
     * 
     * @type {Cooperative}
     * @memberof Case
     */
    cooperative?: Cooperative;
    /**
     * 
     * @type {RealEstate}
     * @memberof Case
     */
    realEstate?: RealEstate;
    /**
     * 
     * @type {Rental}
     * @memberof Case
     */
    rental?: Rental;
    /**
     * url to the case on the realtor homepage
     * @type {string}
     * @memberof Case
     */
    caseUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    descriptionBody?: string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    descriptionTitle?: string;
    /**
     * 
     * @type {OpenHouse}
     * @memberof Case
     */
    nextOpenHouse?: OpenHouse;
    /**
     * 
     * @type {Address}
     * @memberof Case
     */
    address: Address;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    numberOfFloors?: number;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    numberOfRooms?: number;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    yearBuilt?: number;
    /**
     * 
     * @type {EnergyLabel}
     * @memberof Case
     */
    energyLabel?: EnergyLabel;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    lotArea?: number;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    housingArea?: number;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    basementArea?: number;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    weightedArea?: number;
    /**
     * 
     * @type {ImageResource}
     * @memberof Case
     */
    defaultImage?: ImageResource;
    /**
     * 
     * @type {Array<ImageResource>}
     * @memberof Case
     */
    images?: Array<ImageResource>;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    totalClickCount: number;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    totalFavourites: number;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    pageViews: number;
    /**
     * Deprecated; prefer `case.timeOnMarket.current.days` instead.
     * @type {number}
     * @memberof Case
     * @deprecated
     */
    daysOnMarket?: number;
    /**
     * 
     * @type {CaseTimeOnMarket}
     * @memberof Case
     */
    timeOnMarket: CaseTimeOnMarket;
    /**
     * 
     * @type {boolean}
     * @memberof Case
     */
    highlighted: boolean;
    /**
     * 
     * @type {CaseStatus}
     * @memberof Case
     */
    status: CaseStatus;
    /**
     * 
     * @type {GisPoint}
     * @memberof Case
     */
    coordinates: GisPoint;
    /**
     * Per area price is the case\'s price relative to the case\'s size in square meters.
     * @type {number}
     * @memberof Case
     */
    perAreaPrice?: number;
    /**
     * 
     * @type {CaseDistinction}
     * @memberof Case
     */
    distinction: CaseDistinction;
    /**
     * 
     * @type {AddressType}
     * @memberof Case
     */
    secondaryAddressType?: AddressType;
    /**
     * 
     * @type {CaseUtilitiesConnectionFee}
     * @memberof Case
     */
    utilitiesConnectionFee: CaseUtilitiesConnectionFee;
}
/**
 * 
 * @export
 * @interface CaseAllOf
 */
export interface CaseAllOf {
    /**
     * 
     * @type {Realtor}
     * @memberof CaseAllOf
     */
    realtor: Realtor;
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof CaseAllOf
     */
    caseID: string;
    /**
     * 
     * @type {string}
     * @memberof CaseAllOf
     */
    providerCaseID: string;
    /**
     * URL safe string
     * @type {string}
     * @memberof CaseAllOf
     */
    slug: string;
    /**
     * 
     * @type {AddressType}
     * @memberof CaseAllOf
     */
    addressType: AddressType;
    /**
     * The definition of price cash differs based on the distinction of the case: * For real estate cases, price cash is the full purchase price of the case * For cooperative cases, price cash is the deposit (indskud in Danish) * For rental cases, price cash is the deposit 
     * @type {number}
     * @memberof CaseAllOf
     */
    priceCash: number;
    /**
     * The price change in percent since the case came into market, an increase in price will lead to a positive number, and a decrease in price will lead to a negative number 
     * @type {number}
     * @memberof CaseAllOf
     */
    priceChangePercentage?: number;
    /**
     * The definition of monthly expense differs based on the distinction of the case: * For real estate cases, monthly expense is the ownership cost (ejerudgift in Danish) * For cooperative cases, monthly expense is the housing allowance (boligydelse in Danish) * For rental cases, monthly expense is the rent 
     * @type {number}
     * @memberof CaseAllOf
     */
    monthlyExpense?: number;
    /**
     * 
     * @type {Cooperative}
     * @memberof CaseAllOf
     */
    cooperative?: Cooperative;
    /**
     * 
     * @type {RealEstate}
     * @memberof CaseAllOf
     */
    realEstate?: RealEstate;
    /**
     * 
     * @type {Rental}
     * @memberof CaseAllOf
     */
    rental?: Rental;
    /**
     * url to the case on the realtor homepage
     * @type {string}
     * @memberof CaseAllOf
     */
    caseUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseAllOf
     */
    descriptionBody?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseAllOf
     */
    descriptionTitle?: string;
    /**
     * 
     * @type {OpenHouse}
     * @memberof CaseAllOf
     */
    nextOpenHouse?: OpenHouse;
    /**
     * 
     * @type {Address}
     * @memberof CaseAllOf
     */
    address: Address;
    /**
     * 
     * @type {number}
     * @memberof CaseAllOf
     */
    numberOfFloors?: number;
    /**
     * 
     * @type {number}
     * @memberof CaseAllOf
     */
    numberOfRooms?: number;
    /**
     * 
     * @type {number}
     * @memberof CaseAllOf
     */
    yearBuilt?: number;
    /**
     * 
     * @type {EnergyLabel}
     * @memberof CaseAllOf
     */
    energyLabel?: EnergyLabel;
    /**
     * 
     * @type {number}
     * @memberof CaseAllOf
     */
    lotArea?: number;
    /**
     * 
     * @type {number}
     * @memberof CaseAllOf
     */
    housingArea?: number;
    /**
     * 
     * @type {number}
     * @memberof CaseAllOf
     */
    basementArea?: number;
    /**
     * 
     * @type {number}
     * @memberof CaseAllOf
     */
    weightedArea?: number;
    /**
     * 
     * @type {ImageResource}
     * @memberof CaseAllOf
     */
    defaultImage?: ImageResource;
    /**
     * 
     * @type {Array<ImageResource>}
     * @memberof CaseAllOf
     */
    images?: Array<ImageResource>;
    /**
     * 
     * @type {number}
     * @memberof CaseAllOf
     */
    totalClickCount: number;
    /**
     * 
     * @type {number}
     * @memberof CaseAllOf
     */
    totalFavourites: number;
    /**
     * 
     * @type {number}
     * @memberof CaseAllOf
     */
    pageViews: number;
    /**
     * Deprecated; prefer `case.timeOnMarket.current.days` instead.
     * @type {number}
     * @memberof CaseAllOf
     * @deprecated
     */
    daysOnMarket?: number;
    /**
     * 
     * @type {CaseTimeOnMarket}
     * @memberof CaseAllOf
     */
    timeOnMarket: CaseTimeOnMarket;
    /**
     * 
     * @type {boolean}
     * @memberof CaseAllOf
     */
    highlighted: boolean;
    /**
     * 
     * @type {CaseStatus}
     * @memberof CaseAllOf
     */
    status: CaseStatus;
    /**
     * 
     * @type {GisPoint}
     * @memberof CaseAllOf
     */
    coordinates: GisPoint;
    /**
     * Per area price is the case\'s price relative to the case\'s size in square meters.
     * @type {number}
     * @memberof CaseAllOf
     */
    perAreaPrice?: number;
    /**
     * 
     * @type {CaseDistinction}
     * @memberof CaseAllOf
     */
    distinction: CaseDistinction;
    /**
     * 
     * @type {AddressType}
     * @memberof CaseAllOf
     */
    secondaryAddressType?: AddressType;
    /**
     * 
     * @type {CaseUtilitiesConnectionFee}
     * @memberof CaseAllOf
     */
    utilitiesConnectionFee: CaseUtilitiesConnectionFee;
}
/**
 * 
 * @export
 * @interface CaseCurrentTimeOnMarket
 */
export interface CaseCurrentTimeOnMarket {
    /**
     * 
     * @type {number}
     * @memberof CaseCurrentTimeOnMarket
     */
    days: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CaseDistinction {
    RealEstate = 'real_estate',
    Cooperative = 'cooperative',
    Rental = 'rental'
}

/**
 * 
 * @export
 * @interface CaseInteraction
 */
export interface CaseInteraction {
    /**
     * 
     * @type {InteractionType}
     * @memberof CaseInteraction
     */
    interactionType: InteractionType;
    /**
     * Where the interaction has happened
     * @type {string}
     * @memberof CaseInteraction
     */
    interactionSource: CaseInteractionInteractionSourceEnum;
    /**
     * Optional parameters for the interaction
     * @type {Set<InteractionParameter>}
     * @memberof CaseInteraction
     */
    interactionParameters?: Set<InteractionParameter>;
}

/**
    * @export
    * @enum {string}
    */
export enum CaseInteractionInteractionSourceEnum {
    WebApp = 'web_app',
    Email = 'email',
    IosApp = 'ios_app',
    IosPushNotification = 'ios_push_notification',
    AndroidApp = 'android_app',
    AndroidPushNotification = 'android_push_notification',
    HuaweiApp = 'huawei_app',
    HuaweiPushNotification = 'huawei_push_notification',
    CustomerReport = 'customer_report',
    Affiliate = 'affiliate',
    CustomerApi = 'customer_api'
}

/**
 * 
 * @export
 * @interface CasePin
 */
export interface CasePin {
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof CasePin
     */
    caseID: string;
    /**
     * 
     * @type {AddressType}
     * @memberof CasePin
     */
    addressType: AddressType;
    /**
     * 
     * @type {AddressType}
     * @memberof CasePin
     */
    secondaryAddressType?: AddressType;
    /**
     * 
     * @type {GisPoint}
     * @memberof CasePin
     */
    coordinates: GisPoint;
    /**
     * 
     * @type {Realtor}
     * @memberof CasePin
     */
    realtor: Realtor;
    /**
     * URL safe string
     * @type {string}
     * @memberof CasePin
     */
    slug: string;
    /**
     * The definition of price cash differs based on the distinction of the case: * For real estate cases, price cash is the full purchase price of the case * For cooperative cases, price cash is the deposit (indskud in Danish) * For rental cases, price cash is the deposit 
     * @type {number}
     * @memberof CasePin
     */
    priceCash: number;
    /**
     * The price change in percent since the case came into market, an increase in price will lead to a positive number, and a decrease in price will lead to a negative number 
     * @type {number}
     * @memberof CasePin
     */
    priceChangePercentage?: number;
    /**
     * The definition of monthly expense differs based on the distinction of the case: * For real estate cases, monthly expense is the ownership cost (ejerudgift in Danish) * For cooperative cases, monthly expense is the housing allowance (boligydelse in Danish) * For rental cases, monthly expense is the rent 
     * @type {number}
     * @memberof CasePin
     */
    monthlyExpense?: number;
    /**
     * 
     * @type {OpenHouse}
     * @memberof CasePin
     */
    nextOpenHouse?: OpenHouse;
    /**
     * 
     * @type {Address}
     * @memberof CasePin
     */
    address: Address;
    /**
     * 
     * @type {number}
     * @memberof CasePin
     */
    numberOfRooms?: number;
    /**
     * 
     * @type {EnergyLabel}
     * @memberof CasePin
     */
    energyLabel?: EnergyLabel;
    /**
     * 
     * @type {number}
     * @memberof CasePin
     */
    housingArea?: number;
    /**
     * 
     * @type {ImageResource}
     * @memberof CasePin
     */
    image?: ImageResource;
    /**
     * 
     * @type {CaseTimeOnMarket}
     * @memberof CasePin
     */
    timeOnMarket: CaseTimeOnMarket;
}
/**
 * 
 * @export
 * @interface CaseRealtorTotalTimeOnMarket
 */
export interface CaseRealtorTotalTimeOnMarket {
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof CaseRealtorTotalTimeOnMarket
     */
    realtorId: string;
    /**
     * 
     * @type {string}
     * @memberof CaseRealtorTotalTimeOnMarket
     */
    realtorName?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseRealtorTotalTimeOnMarket
     */
    days: number;
}
/**
 * 
 * @export
 * @interface CaseStatistic
 */
export interface CaseStatistic {
    /**
     * 
     * @type {CaseStatisticType}
     * @memberof CaseStatistic
     */
    type: CaseStatisticType;
    /**
     * 
     * @type {CaseStatisticLocationType}
     * @memberof CaseStatistic
     */
    locationType: CaseStatisticLocationType;
    /**
     * 
     * @type {string}
     * @memberof CaseStatistic
     */
    locationName: string;
    /**
     * 
     * @type {string}
     * @memberof CaseStatistic
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof CaseStatistic
     */
    to: string;
    /**
     * 
     * @type {CaseStatisticAddressType}
     * @memberof CaseStatistic
     */
    addressType: CaseStatisticAddressType;
    /**
     * 
     * @type {number}
     * @memberof CaseStatistic
     */
    value: number;
    /**
     * 
     * @type {PreviousCaseStatistic}
     * @memberof CaseStatistic
     */
    previousMonth?: PreviousCaseStatistic;
    /**
     * 
     * @type {PreviousCaseStatistic}
     * @memberof CaseStatistic
     */
    previousYear?: PreviousCaseStatistic;
}
/**
 * Address type to fetch case statistic for all single address types have data, but only a select few address type combinations are supported (such as `villa and terraced house`) 
 * @export
 * @enum {string}
 */
export enum CaseStatisticAddressType {
    Villa = 'villa',
    TerracedHouse = 'terraced house',
    Condo = 'condo',
    HolidayHouse = 'holiday house',
    Allotment = 'allotment',
    Cooperative = 'cooperative',
    Farm = 'farm',
    FullYearPlot = 'full year plot',
    HolidayPlot = 'holiday plot',
    VillaApartment = 'villa apartment',
    CattleFarm = 'cattle farm',
    PigFarm = 'pig farm',
    PlantFarm = 'plant farm',
    Forest = 'forest',
    HobbyFarm = 'hobby farm',
    Special = 'special',
    VillaAndTerracedHouse = 'villa and terraced house',
    Houseboat = 'houseboat'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum CaseStatisticLocationType {
    Country = 'country',
    Region = 'region',
    Province = 'province',
    Municipality = 'municipality',
    ZipCode = 'zip_code'
}

/**
 * `sold_per_area_price` is the average price per square meter for sold cases.  `sold_per_area_price_change` is the average price change per square meter for sold cases. A case which was for sale for 1000000 and sold for 950000 with an area of 100 square meters has a per area price change of -500.  `sold_price_change_percentage` is the average price change in percent for sold cases. A case which was for sale for 1000000 and sold for 950000 has a price change of -5%.  `sold_time_on_market` is the average amount in days that sold cases were on market before being sold.  `sold_price` is the average price that cases are sold for.  `sold_amount` is the amount of cases sold.   `amount_for_sale` is the amount of cases for sale   `price` is the average price that cases are for sale for.   `per_area_price` is the average price per square meter that cases are for sale for. A case which is for sale   for 1000000 with an area of 100 square meters has a per area price of 10000.    `per_area_price_change` is the average price change per square meter for cases for sale. A case which is for   sale for 950000 with an area of 100 square meters, but originaly was for sale at 1000000, has a per area   price change of -500.    `price_change_percentage` is the average price change per square meter for cases for sale. A case which is for   sale for 950000 with an area of 100 square meters, but originaly was for sale at 1000000, has a per area   price change of -5%. 
 * @export
 * @enum {string}
 */
export enum CaseStatisticType {
    SoldPerAreaPrice = 'sold_per_area_price',
    SoldPerAreaPriceChange = 'sold_per_area_price_change',
    SoldPriceChangePercentage = 'sold_price_change_percentage',
    SoldTimeOnMarket = 'sold_time_on_market',
    SoldPrice = 'sold_price',
    SoldAmount = 'sold_amount',
    AmountForSale = 'amount_for_sale',
    Price = 'price',
    PerAreaPrice = 'per_area_price',
    PerAreaPriceChange = 'per_area_price_change',
    PriceChangePercentage = 'price_change_percentage'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum CaseStatus {
    Open = 'open'
}

/**
 * 
 * @export
 * @interface CaseTimeOnMarket
 */
export interface CaseTimeOnMarket {
    /**
     * 
     * @type {CaseCurrentTimeOnMarket}
     * @memberof CaseTimeOnMarket
     */
    current?: CaseCurrentTimeOnMarket;
    /**
     * 
     * @type {CaseTotalTimeOnMarket}
     * @memberof CaseTimeOnMarket
     */
    total?: CaseTotalTimeOnMarket;
}
/**
 * 
 * @export
 * @interface CaseTimeline
 */
export interface CaseTimeline {
    /**
     * 
     * @type {Array<CaseTimelineEvent>}
     * @memberof CaseTimeline
     */
    timeline: Array<CaseTimelineEvent> | null;
}
/**
 * 
 * @export
 * @interface CaseTimelineEvent
 */
export interface CaseTimelineEvent {
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof CaseTimelineEvent
     */
    caseID: string;
    /**
     * 
     * @type {string}
     * @memberof CaseTimelineEvent
     */
    at: string;
    /**
     * 
     * @type {number}
     * @memberof CaseTimelineEvent
     */
    price: number;
    /**
     * 
     * @type {CaseTimelineEventType}
     * @memberof CaseTimelineEvent
     */
    type: CaseTimelineEventType;
    /**
     * 
     * @type {CaseTimelineRegisteredAux | CaseTimelinePriceChangeAux}
     * @memberof CaseTimelineEvent
     */
    aux: CaseTimelineRegisteredAux | CaseTimelinePriceChangeAux | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CaseTimelineEventType {
    Open = 'open',
    PriceChange = 'price_change',
    Closed = 'closed',
    Registered = 'registered'
}

/**
 * Used with the `price_change` event type.
 * @export
 * @interface CaseTimelinePriceChangeAux
 */
export interface CaseTimelinePriceChangeAux {
    /**
     * The absolute difference between the old price and the new price. For example, a price change from 100000 to 80000 has an absolute difference of -20000. 
     * @type {number}
     * @memberof CaseTimelinePriceChangeAux
     */
    priceDifference: number;
    /**
     * The percentage difference between the old price and the new price. For example, a price change from 100000 to 80000 has a percentage difference of -20. 
     * @type {number}
     * @memberof CaseTimelinePriceChangeAux
     */
    priceDifferencePercentage: number;
}
/**
 * Used with the `registered` event type.
 * @export
 * @interface CaseTimelineRegisteredAux
 */
export interface CaseTimelineRegisteredAux {
    /**
     * 
     * @type {RegistrationType}
     * @memberof CaseTimelineRegisteredAux
     */
    type: RegistrationType;
}
/**
 * 
 * @export
 * @interface CaseTotalTimeOnMarket
 */
export interface CaseTotalTimeOnMarket {
    /**
     * 
     * @type {number}
     * @memberof CaseTotalTimeOnMarket
     */
    days: number;
    /**
     * 
     * @type {Set<CaseRealtorTotalTimeOnMarket>}
     * @memberof CaseTotalTimeOnMarket
     */
    realtors: Set<CaseRealtorTotalTimeOnMarket>;
}
/**
 * 
 * @export
 * @interface CaseTrafficStats
 */
export interface CaseTrafficStats {
    /**
     * 
     * @type {Array<CaseTrafficStatsDataPoint>}
     * @memberof CaseTrafficStats
     */
    stats: Array<CaseTrafficStatsDataPoint> | null;
}
/**
 * 
 * @export
 * @interface CaseTrafficStatsDataPoint
 */
export interface CaseTrafficStatsDataPoint {
    /**
     * 
     * @type {string}
     * @memberof CaseTrafficStatsDataPoint
     */
    date?: string;
    /**
     * The statistic for the case itself, i.e. number of views, clicks etc.
     * @type {number}
     * @memberof CaseTrafficStatsDataPoint
     */
    own?: number;
    /**
     * The statistic for similar cases, i.e. number of views, clicks etc.
     * @type {number}
     * @memberof CaseTrafficStatsDataPoint
     */
    similar?: number;
}
/**
 * Utilities connection fee (tilslutningsafgift) is the cost of connecting utilities such as water, heating, sewerage etc. to a case. Connecting utilities is typically only relevant for new plots of land. 
 * @export
 * @interface CaseUtilitiesConnectionFee
 */
export interface CaseUtilitiesConnectionFee {
    /**
     * 
     * @type {CaseUtilitiesConnectionFeeIncludedInPrice}
     * @memberof CaseUtilitiesConnectionFee
     */
    includedInPrice: CaseUtilitiesConnectionFeeIncludedInPrice | null;
}
/**
 * How much of the case\'s utilities connection fee (if there is one) is included in the case\'s price. If the case has no utilities connection fee, then the value of this property is `null`. 
 * @export
 * @enum {string}
 */
export enum CaseUtilitiesConnectionFeeIncludedInPrice {
    Excluded = 'excluded',
    PartiallyIncluded = 'partially included',
    FullyIncluded = 'fully included'
}

/**
 * 
 * @export
 * @interface CasesOpenCountResult
 */
export interface CasesOpenCountResult {
    /**
     * 
     * @type {number}
     * @memberof CasesOpenCountResult
     */
    totalCount?: number;
}
/**
 * 
 * @export
 * @interface CasesStatsRequestBodyItem
 */
export interface CasesStatsRequestBodyItem {
    /**
     * 
     * @type {CaseStatisticType}
     * @memberof CasesStatsRequestBodyItem
     */
    type: CaseStatisticType;
    /**
     * 
     * @type {CaseStatisticLocationType}
     * @memberof CasesStatsRequestBodyItem
     */
    locationType: CaseStatisticLocationType;
    /**
     * The location name to fetch data for. If `locationType` is `country` then this value must be `Denmark` or `Danmark`. 
     * @type {string}
     * @memberof CasesStatsRequestBodyItem
     */
    locationName: string;
    /**
     * 
     * @type {CaseStatisticAddressType}
     * @memberof CasesStatsRequestBodyItem
     */
    addressType: CaseStatisticAddressType;
    /**
     * The first month to select data from. The granularity of case statistics is month, so the day value is not relevant. `from` is inclusive. 
     * @type {string}
     * @memberof CasesStatsRequestBodyItem
     */
    from: string;
    /**
     * The last month to select data from. Like with `from`, the day value of `to` is irrelevant. `to` is inclusive. 
     * @type {string}
     * @memberof CasesStatsRequestBodyItem
     */
    to: string;
}
/**
 * 
 * @export
 * @interface City
 */
export interface City {
    /**
     * 
     * @type {string}
     * @memberof City
     */
    name: string;
    /**
     * URL safe string
     * @type {string}
     * @memberof City
     */
    slug: string;
}
/**
 * 
 * @export
 * @interface CollectionLinks
 */
export interface CollectionLinks {
    /**
     * 
     * @type {HALLinkObject}
     * @memberof CollectionLinks
     */
    self: HALLinkObject;
    /**
     * 
     * @type {HALLinkObject}
     * @memberof CollectionLinks
     */
    next?: HALLinkObject;
    /**
     * 
     * @type {HALLinkObject}
     * @memberof CollectionLinks
     */
    last?: HALLinkObject;
    /**
     * 
     * @type {HALLinkObject}
     * @memberof CollectionLinks
     */
    find?: HALLinkObject;
}
/**
 * 
 * @export
 * @interface ContactInformation
 */
export interface ContactInformation {
    /**
     * 
     * @type {number}
     * @memberof ContactInformation
     */
    zipCode?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    roadName?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    houseNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    floor?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    door?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    cityName?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactInformation
     */
    cvr?: number;
}
/**
 * Data specific to cases of distinction cooperative. Cases with distinction cooperative will always contain this object. Cases with any other distinction will never contain this object. 
 * @export
 * @interface Cooperative
 */
export interface Cooperative {
    /**
     * 
     * @type {number}
     * @memberof Cooperative
     */
    utilities?: number;
    /**
     * 
     * @type {number}
     * @memberof Cooperative
     */
    grossFinancing?: number;
    /**
     * 
     * @type {number}
     * @memberof Cooperative
     */
    netFinancing?: number;
    /**
     * 
     * @type {number}
     * @memberof Cooperative
     */
    paymentFinancing?: number;
    /**
     * 
     * @type {number}
     * @memberof Cooperative
     */
    maxPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Cooperative
     */
    technicalPrice?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Cooperative
     */
    mortgageMaxed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Cooperative
     */
    allocation?: number;
    /**
     * 
     * @type {number}
     * @memberof Cooperative
     */
    associationDebt?: number;
    /**
     * 
     * @type {string}
     * @memberof Cooperative
     */
    loanDescription?: string;
}
/**
 * 
 * @export
 * @interface CustomArea
 */
export interface CustomArea {
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof CustomArea
     */
    customAreaID: string;
    /**
     * 
     * @type {string}
     * @memberof CustomArea
     */
    name: string;
    /**
     * 
     * @type {Set<number>}
     * @memberof CustomArea
     */
    bbox: Set<number>;
    /**
     * 
     * @type {string}
     * @memberof CustomArea
     */
    slug: string;
}
/**
 * 
 * @export
 * @interface CustomAreaResult
 */
export interface CustomAreaResult {
    /**
     * 
     * @type {Array<CustomArea>}
     * @memberof CustomAreaResult
     */
    customAreas: Array<CustomArea>;
}
/**
 * 
 * @export
 * @interface CustomRoad
 */
export interface CustomRoad {
    /**
     * 
     * @type {number}
     * @memberof CustomRoad
     */
    municipalityCode: number;
    /**
     * 
     * @type {string}
     * @memberof CustomRoad
     */
    name: string;
}
/**
 * 
 * @export
 * @interface Difference
 */
export interface Difference {
    /**
     * 
     * @type {number}
     * @memberof Difference
     */
    absolute: number;
    /**
     * 
     * @type {number}
     * @memberof Difference
     */
    percentage: number;
}
/**
 * Describe the image direction. Allowed values are t, n, s, e, and w. Meaning top, north, south, east and west. 
 * @export
 * @enum {string}
 */
export enum DirectionType {
    T = 't',
    N = 'n',
    S = 's',
    E = 'e',
    W = 'w'
}

/**
 * Describe where the content should be displayed. 
 * @export
 * @enum {string}
 */
export enum DisplayOn {
    PropertyDetailPage = 'property_detail_page',
    ResultListPage = 'result_list_page'
}

/**
 * The energy label has to adhere to \"Energistyrelsens mærkningsordning\". 
 * @export
 * @enum {string}
 */
export enum EnergyLabel {
    A2010 = 'A2010',
    A2015 = 'A2015',
    A2020 = 'A2020',
    A1 = 'A1',
    A2 = 'A2',
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    G = 'G'
}

/**
 * 
 * @export
 * @interface EnergyLabelImprovement
 */
export interface EnergyLabelImprovement {
    /**
     * 
     * @type {string}
     * @memberof EnergyLabelImprovement
     */
    improvementCase: EnergyLabelImprovementImprovementCaseEnum;
    /**
     * 
     * @type {EnergyLabel}
     * @memberof EnergyLabelImprovement
     */
    current?: EnergyLabel;
    /**
     * 
     * @type {EnergyLabel}
     * @memberof EnergyLabelImprovement
     */
    achievable?: EnergyLabel;
    /**
     * 
     * @type {number}
     * @memberof EnergyLabelImprovement
     */
    savingPerYear?: number;
    /**
     * 
     * @type {string}
     * @memberof EnergyLabelImprovement
     */
    sparEnergiLink: string;
}

/**
    * @export
    * @enum {string}
    */
export enum EnergyLabelImprovementImprovementCaseEnum {
    Fallback = 'FALLBACK'
}

/**
 * 
 * @export
 * @interface FavoriteAddressesResult
 */
export interface FavoriteAddressesResult {
    /**
     * 
     * @type {Set<Address>}
     * @memberof FavoriteAddressesResult
     */
    addresses?: Set<Address>;
}
/**
 * 
 * @export
 * @interface FavoriteResult
 */
export interface FavoriteResult {
    /**
     * 
     * @type {Array<Address>}
     * @memberof FavoriteResult
     */
    addresses: Array<Address>;
    /**
     * 
     * @type {Array<Preview>}
     * @memberof FavoriteResult
     */
    previews: Array<Preview>;
}
/**
 * GeoJSON Feature
 * @export
 * @interface Features
 */
export interface Features {
    /**
     * 
     * @type {TypeFeature}
     * @memberof Features
     */
    type: TypeFeature;
    /**
     * 
     * @type {Geometry}
     * @memberof Features
     */
    geometry: Geometry;
}
/**
 * GeoJSON geometry
 * @export
 * @interface Geometry
 */
export interface Geometry {
    /**
     * The geometry type
     * @type {string}
     * @memberof Geometry
     */
    type: GeometryTypeEnum;
    /**
     * Coordinates order is longitude and latitude, or easting and northing.
     * @type {Set<number>}
     * @memberof Geometry
     */
    coordinates: Set<number>;
}

/**
    * @export
    * @enum {string}
    */
export enum GeometryTypeEnum {
    Point = 'Point'
}

/**
 * 
 * @export
 * @interface GetPromotionsResult
 */
export interface GetPromotionsResult {
    /**
     * 
     * @type {Array<Promotion>}
     * @memberof GetPromotionsResult
     */
    promotions: Array<Promotion> | null;
}
/**
 * 
 * @export
 * @interface GetUserPointsOfInterestResult
 */
export interface GetUserPointsOfInterestResult {
    /**
     * 
     * @type {Array<UserPointOfInterest>}
     * @memberof GetUserPointsOfInterestResult
     */
    userPointsOfInterest: Array<UserPointOfInterest> | null;
}
/**
 * Geographical location in Denmark (including Bornholm).
 * @export
 * @interface GisPoint
 */
export interface GisPoint {
    /**
     * 
     * @type {string}
     * @memberof GisPoint
     */
    type: GisPointTypeEnum;
    /**
     * longitude
     * @type {number}
     * @memberof GisPoint
     */
    lon: number;
    /**
     * latitude
     * @type {number}
     * @memberof GisPoint
     */
    lat: number;
}

/**
    * @export
    * @enum {string}
    */
export enum GisPointTypeEnum {
    Epsg4326 = 'EPSG4326'
}

/**
 * JSON Hypertext Application Language. Definition of [HAL message format](https://tools.ietf.org/html/draft-kelly-json-hal-08)
 * @export
 * @interface HALCollection
 */
export interface HALCollection {
    /**
     * 
     * @type {CollectionLinks}
     * @memberof HALCollection
     */
    _links: CollectionLinks;
}
/**
 * HALLinkObject defines the allowed links. The example is reused to avoid a lot of overwriting. 
 * @export
 * @interface HALLinkObject
 */
export interface HALLinkObject {
    /**
     * 
     * @type {string}
     * @memberof HALLinkObject
     */
    href: string;
    /**
     * 
     * @type {boolean}
     * @memberof HALLinkObject
     */
    templated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HALLinkObject
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof HALLinkObject
     */
    deprecation?: string;
    /**
     * 
     * @type {string}
     * @memberof HALLinkObject
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof HALLinkObject
     */
    profile?: string;
    /**
     * 
     * @type {string}
     * @memberof HALLinkObject
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof HALLinkObject
     */
    hreflang?: string;
}
/**
 * JSON Hypertext Application Language. Definition of [HAL message format](https://tools.ietf.org/html/draft-kelly-json-hal-08)
 * @export
 * @interface HALResource
 */
export interface HALResource {
    /**
     * 
     * @type {ResourceLinks}
     * @memberof HALResource
     */
    _links: ResourceLinks;
}
/**
 * 
 * @export
 * @interface ImageResource
 */
export interface ImageResource {
    /**
     * 
     * @type {Array<ImageSource>}
     * @memberof ImageResource
     */
    imageSources: Array<ImageSource>;
}
/**
 * 
 * @export
 * @interface ImageSize
 */
export interface ImageSize {
    /**
     * 
     * @type {number}
     * @memberof ImageSize
     */
    height: number;
    /**
     * 
     * @type {number}
     * @memberof ImageSize
     */
    width: number;
}
/**
 * 
 * @export
 * @interface ImageSource
 */
export interface ImageSource {
    /**
     * 
     * @type {string}
     * @memberof ImageSource
     */
    mimeType?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageSource
     */
    url: string;
    /**
     * 
     * @type {ImageSize}
     * @memberof ImageSource
     */
    size: ImageSize;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    message: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {Array<PollConcise>}
     * @memberof InlineResponse2001
     */
    polls: Array<PollConcise> | null;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {Poll}
     * @memberof InlineResponse2002
     */
    poll: Poll;
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {Array<PollAnswer>}
     * @memberof InlineResponse2003
     */
    answers: Array<PollAnswer> | null;
}
/**
 * 
 * @export
 * @interface Institution
 */
export interface Institution {
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof Institution
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof Institution
     */
    municipalityCode?: number;
    /**
     * 
     * @type {string}
     * @memberof Institution
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Institution
     */
    externalId: number;
    /**
     * 
     * @type {number}
     * @memberof Institution
     */
    principalGPA?: number;
    /**
     * 
     * @type {number}
     * @memberof Institution
     */
    mandatoryGPA?: number;
    /**
     * 
     * @type {number}
     * @memberof Institution
     */
    overallIndicatorResponse?: number;
    /**
     * 
     * @type {number}
     * @memberof Institution
     */
    shareOfOverallIndicatorResponsePercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof Institution
     */
    type2: number;
    /**
     * 
     * @type {number}
     * @memberof Institution
     */
    type3: number;
    /**
     * 
     * @type {GisPoint}
     * @memberof Institution
     */
    location: GisPoint;
    /**
     * 
     * @type {string}
     * @memberof Institution
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Institution
     */
    updatedAt: string;
}
/**
 * The following types require `caseID`: * `click_to_realtor` * `presentation_page_viewed` * `calculate_mortgage` * `click_customer_to_realtor` * `click_highlight_to_realtor` * `click_rental_to_realtor` * `click_foreclosure_to_realtor` * `click_open_house` * `click_favorite` The following types require `CaseID` or `AddressID`: * `click_to_partner`  When the interaction type is `click_to_partner` it\'s required only one parameter with name equal to `partner_name`. 
 * @export
 * @interface Interaction
 */
export interface Interaction {
    /**
     * 
     * @type {InteractionType}
     * @memberof Interaction
     */
    type: InteractionType;
    /**
     * Where the interaction has happened
     * @type {string}
     * @memberof Interaction
     */
    source: InteractionSourceEnum;
    /**
     * Optional parameters for the interaction
     * @type {Set<InteractionParameter>}
     * @memberof Interaction
     */
    parameters?: Set<InteractionParameter>;
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof Interaction
     */
    caseID?: string;
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof Interaction
     */
    addressID?: string;
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof Interaction
     */
    userID?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InteractionSourceEnum {
    WebApp = 'web_app',
    Email = 'email',
    IosApp = 'ios_app',
    IosPushNotification = 'ios_push_notification',
    AndroidApp = 'android_app',
    AndroidPushNotification = 'android_push_notification',
    HuaweiApp = 'huawei_app',
    HuaweiPushNotification = 'huawei_push_notification',
    CustomerReport = 'customer_report',
    Affiliate = 'affiliate',
    CustomerApi = 'customer_api'
}

/**
 * 
 * @export
 * @interface InteractionParameter
 */
export interface InteractionParameter {
    /**
     * 
     * @type {string}
     * @memberof InteractionParameter
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InteractionParameter
     */
    value: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum InteractionType {
    ClickToRealtor = 'click_to_realtor',
    PresentationPageViewed = 'presentation_page_viewed',
    CalculateMortgage = 'calculate_mortgage',
    ClickCustomerToRealtor = 'click_customer_to_realtor',
    ClickHighlightToRealtor = 'click_highlight_to_realtor',
    ClickRentalToRealtor = 'click_rental_to_realtor',
    ClickForeclosureToRealtor = 'click_foreclosure_to_realtor',
    ClickOpenHouse = 'click_open_house',
    ClickFavorite = 'click_favorite',
    ClickToPartner = 'click_to_partner'
}

/**
 * 
 * @export
 * @interface LocationsSlugsResult
 */
export interface LocationsSlugsResult {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LocationsSlugsResult
     */
    provinces?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LocationsSlugsResult
     */
    municipalities?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LocationsSlugsResult
     */
    cities?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LocationsSlugsResult
     */
    roads?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LocationsSlugsResult
     */
    placeNames?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LocationsSlugsResult
     */
    customAreas?: { [key: string]: object; };
}
/**
 * CTA source page for mortgage calculation
 * @export
 * @enum {string}
 */
export enum MortgageSource {
    WebPresentationPage = 'web_presentation_page',
    WebResultPage = 'web_result_page',
    AppPresentationPage = 'app_presentation_page'
}

/**
 * 
 * @export
 * @interface Municipality
 */
export interface Municipality {
    /**
     * 
     * @type {string}
     * @memberof Municipality
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Municipality
     */
    municipalityCode: number;
    /**
     * 
     * @type {number}
     * @memberof Municipality
     */
    regionCode?: number;
    /**
     * URL safe string
     * @type {string}
     * @memberof Municipality
     */
    slug: string;
    /**
     * The count of municipality population
     * @type {number}
     * @memberof Municipality
     */
    population?: number;
    /**
     * The percentage of tax in the municipality (\"kommuneskat\" in Danish)
     * @type {number}
     * @memberof Municipality
     */
    councilTaxPercentage?: number;
    /**
     * The percentage of church tax in the municipality (\"kirkeskat\" in Danish)
     * @type {number}
     * @memberof Municipality
     */
    churchTaxPercentage?: number;
    /**
     * The level of land value tax per thousand in the municipality (\"grundskyld\" in Danish)
     * @type {number}
     * @memberof Municipality
     */
    landValueTaxLevelPerThousand?: number;
    /**
     * The link to the latest district plan in the municipality (\"lokalplan\" in Danish)
     * @type {string}
     * @memberof Municipality
     */
    districtPlanPdfUrl?: string;
    /**
     * The date from which district plan is valid
     * @type {string}
     * @memberof Municipality
     */
    districtPlanFrom?: string;
    /**
     * The link to the latest municipality master plan (\"kommuneplan\" in Danish)
     * @type {string}
     * @memberof Municipality
     */
    masterPlanPdfUrl?: string;
    /**
     * The number of schools in the municipality
     * @type {number}
     * @memberof Municipality
     */
    numberOfSchools?: number;
}
/**
 * 
 * @export
 * @interface MunicipalityCollectionResult
 */
export interface MunicipalityCollectionResult {
    /**
     * 
     * @type {Array<Municipality>}
     * @memberof MunicipalityCollectionResult
     */
    municipalities?: Array<Municipality>;
}
/**
 * 
 * @export
 * @interface OpenHouse
 */
export interface OpenHouse {
    /**
     * 
     * @type {string}
     * @memberof OpenHouse
     */
    date: string;
    /**
     * Event duration in minutes
     * @type {number}
     * @memberof OpenHouse
     */
    duration?: number;
    /**
     * Whether or not sign up is required, or if people can just drop by. 
     * @type {boolean}
     * @memberof OpenHouse
     */
    signUp: boolean;
    /**
     * 
     * @type {string}
     * @memberof OpenHouse
     */
    signUpUrl?: string;
}
/**
 * 
 * @export
 * @interface PingMessageBody
 */
export interface PingMessageBody {
    /**
     * 
     * @type {string}
     * @memberof PingMessageBody
     */
    message: string;
}
/**
 * 
 * @export
 * @interface Poll
 */
export interface Poll {
    /**
     * 
     * @type {string}
     * @memberof Poll
     */
    pollID: string;
    /**
     * 
     * @type {PollQuestion}
     * @memberof Poll
     */
    question: PollQuestion;
    /**
     * 
     * @type {boolean}
     * @memberof Poll
     */
    randomiseAnswers: boolean;
    /**
     * It sets how the results will be displayed.
     * @type {string}
     * @memberof Poll
     */
    resultsType: PollResultsTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PollResultsTypeEnum {
    Show = 'show',
    Percent = 'percent',
    Hide = 'hide'
}

/**
 * 
 * @export
 * @interface PollAnswer
 */
export interface PollAnswer {
    /**
     * 
     * @type {string}
     * @memberof PollAnswer
     */
    pollAnswerID: string;
    /**
     * 
     * @type {string}
     * @memberof PollAnswer
     */
    text: string;
    /**
     * 
     * @type {PollAnswerResults}
     * @memberof PollAnswer
     */
    results: PollAnswerResults;
}
/**
 * 
 * @export
 * @interface PollAnswerResults
 */
export interface PollAnswerResults {
    /**
     * The amount of responses that this answer option has received. 
     * @type {number}
     * @memberof PollAnswerResults
     */
    amount: number;
    /**
     * How many responses this answer options has received relative to other answer options on the poll. 
     * @type {number}
     * @memberof PollAnswerResults
     */
    percentage: number;
}
/**
 * 
 * @export
 * @interface PollConcise
 */
export interface PollConcise {
    /**
     * 
     * @type {string}
     * @memberof PollConcise
     */
    pollID: string;
    /**
     * 
     * @type {PollResponses}
     * @memberof PollConcise
     */
    responses: PollResponses;
    /**
     * 
     * @type {PollQuestion}
     * @memberof PollConcise
     */
    question: PollQuestion;
}
/**
 * 
 * @export
 * @interface PollQuestion
 */
export interface PollQuestion {
    /**
     * 
     * @type {string}
     * @memberof PollQuestion
     */
    text: string;
}
/**
 * 
 * @export
 * @interface PollResponses
 */
export interface PollResponses {
    /**
     * 
     * @type {number}
     * @memberof PollResponses
     */
    amount: number;
}
/**
 * 
 * @export
 * @interface Polygon
 */
export interface Polygon {
    /**
     * 
     * @type {TypeFeatureCollection}
     * @memberof Polygon
     */
    type: TypeFeatureCollection;
    /**
     * 
     * @type {Array<Features>}
     * @memberof Polygon
     */
    features: Array<Features>;
}
/**
 * 
 * @export
 * @interface PostPromotionInteraction
 */
export interface PostPromotionInteraction {
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof PostPromotionInteraction
     */
    promotionID: string;
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof PostPromotionInteraction
     */
    caseID?: string;
    /**
     * 
     * @type {PromotionInteractionType}
     * @memberof PostPromotionInteraction
     */
    type: PromotionInteractionType;
    /**
     * 
     * @type {PromotionInteractionSource}
     * @memberof PostPromotionInteraction
     */
    source: PromotionInteractionSource;
}
/**
 * 
 * @export
 * @interface PostUserPointOfInterest
 */
export interface PostUserPointOfInterest {
    /**
     * 
     * @type {string}
     * @memberof PostUserPointOfInterest
     */
    name: string;
    /**
     * 
     * @type {GisPoint}
     * @memberof PostUserPointOfInterest
     */
    location: GisPoint;
}
/**
 * 
 * @export
 * @interface PostUserPointOfInterestResult
 */
export interface PostUserPointOfInterestResult {
    /**
     * 
     * @type {UserPointOfInterest}
     * @memberof PostUserPointOfInterestResult
     */
    userPointOfInterest: UserPointOfInterest;
}
/**
 * 
 * @export
 * @interface Preview
 */
export interface Preview {
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof Preview
     */
    previewID: string;
    /**
     * The definition of price cash differs based on the distinction of the case: * For real estate cases, price cash is the full purchase price of the case * For cooperative cases, price cash is the deposit (indskud in Danish) * For rental cases, price cash is the deposit 
     * @type {number}
     * @memberof Preview
     */
    price: number;
    /**
     * Per area price is the case\'s price relative to the case\'s size in square meters.
     * @type {number}
     * @memberof Preview
     */
    perAreaPrice: number;
    /**
     * 
     * @type {string}
     * @memberof Preview
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Preview
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof Preview
     */
    numberOfFloors?: number;
    /**
     * 
     * @type {number}
     * @memberof Preview
     */
    numberOfRooms?: number;
    /**
     * 
     * @type {number}
     * @memberof Preview
     */
    yearBuilt?: number;
    /**
     * 
     * @type {number}
     * @memberof Preview
     */
    lotArea?: number;
    /**
     * 
     * @type {number}
     * @memberof Preview
     */
    housingArea?: number;
    /**
     * 
     * @type {number}
     * @memberof Preview
     */
    basementArea?: number;
    /**
     * url to the case on the realtor homepage
     * @type {string}
     * @memberof Preview
     */
    url?: string;
    /**
     * 
     * @type {PreviewAddress}
     * @memberof Preview
     */
    address: PreviewAddress;
}
/**
 * 
 * @export
 * @interface PreviewAddress
 */
export interface PreviewAddress {
    /**
     * 
     * @type {AddressType}
     * @memberof PreviewAddress
     */
    type: AddressType;
    /**
     * 
     * @type {ZipCode}
     * @memberof PreviewAddress
     */
    zip: ZipCode;
    /**
     * 
     * @type {City}
     * @memberof PreviewAddress
     */
    city: City;
    /**
     * 
     * @type {AdditionalCityName}
     * @memberof PreviewAddress
     */
    place?: AdditionalCityName;
    /**
     * 
     * @type {Municipality}
     * @memberof PreviewAddress
     */
    municipality: Municipality;
    /**
     * 
     * @type {Province}
     * @memberof PreviewAddress
     */
    province: Province;
    /**
     * 
     * @type {CustomArea}
     * @memberof PreviewAddress
     */
    customArea?: CustomArea;
}
/**
 * 
 * @export
 * @interface PreviousCaseStatistic
 */
export interface PreviousCaseStatistic {
    /**
     * 
     * @type {Difference}
     * @memberof PreviousCaseStatistic
     */
    difference?: Difference;
    /**
     * 
     * @type {number}
     * @memberof PreviousCaseStatistic
     */
    value: number;
}
/**
 * 
 * @export
 * @interface Promotion
 */
export interface Promotion {
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof Promotion
     */
    promotionID: string;
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof Promotion
     */
    promoterID: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    content: string;
    /**
     * 
     * @type {number}
     * @memberof Promotion
     */
    rank: number;
    /**
     * Whether or not this promotion is for third party content. For example, a promotion for an IKEA kitchen would be an advertisement. A promotion for a Boligsiden blog post would not be an advertisement. 
     * @type {boolean}
     * @memberof Promotion
     */
    isAdvertisement: boolean;
    /**
     * 
     * @type {PromotionLimits}
     * @memberof Promotion
     */
    limits: PromotionLimits;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PromotionInteractionSource {
    WebApp = 'web_app',
    IosApp = 'ios_app',
    AndroidApp = 'android_app',
    HuaweiApp = 'huawei_app'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum PromotionInteractionType {
    View = 'view',
    Click = 'click'
}

/**
 * 
 * @export
 * @interface PromotionLimit
 */
export interface PromotionLimit {
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof PromotionLimit
     */
    promotionLimitID: string;
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof PromotionLimit
     */
    promotionID: string;
    /**
     * 
     * @type {PromotionInteractionType}
     * @memberof PromotionLimit
     */
    interactionType: PromotionInteractionType;
    /**
     * 
     * @type {PromotionLimitPrecision}
     * @memberof PromotionLimit
     */
    precision: PromotionLimitPrecision;
    /**
     * 
     * @type {number}
     * @memberof PromotionLimit
     */
    amount: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PromotionLimitPrecision {
    Day = 'day',
    Week = 'week',
    Month = 'month',
    Year = 'year',
    Millenium = 'millenium'
}

/**
 * Boligsiden limits the amount of times that the same user can view a promotion.  A limit has: * An interaction type to which the limit applies. For example, a limit might only limit the amount of clicks on a promotion, and not the amount of views. * A precision. A limit\'s precision is the interval with which the limit resets. For example, monthly limits prevent a user from interacting with the same limit too many times per month, and are reset the first of every month. * An amount. A limit\'s amount is the amount of interactions that a given user may have with the promotion within the limit\'s precision before the user is no longer shown that promotion.  If a promotion has a limit which applies to views on a weekly basis with an amount of 10, then any given user may only view that promotion 10 times per week.  A promotion can have several limits, and is hidden to a user if the user has reached just one of the promotion\'s limits.  A promotion has both total and per-user limits. Only per-user limits are exposed. 
 * @export
 * @interface PromotionLimits
 */
export interface PromotionLimits {
    /**
     * 
     * @type {Array<PromotionLimit>}
     * @memberof PromotionLimits
     */
    user: Array<PromotionLimit> | null;
}
/**
 * 
 * @export
 * @interface Province
 */
export interface Province {
    /**
     * 
     * @type {string}
     * @memberof Province
     */
    provinceCode: string;
    /**
     * 
     * @type {number}
     * @memberof Province
     */
    regionCode: number;
    /**
     * 
     * @type {string}
     * @memberof Province
     */
    name: string;
    /**
     * URL safe string
     * @type {string}
     * @memberof Province
     */
    slug: string;
}
/**
 * 
 * @export
 * @interface ProvinceCollectionResult
 */
export interface ProvinceCollectionResult {
    /**
     * 
     * @type {Array<Province>}
     * @memberof ProvinceCollectionResult
     */
    provinces?: Array<Province>;
}
/**
 * 
 * @export
 * @interface PutUserPointOfInterestResult
 */
export interface PutUserPointOfInterestResult {
    /**
     * 
     * @type {UserPointOfInterest}
     * @memberof PutUserPointOfInterestResult
     */
    userPointOfInterest: UserPointOfInterest;
}
/**
 * 
 * @export
 * @interface Question
 */
export interface Question {
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    text: string;
    /**
     * 
     * @type {QuestionScores}
     * @memberof Question
     */
    scores: QuestionScores;
}
/**
 * 
 * @export
 * @interface QuestionScores
 */
export interface QuestionScores {
    /**
     * 
     * @type {RealtorRating}
     * @memberof QuestionScores
     */
    realtor: RealtorRating;
    /**
     * 
     * @type {RealtorProvinceRating}
     * @memberof QuestionScores
     */
    province?: RealtorProvinceRating;
}
/**
 * 
 * @export
 * @interface Radius
 */
export interface Radius {
    /**
     * Coordinates order is longitude and latitude, or easting and northing.
     * @type {Set<number>}
     * @memberof Radius
     */
    center: Set<number>;
    /**
     * Distance in meters to expand from the center
     * @type {number}
     * @memberof Radius
     */
    radius: number;
}
/**
 * Data specific to cases of distinction real estate. Cases with distinction real estate will always contain this object. Cases with any other distinction will never contain this object. 
 * @export
 * @interface RealEstate
 */
export interface RealEstate {
    /**
     * 
     * @type {number}
     * @memberof RealEstate
     */
    downPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof RealEstate
     */
    grossMortgage?: number;
    /**
     * 
     * @type {number}
     * @memberof RealEstate
     */
    netMortgage?: number;
}
/**
 * 
 * @export
 * @interface Realtor
 */
export interface Realtor {
    /**
     * 
     * @type {ResourceLinks}
     * @memberof Realtor
     */
    _links: ResourceLinks;
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof Realtor
     */
    realtorID: string;
    /**
     * 
     * @type {RealtorBranch}
     * @memberof Realtor
     */
    realtorBranch?: RealtorBranch;
    /**
     * 
     * @type {string}
     * @memberof Realtor
     */
    name?: string;
    /**
     * URL safe string
     * @type {string}
     * @memberof Realtor
     */
    slug: string;
    /**
     * 
     * @type {ContactInformation}
     * @memberof Realtor
     */
    contactInformation: ContactInformation;
    /**
     * 
     * @type {ImageResource}
     * @memberof Realtor
     */
    image?: ImageResource;
    /**
     * 
     * @type {string}
     * @memberof Realtor
     */
    descriptionTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof Realtor
     */
    descriptionBody?: string;
    /**
     * 
     * @type {number}
     * @memberof Realtor
     */
    numberOfRealtors?: number;
    /**
     * 
     * @type {number}
     * @memberof Realtor
     */
    numberOfEmployees?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Realtor
     */
    associationOfDanishRealtorsMember?: boolean;
    /**
     * URL to the realtor homepage
     * @type {string}
     * @memberof Realtor
     */
    url?: string;
    /**
     * 
     * @type {RealtorStats}
     * @memberof Realtor
     */
    stats?: RealtorStats;
    /**
     * 
     * @type {RealtorSalesPercentages}
     * @memberof Realtor
     */
    salesPercentages?: RealtorSalesPercentages;
    /**
     * 
     * @type {RealtorOverallRating}
     * @memberof Realtor
     */
    rating: RealtorOverallRating;
    /**
     * 
     * @type {RealtorDetailedRatings}
     * @memberof Realtor
     */
    ratings: RealtorDetailedRatings;
    /**
     * Whether or not the realtor was opened recently
     * @type {boolean}
     * @memberof Realtor
     */
    isNew: boolean;
    /**
     * Whether or not to give this realtor preferential treatment in search results
     * @type {boolean}
     * @memberof Realtor
     */
    preferred: boolean;
    /**
     * 
     * @type {RealtorBroadcast}
     * @memberof Realtor
     */
    broadcast: RealtorBroadcast;
    /**
     * 
     * @type {RealtorAddress}
     * @memberof Realtor
     */
    address?: RealtorAddress;
}
/**
 * 
 * @export
 * @interface RealtorAddress
 */
export interface RealtorAddress {
    /**
     * 
     * @type {City}
     * @memberof RealtorAddress
     */
    city?: City;
}
/**
 * 
 * @export
 * @interface RealtorAllOf
 */
export interface RealtorAllOf {
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof RealtorAllOf
     */
    realtorID: string;
    /**
     * 
     * @type {RealtorBranch}
     * @memberof RealtorAllOf
     */
    realtorBranch?: RealtorBranch;
    /**
     * 
     * @type {string}
     * @memberof RealtorAllOf
     */
    name?: string;
    /**
     * URL safe string
     * @type {string}
     * @memberof RealtorAllOf
     */
    slug: string;
    /**
     * 
     * @type {ContactInformation}
     * @memberof RealtorAllOf
     */
    contactInformation: ContactInformation;
    /**
     * 
     * @type {ImageResource}
     * @memberof RealtorAllOf
     */
    image?: ImageResource;
    /**
     * 
     * @type {string}
     * @memberof RealtorAllOf
     */
    descriptionTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof RealtorAllOf
     */
    descriptionBody?: string;
    /**
     * 
     * @type {number}
     * @memberof RealtorAllOf
     */
    numberOfRealtors?: number;
    /**
     * 
     * @type {number}
     * @memberof RealtorAllOf
     */
    numberOfEmployees?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RealtorAllOf
     */
    associationOfDanishRealtorsMember?: boolean;
    /**
     * URL to the realtor homepage
     * @type {string}
     * @memberof RealtorAllOf
     */
    url?: string;
    /**
     * 
     * @type {RealtorStats}
     * @memberof RealtorAllOf
     */
    stats?: RealtorStats;
    /**
     * 
     * @type {RealtorSalesPercentages}
     * @memberof RealtorAllOf
     */
    salesPercentages?: RealtorSalesPercentages;
    /**
     * 
     * @type {RealtorOverallRating}
     * @memberof RealtorAllOf
     */
    rating: RealtorOverallRating;
    /**
     * 
     * @type {RealtorDetailedRatings}
     * @memberof RealtorAllOf
     */
    ratings: RealtorDetailedRatings;
    /**
     * Whether or not the realtor was opened recently
     * @type {boolean}
     * @memberof RealtorAllOf
     */
    isNew: boolean;
    /**
     * Whether or not to give this realtor preferential treatment in search results
     * @type {boolean}
     * @memberof RealtorAllOf
     */
    preferred: boolean;
    /**
     * 
     * @type {RealtorBroadcast}
     * @memberof RealtorAllOf
     */
    broadcast: RealtorBroadcast;
    /**
     * 
     * @type {RealtorAddress}
     * @memberof RealtorAllOf
     */
    address?: RealtorAddress;
}
/**
 * 
 * @export
 * @interface RealtorBranch
 */
export interface RealtorBranch {
    /**
     * The branch name
     * @type {string}
     * @memberof RealtorBranch
     */
    name: string;
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof RealtorBranch
     */
    realtorBranchID: string;
    /**
     * 
     * @type {ImageResource}
     * @memberof RealtorBranch
     */
    logo?: ImageResource;
}
/**
 * Broadcast refers to the act of sending questionnaires to the realtor\'s customers 
 * @export
 * @interface RealtorBroadcast
 */
export interface RealtorBroadcast {
    /**
     * The percentage of available customers that the realtor has sent a questionnaire to. If the realtor has not sent out any questionnaires, this value will be `null`. 
     * @type {number}
     * @memberof RealtorBroadcast
     */
    percentage?: number;
    /**
     * The broadcast percentage that realtors target. Realtors that have a broadcast.percentage at or above the target are given preferential treatment. 
     * @type {number}
     * @memberof RealtorBroadcast
     */
    target: number;
}
/**
 * 
 * @export
 * @interface RealtorBuyerSurveyResults
 */
export interface RealtorBuyerSurveyResults {
    /**
     * Average rating for each question asked to buyers. Buyers are asked six questions. Questions in the array are ordered deterministically. 
     * @type {Array<Question>}
     * @memberof RealtorBuyerSurveyResults
     */
    questions?: Array<Question>;
}
/**
 * Ratings for each question asked to buyers and sellers of the realtor\'s cases. If no one has answered a specific question for a realtor, that question will be excluded from that realtor\'s ratings. 
 * @export
 * @interface RealtorDetailedRatings
 */
export interface RealtorDetailedRatings {
    /**
     * 
     * @type {RealtorSellerSurveyResults}
     * @memberof RealtorDetailedRatings
     */
    seller: RealtorSellerSurveyResults;
    /**
     * 
     * @type {RealtorBuyerSurveyResults}
     * @memberof RealtorDetailedRatings
     */
    buyer: RealtorBuyerSurveyResults;
}
/**
 * The realtor\'s overall rating.
 * @export
 * @interface RealtorOverallRating
 */
export interface RealtorOverallRating {
    /**
     * 
     * @type {RealtorRating}
     * @memberof RealtorOverallRating
     */
    seller?: RealtorRating;
    /**
     * 
     * @type {RealtorRating}
     * @memberof RealtorOverallRating
     */
    buyer?: RealtorRating;
}
/**
 * 
 * @export
 * @interface RealtorProvinceRating
 */
export interface RealtorProvinceRating {
    /**
     * The average score.
     * @type {number}
     * @memberof RealtorProvinceRating
     */
    score: number;
    /**
     * The amount of data points that make up the score.
     * @type {number}
     * @memberof RealtorProvinceRating
     */
    basis: number;
}
/**
 * 
 * @export
 * @interface RealtorRating
 */
export interface RealtorRating {
    /**
     * The average score.
     * @type {number}
     * @memberof RealtorRating
     */
    score: number;
    /**
     * The amount of data points that make up the score.
     * @type {number}
     * @memberof RealtorRating
     */
    basis: number;
    /**
     * In order for a score to be valid, there are a couple of intricate criteria it must meet. Invalid ratings will have a `score` of `0`. 
     * @type {string}
     * @memberof RealtorRating
     */
    state: RealtorRatingStateEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RealtorRatingStateEnum {
    Valid = 'valid',
    TooFewAnswers = 'too_few_answers',
    NotEnoughSurveysSent = 'not_enough_surveys_sent'
}

/**
 * Realtor sales percentage for a specific geographical area. Data in this object is limited to only the realtor\'s sold, closed, registered cases and in a specific geographical area. Note that locationType and addressType are optional, if the keys are omitted this means the stats are for all locationTypes/addressTypes. In simple words - omitted locationType/addressType = all. 
 * @export
 * @interface RealtorSalesPercentageItem
 */
export interface RealtorSalesPercentageItem {
    /**
     * 
     * @type {RealtorStatsLocationType}
     * @memberof RealtorSalesPercentageItem
     */
    locationType?: RealtorStatsLocationType;
    /**
     * The name of the location. Note that this value is always `null` for location type `all`
     * @type {string}
     * @memberof RealtorSalesPercentageItem
     */
    locationName?: string;
    /**
     * 
     * @type {AddressType}
     * @memberof RealtorSalesPercentageItem
     */
    addressType?: AddressType;
    /**
     * The percentage of cases that the realtor has sold, as relative to the total amount of cases closed by the realtor. 
     * @type {number}
     * @memberof RealtorSalesPercentageItem
     */
    salesPercentage: number;
}
/**
 * 
 * @export
 * @interface RealtorSalesPercentages
 */
export interface RealtorSalesPercentages {
    /**
     * 
     * @type {Array<RealtorSalesPercentageItem>}
     * @memberof RealtorSalesPercentages
     */
    all: Array<RealtorSalesPercentageItem>;
    /**
     * 
     * @type {Array<RealtorSalesPercentageItem>}
     * @memberof RealtorSalesPercentages
     */
    province: Array<RealtorSalesPercentageItem>;
    /**
     * 
     * @type {Array<RealtorSalesPercentageItem>}
     * @memberof RealtorSalesPercentages
     */
    municipality: Array<RealtorSalesPercentageItem>;
    /**
     * 
     * @type {Array<RealtorSalesPercentageItem>}
     * @memberof RealtorSalesPercentages
     */
    zip_code: Array<RealtorSalesPercentageItem>;
    /**
     * 
     * @type {Array<RealtorSalesPercentageItem>}
     * @memberof RealtorSalesPercentages
     */
    city: Array<RealtorSalesPercentageItem>;
}
/**
 * 
 * @export
 * @interface RealtorSellerSurveyResults
 */
export interface RealtorSellerSurveyResults {
    /**
     * Average rating for each question asked to sellers. Sellers are asked ten questions. Questions in the array are ordered deterministically. 
     * @type {Array<Question>}
     * @memberof RealtorSellerSurveyResults
     */
    questions?: Array<Question>;
}
/**
 * Various data about the realtor\'s cases. Data is grouped by location type, location name, address type and status.  For example, if one wanted to get the amount of villas that the realtor has sold in the `København` municipality, one would access `realtor.stats.sold.municipality` and then take the `amount` value from the item with the location name `København` and address type `villa`.  As another example, if one wanted to get the average time on market for condos that the realtor currently has for sale across all geographical locations, one would access `realtor.stats.open.all` and then take the `timeOnMarket` value from the item with location name `null` (because we are searching across all geographical areas) and address type `condo`.  Note that no properties in this object are guaranteed to exist; if a realtor has no cases at all, they will have no stats, and this entire object will be empty. This also goes for the smaller denominations; if a realtor has no villas for sale in the `Odense` municipality, `realtor.stats.open.municipality` will not contain an item with location name `Odense` and address type `villa` 
 * @export
 * @interface RealtorStats
 */
export interface RealtorStats {
    /**
     * 
     * @type {RealtorStatsStatus}
     * @memberof RealtorStats
     */
    sold?: RealtorStatsStatus;
    /**
     * 
     * @type {RealtorStatsStatus}
     * @memberof RealtorStats
     */
    open?: RealtorStatsStatus;
}
/**
 * Various data about a realtor\'s cases with a specific status in a specific geographical area. Data in this object is limited to only the realtor\'s cases with a specific status and in a specific geographical area. Note that locationType and addressType are optional, if the keys are omitted this means the stats are for all locationTypes/addressTypes. In simple words - omitted locationType/addressType = all. 
 * @export
 * @interface RealtorStatsItem
 */
export interface RealtorStatsItem {
    /**
     * 
     * @type {RealtorStatsLocationType}
     * @memberof RealtorStatsItem
     */
    locationType?: RealtorStatsLocationType;
    /**
     * The name of the location. Note that this value is always `null` for location type `all`
     * @type {string}
     * @memberof RealtorStatsItem
     */
    locationName?: string;
    /**
     * 
     * @type {AddressType}
     * @memberof RealtorStatsItem
     */
    addressType?: AddressType;
    /**
     * 
     * @type {RealtorStatsStatusClassification}
     * @memberof RealtorStatsItem
     */
    status: RealtorStatsStatusClassification;
    /**
     * The amount of cases the realtor has in the area
     * @type {number}
     * @memberof RealtorStatsItem
     */
    amount: number;
    /**
     * The amount of presale cases the realtor has in the area
     * @type {number}
     * @memberof RealtorStatsItem
     */
    presaleAmount: number;
    /**
     * The average time on market for the realtor\'s cases in the area
     * @type {number}
     * @memberof RealtorStatsItem
     */
    timeOnMarket?: number;
    /**
     * The average price for the realtor\'s cases in the area
     * @type {number}
     * @memberof RealtorStatsItem
     */
    price?: number;
    /**
     * The average price per square meter for the realtor\'s cases in the area
     * @type {number}
     * @memberof RealtorStatsItem
     */
    perAreaPrice?: number;
    /**
     * The average price change in percent for the realtor\'s cases in the area. Note that negative values are allowed 
     * @type {number}
     * @memberof RealtorStatsItem
     */
    priceChangePercentage?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum RealtorStatsLocationType {
    Province = 'province',
    Municipality = 'municipality',
    ZipCode = 'zip_code',
    City = 'city'
}

/**
 * 
 * @export
 * @interface RealtorStatsStatus
 */
export interface RealtorStatsStatus {
    /**
     * 
     * @type {Set<RealtorStatsItem>}
     * @memberof RealtorStatsStatus
     */
    all: Set<RealtorStatsItem>;
    /**
     * 
     * @type {Set<RealtorStatsItem>}
     * @memberof RealtorStatsStatus
     */
    province: Set<RealtorStatsItem>;
    /**
     * 
     * @type {Set<RealtorStatsItem>}
     * @memberof RealtorStatsStatus
     */
    municipality: Set<RealtorStatsItem>;
    /**
     * 
     * @type {Set<RealtorStatsItem>}
     * @memberof RealtorStatsStatus
     */
    zip_code: Set<RealtorStatsItem>;
    /**
     * 
     * @type {Set<RealtorStatsItem>}
     * @memberof RealtorStatsStatus
     */
    city: Set<RealtorStatsItem>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum RealtorStatsStatusClassification {
    Sold = 'sold',
    Open = 'open'
}

/**
 * 
 * @export
 * @interface RealtorsCountResult
 */
export interface RealtorsCountResult {
    /**
     * 
     * @type {number}
     * @memberof RealtorsCountResult
     */
    totalCount: number;
}
/**
 * 
 * @export
 * @interface Registration
 */
export interface Registration {
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    registrationID: string;
    /**
     * 
     * @type {number}
     * @memberof Registration
     */
    municipalityCode: number;
    /**
     * 
     * @type {number}
     * @memberof Registration
     */
    propertyNumber: number;
    /**
     * 
     * @type {RegistrationType}
     * @memberof Registration
     */
    type: RegistrationType;
    /**
     * 
     * @type {number}
     * @memberof Registration
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof Registration
     */
    area?: number;
    /**
     * 
     * @type {number}
     * @memberof Registration
     */
    perAreaPrice?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum RegistrationType {
    Normal = 'normal',
    Family = 'family',
    Auction = 'auction',
    Other = 'other'
}

/**
 * Data specific to cases of distinction rental. Cases with distinction rental will always contain this object. Cases with any other distinction will never contain this object. 
 * @export
 * @interface Rental
 */
export interface Rental {
    /**
     * Rent paid in advance by the tenant. \"Forudbetalt leje\" in Danish
     * @type {number}
     * @memberof Rental
     */
    prepaidRent?: number;
    /**
     * Whether or not pets are allowed at the rental
     * @type {boolean}
     * @memberof Rental
     */
    petsAllowed: boolean;
    /**
     * Forkøbsret in Danish
     * @type {boolean}
     * @memberof Rental
     */
    rightOfFirstRefusal: boolean;
    /**
     * The date from which the rental is available
     * @type {string}
     * @memberof Rental
     */
    from: string;
    /**
     * The date to which the rental is available
     * @type {string}
     * @memberof Rental
     */
    to?: string;
    /**
     * The amount of time in months that the rental is available
     * @type {number}
     * @memberof Rental
     */
    maxRentalMonth?: number;
}
/**
 * 
 * @export
 * @interface ResourceLinks
 */
export interface ResourceLinks {
    /**
     * 
     * @type {HALLinkObject}
     * @memberof ResourceLinks
     */
    self: HALLinkObject;
}
/**
 * 
 * @export
 * @interface Road
 */
export interface Road {
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof Road
     */
    roadID: string;
    /**
     * 
     * @type {number}
     * @memberof Road
     */
    municipalityCode: number;
    /**
     * 
     * @type {number}
     * @memberof Road
     */
    roadCode: number;
    /**
     * 
     * @type {string}
     * @memberof Road
     */
    name: string;
    /**
     * URL safe string
     * @type {string}
     * @memberof Road
     */
    slug: string;
}
/**
 * 
 * @export
 * @interface RoadCollectionResult
 */
export interface RoadCollectionResult {
    /**
     * 
     * @type {Array<Road>}
     * @memberof RoadCollectionResult
     */
    roads?: Array<Road>;
    /**
     * 
     * @type {Array<CustomRoad>}
     * @memberof RoadCollectionResult
     */
    customRoads?: Array<CustomRoad>;
}
/**
 * 
 * @export
 * @interface Search
 */
export interface Search {
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof Search
     */
    searchID?: string;
    /**
     * 
     * @type {SearchQueryMetadata}
     * @memberof Search
     */
    metadata: SearchQueryMetadata;
    /**
     * 
     * @type {SearchQuery}
     * @memberof Search
     */
    query: SearchQuery;
}
/**
 * 
 * @export
 * @interface SearchAddressLocationsResult
 */
export interface SearchAddressLocationsResult {
    /**
     * 
     * @type {Array<SearchLocationsGeographicalEntity>}
     * @memberof SearchAddressLocationsResult
     */
    provinces: Array<SearchLocationsGeographicalEntity>;
    /**
     * 
     * @type {Array<SearchLocationsGeographicalEntity>}
     * @memberof SearchAddressLocationsResult
     */
    municipalities: Array<SearchLocationsGeographicalEntity>;
    /**
     * 
     * @type {Array<SearchLocationsGeographicalEntity>}
     * @memberof SearchAddressLocationsResult
     */
    zipCodes: Array<SearchLocationsGeographicalEntity>;
    /**
     * 
     * @type {Array<SearchLocationsGeographicalEntity>}
     * @memberof SearchAddressLocationsResult
     */
    roads: Array<SearchLocationsGeographicalEntity>;
    /**
     * 
     * @type {Array<SearchLocationsGeographicalEntity>}
     * @memberof SearchAddressLocationsResult
     */
    cities: Array<SearchLocationsGeographicalEntity>;
    /**
     * 
     * @type {Array<SearchLocationsAddress>}
     * @memberof SearchAddressLocationsResult
     */
    addresses: Array<SearchLocationsAddress>;
}
/**
 * 
 * @export
 * @interface SearchAddressesResult
 */
export interface SearchAddressesResult {
    /**
     * 
     * @type {Array<Address>}
     * @memberof SearchAddressesResult
     */
    addresses?: Array<Address>;
    /**
     * The total amount of hits for this search. 
     * @type {number}
     * @memberof SearchAddressesResult
     */
    totalHits: number;
}
/**
 * 
 * @export
 * @interface SearchCaseLocationsResult
 */
export interface SearchCaseLocationsResult {
    /**
     * 
     * @type {Array<SearchLocationsGeographicalEntity>}
     * @memberof SearchCaseLocationsResult
     */
    provinces: Array<SearchLocationsGeographicalEntity>;
    /**
     * 
     * @type {Array<SearchLocationsGeographicalEntity>}
     * @memberof SearchCaseLocationsResult
     */
    municipalities: Array<SearchLocationsGeographicalEntity>;
    /**
     * 
     * @type {Array<SearchLocationsGeographicalEntity>}
     * @memberof SearchCaseLocationsResult
     */
    zipCodes: Array<SearchLocationsGeographicalEntity>;
    /**
     * 
     * @type {Array<SearchLocationsGeographicalEntity>}
     * @memberof SearchCaseLocationsResult
     */
    roads: Array<SearchLocationsGeographicalEntity>;
    /**
     * 
     * @type {Array<SearchLocationsGeographicalEntity>}
     * @memberof SearchCaseLocationsResult
     */
    cities: Array<SearchLocationsGeographicalEntity>;
    /**
     * 
     * @type {Array<SearchLocationsGeographicalEntity>}
     * @memberof SearchCaseLocationsResult
     */
    places: Array<SearchLocationsGeographicalEntity>;
    /**
     * 
     * @type {Array<SearchLocationsCaseAddress>}
     * @memberof SearchCaseLocationsResult
     */
    addresses: Array<SearchLocationsCaseAddress>;
    /**
     * 
     * @type {Array<SearchLocationsGeographicalEntity>}
     * @memberof SearchCaseLocationsResult
     */
    customAreas: Array<SearchLocationsGeographicalEntity>;
}
/**
 * 
 * @export
 * @interface SearchCasesResult
 */
export interface SearchCasesResult {
    /**
     * 
     * @type {Array<Case>}
     * @memberof SearchCasesResult
     */
    cases?: Array<Case>;
    /**
     * The total amount of hits for this search. 
     * @type {number}
     * @memberof SearchCasesResult
     */
    totalHits: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SearchCasesSortBy {
    Address = 'address',
    LivingArea = 'livingArea',
    LotArea = 'lotArea',
    Price = 'price',
    TimeOnMarket = 'timeOnMarket',
    PerAreaPrice = 'perAreaPrice',
    ZipCode = 'zipCode',
    PriceChange = 'priceChange',
    NumRooms = 'numRooms',
    MonthlyExpense = 'monthlyExpense',
    Distance = 'distance',
    Random = 'random',
    LastPriceDropAt = 'lastPriceDropAt'
}

/**
 * 
 * @export
 * @interface SearchLocationsAddress
 */
export interface SearchLocationsAddress {
    /**
     * 
     * @type {string}
     * @memberof SearchLocationsAddress
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SearchLocationsAddress
     */
    addressID: string;
}
/**
 * 
 * @export
 * @interface SearchLocationsCaseAddress
 */
export interface SearchLocationsCaseAddress {
    /**
     * 
     * @type {string}
     * @memberof SearchLocationsCaseAddress
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SearchLocationsCaseAddress
     */
    caseID: string;
}
/**
 * 
 * @export
 * @interface SearchLocationsGeographicalEntity
 */
export interface SearchLocationsGeographicalEntity {
    /**
     * 
     * @type {string}
     * @memberof SearchLocationsGeographicalEntity
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SearchLocationsGeographicalEntity
     */
    count: number;
    /**
     * URL safe string
     * @type {string}
     * @memberof SearchLocationsGeographicalEntity
     */
    slug: string;
}
/**
 * 
 * @export
 * @interface SearchMapAddressesResult
 */
export interface SearchMapAddressesResult {
    /**
     * 
     * @type {Array<AddressPin>}
     * @memberof SearchMapAddressesResult
     */
    addresses?: Array<AddressPin>;
    /**
     * The total amount of hits for this search. 
     * @type {number}
     * @memberof SearchMapAddressesResult
     */
    totalHits: number;
}
/**
 * 
 * @export
 * @interface SearchMapCasesResult
 */
export interface SearchMapCasesResult {
    /**
     * 
     * @type {Array<CasePin>}
     * @memberof SearchMapCasesResult
     */
    cases?: Array<CasePin>;
    /**
     * The total amount of hits for this search. 
     * @type {number}
     * @memberof SearchMapCasesResult
     */
    totalHits: number;
}
/**
 * 
 * @export
 * @interface SearchQuery
 */
export interface SearchQuery {
    /**
     * 
     * @type {SearchScope}
     * @memberof SearchQuery
     */
    scope?: SearchScope;
    /**
     * 
     * @type {Set<AddressType>}
     * @memberof SearchQuery
     */
    addressTypes?: Set<AddressType>;
    /**
     * 
     * @type {Set<EnergyLabel>}
     * @memberof SearchQuery
     */
    energyLabels?: Set<EnergyLabel>;
    /**
     * 
     * @type {number}
     * @memberof SearchQuery
     */
    priceMin?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchQuery
     */
    priceMax?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchQuery
     */
    areaMin?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchQuery
     */
    areaMax?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchQuery
     */
    numberOfRoomsMin?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchQuery
     */
    numberOfRoomsMax?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchQuery
     */
    lotAreaMin?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchQuery
     */
    lotAreaMax?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchQuery
     */
    yearBuiltFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchQuery
     */
    yearBuiltTo?: number;
    /**
     * Lower bound on
     * @type {number}
     * @memberof SearchQuery
     */
    costMin?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchQuery
     */
    costMax?: number;
    /**
     * minimum number of days on the market.
     * @type {number}
     * @memberof SearchQuery
     */
    timeOnMarketMin?: number;
    /**
     * maximum number of days on the market.
     * @type {number}
     * @memberof SearchQuery
     */
    timeOnMarketMax?: number;
    /**
     * the minimum floor where an address resides like minimum on the third floor.
     * @type {number}
     * @memberof SearchQuery
     */
    floorMin?: number;
    /**
     * the maximum floor where an address (apartment) resides like at most the third floor.
     * @type {number}
     * @memberof SearchQuery
     */
    floorMax?: number;
    /**
     * An address has at least this many levels - like a house in two levels.
     * @type {number}
     * @memberof SearchQuery
     */
    levelsMin?: number;
    /**
     * An address has maximum this many levels - like a house in max one level.
     * @type {number}
     * @memberof SearchQuery
     */
    levelsMax?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchQuery
     */
    text?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchQuery
     */
    filterBasement?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchQuery
     */
    filterPriceDecrease?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SearchQuery
     */
    lastPriceDropAtMax?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SearchQuery
     */
    filterOpenHouse?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchQuery
     */
    foreclosureEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchQuery
     */
    foreclosureAuctionType?: SearchQueryForeclosureAuctionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchQuery
     */
    foreclosureAuctionDates?: SearchQueryForeclosureAuctionDatesEnum;
    /**
     * When enabled the result should only include pre-sale (projektsalg) 
     * @type {boolean}
     * @memberof SearchQuery
     */
    preSale?: boolean;
    /**
     * 
     * @type {Set<string>}
     * @memberof SearchQuery
     */
    provinces?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof SearchQuery
     */
    municipalities?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof SearchQuery
     */
    cities?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof SearchQuery
     */
    zipCodes?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof SearchQuery
     */
    roads?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof SearchQuery
     */
    placeNames?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof SearchQuery
     */
    customAreas?: Set<string>;
    /**
     * 
     * @type {Polygon}
     * @memberof SearchQuery
     */
    polygon?: Polygon;
    /**
     * 
     * @type {Radius}
     * @memberof SearchQuery
     */
    radius?: Radius;
    /**
     * Coordinates order is longitude and latitude, or easting and northing.
     * @type {Set<number>}
     * @memberof SearchQuery
     */
    distanceCenter?: Set<number>;
    /**
     * 
     * @type {SearchQuerySortBy}
     * @memberof SearchQuery
     */
    sortBy?: SearchQuerySortBy;
    /**
     * 
     * @type {boolean}
     * @memberof SearchQuery
     */
    sortAscending?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum SearchQueryForeclosureAuctionTypeEnum {
    All = 'All',
    New = 'New',
    FirstAuction = 'FirstAuction',
    SecondAuction = 'SecondAuction'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchQueryForeclosureAuctionDatesEnum {
    NumberOfDays = 'NumberOfDays',
    NextDay = 'NextDay',
    ThreeDays = 'ThreeDays',
    SevenDays = 'SevenDays',
    FourteenDays = 'FourteenDays',
    ThirtyDays = 'ThirtyDays'
}

/**
 * 
 * @export
 * @interface SearchQueryCollectionResult
 */
export interface SearchQueryCollectionResult {
    /**
     * 
     * @type {Array<Search>}
     * @memberof SearchQueryCollectionResult
     */
    searches: Array<Search>;
    /**
     * 
     * @type {number}
     * @memberof SearchQueryCollectionResult
     */
    searchesCount: number;
}
/**
 * 
 * @export
 * @interface SearchQueryMetadata
 */
export interface SearchQueryMetadata {
    /**
     * The user can specify a name for a given query
     * @type {string}
     * @memberof SearchQueryMetadata
     */
    name: string;
    /**
     * 
     * @type {SearchQueryMetadataSubscribe}
     * @memberof SearchQueryMetadata
     */
    subscribe: SearchQueryMetadataSubscribe;
}
/**
 * subscribe to notifications on price changes and new addresses matching the search
 * @export
 * @interface SearchQueryMetadataSubscribe
 */
export interface SearchQueryMetadataSubscribe {
    /**
     * 
     * @type {boolean}
     * @memberof SearchQueryMetadataSubscribe
     */
    email: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchQueryMetadataSubscribe
     */
    pushNotification: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SearchQuerySortBy {
    Address = 'address',
    LivingArea = 'livingArea',
    LotArea = 'lotArea',
    Price = 'price',
    TimeOnMarket = 'timeOnMarket',
    PerAreaPrice = 'perAreaPrice',
    ZipCode = 'zipCode',
    PriceChange = 'priceChange',
    NumRooms = 'numRooms',
    MonthlyExpense = 'monthlyExpense',
    Distance = 'distance',
    LastPriceDropAt = 'lastPriceDropAt'
}

/**
 * 
 * @export
 * @interface SearchRealtorLocationsResult
 */
export interface SearchRealtorLocationsResult {
    /**
     * 
     * @type {Array<SearchLocationsGeographicalEntity>}
     * @memberof SearchRealtorLocationsResult
     */
    provinces: Array<SearchLocationsGeographicalEntity>;
    /**
     * 
     * @type {Array<SearchLocationsGeographicalEntity>}
     * @memberof SearchRealtorLocationsResult
     */
    municipalities: Array<SearchLocationsGeographicalEntity>;
    /**
     * 
     * @type {Array<SearchLocationsGeographicalEntity>}
     * @memberof SearchRealtorLocationsResult
     */
    zipCodes: Array<SearchLocationsGeographicalEntity>;
    /**
     * 
     * @type {Array<SearchLocationsGeographicalEntity>}
     * @memberof SearchRealtorLocationsResult
     */
    cities: Array<SearchLocationsGeographicalEntity>;
}
/**
 * 
 * @export
 * @interface SearchRealtorsResult
 */
export interface SearchRealtorsResult {
    /**
     * 
     * @type {Array<Realtor>}
     * @memberof SearchRealtorsResult
     */
    realtors: Array<Realtor>;
}
/**
 * Search for addresses that are either on market (`ON_MARKET`), sold (`SOLD`) or for rent (`RENT`). Defaults to `ON_MARKET`. 
 * @export
 * @enum {string}
 */
export enum SearchScope {
    OnMarket = 'ON_MARKET',
    Sold = 'SOLD',
    Rent = 'RENT'
}

/**
 * Type Feature used inside Polygon > Feature
 * @export
 * @enum {string}
 */
export enum TypeFeature {
    Feature = 'Feature'
}

/**
 * Type Feature Collection used inside Polygon
 * @export
 * @enum {string}
 */
export enum TypeFeatureCollection {
    FeatureCollection = 'FeatureCollection'
}

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name?: string;
    /**
     * The amount of addresses that the user has favorited
     * @type {number}
     * @memberof User
     */
    favorited: number;
    /**
     * 
     * @type {UserConsent}
     * @memberof User
     */
    consent: UserConsent;
}
/**
 * 
 * @export
 * @interface UserConsent
 */
export interface UserConsent {
    /**
     * Whether or not the user consents to receiving notifications for their saved searches
     * @type {boolean}
     * @memberof UserConsent
     */
    searches: boolean;
    /**
     * Whether or not the user consents to receiving notifications for their favorite addresses
     * @type {boolean}
     * @memberof UserConsent
     */
    favorites: boolean;
}
/**
 * 
 * @export
 * @interface UserPointOfInterest
 */
export interface UserPointOfInterest {
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof UserPointOfInterest
     */
    userPointOfInterestID: string;
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof UserPointOfInterest
     */
    userID: string;
    /**
     * 
     * @type {string}
     * @memberof UserPointOfInterest
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof UserPointOfInterest
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof UserPointOfInterest
     */
    name: string;
    /**
     * 
     * @type {GisPoint}
     * @memberof UserPointOfInterest
     */
    location: GisPoint;
}
/**
 * 
 * @export
 * @interface UserPointOfInterestAllOf
 */
export interface UserPointOfInterestAllOf {
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof UserPointOfInterestAllOf
     */
    userPointOfInterestID: string;
    /**
     * UUIDv4 36 hex characters
     * @type {string}
     * @memberof UserPointOfInterestAllOf
     */
    userID: string;
    /**
     * 
     * @type {string}
     * @memberof UserPointOfInterestAllOf
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof UserPointOfInterestAllOf
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface UserTokenBody
 */
export interface UserTokenBody {
    /**
     * The device token to add
     * @type {string}
     * @memberof UserTokenBody
     */
    token: string;
}
/**
 * 
 * @export
 * @interface UserTokenBody1
 */
export interface UserTokenBody1 {
    /**
     * The device token to delete
     * @type {string}
     * @memberof UserTokenBody1
     */
    token: string;
}
/**
 * 
 * @export
 * @interface UserUpdate
 */
export interface UserUpdate {
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    name?: string;
    /**
     * 
     * @type {UserConsent}
     * @memberof UserUpdate
     */
    consent: UserConsent;
}
/**
 * 
 * @export
 * @interface ZipCode
 */
export interface ZipCode {
    /**
     * 
     * @type {string}
     * @memberof ZipCode
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ZipCode
     */
    zipCode: number;
    /**
     * The group that the zip code belongs to. Zip code groups are used mostly in Copenhagen to group the myriad of smaller zipcodes into larger groups. Examples are zip codes `1050` through `1074` which all belong to the `1000 København K` group. 
     * @type {number}
     * @memberof ZipCode
     */
    group?: number;
    /**
     * URL safe string
     * @type {string}
     * @memberof ZipCode
     */
    slug: string;
}
/**
 * 
 * @export
 * @interface ZipCodeCollectionResult
 */
export interface ZipCodeCollectionResult {
    /**
     * 
     * @type {Array<ZipCode>}
     * @memberof ZipCodeCollectionResult
     */
    zipCodes?: Array<ZipCode>;
}

/**
 * AddressesApi - axios parameter creator
 * @export
 */
export const AddressesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint exists for reasons of backwards compatibility and may be removed at any time. `gstkvhx` keys are mostly unique across the dataset of addresses. This endpoint is not deterministic; that is, if multiple addresses have the same `gstkvhx` key, this endpoint will return only one address *at random* 
         * @summary Get an address by Gstkvhx
         * @param {string} gstkvhx gstkvhx of the Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressGstkvhx: async (gstkvhx: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gstkvhx' is not null or undefined
            assertParamExists('addressGstkvhx', 'gstkvhx', gstkvhx)
            const localVarPath = `/address/{gstkvhx}`
                .replace(`{${"gstkvhx"}}`, encodeURIComponent(String(gstkvhx)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an address by ID
         * @param {string} addressID UUID of the Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressesAddressID: async (addressID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressID' is not null or undefined
            assertParamExists('addressesAddressID', 'addressID', addressID)
            const localVarPath = `/addresses/{addressID}`
                .replace(`{${"addressID"}}`, encodeURIComponent(String(addressID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint only supports public addresses. Attempting to use this endpoint with an address that is not public will result in a status code `404`. 
         * @summary Get the description of an address
         * @param {string} addressID UUID of the Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressesAddressIDDescription: async (addressID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressID' is not null or undefined
            assertParamExists('addressesAddressIDDescription', 'addressID', addressID)
            const localVarPath = `/addresses/{addressID}/description`
                .replace(`{${"addressID"}}`, encodeURIComponent(String(addressID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint exists for reasons of backwards compatibility and may be removed at any time. 
         * @summary Get an address by legacyID, aka uniknummer
         * @param {number} legacyId legacy id of a case
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressByLegacyID: async (legacyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'legacyId' is not null or undefined
            assertParamExists('getAddressByLegacyID', 'legacyId', legacyId)
            const localVarPath = `/addresses/legacy/{legacy_id}`
                .replace(`{${"legacy_id"}}`, encodeURIComponent(String(legacyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint exists for reasons of backwards compatibility and may be removed at any time. 
         * @summary Get an address by municipality code and property number
         * @param {number} municipalityCode 
         * @param {number} propertyNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressByMunicipalityCodeAndPropertyNumber: async (municipalityCode: number, propertyNumber: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'municipalityCode' is not null or undefined
            assertParamExists('getAddressByMunicipalityCodeAndPropertyNumber', 'municipalityCode', municipalityCode)
            // verify required parameter 'propertyNumber' is not null or undefined
            assertParamExists('getAddressByMunicipalityCodeAndPropertyNumber', 'propertyNumber', propertyNumber)
            const localVarPath = `/addresses/{municipalityCode}/{propertyNumber}`
                .replace(`{${"municipalityCode"}}`, encodeURIComponent(String(municipalityCode)))
                .replace(`{${"propertyNumber"}}`, encodeURIComponent(String(propertyNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a timeline of events (built, renovated, price changes, and more) for this address, newest first. If the address doesn\'t exist, returns an empty list.
         * @param {string} addressID UUID of the Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressTimeline: async (addressID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressID' is not null or undefined
            assertParamExists('getAddressTimeline', 'addressID', addressID)
            const localVarPath = `/addresses/{addressID}/timeline`
                .replace(`{${"addressID"}}`, encodeURIComponent(String(addressID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Address\'s oblique image. 
         * @summary Get oblique by address ID
         * @param {string} addressID UUID of the Address
         * @param {DirectionType} [direction] Chooses the image direction.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObliqueByAddressID: async (addressID: string, direction?: DirectionType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressID' is not null or undefined
            assertParamExists('getObliqueByAddressID', 'addressID', addressID)
            const localVarPath = `/address/{addressID}/oblique`
                .replace(`{${"addressID"}}`, encodeURIComponent(String(addressID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a user\'s favorite addresses and previews
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFavorites: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/favorites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search addresses
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [zipCodes] This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
         * @param {Set<string>} [roads] Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
         * @param {Set<string>} [cities] 
         * @param {number} [perPage] Limits the number of results per page. 
         * @param {number} [page] Page number 
         * @param {number} [priceMax] 
         * @param {number} [priceMin] 
         * @param {number} [yearSoldFrom] yearSoldFrom is inclusive
         * @param {number} [yearSoldTo] yearSoldTo is inclusive
         * @param {Set<RegistrationType>} [registrationTypes] The registration type to filter by
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {Set<number>} [bbox] Bounding box. Use like &#x60;api.dk/search?bbox&#x3D;10.721,55.310,12.678,55.841&#x60;. * Index zero is minX * Index one is minY * Index two is maxX * Index three is maxY  X runs left to right (i.e. minX is left and maxX is right). Y runs bottom to top (i.e. minY is bottom and maxY is top).  If the &#x60;bbox&#x60; query parameter is sent, it must contain exactly four items. 
         * @param {number} [areaMin] 
         * @param {number} [areaMax] 
         * @param {boolean} [sold] An address is defined as sold if it has been sold _at least once_ during its existence
         * @param {boolean} [sortAscending] 
         * @param {string} [polygon] Polygon. Use like &#x60;api.dk/search?polygon&#x3D;12.552178,55.784813|12.497472,55.784813|12.304046,55.685812|12.302093,55.654960&#x60;. * Format polygon&#x3D;[lon,lat]|[lon,lat]|.... * A polygon can have a max of 500 points 
         * @param {string} [radius] Radius. Use like &#x60;api.dk/search?radius&#x3D;10350|11.852554,55.767563&#x60;. * Format: [meters]|[lon:centerPoint],[lat:centerPoint] * [meters] must be at least 1 
         * @param {Set<string>} [customAreas] 
         * @param {Set<string>} [additionalCityNames] 
         * @param {'address' | 'livingArea' | 'lotArea' | 'monthlyExpense' | 'numRooms' | 'perAreaPrice' | 'price' | 'priceChange' | 'road' | 'soldDate' | 'soldPrice' | 'timeOnMarket' | 'zipCode'} [sortBy] The &#x60;timeOnMarket&#x60; value has been deprecated, and should not be used. Attempting to sort by &#x60;timeOnMarket&#x60; will not return an error; rather, the default sort value will be used. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAddresses: async (provinces?: Set<string>, municipalities?: Set<string>, zipCodes?: Set<string>, roads?: Set<string>, cities?: Set<string>, perPage?: number, page?: number, priceMax?: number, priceMin?: number, yearSoldFrom?: number, yearSoldTo?: number, registrationTypes?: Set<RegistrationType>, addressTypes?: Set<AddressType>, bbox?: Set<number>, areaMin?: number, areaMax?: number, sold?: boolean, sortAscending?: boolean, polygon?: string, radius?: string, customAreas?: Set<string>, additionalCityNames?: Set<string>, sortBy?: 'address' | 'livingArea' | 'lotArea' | 'monthlyExpense' | 'numRooms' | 'perAreaPrice' | 'price' | 'priceChange' | 'road' | 'soldDate' | 'soldPrice' | 'timeOnMarket' | 'zipCode', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/search/addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (provinces) {
                localVarQueryParameter['provinces'] = Array.from(provinces);
            }

            if (municipalities) {
                localVarQueryParameter['municipalities'] = Array.from(municipalities);
            }

            if (zipCodes) {
                localVarQueryParameter['zipCodes'] = Array.from(zipCodes);
            }

            if (roads) {
                localVarQueryParameter['roads'] = Array.from(roads);
            }

            if (cities) {
                localVarQueryParameter['cities'] = Array.from(cities);
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (priceMax !== undefined) {
                localVarQueryParameter['priceMax'] = priceMax;
            }

            if (priceMin !== undefined) {
                localVarQueryParameter['priceMin'] = priceMin;
            }

            if (yearSoldFrom !== undefined) {
                localVarQueryParameter['yearSoldFrom'] = yearSoldFrom;
            }

            if (yearSoldTo !== undefined) {
                localVarQueryParameter['yearSoldTo'] = yearSoldTo;
            }

            if (registrationTypes) {
                localVarQueryParameter['registrationTypes'] = Array.from(registrationTypes);
            }

            if (addressTypes) {
                localVarQueryParameter['addressTypes'] = Array.from(addressTypes).join(COLLECTION_FORMATS.csv);
            }

            if (bbox) {
                localVarQueryParameter['bbox'] = Array.from(bbox).join(COLLECTION_FORMATS.csv);
            }

            if (areaMin !== undefined) {
                localVarQueryParameter['areaMin'] = areaMin;
            }

            if (areaMax !== undefined) {
                localVarQueryParameter['areaMax'] = areaMax;
            }

            if (sold !== undefined) {
                localVarQueryParameter['sold'] = sold;
            }

            if (sortAscending !== undefined) {
                localVarQueryParameter['sortAscending'] = sortAscending;
            }

            if (polygon !== undefined) {
                localVarQueryParameter['polygon'] = polygon;
            }

            if (radius !== undefined) {
                localVarQueryParameter['radius'] = radius;
            }

            if (customAreas) {
                localVarQueryParameter['customAreas'] = Array.from(customAreas);
            }

            if (additionalCityNames) {
                localVarQueryParameter['additionalCityNames'] = Array.from(additionalCityNames);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search for addresses by locations.  The result is grouped into following location groups:  - `provinces` - `municipalities` - `cities` - `zipCodes` - `roads` - `addresses` 
         * @summary Search address locations
         * @param {string} text Free form search string
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {boolean} [sold] An address is defined as sold if it has been sold _at least once_ during its existence
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAddressesByLocations: async (text: string, addressTypes?: Set<AddressType>, sold?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'text' is not null or undefined
            assertParamExists('searchAddressesByLocations', 'text', text)
            const localVarPath = `/search/locations/addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (addressTypes) {
                localVarQueryParameter['addressTypes'] = Array.from(addressTypes).join(COLLECTION_FORMATS.csv);
            }

            if (sold !== undefined) {
                localVarQueryParameter['sold'] = sold;
            }

            if (text !== undefined) {
                localVarQueryParameter['text'] = text;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search map addresses
         * @param {Set<number>} bbox Bounding box. Use like &#x60;api.dk/search?bbox&#x3D;10.721,55.310,12.678,55.841&#x60;. * Index zero is minX * Index one is minY * Index two is maxX * Index three is maxY  X runs left to right (i.e. minX is left and maxX is right). Y runs bottom to top (i.e. minY is bottom and maxY is top).  If the &#x60;bbox&#x60; query parameter is sent, it must contain exactly four items. 
         * @param {number} [limit] The maximum amount of addresses to return. 
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [zipCodes] This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
         * @param {Set<string>} [roads] Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
         * @param {Set<string>} [cities] 
         * @param {boolean} [sold] An address is defined as sold if it has been sold _at least once_ during its existence
         * @param {number} [yearSoldFrom] yearSoldFrom is inclusive
         * @param {number} [yearSoldTo] yearSoldTo is inclusive
         * @param {number} [priceMax] 
         * @param {number} [priceMin] 
         * @param {Set<RegistrationType>} [registrationTypes] The registration type to filter by
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {number} [areaMin] 
         * @param {number} [areaMax] 
         * @param {string} [polygon] Polygon. Use like &#x60;api.dk/search?polygon&#x3D;12.552178,55.784813|12.497472,55.784813|12.304046,55.685812|12.302093,55.654960&#x60;. * Format polygon&#x3D;[lon,lat]|[lon,lat]|.... * A polygon can have a max of 500 points 
         * @param {string} [radius] Radius. Use like &#x60;api.dk/search?radius&#x3D;10350|11.852554,55.767563&#x60;. * Format: [meters]|[lon:centerPoint],[lat:centerPoint] * [meters] must be at least 1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMapAddresses: async (bbox: Set<number>, limit?: number, provinces?: Set<string>, municipalities?: Set<string>, zipCodes?: Set<string>, roads?: Set<string>, cities?: Set<string>, sold?: boolean, yearSoldFrom?: number, yearSoldTo?: number, priceMax?: number, priceMin?: number, registrationTypes?: Set<RegistrationType>, addressTypes?: Set<AddressType>, areaMin?: number, areaMax?: number, polygon?: string, radius?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bbox' is not null or undefined
            assertParamExists('searchMapAddresses', 'bbox', bbox)
            const localVarPath = `/search/map/addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (bbox) {
                localVarQueryParameter['bbox'] = Array.from(bbox).join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (provinces) {
                localVarQueryParameter['provinces'] = Array.from(provinces);
            }

            if (municipalities) {
                localVarQueryParameter['municipalities'] = Array.from(municipalities);
            }

            if (zipCodes) {
                localVarQueryParameter['zipCodes'] = Array.from(zipCodes);
            }

            if (roads) {
                localVarQueryParameter['roads'] = Array.from(roads);
            }

            if (cities) {
                localVarQueryParameter['cities'] = Array.from(cities);
            }

            if (sold !== undefined) {
                localVarQueryParameter['sold'] = sold;
            }

            if (yearSoldFrom !== undefined) {
                localVarQueryParameter['yearSoldFrom'] = yearSoldFrom;
            }

            if (yearSoldTo !== undefined) {
                localVarQueryParameter['yearSoldTo'] = yearSoldTo;
            }

            if (priceMax !== undefined) {
                localVarQueryParameter['priceMax'] = priceMax;
            }

            if (priceMin !== undefined) {
                localVarQueryParameter['priceMin'] = priceMin;
            }

            if (registrationTypes) {
                localVarQueryParameter['registrationTypes'] = Array.from(registrationTypes);
            }

            if (addressTypes) {
                localVarQueryParameter['addressTypes'] = Array.from(addressTypes).join(COLLECTION_FORMATS.csv);
            }

            if (areaMin !== undefined) {
                localVarQueryParameter['areaMin'] = areaMin;
            }

            if (areaMax !== undefined) {
                localVarQueryParameter['areaMax'] = areaMax;
            }

            if (polygon !== undefined) {
                localVarQueryParameter['polygon'] = polygon;
            }

            if (radius !== undefined) {
                localVarQueryParameter['radius'] = radius;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a user\'s favorite addresses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAddressesFavorites: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/addresses/favorites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This operation is idempotent; calling it for an address that is not in the user\'s list of favorites has no side effects. Note that this endpoint is only compatible with public addresses. Attempting to use an address that is not public in this endpoint will result in a status code `404`. 
         * @summary Remove an address from the user\'s list of favorites
         * @param {string} addressID UUID of the Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoriteAddressDelete: async (addressID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressID' is not null or undefined
            assertParamExists('userFavoriteAddressDelete', 'addressID', addressID)
            const localVarPath = `/user/addresses/{addressID}/favorite`
                .replace(`{${"addressID"}}`, encodeURIComponent(String(addressID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This operation is idempotent; calling it for an address that is already in the user\'s list of favorites has no side effects. Note that this endpoint is only compatible with public addresses. Attempting to use an address that is not public in this endpoint will result in a status code `404`. 
         * @summary Add an address to the user\'s list of favorites
         * @param {string} addressID UUID of the Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoriteAddressPut: async (addressID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressID' is not null or undefined
            assertParamExists('userFavoriteAddressPut', 'addressID', addressID)
            const localVarPath = `/user/addresses/{addressID}/favorite`
                .replace(`{${"addressID"}}`, encodeURIComponent(String(addressID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddressesApi - functional programming interface
 * @export
 */
export const AddressesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AddressesApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint exists for reasons of backwards compatibility and may be removed at any time. `gstkvhx` keys are mostly unique across the dataset of addresses. This endpoint is not deterministic; that is, if multiple addresses have the same `gstkvhx` key, this endpoint will return only one address *at random* 
         * @summary Get an address by Gstkvhx
         * @param {string} gstkvhx gstkvhx of the Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressGstkvhx(gstkvhx: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressGstkvhx(gstkvhx, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an address by ID
         * @param {string} addressID UUID of the Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressesAddressID(addressID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressesAddressID(addressID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint only supports public addresses. Attempting to use this endpoint with an address that is not public will result in a status code `404`. 
         * @summary Get the description of an address
         * @param {string} addressID UUID of the Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressesAddressIDDescription(addressID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressesAddressIDDescription(addressID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint exists for reasons of backwards compatibility and may be removed at any time. 
         * @summary Get an address by legacyID, aka uniknummer
         * @param {number} legacyId legacy id of a case
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressByLegacyID(legacyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddressByLegacyID(legacyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint exists for reasons of backwards compatibility and may be removed at any time. 
         * @summary Get an address by municipality code and property number
         * @param {number} municipalityCode 
         * @param {number} propertyNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressByMunicipalityCodeAndPropertyNumber(municipalityCode: number, propertyNumber: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddressByMunicipalityCodeAndPropertyNumber(municipalityCode, propertyNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a timeline of events (built, renovated, price changes, and more) for this address, newest first. If the address doesn\'t exist, returns an empty list.
         * @param {string} addressID UUID of the Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressTimeline(addressID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddressTimelineEvent>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddressTimeline(addressID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Address\'s oblique image. 
         * @summary Get oblique by address ID
         * @param {string} addressID UUID of the Address
         * @param {DirectionType} [direction] Chooses the image direction.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getObliqueByAddressID(addressID: string, direction?: DirectionType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getObliqueByAddressID(addressID, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a user\'s favorite addresses and previews
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserFavorites(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FavoriteResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserFavorites(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search addresses
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [zipCodes] This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
         * @param {Set<string>} [roads] Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
         * @param {Set<string>} [cities] 
         * @param {number} [perPage] Limits the number of results per page. 
         * @param {number} [page] Page number 
         * @param {number} [priceMax] 
         * @param {number} [priceMin] 
         * @param {number} [yearSoldFrom] yearSoldFrom is inclusive
         * @param {number} [yearSoldTo] yearSoldTo is inclusive
         * @param {Set<RegistrationType>} [registrationTypes] The registration type to filter by
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {Set<number>} [bbox] Bounding box. Use like &#x60;api.dk/search?bbox&#x3D;10.721,55.310,12.678,55.841&#x60;. * Index zero is minX * Index one is minY * Index two is maxX * Index three is maxY  X runs left to right (i.e. minX is left and maxX is right). Y runs bottom to top (i.e. minY is bottom and maxY is top).  If the &#x60;bbox&#x60; query parameter is sent, it must contain exactly four items. 
         * @param {number} [areaMin] 
         * @param {number} [areaMax] 
         * @param {boolean} [sold] An address is defined as sold if it has been sold _at least once_ during its existence
         * @param {boolean} [sortAscending] 
         * @param {string} [polygon] Polygon. Use like &#x60;api.dk/search?polygon&#x3D;12.552178,55.784813|12.497472,55.784813|12.304046,55.685812|12.302093,55.654960&#x60;. * Format polygon&#x3D;[lon,lat]|[lon,lat]|.... * A polygon can have a max of 500 points 
         * @param {string} [radius] Radius. Use like &#x60;api.dk/search?radius&#x3D;10350|11.852554,55.767563&#x60;. * Format: [meters]|[lon:centerPoint],[lat:centerPoint] * [meters] must be at least 1 
         * @param {Set<string>} [customAreas] 
         * @param {Set<string>} [additionalCityNames] 
         * @param {'address' | 'livingArea' | 'lotArea' | 'monthlyExpense' | 'numRooms' | 'perAreaPrice' | 'price' | 'priceChange' | 'road' | 'soldDate' | 'soldPrice' | 'timeOnMarket' | 'zipCode'} [sortBy] The &#x60;timeOnMarket&#x60; value has been deprecated, and should not be used. Attempting to sort by &#x60;timeOnMarket&#x60; will not return an error; rather, the default sort value will be used. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAddresses(provinces?: Set<string>, municipalities?: Set<string>, zipCodes?: Set<string>, roads?: Set<string>, cities?: Set<string>, perPage?: number, page?: number, priceMax?: number, priceMin?: number, yearSoldFrom?: number, yearSoldTo?: number, registrationTypes?: Set<RegistrationType>, addressTypes?: Set<AddressType>, bbox?: Set<number>, areaMin?: number, areaMax?: number, sold?: boolean, sortAscending?: boolean, polygon?: string, radius?: string, customAreas?: Set<string>, additionalCityNames?: Set<string>, sortBy?: 'address' | 'livingArea' | 'lotArea' | 'monthlyExpense' | 'numRooms' | 'perAreaPrice' | 'price' | 'priceChange' | 'road' | 'soldDate' | 'soldPrice' | 'timeOnMarket' | 'zipCode', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HALCollection & SearchAddressesResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAddresses(provinces, municipalities, zipCodes, roads, cities, perPage, page, priceMax, priceMin, yearSoldFrom, yearSoldTo, registrationTypes, addressTypes, bbox, areaMin, areaMax, sold, sortAscending, polygon, radius, customAreas, additionalCityNames, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Search for addresses by locations.  The result is grouped into following location groups:  - `provinces` - `municipalities` - `cities` - `zipCodes` - `roads` - `addresses` 
         * @summary Search address locations
         * @param {string} text Free form search string
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {boolean} [sold] An address is defined as sold if it has been sold _at least once_ during its existence
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAddressesByLocations(text: string, addressTypes?: Set<AddressType>, sold?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchAddressLocationsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAddressesByLocations(text, addressTypes, sold, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search map addresses
         * @param {Set<number>} bbox Bounding box. Use like &#x60;api.dk/search?bbox&#x3D;10.721,55.310,12.678,55.841&#x60;. * Index zero is minX * Index one is minY * Index two is maxX * Index three is maxY  X runs left to right (i.e. minX is left and maxX is right). Y runs bottom to top (i.e. minY is bottom and maxY is top).  If the &#x60;bbox&#x60; query parameter is sent, it must contain exactly four items. 
         * @param {number} [limit] The maximum amount of addresses to return. 
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [zipCodes] This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
         * @param {Set<string>} [roads] Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
         * @param {Set<string>} [cities] 
         * @param {boolean} [sold] An address is defined as sold if it has been sold _at least once_ during its existence
         * @param {number} [yearSoldFrom] yearSoldFrom is inclusive
         * @param {number} [yearSoldTo] yearSoldTo is inclusive
         * @param {number} [priceMax] 
         * @param {number} [priceMin] 
         * @param {Set<RegistrationType>} [registrationTypes] The registration type to filter by
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {number} [areaMin] 
         * @param {number} [areaMax] 
         * @param {string} [polygon] Polygon. Use like &#x60;api.dk/search?polygon&#x3D;12.552178,55.784813|12.497472,55.784813|12.304046,55.685812|12.302093,55.654960&#x60;. * Format polygon&#x3D;[lon,lat]|[lon,lat]|.... * A polygon can have a max of 500 points 
         * @param {string} [radius] Radius. Use like &#x60;api.dk/search?radius&#x3D;10350|11.852554,55.767563&#x60;. * Format: [meters]|[lon:centerPoint],[lat:centerPoint] * [meters] must be at least 1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchMapAddresses(bbox: Set<number>, limit?: number, provinces?: Set<string>, municipalities?: Set<string>, zipCodes?: Set<string>, roads?: Set<string>, cities?: Set<string>, sold?: boolean, yearSoldFrom?: number, yearSoldTo?: number, priceMax?: number, priceMin?: number, registrationTypes?: Set<RegistrationType>, addressTypes?: Set<AddressType>, areaMin?: number, areaMax?: number, polygon?: string, radius?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchMapAddressesResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchMapAddresses(bbox, limit, provinces, municipalities, zipCodes, roads, cities, sold, yearSoldFrom, yearSoldTo, priceMax, priceMin, registrationTypes, addressTypes, areaMin, areaMax, polygon, radius, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a user\'s favorite addresses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAddressesFavorites(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FavoriteAddressesResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAddressesFavorites(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This operation is idempotent; calling it for an address that is not in the user\'s list of favorites has no side effects. Note that this endpoint is only compatible with public addresses. Attempting to use an address that is not public in this endpoint will result in a status code `404`. 
         * @summary Remove an address from the user\'s list of favorites
         * @param {string} addressID UUID of the Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userFavoriteAddressDelete(addressID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userFavoriteAddressDelete(addressID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This operation is idempotent; calling it for an address that is already in the user\'s list of favorites has no side effects. Note that this endpoint is only compatible with public addresses. Attempting to use an address that is not public in this endpoint will result in a status code `404`. 
         * @summary Add an address to the user\'s list of favorites
         * @param {string} addressID UUID of the Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userFavoriteAddressPut(addressID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userFavoriteAddressPut(addressID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AddressesApi - factory interface
 * @export
 */
export const AddressesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddressesApiFp(configuration)
    return {
        /**
         * This endpoint exists for reasons of backwards compatibility and may be removed at any time. `gstkvhx` keys are mostly unique across the dataset of addresses. This endpoint is not deterministic; that is, if multiple addresses have the same `gstkvhx` key, this endpoint will return only one address *at random* 
         * @summary Get an address by Gstkvhx
         * @param {string} gstkvhx gstkvhx of the Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressGstkvhx(gstkvhx: string, options?: any): AxiosPromise<Address> {
            return localVarFp.addressGstkvhx(gstkvhx, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an address by ID
         * @param {string} addressID UUID of the Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressesAddressID(addressID: string, options?: any): AxiosPromise<Address> {
            return localVarFp.addressesAddressID(addressID, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint only supports public addresses. Attempting to use this endpoint with an address that is not public will result in a status code `404`. 
         * @summary Get the description of an address
         * @param {string} addressID UUID of the Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressesAddressIDDescription(addressID: string, options?: any): AxiosPromise<string> {
            return localVarFp.addressesAddressIDDescription(addressID, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint exists for reasons of backwards compatibility and may be removed at any time. 
         * @summary Get an address by legacyID, aka uniknummer
         * @param {number} legacyId legacy id of a case
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressByLegacyID(legacyId: number, options?: any): AxiosPromise<Address> {
            return localVarFp.getAddressByLegacyID(legacyId, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint exists for reasons of backwards compatibility and may be removed at any time. 
         * @summary Get an address by municipality code and property number
         * @param {number} municipalityCode 
         * @param {number} propertyNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressByMunicipalityCodeAndPropertyNumber(municipalityCode: number, propertyNumber: number, options?: any): AxiosPromise<Address> {
            return localVarFp.getAddressByMunicipalityCodeAndPropertyNumber(municipalityCode, propertyNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a timeline of events (built, renovated, price changes, and more) for this address, newest first. If the address doesn\'t exist, returns an empty list.
         * @param {string} addressID UUID of the Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressTimeline(addressID: string, options?: any): AxiosPromise<Array<AddressTimelineEvent>> {
            return localVarFp.getAddressTimeline(addressID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Address\'s oblique image. 
         * @summary Get oblique by address ID
         * @param {string} addressID UUID of the Address
         * @param {DirectionType} [direction] Chooses the image direction.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObliqueByAddressID(addressID: string, direction?: DirectionType, options?: any): AxiosPromise<any> {
            return localVarFp.getObliqueByAddressID(addressID, direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a user\'s favorite addresses and previews
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFavorites(options?: any): AxiosPromise<FavoriteResult> {
            return localVarFp.getUserFavorites(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search addresses
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [zipCodes] This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
         * @param {Set<string>} [roads] Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
         * @param {Set<string>} [cities] 
         * @param {number} [perPage] Limits the number of results per page. 
         * @param {number} [page] Page number 
         * @param {number} [priceMax] 
         * @param {number} [priceMin] 
         * @param {number} [yearSoldFrom] yearSoldFrom is inclusive
         * @param {number} [yearSoldTo] yearSoldTo is inclusive
         * @param {Set<RegistrationType>} [registrationTypes] The registration type to filter by
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {Set<number>} [bbox] Bounding box. Use like &#x60;api.dk/search?bbox&#x3D;10.721,55.310,12.678,55.841&#x60;. * Index zero is minX * Index one is minY * Index two is maxX * Index three is maxY  X runs left to right (i.e. minX is left and maxX is right). Y runs bottom to top (i.e. minY is bottom and maxY is top).  If the &#x60;bbox&#x60; query parameter is sent, it must contain exactly four items. 
         * @param {number} [areaMin] 
         * @param {number} [areaMax] 
         * @param {boolean} [sold] An address is defined as sold if it has been sold _at least once_ during its existence
         * @param {boolean} [sortAscending] 
         * @param {string} [polygon] Polygon. Use like &#x60;api.dk/search?polygon&#x3D;12.552178,55.784813|12.497472,55.784813|12.304046,55.685812|12.302093,55.654960&#x60;. * Format polygon&#x3D;[lon,lat]|[lon,lat]|.... * A polygon can have a max of 500 points 
         * @param {string} [radius] Radius. Use like &#x60;api.dk/search?radius&#x3D;10350|11.852554,55.767563&#x60;. * Format: [meters]|[lon:centerPoint],[lat:centerPoint] * [meters] must be at least 1 
         * @param {Set<string>} [customAreas] 
         * @param {Set<string>} [additionalCityNames] 
         * @param {'address' | 'livingArea' | 'lotArea' | 'monthlyExpense' | 'numRooms' | 'perAreaPrice' | 'price' | 'priceChange' | 'road' | 'soldDate' | 'soldPrice' | 'timeOnMarket' | 'zipCode'} [sortBy] The &#x60;timeOnMarket&#x60; value has been deprecated, and should not be used. Attempting to sort by &#x60;timeOnMarket&#x60; will not return an error; rather, the default sort value will be used. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAddresses(provinces?: Set<string>, municipalities?: Set<string>, zipCodes?: Set<string>, roads?: Set<string>, cities?: Set<string>, perPage?: number, page?: number, priceMax?: number, priceMin?: number, yearSoldFrom?: number, yearSoldTo?: number, registrationTypes?: Set<RegistrationType>, addressTypes?: Set<AddressType>, bbox?: Set<number>, areaMin?: number, areaMax?: number, sold?: boolean, sortAscending?: boolean, polygon?: string, radius?: string, customAreas?: Set<string>, additionalCityNames?: Set<string>, sortBy?: 'address' | 'livingArea' | 'lotArea' | 'monthlyExpense' | 'numRooms' | 'perAreaPrice' | 'price' | 'priceChange' | 'road' | 'soldDate' | 'soldPrice' | 'timeOnMarket' | 'zipCode', options?: any): AxiosPromise<HALCollection & SearchAddressesResult> {
            return localVarFp.searchAddresses(provinces, municipalities, zipCodes, roads, cities, perPage, page, priceMax, priceMin, yearSoldFrom, yearSoldTo, registrationTypes, addressTypes, bbox, areaMin, areaMax, sold, sortAscending, polygon, radius, customAreas, additionalCityNames, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Search for addresses by locations.  The result is grouped into following location groups:  - `provinces` - `municipalities` - `cities` - `zipCodes` - `roads` - `addresses` 
         * @summary Search address locations
         * @param {string} text Free form search string
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {boolean} [sold] An address is defined as sold if it has been sold _at least once_ during its existence
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAddressesByLocations(text: string, addressTypes?: Set<AddressType>, sold?: boolean, options?: any): AxiosPromise<SearchAddressLocationsResult> {
            return localVarFp.searchAddressesByLocations(text, addressTypes, sold, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search map addresses
         * @param {Set<number>} bbox Bounding box. Use like &#x60;api.dk/search?bbox&#x3D;10.721,55.310,12.678,55.841&#x60;. * Index zero is minX * Index one is minY * Index two is maxX * Index three is maxY  X runs left to right (i.e. minX is left and maxX is right). Y runs bottom to top (i.e. minY is bottom and maxY is top).  If the &#x60;bbox&#x60; query parameter is sent, it must contain exactly four items. 
         * @param {number} [limit] The maximum amount of addresses to return. 
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [zipCodes] This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
         * @param {Set<string>} [roads] Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
         * @param {Set<string>} [cities] 
         * @param {boolean} [sold] An address is defined as sold if it has been sold _at least once_ during its existence
         * @param {number} [yearSoldFrom] yearSoldFrom is inclusive
         * @param {number} [yearSoldTo] yearSoldTo is inclusive
         * @param {number} [priceMax] 
         * @param {number} [priceMin] 
         * @param {Set<RegistrationType>} [registrationTypes] The registration type to filter by
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {number} [areaMin] 
         * @param {number} [areaMax] 
         * @param {string} [polygon] Polygon. Use like &#x60;api.dk/search?polygon&#x3D;12.552178,55.784813|12.497472,55.784813|12.304046,55.685812|12.302093,55.654960&#x60;. * Format polygon&#x3D;[lon,lat]|[lon,lat]|.... * A polygon can have a max of 500 points 
         * @param {string} [radius] Radius. Use like &#x60;api.dk/search?radius&#x3D;10350|11.852554,55.767563&#x60;. * Format: [meters]|[lon:centerPoint],[lat:centerPoint] * [meters] must be at least 1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMapAddresses(bbox: Set<number>, limit?: number, provinces?: Set<string>, municipalities?: Set<string>, zipCodes?: Set<string>, roads?: Set<string>, cities?: Set<string>, sold?: boolean, yearSoldFrom?: number, yearSoldTo?: number, priceMax?: number, priceMin?: number, registrationTypes?: Set<RegistrationType>, addressTypes?: Set<AddressType>, areaMin?: number, areaMax?: number, polygon?: string, radius?: string, options?: any): AxiosPromise<SearchMapAddressesResult> {
            return localVarFp.searchMapAddresses(bbox, limit, provinces, municipalities, zipCodes, roads, cities, sold, yearSoldFrom, yearSoldTo, priceMax, priceMin, registrationTypes, addressTypes, areaMin, areaMax, polygon, radius, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a user\'s favorite addresses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAddressesFavorites(options?: any): AxiosPromise<FavoriteAddressesResult> {
            return localVarFp.userAddressesFavorites(options).then((request) => request(axios, basePath));
        },
        /**
         * This operation is idempotent; calling it for an address that is not in the user\'s list of favorites has no side effects. Note that this endpoint is only compatible with public addresses. Attempting to use an address that is not public in this endpoint will result in a status code `404`. 
         * @summary Remove an address from the user\'s list of favorites
         * @param {string} addressID UUID of the Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoriteAddressDelete(addressID: string, options?: any): AxiosPromise<void> {
            return localVarFp.userFavoriteAddressDelete(addressID, options).then((request) => request(axios, basePath));
        },
        /**
         * This operation is idempotent; calling it for an address that is already in the user\'s list of favorites has no side effects. Note that this endpoint is only compatible with public addresses. Attempting to use an address that is not public in this endpoint will result in a status code `404`. 
         * @summary Add an address to the user\'s list of favorites
         * @param {string} addressID UUID of the Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoriteAddressPut(addressID: string, options?: any): AxiosPromise<void> {
            return localVarFp.userFavoriteAddressPut(addressID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addressGstkvhx operation in AddressesApi.
 * @export
 * @interface AddressesApiAddressGstkvhxRequest
 */
export interface AddressesApiAddressGstkvhxRequest {
    /**
     * gstkvhx of the Address
     * @type {string}
     * @memberof AddressesApiAddressGstkvhx
     */
    readonly gstkvhx: string
}

/**
 * Request parameters for addressesAddressID operation in AddressesApi.
 * @export
 * @interface AddressesApiAddressesAddressIDRequest
 */
export interface AddressesApiAddressesAddressIDRequest {
    /**
     * UUID of the Address
     * @type {string}
     * @memberof AddressesApiAddressesAddressID
     */
    readonly addressID: string
}

/**
 * Request parameters for addressesAddressIDDescription operation in AddressesApi.
 * @export
 * @interface AddressesApiAddressesAddressIDDescriptionRequest
 */
export interface AddressesApiAddressesAddressIDDescriptionRequest {
    /**
     * UUID of the Address
     * @type {string}
     * @memberof AddressesApiAddressesAddressIDDescription
     */
    readonly addressID: string
}

/**
 * Request parameters for getAddressByLegacyID operation in AddressesApi.
 * @export
 * @interface AddressesApiGetAddressByLegacyIDRequest
 */
export interface AddressesApiGetAddressByLegacyIDRequest {
    /**
     * legacy id of a case
     * @type {number}
     * @memberof AddressesApiGetAddressByLegacyID
     */
    readonly legacyId: number
}

/**
 * Request parameters for getAddressByMunicipalityCodeAndPropertyNumber operation in AddressesApi.
 * @export
 * @interface AddressesApiGetAddressByMunicipalityCodeAndPropertyNumberRequest
 */
export interface AddressesApiGetAddressByMunicipalityCodeAndPropertyNumberRequest {
    /**
     * 
     * @type {number}
     * @memberof AddressesApiGetAddressByMunicipalityCodeAndPropertyNumber
     */
    readonly municipalityCode: number

    /**
     * 
     * @type {number}
     * @memberof AddressesApiGetAddressByMunicipalityCodeAndPropertyNumber
     */
    readonly propertyNumber: number
}

/**
 * Request parameters for getAddressTimeline operation in AddressesApi.
 * @export
 * @interface AddressesApiGetAddressTimelineRequest
 */
export interface AddressesApiGetAddressTimelineRequest {
    /**
     * UUID of the Address
     * @type {string}
     * @memberof AddressesApiGetAddressTimeline
     */
    readonly addressID: string
}

/**
 * Request parameters for getObliqueByAddressID operation in AddressesApi.
 * @export
 * @interface AddressesApiGetObliqueByAddressIDRequest
 */
export interface AddressesApiGetObliqueByAddressIDRequest {
    /**
     * UUID of the Address
     * @type {string}
     * @memberof AddressesApiGetObliqueByAddressID
     */
    readonly addressID: string

    /**
     * Chooses the image direction.
     * @type {DirectionType}
     * @memberof AddressesApiGetObliqueByAddressID
     */
    readonly direction?: DirectionType
}

/**
 * Request parameters for searchAddresses operation in AddressesApi.
 * @export
 * @interface AddressesApiSearchAddressesRequest
 */
export interface AddressesApiSearchAddressesRequest {
    /**
     * 
     * @type {Set<string>}
     * @memberof AddressesApiSearchAddresses
     */
    readonly provinces?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof AddressesApiSearchAddresses
     */
    readonly municipalities?: Set<string>

    /**
     * This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
     * @type {Set<string>}
     * @memberof AddressesApiSearchAddresses
     */
    readonly zipCodes?: Set<string>

    /**
     * Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
     * @type {Set<string>}
     * @memberof AddressesApiSearchAddresses
     */
    readonly roads?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof AddressesApiSearchAddresses
     */
    readonly cities?: Set<string>

    /**
     * Limits the number of results per page. 
     * @type {number}
     * @memberof AddressesApiSearchAddresses
     */
    readonly perPage?: number

    /**
     * Page number 
     * @type {number}
     * @memberof AddressesApiSearchAddresses
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AddressesApiSearchAddresses
     */
    readonly priceMax?: number

    /**
     * 
     * @type {number}
     * @memberof AddressesApiSearchAddresses
     */
    readonly priceMin?: number

    /**
     * yearSoldFrom is inclusive
     * @type {number}
     * @memberof AddressesApiSearchAddresses
     */
    readonly yearSoldFrom?: number

    /**
     * yearSoldTo is inclusive
     * @type {number}
     * @memberof AddressesApiSearchAddresses
     */
    readonly yearSoldTo?: number

    /**
     * The registration type to filter by
     * @type {Set<RegistrationType>}
     * @memberof AddressesApiSearchAddresses
     */
    readonly registrationTypes?: Set<RegistrationType>

    /**
     * The address type to filter by
     * @type {Set<AddressType>}
     * @memberof AddressesApiSearchAddresses
     */
    readonly addressTypes?: Set<AddressType>

    /**
     * Bounding box. Use like &#x60;api.dk/search?bbox&#x3D;10.721,55.310,12.678,55.841&#x60;. * Index zero is minX * Index one is minY * Index two is maxX * Index three is maxY  X runs left to right (i.e. minX is left and maxX is right). Y runs bottom to top (i.e. minY is bottom and maxY is top).  If the &#x60;bbox&#x60; query parameter is sent, it must contain exactly four items. 
     * @type {Set<number>}
     * @memberof AddressesApiSearchAddresses
     */
    readonly bbox?: Set<number>

    /**
     * 
     * @type {number}
     * @memberof AddressesApiSearchAddresses
     */
    readonly areaMin?: number

    /**
     * 
     * @type {number}
     * @memberof AddressesApiSearchAddresses
     */
    readonly areaMax?: number

    /**
     * An address is defined as sold if it has been sold _at least once_ during its existence
     * @type {boolean}
     * @memberof AddressesApiSearchAddresses
     */
    readonly sold?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AddressesApiSearchAddresses
     */
    readonly sortAscending?: boolean

    /**
     * Polygon. Use like &#x60;api.dk/search?polygon&#x3D;12.552178,55.784813|12.497472,55.784813|12.304046,55.685812|12.302093,55.654960&#x60;. * Format polygon&#x3D;[lon,lat]|[lon,lat]|.... * A polygon can have a max of 500 points 
     * @type {string}
     * @memberof AddressesApiSearchAddresses
     */
    readonly polygon?: string

    /**
     * Radius. Use like &#x60;api.dk/search?radius&#x3D;10350|11.852554,55.767563&#x60;. * Format: [meters]|[lon:centerPoint],[lat:centerPoint] * [meters] must be at least 1 
     * @type {string}
     * @memberof AddressesApiSearchAddresses
     */
    readonly radius?: string

    /**
     * 
     * @type {Set<string>}
     * @memberof AddressesApiSearchAddresses
     */
    readonly customAreas?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof AddressesApiSearchAddresses
     */
    readonly additionalCityNames?: Set<string>

    /**
     * The &#x60;timeOnMarket&#x60; value has been deprecated, and should not be used. Attempting to sort by &#x60;timeOnMarket&#x60; will not return an error; rather, the default sort value will be used. 
     * @type {'address' | 'livingArea' | 'lotArea' | 'monthlyExpense' | 'numRooms' | 'perAreaPrice' | 'price' | 'priceChange' | 'road' | 'soldDate' | 'soldPrice' | 'timeOnMarket' | 'zipCode'}
     * @memberof AddressesApiSearchAddresses
     */
    readonly sortBy?: 'address' | 'livingArea' | 'lotArea' | 'monthlyExpense' | 'numRooms' | 'perAreaPrice' | 'price' | 'priceChange' | 'road' | 'soldDate' | 'soldPrice' | 'timeOnMarket' | 'zipCode'
}

/**
 * Request parameters for searchAddressesByLocations operation in AddressesApi.
 * @export
 * @interface AddressesApiSearchAddressesByLocationsRequest
 */
export interface AddressesApiSearchAddressesByLocationsRequest {
    /**
     * Free form search string
     * @type {string}
     * @memberof AddressesApiSearchAddressesByLocations
     */
    readonly text: string

    /**
     * The address type to filter by
     * @type {Set<AddressType>}
     * @memberof AddressesApiSearchAddressesByLocations
     */
    readonly addressTypes?: Set<AddressType>

    /**
     * An address is defined as sold if it has been sold _at least once_ during its existence
     * @type {boolean}
     * @memberof AddressesApiSearchAddressesByLocations
     */
    readonly sold?: boolean
}

/**
 * Request parameters for searchMapAddresses operation in AddressesApi.
 * @export
 * @interface AddressesApiSearchMapAddressesRequest
 */
export interface AddressesApiSearchMapAddressesRequest {
    /**
     * Bounding box. Use like &#x60;api.dk/search?bbox&#x3D;10.721,55.310,12.678,55.841&#x60;. * Index zero is minX * Index one is minY * Index two is maxX * Index three is maxY  X runs left to right (i.e. minX is left and maxX is right). Y runs bottom to top (i.e. minY is bottom and maxY is top).  If the &#x60;bbox&#x60; query parameter is sent, it must contain exactly four items. 
     * @type {Set<number>}
     * @memberof AddressesApiSearchMapAddresses
     */
    readonly bbox: Set<number>

    /**
     * The maximum amount of addresses to return. 
     * @type {number}
     * @memberof AddressesApiSearchMapAddresses
     */
    readonly limit?: number

    /**
     * 
     * @type {Set<string>}
     * @memberof AddressesApiSearchMapAddresses
     */
    readonly provinces?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof AddressesApiSearchMapAddresses
     */
    readonly municipalities?: Set<string>

    /**
     * This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
     * @type {Set<string>}
     * @memberof AddressesApiSearchMapAddresses
     */
    readonly zipCodes?: Set<string>

    /**
     * Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
     * @type {Set<string>}
     * @memberof AddressesApiSearchMapAddresses
     */
    readonly roads?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof AddressesApiSearchMapAddresses
     */
    readonly cities?: Set<string>

    /**
     * An address is defined as sold if it has been sold _at least once_ during its existence
     * @type {boolean}
     * @memberof AddressesApiSearchMapAddresses
     */
    readonly sold?: boolean

    /**
     * yearSoldFrom is inclusive
     * @type {number}
     * @memberof AddressesApiSearchMapAddresses
     */
    readonly yearSoldFrom?: number

    /**
     * yearSoldTo is inclusive
     * @type {number}
     * @memberof AddressesApiSearchMapAddresses
     */
    readonly yearSoldTo?: number

    /**
     * 
     * @type {number}
     * @memberof AddressesApiSearchMapAddresses
     */
    readonly priceMax?: number

    /**
     * 
     * @type {number}
     * @memberof AddressesApiSearchMapAddresses
     */
    readonly priceMin?: number

    /**
     * The registration type to filter by
     * @type {Set<RegistrationType>}
     * @memberof AddressesApiSearchMapAddresses
     */
    readonly registrationTypes?: Set<RegistrationType>

    /**
     * The address type to filter by
     * @type {Set<AddressType>}
     * @memberof AddressesApiSearchMapAddresses
     */
    readonly addressTypes?: Set<AddressType>

    /**
     * 
     * @type {number}
     * @memberof AddressesApiSearchMapAddresses
     */
    readonly areaMin?: number

    /**
     * 
     * @type {number}
     * @memberof AddressesApiSearchMapAddresses
     */
    readonly areaMax?: number

    /**
     * Polygon. Use like &#x60;api.dk/search?polygon&#x3D;12.552178,55.784813|12.497472,55.784813|12.304046,55.685812|12.302093,55.654960&#x60;. * Format polygon&#x3D;[lon,lat]|[lon,lat]|.... * A polygon can have a max of 500 points 
     * @type {string}
     * @memberof AddressesApiSearchMapAddresses
     */
    readonly polygon?: string

    /**
     * Radius. Use like &#x60;api.dk/search?radius&#x3D;10350|11.852554,55.767563&#x60;. * Format: [meters]|[lon:centerPoint],[lat:centerPoint] * [meters] must be at least 1 
     * @type {string}
     * @memberof AddressesApiSearchMapAddresses
     */
    readonly radius?: string
}

/**
 * Request parameters for userFavoriteAddressDelete operation in AddressesApi.
 * @export
 * @interface AddressesApiUserFavoriteAddressDeleteRequest
 */
export interface AddressesApiUserFavoriteAddressDeleteRequest {
    /**
     * UUID of the Address
     * @type {string}
     * @memberof AddressesApiUserFavoriteAddressDelete
     */
    readonly addressID: string
}

/**
 * Request parameters for userFavoriteAddressPut operation in AddressesApi.
 * @export
 * @interface AddressesApiUserFavoriteAddressPutRequest
 */
export interface AddressesApiUserFavoriteAddressPutRequest {
    /**
     * UUID of the Address
     * @type {string}
     * @memberof AddressesApiUserFavoriteAddressPut
     */
    readonly addressID: string
}

/**
 * AddressesApi - object-oriented interface
 * @export
 * @class AddressesApi
 * @extends {BaseAPI}
 */
export class AddressesApi extends BaseAPI {
    /**
     * This endpoint exists for reasons of backwards compatibility and may be removed at any time. `gstkvhx` keys are mostly unique across the dataset of addresses. This endpoint is not deterministic; that is, if multiple addresses have the same `gstkvhx` key, this endpoint will return only one address *at random* 
     * @summary Get an address by Gstkvhx
     * @param {AddressesApiAddressGstkvhxRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressesApi
     */
    public addressGstkvhx(requestParameters: AddressesApiAddressGstkvhxRequest, options?: any) {
        return AddressesApiFp(this.configuration).addressGstkvhx(requestParameters.gstkvhx, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an address by ID
     * @param {AddressesApiAddressesAddressIDRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressesApi
     */
    public addressesAddressID(requestParameters: AddressesApiAddressesAddressIDRequest, options?: any) {
        return AddressesApiFp(this.configuration).addressesAddressID(requestParameters.addressID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint only supports public addresses. Attempting to use this endpoint with an address that is not public will result in a status code `404`. 
     * @summary Get the description of an address
     * @param {AddressesApiAddressesAddressIDDescriptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressesApi
     */
    public addressesAddressIDDescription(requestParameters: AddressesApiAddressesAddressIDDescriptionRequest, options?: any) {
        return AddressesApiFp(this.configuration).addressesAddressIDDescription(requestParameters.addressID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint exists for reasons of backwards compatibility and may be removed at any time. 
     * @summary Get an address by legacyID, aka uniknummer
     * @param {AddressesApiGetAddressByLegacyIDRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressesApi
     */
    public getAddressByLegacyID(requestParameters: AddressesApiGetAddressByLegacyIDRequest, options?: any) {
        return AddressesApiFp(this.configuration).getAddressByLegacyID(requestParameters.legacyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint exists for reasons of backwards compatibility and may be removed at any time. 
     * @summary Get an address by municipality code and property number
     * @param {AddressesApiGetAddressByMunicipalityCodeAndPropertyNumberRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressesApi
     */
    public getAddressByMunicipalityCodeAndPropertyNumber(requestParameters: AddressesApiGetAddressByMunicipalityCodeAndPropertyNumberRequest, options?: any) {
        return AddressesApiFp(this.configuration).getAddressByMunicipalityCodeAndPropertyNumber(requestParameters.municipalityCode, requestParameters.propertyNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a timeline of events (built, renovated, price changes, and more) for this address, newest first. If the address doesn\'t exist, returns an empty list.
     * @param {AddressesApiGetAddressTimelineRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressesApi
     */
    public getAddressTimeline(requestParameters: AddressesApiGetAddressTimelineRequest, options?: any) {
        return AddressesApiFp(this.configuration).getAddressTimeline(requestParameters.addressID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Address\'s oblique image. 
     * @summary Get oblique by address ID
     * @param {AddressesApiGetObliqueByAddressIDRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressesApi
     */
    public getObliqueByAddressID(requestParameters: AddressesApiGetObliqueByAddressIDRequest, options?: any) {
        return AddressesApiFp(this.configuration).getObliqueByAddressID(requestParameters.addressID, requestParameters.direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a user\'s favorite addresses and previews
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressesApi
     */
    public getUserFavorites(options?: any) {
        return AddressesApiFp(this.configuration).getUserFavorites(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search addresses
     * @param {AddressesApiSearchAddressesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressesApi
     */
    public searchAddresses(requestParameters: AddressesApiSearchAddressesRequest = {}, options?: any) {
        return AddressesApiFp(this.configuration).searchAddresses(requestParameters.provinces, requestParameters.municipalities, requestParameters.zipCodes, requestParameters.roads, requestParameters.cities, requestParameters.perPage, requestParameters.page, requestParameters.priceMax, requestParameters.priceMin, requestParameters.yearSoldFrom, requestParameters.yearSoldTo, requestParameters.registrationTypes, requestParameters.addressTypes, requestParameters.bbox, requestParameters.areaMin, requestParameters.areaMax, requestParameters.sold, requestParameters.sortAscending, requestParameters.polygon, requestParameters.radius, requestParameters.customAreas, requestParameters.additionalCityNames, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search for addresses by locations.  The result is grouped into following location groups:  - `provinces` - `municipalities` - `cities` - `zipCodes` - `roads` - `addresses` 
     * @summary Search address locations
     * @param {AddressesApiSearchAddressesByLocationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressesApi
     */
    public searchAddressesByLocations(requestParameters: AddressesApiSearchAddressesByLocationsRequest, options?: any) {
        return AddressesApiFp(this.configuration).searchAddressesByLocations(requestParameters.text, requestParameters.addressTypes, requestParameters.sold, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search map addresses
     * @param {AddressesApiSearchMapAddressesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressesApi
     */
    public searchMapAddresses(requestParameters: AddressesApiSearchMapAddressesRequest, options?: any) {
        return AddressesApiFp(this.configuration).searchMapAddresses(requestParameters.bbox, requestParameters.limit, requestParameters.provinces, requestParameters.municipalities, requestParameters.zipCodes, requestParameters.roads, requestParameters.cities, requestParameters.sold, requestParameters.yearSoldFrom, requestParameters.yearSoldTo, requestParameters.priceMax, requestParameters.priceMin, requestParameters.registrationTypes, requestParameters.addressTypes, requestParameters.areaMin, requestParameters.areaMax, requestParameters.polygon, requestParameters.radius, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a user\'s favorite addresses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressesApi
     */
    public userAddressesFavorites(options?: any) {
        return AddressesApiFp(this.configuration).userAddressesFavorites(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This operation is idempotent; calling it for an address that is not in the user\'s list of favorites has no side effects. Note that this endpoint is only compatible with public addresses. Attempting to use an address that is not public in this endpoint will result in a status code `404`. 
     * @summary Remove an address from the user\'s list of favorites
     * @param {AddressesApiUserFavoriteAddressDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressesApi
     */
    public userFavoriteAddressDelete(requestParameters: AddressesApiUserFavoriteAddressDeleteRequest, options?: any) {
        return AddressesApiFp(this.configuration).userFavoriteAddressDelete(requestParameters.addressID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This operation is idempotent; calling it for an address that is already in the user\'s list of favorites has no side effects. Note that this endpoint is only compatible with public addresses. Attempting to use an address that is not public in this endpoint will result in a status code `404`. 
     * @summary Add an address to the user\'s list of favorites
     * @param {AddressesApiUserFavoriteAddressPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressesApi
     */
    public userFavoriteAddressPut(requestParameters: AddressesApiUserFavoriteAddressPutRequest, options?: any) {
        return AddressesApiFp(this.configuration).userFavoriteAddressPut(requestParameters.addressID, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CasesApi - axios parameter creator
 * @export
 */
export const CasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a case by ID
         * @param {string} caseID Case uuid (UUIDv4 format).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        casesCaseID: async (caseID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseID' is not null or undefined
            assertParamExists('casesCaseID', 'caseID', caseID)
            const localVarPath = `/cases/{caseID}`
                .replace(`{${"caseID"}}`, encodeURIComponent(String(caseID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Register a case interaction of a given type 
         * @summary This endpoint is deprecated; prefer POST /interactions.
         * @param {string} caseID Case uuid (UUIDv4 format).
         * @param {CaseInteraction} caseInteraction User-case interaction data
         * @param {string} [userID] user uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        casesCaseIDInteractionPost: async (caseID: string, caseInteraction: CaseInteraction, userID?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseID' is not null or undefined
            assertParamExists('casesCaseIDInteractionPost', 'caseID', caseID)
            // verify required parameter 'caseInteraction' is not null or undefined
            assertParamExists('casesCaseIDInteractionPost', 'caseInteraction', caseInteraction)
            const localVarPath = `/cases/{caseID}/interaction`
                .replace(`{${"caseID"}}`, encodeURIComponent(String(caseID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userID !== undefined) {
                localVarQueryParameter['userID'] = userID;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caseInteraction, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get timeline of a case
         * @param {string} caseID Case uuid (UUIDv4 format).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        casesCaseIDTimeline: async (caseID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseID' is not null or undefined
            assertParamExists('casesCaseIDTimeline', 'caseID', caseID)
            const localVarPath = `/cases/{caseID}/timeline`
                .replace(`{${"caseID"}}`, encodeURIComponent(String(caseID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get traffic statistics of a case
         * @param {string} caseID Case uuid (UUIDv4 format).
         * @param {InteractionType} interactionType This is the same as &#x60;type&#x60; in &#x60;POST /interactions&#x60; endpoint. However, only the following types are considered valid: * click_to_realtor * presentation_page_viewed * click_favorite 
         * @param {'day' | 'week' | 'month'} [granularity] This is the duration of time with which to group the data. Defaults to \&quot;day\&quot; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        casesCaseIDTraffic: async (caseID: string, interactionType: InteractionType, granularity?: 'day' | 'week' | 'month', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseID' is not null or undefined
            assertParamExists('casesCaseIDTraffic', 'caseID', caseID)
            // verify required parameter 'interactionType' is not null or undefined
            assertParamExists('casesCaseIDTraffic', 'interactionType', interactionType)
            const localVarPath = `/cases/{caseID}/traffic`
                .replace(`{${"caseID"}}`, encodeURIComponent(String(caseID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (interactionType !== undefined) {
                localVarQueryParameter['interactionType'] = interactionType;
            }

            if (granularity !== undefined) {
                localVarQueryParameter['granularity'] = granularity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint is deprecated; prefer the totalHits property of GET /search/cases.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        casesOpenCount: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cases/open/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Case\'s oblique image. 
         * @summary Get oblique
         * @param {string} caseID Case uuid (UUIDv4 format).
         * @param {DirectionType} [direction] Chooses the image direction.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOblique: async (caseID: string, direction?: DirectionType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseID' is not null or undefined
            assertParamExists('getOblique', 'caseID', caseID)
            const localVarPath = `/cases/{caseID}/oblique`
                .replace(`{${"caseID"}}`, encodeURIComponent(String(caseID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get case statistics
         * @summary Get case statistics. The order of results is the same as the input order.
         * @param {Array<CasesStatsRequestBodyItem>} casesStatsRequestBodyItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCasesStats: async (casesStatsRequestBodyItem: Array<CasesStatsRequestBodyItem>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'casesStatsRequestBodyItem' is not null or undefined
            assertParamExists('postCasesStats', 'casesStatsRequestBodyItem', casesStatsRequestBodyItem)
            const localVarPath = `/cases/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(casesStatsRequestBodyItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search cases
         * @param {number} [floorMin] the minimum floor where an address resides like minimum on the third floor.
         * @param {number} [floorMax] the maximum floor where an address (apartment) resides like at most the third floor.
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [cities] 
         * @param {Set<string>} [zipCodes] This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
         * @param {Set<string>} [roads] Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
         * @param {Set<string>} [placeNames] 
         * @param {Set<string>} [customAreas] 
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {number} [priceMax] 
         * @param {number} [priceMin] 
         * @param {number} [monthlyExpenseMin] Case minimum monthly expense.
         * @param {number} [monthlyExpenseMax] Case maximum monthly expense.
         * @param {string} [freeText] Free text search in the title and description of cases
         * @param {boolean} [filterOpenHouse] Whether or not cases returned have an upcoming open house event
         * @param {number} [perPage] Limits the number of results per page. 
         * @param {number} [page] Page number 
         * @param {Set<EnergyLabel>} [energyLabels] 
         * @param {number} [areaMin] 
         * @param {number} [areaMax] 
         * @param {number} [numberOfRoomsMin] 
         * @param {number} [numberOfRoomsMax] 
         * @param {number} [yearBuiltFrom] 
         * @param {number} [yearBuiltTo] 
         * @param {number} [lotAreaMin] 
         * @param {number} [lotAreaMax] 
         * @param {number} [levelsMin] An address has at least this many levels - like a house in two levels.
         * @param {number} [levelsMax] An address has maximum this many levels - like a house in max one level.
         * @param {boolean} [presale] When enabled the result should only include pre-sale (projektsalg)
         * @param {number} [basementAreaMin] 
         * @param {number} [basementAreaMax] 
         * @param {boolean} [filterPriceDrop] 
         * @param {number} [lastPriceDropAtMax] Maximum number of days since the last price drop, if any
         * @param {number} [costMin] Lower bound on price
         * @param {number} [costMax] upper bound on price
         * @param {number} [timeOnMarketMin] minimum number of days on the market.
         * @param {number} [timeOnMarketMax] maximum number of days on the market.
         * @param {boolean} [filterBasement] Whether or not cases returned have a basement
         * @param {string} [yearSoldMin] 
         * @param {string} [yearSoldMax] 
         * @param {boolean} [highlighted] 
         * @param {boolean} [sortAscending] 
         * @param {string} [polygon] Polygon. Use like &#x60;api.dk/search?polygon&#x3D;12.552178,55.784813|12.497472,55.784813|12.304046,55.685812|12.302093,55.654960&#x60;. * Format polygon&#x3D;[lon,lat]|[lon,lat]|.... * A polygon can have a max of 500 points 
         * @param {string} [radius] Radius. Use like &#x60;api.dk/search?radius&#x3D;10350|11.852554,55.767563&#x60;. * Format: [meters]|[lon:centerPoint],[lat:centerPoint] * [meters] must be at least 1 
         * @param {string} [realtorBranchName] 
         * @param {string} [realtorID] 
         * @param {SearchCasesSortBy} [sortBy] 
         * @param {Set<number>} [sortByDistanceCenter] required when sortBy is set to distance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCases: async (floorMin?: number, floorMax?: number, provinces?: Set<string>, municipalities?: Set<string>, cities?: Set<string>, zipCodes?: Set<string>, roads?: Set<string>, placeNames?: Set<string>, customAreas?: Set<string>, addressTypes?: Set<AddressType>, priceMax?: number, priceMin?: number, monthlyExpenseMin?: number, monthlyExpenseMax?: number, freeText?: string, filterOpenHouse?: boolean, perPage?: number, page?: number, energyLabels?: Set<EnergyLabel>, areaMin?: number, areaMax?: number, numberOfRoomsMin?: number, numberOfRoomsMax?: number, yearBuiltFrom?: number, yearBuiltTo?: number, lotAreaMin?: number, lotAreaMax?: number, levelsMin?: number, levelsMax?: number, presale?: boolean, basementAreaMin?: number, basementAreaMax?: number, filterPriceDrop?: boolean, lastPriceDropAtMax?: number, costMin?: number, costMax?: number, timeOnMarketMin?: number, timeOnMarketMax?: number, filterBasement?: boolean, yearSoldMin?: string, yearSoldMax?: string, highlighted?: boolean, sortAscending?: boolean, polygon?: string, radius?: string, realtorBranchName?: string, realtorID?: string, sortBy?: SearchCasesSortBy, sortByDistanceCenter?: Set<number>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/search/cases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (floorMin !== undefined) {
                localVarQueryParameter['floorMin'] = floorMin;
            }

            if (floorMax !== undefined) {
                localVarQueryParameter['floorMax'] = floorMax;
            }

            if (provinces) {
                localVarQueryParameter['provinces'] = Array.from(provinces);
            }

            if (municipalities) {
                localVarQueryParameter['municipalities'] = Array.from(municipalities);
            }

            if (cities) {
                localVarQueryParameter['cities'] = Array.from(cities);
            }

            if (zipCodes) {
                localVarQueryParameter['zipCodes'] = Array.from(zipCodes);
            }

            if (roads) {
                localVarQueryParameter['roads'] = Array.from(roads);
            }

            if (placeNames) {
                localVarQueryParameter['placeNames'] = Array.from(placeNames);
            }

            if (customAreas) {
                localVarQueryParameter['customAreas'] = Array.from(customAreas);
            }

            if (addressTypes) {
                localVarQueryParameter['addressTypes'] = Array.from(addressTypes).join(COLLECTION_FORMATS.csv);
            }

            if (priceMax !== undefined) {
                localVarQueryParameter['priceMax'] = priceMax;
            }

            if (priceMin !== undefined) {
                localVarQueryParameter['priceMin'] = priceMin;
            }

            if (monthlyExpenseMin !== undefined) {
                localVarQueryParameter['monthlyExpenseMin'] = monthlyExpenseMin;
            }

            if (monthlyExpenseMax !== undefined) {
                localVarQueryParameter['monthlyExpenseMax'] = monthlyExpenseMax;
            }

            if (freeText !== undefined) {
                localVarQueryParameter['freeText'] = freeText;
            }

            if (filterOpenHouse !== undefined) {
                localVarQueryParameter['filterOpenHouse'] = filterOpenHouse;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (energyLabels) {
                localVarQueryParameter['energyLabels'] = Array.from(energyLabels).join(COLLECTION_FORMATS.csv);
            }

            if (areaMin !== undefined) {
                localVarQueryParameter['areaMin'] = areaMin;
            }

            if (areaMax !== undefined) {
                localVarQueryParameter['areaMax'] = areaMax;
            }

            if (numberOfRoomsMin !== undefined) {
                localVarQueryParameter['numberOfRoomsMin'] = numberOfRoomsMin;
            }

            if (numberOfRoomsMax !== undefined) {
                localVarQueryParameter['numberOfRoomsMax'] = numberOfRoomsMax;
            }

            if (yearBuiltFrom !== undefined) {
                localVarQueryParameter['yearBuiltFrom'] = yearBuiltFrom;
            }

            if (yearBuiltTo !== undefined) {
                localVarQueryParameter['yearBuiltTo'] = yearBuiltTo;
            }

            if (lotAreaMin !== undefined) {
                localVarQueryParameter['lotAreaMin'] = lotAreaMin;
            }

            if (lotAreaMax !== undefined) {
                localVarQueryParameter['lotAreaMax'] = lotAreaMax;
            }

            if (levelsMin !== undefined) {
                localVarQueryParameter['levelsMin'] = levelsMin;
            }

            if (levelsMax !== undefined) {
                localVarQueryParameter['levelsMax'] = levelsMax;
            }

            if (presale !== undefined) {
                localVarQueryParameter['presale'] = presale;
            }

            if (basementAreaMin !== undefined) {
                localVarQueryParameter['basementAreaMin'] = basementAreaMin;
            }

            if (basementAreaMax !== undefined) {
                localVarQueryParameter['basementAreaMax'] = basementAreaMax;
            }

            if (filterPriceDrop !== undefined) {
                localVarQueryParameter['filterPriceDrop'] = filterPriceDrop;
            }

            if (lastPriceDropAtMax !== undefined) {
                localVarQueryParameter['lastPriceDropAtMax'] = lastPriceDropAtMax;
            }

            if (costMin !== undefined) {
                localVarQueryParameter['costMin'] = costMin;
            }

            if (costMax !== undefined) {
                localVarQueryParameter['costMax'] = costMax;
            }

            if (timeOnMarketMin !== undefined) {
                localVarQueryParameter['timeOnMarketMin'] = timeOnMarketMin;
            }

            if (timeOnMarketMax !== undefined) {
                localVarQueryParameter['timeOnMarketMax'] = timeOnMarketMax;
            }

            if (filterBasement !== undefined) {
                localVarQueryParameter['filterBasement'] = filterBasement;
            }

            if (yearSoldMin !== undefined) {
                localVarQueryParameter['yearSoldMin'] = (yearSoldMin as any instanceof Date) ?
                    (yearSoldMin as any).toISOString().substr(0,10) :
                    yearSoldMin;
            }

            if (yearSoldMax !== undefined) {
                localVarQueryParameter['yearSoldMax'] = (yearSoldMax as any instanceof Date) ?
                    (yearSoldMax as any).toISOString().substr(0,10) :
                    yearSoldMax;
            }

            if (highlighted !== undefined) {
                localVarQueryParameter['highlighted'] = highlighted;
            }

            if (sortAscending !== undefined) {
                localVarQueryParameter['sortAscending'] = sortAscending;
            }

            if (polygon !== undefined) {
                localVarQueryParameter['polygon'] = polygon;
            }

            if (radius !== undefined) {
                localVarQueryParameter['radius'] = radius;
            }

            if (realtorBranchName !== undefined) {
                localVarQueryParameter['realtorBranchName'] = realtorBranchName;
            }

            if (realtorID !== undefined) {
                localVarQueryParameter['realtorID'] = realtorID;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortByDistanceCenter) {
                localVarQueryParameter['sortByDistanceCenter'] = Array.from(sortByDistanceCenter);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search for cases by locations.  The result is grouped into following location groups:  - `provinces` - `municipalities` - `cities` - `zipCodes` - `roads` - `places` - `addresses` - `customAreas` 
         * @summary Search case locations
         * @param {string} text Free form search string
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCasesByLocations: async (text: string, addressTypes?: Set<AddressType>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'text' is not null or undefined
            assertParamExists('searchCasesByLocations', 'text', text)
            const localVarPath = `/search/locations/cases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (addressTypes) {
                localVarQueryParameter['addressTypes'] = Array.from(addressTypes).join(COLLECTION_FORMATS.csv);
            }

            if (text !== undefined) {
                localVarQueryParameter['text'] = text;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Map search cases
         * @param {Set<number>} bbox Bounding box. Use like &#x60;api.dk/search?bbox&#x3D;10.721,55.310,12.678,55.841&#x60;. * Index zero is minX * Index one is minY * Index two is maxX * Index three is maxY  X runs left to right (i.e. minX is left and maxX is right). Y runs bottom to top (i.e. minY is bottom and maxY is top).  If the &#x60;bbox&#x60; query parameter is sent, it must contain exactly four items. 
         * @param {number} [floorMin] the minimum floor where an address resides like minimum on the third floor.
         * @param {number} [floorMax] the maximum floor where an address (apartment) resides like at most the third floor.
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [cities] 
         * @param {Set<string>} [zipCodes] This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
         * @param {Set<string>} [roads] Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
         * @param {Set<string>} [placeNames] 
         * @param {Set<string>} [customAreas] 
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {number} [priceMax] 
         * @param {number} [priceMin] 
         * @param {number} [monthlyExpenseMin] Case minimum monthly expense.
         * @param {number} [monthlyExpenseMax] Case maximum monthly expense.
         * @param {string} [freeText] Free text search in the title and description of cases
         * @param {boolean} [filterOpenHouse] Whether or not cases returned have an upcoming open house event
         * @param {Set<EnergyLabel>} [energyLabels] 
         * @param {number} [areaMin] 
         * @param {number} [areaMax] 
         * @param {number} [numberOfRoomsMin] 
         * @param {number} [numberOfRoomsMax] 
         * @param {number} [yearBuiltFrom] 
         * @param {number} [yearBuiltTo] 
         * @param {number} [lotAreaMin] 
         * @param {number} [lotAreaMax] 
         * @param {number} [levelsMin] An address has at least this many levels - like a house in two levels.
         * @param {number} [levelsMax] An address has maximum this many levels - like a house in max one level.
         * @param {boolean} [presale] When enabled the result should only include pre-sale (projektsalg)
         * @param {number} [basementAreaMin] 
         * @param {number} [basementAreaMax] 
         * @param {boolean} [filterPriceDrop] 
         * @param {number} [lastPriceDropAtMax] Maximum number of days since the last price drop, if any
         * @param {number} [costMin] Lower bound on price
         * @param {number} [costMax] upper bound on price
         * @param {number} [timeOnMarketMin] minimum number of days on the market.
         * @param {number} [timeOnMarketMax] maximum number of days on the market.
         * @param {boolean} [filterBasement] Whether or not cases returned have a basement
         * @param {string} [yearSoldMin] 
         * @param {string} [yearSoldMax] 
         * @param {boolean} [highlighted] 
         * @param {string} [polygon] Polygon. Use like &#x60;api.dk/search?polygon&#x3D;12.552178,55.784813|12.497472,55.784813|12.304046,55.685812|12.302093,55.654960&#x60;. * Format polygon&#x3D;[lon,lat]|[lon,lat]|.... * A polygon can have a max of 500 points 
         * @param {string} [radius] Radius. Use like &#x60;api.dk/search?radius&#x3D;10350|11.852554,55.767563&#x60;. * Format: [meters]|[lon:centerPoint],[lat:centerPoint] * [meters] must be at least 1 
         * @param {SearchCasesSortBy} [sortBy] 
         * @param {boolean} [sortAscending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMapCases: async (bbox: Set<number>, floorMin?: number, floorMax?: number, provinces?: Set<string>, municipalities?: Set<string>, cities?: Set<string>, zipCodes?: Set<string>, roads?: Set<string>, placeNames?: Set<string>, customAreas?: Set<string>, addressTypes?: Set<AddressType>, priceMax?: number, priceMin?: number, monthlyExpenseMin?: number, monthlyExpenseMax?: number, freeText?: string, filterOpenHouse?: boolean, energyLabels?: Set<EnergyLabel>, areaMin?: number, areaMax?: number, numberOfRoomsMin?: number, numberOfRoomsMax?: number, yearBuiltFrom?: number, yearBuiltTo?: number, lotAreaMin?: number, lotAreaMax?: number, levelsMin?: number, levelsMax?: number, presale?: boolean, basementAreaMin?: number, basementAreaMax?: number, filterPriceDrop?: boolean, lastPriceDropAtMax?: number, costMin?: number, costMax?: number, timeOnMarketMin?: number, timeOnMarketMax?: number, filterBasement?: boolean, yearSoldMin?: string, yearSoldMax?: string, highlighted?: boolean, polygon?: string, radius?: string, sortBy?: SearchCasesSortBy, sortAscending?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bbox' is not null or undefined
            assertParamExists('searchMapCases', 'bbox', bbox)
            const localVarPath = `/search/map/cases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (floorMin !== undefined) {
                localVarQueryParameter['floorMin'] = floorMin;
            }

            if (floorMax !== undefined) {
                localVarQueryParameter['floorMax'] = floorMax;
            }

            if (provinces) {
                localVarQueryParameter['provinces'] = Array.from(provinces);
            }

            if (municipalities) {
                localVarQueryParameter['municipalities'] = Array.from(municipalities);
            }

            if (cities) {
                localVarQueryParameter['cities'] = Array.from(cities);
            }

            if (zipCodes) {
                localVarQueryParameter['zipCodes'] = Array.from(zipCodes);
            }

            if (roads) {
                localVarQueryParameter['roads'] = Array.from(roads);
            }

            if (placeNames) {
                localVarQueryParameter['placeNames'] = Array.from(placeNames);
            }

            if (customAreas) {
                localVarQueryParameter['customAreas'] = Array.from(customAreas);
            }

            if (addressTypes) {
                localVarQueryParameter['addressTypes'] = Array.from(addressTypes).join(COLLECTION_FORMATS.csv);
            }

            if (priceMax !== undefined) {
                localVarQueryParameter['priceMax'] = priceMax;
            }

            if (priceMin !== undefined) {
                localVarQueryParameter['priceMin'] = priceMin;
            }

            if (monthlyExpenseMin !== undefined) {
                localVarQueryParameter['monthlyExpenseMin'] = monthlyExpenseMin;
            }

            if (monthlyExpenseMax !== undefined) {
                localVarQueryParameter['monthlyExpenseMax'] = monthlyExpenseMax;
            }

            if (freeText !== undefined) {
                localVarQueryParameter['freeText'] = freeText;
            }

            if (filterOpenHouse !== undefined) {
                localVarQueryParameter['filterOpenHouse'] = filterOpenHouse;
            }

            if (energyLabels) {
                localVarQueryParameter['energyLabels'] = Array.from(energyLabels).join(COLLECTION_FORMATS.csv);
            }

            if (areaMin !== undefined) {
                localVarQueryParameter['areaMin'] = areaMin;
            }

            if (areaMax !== undefined) {
                localVarQueryParameter['areaMax'] = areaMax;
            }

            if (numberOfRoomsMin !== undefined) {
                localVarQueryParameter['numberOfRoomsMin'] = numberOfRoomsMin;
            }

            if (numberOfRoomsMax !== undefined) {
                localVarQueryParameter['numberOfRoomsMax'] = numberOfRoomsMax;
            }

            if (yearBuiltFrom !== undefined) {
                localVarQueryParameter['yearBuiltFrom'] = yearBuiltFrom;
            }

            if (yearBuiltTo !== undefined) {
                localVarQueryParameter['yearBuiltTo'] = yearBuiltTo;
            }

            if (lotAreaMin !== undefined) {
                localVarQueryParameter['lotAreaMin'] = lotAreaMin;
            }

            if (lotAreaMax !== undefined) {
                localVarQueryParameter['lotAreaMax'] = lotAreaMax;
            }

            if (levelsMin !== undefined) {
                localVarQueryParameter['levelsMin'] = levelsMin;
            }

            if (levelsMax !== undefined) {
                localVarQueryParameter['levelsMax'] = levelsMax;
            }

            if (presale !== undefined) {
                localVarQueryParameter['presale'] = presale;
            }

            if (basementAreaMin !== undefined) {
                localVarQueryParameter['basementAreaMin'] = basementAreaMin;
            }

            if (basementAreaMax !== undefined) {
                localVarQueryParameter['basementAreaMax'] = basementAreaMax;
            }

            if (filterPriceDrop !== undefined) {
                localVarQueryParameter['filterPriceDrop'] = filterPriceDrop;
            }

            if (lastPriceDropAtMax !== undefined) {
                localVarQueryParameter['lastPriceDropAtMax'] = lastPriceDropAtMax;
            }

            if (costMin !== undefined) {
                localVarQueryParameter['costMin'] = costMin;
            }

            if (costMax !== undefined) {
                localVarQueryParameter['costMax'] = costMax;
            }

            if (timeOnMarketMin !== undefined) {
                localVarQueryParameter['timeOnMarketMin'] = timeOnMarketMin;
            }

            if (timeOnMarketMax !== undefined) {
                localVarQueryParameter['timeOnMarketMax'] = timeOnMarketMax;
            }

            if (filterBasement !== undefined) {
                localVarQueryParameter['filterBasement'] = filterBasement;
            }

            if (yearSoldMin !== undefined) {
                localVarQueryParameter['yearSoldMin'] = (yearSoldMin as any instanceof Date) ?
                    (yearSoldMin as any).toISOString().substr(0,10) :
                    yearSoldMin;
            }

            if (yearSoldMax !== undefined) {
                localVarQueryParameter['yearSoldMax'] = (yearSoldMax as any instanceof Date) ?
                    (yearSoldMax as any).toISOString().substr(0,10) :
                    yearSoldMax;
            }

            if (highlighted !== undefined) {
                localVarQueryParameter['highlighted'] = highlighted;
            }

            if (bbox) {
                localVarQueryParameter['bbox'] = Array.from(bbox).join(COLLECTION_FORMATS.csv);
            }

            if (polygon !== undefined) {
                localVarQueryParameter['polygon'] = polygon;
            }

            if (radius !== undefined) {
                localVarQueryParameter['radius'] = radius;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortAscending !== undefined) {
                localVarQueryParameter['sortAscending'] = sortAscending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CasesApi - functional programming interface
 * @export
 */
export const CasesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CasesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a case by ID
         * @param {string} caseID Case uuid (UUIDv4 format).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async casesCaseID(caseID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Case>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.casesCaseID(caseID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Register a case interaction of a given type 
         * @summary This endpoint is deprecated; prefer POST /interactions.
         * @param {string} caseID Case uuid (UUIDv4 format).
         * @param {CaseInteraction} caseInteraction User-case interaction data
         * @param {string} [userID] user uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async casesCaseIDInteractionPost(caseID: string, caseInteraction: CaseInteraction, userID?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.casesCaseIDInteractionPost(caseID, caseInteraction, userID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get timeline of a case
         * @param {string} caseID Case uuid (UUIDv4 format).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async casesCaseIDTimeline(caseID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseTimeline>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.casesCaseIDTimeline(caseID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get traffic statistics of a case
         * @param {string} caseID Case uuid (UUIDv4 format).
         * @param {InteractionType} interactionType This is the same as &#x60;type&#x60; in &#x60;POST /interactions&#x60; endpoint. However, only the following types are considered valid: * click_to_realtor * presentation_page_viewed * click_favorite 
         * @param {'day' | 'week' | 'month'} [granularity] This is the duration of time with which to group the data. Defaults to \&quot;day\&quot; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async casesCaseIDTraffic(caseID: string, interactionType: InteractionType, granularity?: 'day' | 'week' | 'month', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseTrafficStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.casesCaseIDTraffic(caseID, interactionType, granularity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This endpoint is deprecated; prefer the totalHits property of GET /search/cases.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async casesOpenCount(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CasesOpenCountResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.casesOpenCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Case\'s oblique image. 
         * @summary Get oblique
         * @param {string} caseID Case uuid (UUIDv4 format).
         * @param {DirectionType} [direction] Chooses the image direction.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOblique(caseID: string, direction?: DirectionType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOblique(caseID, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get case statistics
         * @summary Get case statistics. The order of results is the same as the input order.
         * @param {Array<CasesStatsRequestBodyItem>} casesStatsRequestBodyItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCasesStats(casesStatsRequestBodyItem: Array<CasesStatsRequestBodyItem>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Array<CaseStatistic>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCasesStats(casesStatsRequestBodyItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search cases
         * @param {number} [floorMin] the minimum floor where an address resides like minimum on the third floor.
         * @param {number} [floorMax] the maximum floor where an address (apartment) resides like at most the third floor.
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [cities] 
         * @param {Set<string>} [zipCodes] This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
         * @param {Set<string>} [roads] Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
         * @param {Set<string>} [placeNames] 
         * @param {Set<string>} [customAreas] 
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {number} [priceMax] 
         * @param {number} [priceMin] 
         * @param {number} [monthlyExpenseMin] Case minimum monthly expense.
         * @param {number} [monthlyExpenseMax] Case maximum monthly expense.
         * @param {string} [freeText] Free text search in the title and description of cases
         * @param {boolean} [filterOpenHouse] Whether or not cases returned have an upcoming open house event
         * @param {number} [perPage] Limits the number of results per page. 
         * @param {number} [page] Page number 
         * @param {Set<EnergyLabel>} [energyLabels] 
         * @param {number} [areaMin] 
         * @param {number} [areaMax] 
         * @param {number} [numberOfRoomsMin] 
         * @param {number} [numberOfRoomsMax] 
         * @param {number} [yearBuiltFrom] 
         * @param {number} [yearBuiltTo] 
         * @param {number} [lotAreaMin] 
         * @param {number} [lotAreaMax] 
         * @param {number} [levelsMin] An address has at least this many levels - like a house in two levels.
         * @param {number} [levelsMax] An address has maximum this many levels - like a house in max one level.
         * @param {boolean} [presale] When enabled the result should only include pre-sale (projektsalg)
         * @param {number} [basementAreaMin] 
         * @param {number} [basementAreaMax] 
         * @param {boolean} [filterPriceDrop] 
         * @param {number} [lastPriceDropAtMax] Maximum number of days since the last price drop, if any
         * @param {number} [costMin] Lower bound on price
         * @param {number} [costMax] upper bound on price
         * @param {number} [timeOnMarketMin] minimum number of days on the market.
         * @param {number} [timeOnMarketMax] maximum number of days on the market.
         * @param {boolean} [filterBasement] Whether or not cases returned have a basement
         * @param {string} [yearSoldMin] 
         * @param {string} [yearSoldMax] 
         * @param {boolean} [highlighted] 
         * @param {boolean} [sortAscending] 
         * @param {string} [polygon] Polygon. Use like &#x60;api.dk/search?polygon&#x3D;12.552178,55.784813|12.497472,55.784813|12.304046,55.685812|12.302093,55.654960&#x60;. * Format polygon&#x3D;[lon,lat]|[lon,lat]|.... * A polygon can have a max of 500 points 
         * @param {string} [radius] Radius. Use like &#x60;api.dk/search?radius&#x3D;10350|11.852554,55.767563&#x60;. * Format: [meters]|[lon:centerPoint],[lat:centerPoint] * [meters] must be at least 1 
         * @param {string} [realtorBranchName] 
         * @param {string} [realtorID] 
         * @param {SearchCasesSortBy} [sortBy] 
         * @param {Set<number>} [sortByDistanceCenter] required when sortBy is set to distance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchCases(floorMin?: number, floorMax?: number, provinces?: Set<string>, municipalities?: Set<string>, cities?: Set<string>, zipCodes?: Set<string>, roads?: Set<string>, placeNames?: Set<string>, customAreas?: Set<string>, addressTypes?: Set<AddressType>, priceMax?: number, priceMin?: number, monthlyExpenseMin?: number, monthlyExpenseMax?: number, freeText?: string, filterOpenHouse?: boolean, perPage?: number, page?: number, energyLabels?: Set<EnergyLabel>, areaMin?: number, areaMax?: number, numberOfRoomsMin?: number, numberOfRoomsMax?: number, yearBuiltFrom?: number, yearBuiltTo?: number, lotAreaMin?: number, lotAreaMax?: number, levelsMin?: number, levelsMax?: number, presale?: boolean, basementAreaMin?: number, basementAreaMax?: number, filterPriceDrop?: boolean, lastPriceDropAtMax?: number, costMin?: number, costMax?: number, timeOnMarketMin?: number, timeOnMarketMax?: number, filterBasement?: boolean, yearSoldMin?: string, yearSoldMax?: string, highlighted?: boolean, sortAscending?: boolean, polygon?: string, radius?: string, realtorBranchName?: string, realtorID?: string, sortBy?: SearchCasesSortBy, sortByDistanceCenter?: Set<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HALCollection & SearchCasesResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchCases(floorMin, floorMax, provinces, municipalities, cities, zipCodes, roads, placeNames, customAreas, addressTypes, priceMax, priceMin, monthlyExpenseMin, monthlyExpenseMax, freeText, filterOpenHouse, perPage, page, energyLabels, areaMin, areaMax, numberOfRoomsMin, numberOfRoomsMax, yearBuiltFrom, yearBuiltTo, lotAreaMin, lotAreaMax, levelsMin, levelsMax, presale, basementAreaMin, basementAreaMax, filterPriceDrop, lastPriceDropAtMax, costMin, costMax, timeOnMarketMin, timeOnMarketMax, filterBasement, yearSoldMin, yearSoldMax, highlighted, sortAscending, polygon, radius, realtorBranchName, realtorID, sortBy, sortByDistanceCenter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Search for cases by locations.  The result is grouped into following location groups:  - `provinces` - `municipalities` - `cities` - `zipCodes` - `roads` - `places` - `addresses` - `customAreas` 
         * @summary Search case locations
         * @param {string} text Free form search string
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchCasesByLocations(text: string, addressTypes?: Set<AddressType>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchCaseLocationsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchCasesByLocations(text, addressTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Map search cases
         * @param {Set<number>} bbox Bounding box. Use like &#x60;api.dk/search?bbox&#x3D;10.721,55.310,12.678,55.841&#x60;. * Index zero is minX * Index one is minY * Index two is maxX * Index three is maxY  X runs left to right (i.e. minX is left and maxX is right). Y runs bottom to top (i.e. minY is bottom and maxY is top).  If the &#x60;bbox&#x60; query parameter is sent, it must contain exactly four items. 
         * @param {number} [floorMin] the minimum floor where an address resides like minimum on the third floor.
         * @param {number} [floorMax] the maximum floor where an address (apartment) resides like at most the third floor.
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [cities] 
         * @param {Set<string>} [zipCodes] This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
         * @param {Set<string>} [roads] Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
         * @param {Set<string>} [placeNames] 
         * @param {Set<string>} [customAreas] 
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {number} [priceMax] 
         * @param {number} [priceMin] 
         * @param {number} [monthlyExpenseMin] Case minimum monthly expense.
         * @param {number} [monthlyExpenseMax] Case maximum monthly expense.
         * @param {string} [freeText] Free text search in the title and description of cases
         * @param {boolean} [filterOpenHouse] Whether or not cases returned have an upcoming open house event
         * @param {Set<EnergyLabel>} [energyLabels] 
         * @param {number} [areaMin] 
         * @param {number} [areaMax] 
         * @param {number} [numberOfRoomsMin] 
         * @param {number} [numberOfRoomsMax] 
         * @param {number} [yearBuiltFrom] 
         * @param {number} [yearBuiltTo] 
         * @param {number} [lotAreaMin] 
         * @param {number} [lotAreaMax] 
         * @param {number} [levelsMin] An address has at least this many levels - like a house in two levels.
         * @param {number} [levelsMax] An address has maximum this many levels - like a house in max one level.
         * @param {boolean} [presale] When enabled the result should only include pre-sale (projektsalg)
         * @param {number} [basementAreaMin] 
         * @param {number} [basementAreaMax] 
         * @param {boolean} [filterPriceDrop] 
         * @param {number} [lastPriceDropAtMax] Maximum number of days since the last price drop, if any
         * @param {number} [costMin] Lower bound on price
         * @param {number} [costMax] upper bound on price
         * @param {number} [timeOnMarketMin] minimum number of days on the market.
         * @param {number} [timeOnMarketMax] maximum number of days on the market.
         * @param {boolean} [filterBasement] Whether or not cases returned have a basement
         * @param {string} [yearSoldMin] 
         * @param {string} [yearSoldMax] 
         * @param {boolean} [highlighted] 
         * @param {string} [polygon] Polygon. Use like &#x60;api.dk/search?polygon&#x3D;12.552178,55.784813|12.497472,55.784813|12.304046,55.685812|12.302093,55.654960&#x60;. * Format polygon&#x3D;[lon,lat]|[lon,lat]|.... * A polygon can have a max of 500 points 
         * @param {string} [radius] Radius. Use like &#x60;api.dk/search?radius&#x3D;10350|11.852554,55.767563&#x60;. * Format: [meters]|[lon:centerPoint],[lat:centerPoint] * [meters] must be at least 1 
         * @param {SearchCasesSortBy} [sortBy] 
         * @param {boolean} [sortAscending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchMapCases(bbox: Set<number>, floorMin?: number, floorMax?: number, provinces?: Set<string>, municipalities?: Set<string>, cities?: Set<string>, zipCodes?: Set<string>, roads?: Set<string>, placeNames?: Set<string>, customAreas?: Set<string>, addressTypes?: Set<AddressType>, priceMax?: number, priceMin?: number, monthlyExpenseMin?: number, monthlyExpenseMax?: number, freeText?: string, filterOpenHouse?: boolean, energyLabels?: Set<EnergyLabel>, areaMin?: number, areaMax?: number, numberOfRoomsMin?: number, numberOfRoomsMax?: number, yearBuiltFrom?: number, yearBuiltTo?: number, lotAreaMin?: number, lotAreaMax?: number, levelsMin?: number, levelsMax?: number, presale?: boolean, basementAreaMin?: number, basementAreaMax?: number, filterPriceDrop?: boolean, lastPriceDropAtMax?: number, costMin?: number, costMax?: number, timeOnMarketMin?: number, timeOnMarketMax?: number, filterBasement?: boolean, yearSoldMin?: string, yearSoldMax?: string, highlighted?: boolean, polygon?: string, radius?: string, sortBy?: SearchCasesSortBy, sortAscending?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchMapCasesResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchMapCases(bbox, floorMin, floorMax, provinces, municipalities, cities, zipCodes, roads, placeNames, customAreas, addressTypes, priceMax, priceMin, monthlyExpenseMin, monthlyExpenseMax, freeText, filterOpenHouse, energyLabels, areaMin, areaMax, numberOfRoomsMin, numberOfRoomsMax, yearBuiltFrom, yearBuiltTo, lotAreaMin, lotAreaMax, levelsMin, levelsMax, presale, basementAreaMin, basementAreaMax, filterPriceDrop, lastPriceDropAtMax, costMin, costMax, timeOnMarketMin, timeOnMarketMax, filterBasement, yearSoldMin, yearSoldMax, highlighted, polygon, radius, sortBy, sortAscending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CasesApi - factory interface
 * @export
 */
export const CasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CasesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a case by ID
         * @param {string} caseID Case uuid (UUIDv4 format).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        casesCaseID(caseID: string, options?: any): AxiosPromise<Case> {
            return localVarFp.casesCaseID(caseID, options).then((request) => request(axios, basePath));
        },
        /**
         * Register a case interaction of a given type 
         * @summary This endpoint is deprecated; prefer POST /interactions.
         * @param {string} caseID Case uuid (UUIDv4 format).
         * @param {CaseInteraction} caseInteraction User-case interaction data
         * @param {string} [userID] user uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        casesCaseIDInteractionPost(caseID: string, caseInteraction: CaseInteraction, userID?: string, options?: any): AxiosPromise<void> {
            return localVarFp.casesCaseIDInteractionPost(caseID, caseInteraction, userID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get timeline of a case
         * @param {string} caseID Case uuid (UUIDv4 format).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        casesCaseIDTimeline(caseID: string, options?: any): AxiosPromise<CaseTimeline> {
            return localVarFp.casesCaseIDTimeline(caseID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get traffic statistics of a case
         * @param {string} caseID Case uuid (UUIDv4 format).
         * @param {InteractionType} interactionType This is the same as &#x60;type&#x60; in &#x60;POST /interactions&#x60; endpoint. However, only the following types are considered valid: * click_to_realtor * presentation_page_viewed * click_favorite 
         * @param {'day' | 'week' | 'month'} [granularity] This is the duration of time with which to group the data. Defaults to \&quot;day\&quot; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        casesCaseIDTraffic(caseID: string, interactionType: InteractionType, granularity?: 'day' | 'week' | 'month', options?: any): AxiosPromise<CaseTrafficStats> {
            return localVarFp.casesCaseIDTraffic(caseID, interactionType, granularity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This endpoint is deprecated; prefer the totalHits property of GET /search/cases.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        casesOpenCount(options?: any): AxiosPromise<CasesOpenCountResult> {
            return localVarFp.casesOpenCount(options).then((request) => request(axios, basePath));
        },
        /**
         * Get Case\'s oblique image. 
         * @summary Get oblique
         * @param {string} caseID Case uuid (UUIDv4 format).
         * @param {DirectionType} [direction] Chooses the image direction.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOblique(caseID: string, direction?: DirectionType, options?: any): AxiosPromise<any> {
            return localVarFp.getOblique(caseID, direction, options).then((request) => request(axios, basePath));
        },
        /**
         * Get case statistics
         * @summary Get case statistics. The order of results is the same as the input order.
         * @param {Array<CasesStatsRequestBodyItem>} casesStatsRequestBodyItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCasesStats(casesStatsRequestBodyItem: Array<CasesStatsRequestBodyItem>, options?: any): AxiosPromise<Array<Array<CaseStatistic>>> {
            return localVarFp.postCasesStats(casesStatsRequestBodyItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search cases
         * @param {number} [floorMin] the minimum floor where an address resides like minimum on the third floor.
         * @param {number} [floorMax] the maximum floor where an address (apartment) resides like at most the third floor.
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [cities] 
         * @param {Set<string>} [zipCodes] This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
         * @param {Set<string>} [roads] Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
         * @param {Set<string>} [placeNames] 
         * @param {Set<string>} [customAreas] 
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {number} [priceMax] 
         * @param {number} [priceMin] 
         * @param {number} [monthlyExpenseMin] Case minimum monthly expense.
         * @param {number} [monthlyExpenseMax] Case maximum monthly expense.
         * @param {string} [freeText] Free text search in the title and description of cases
         * @param {boolean} [filterOpenHouse] Whether or not cases returned have an upcoming open house event
         * @param {number} [perPage] Limits the number of results per page. 
         * @param {number} [page] Page number 
         * @param {Set<EnergyLabel>} [energyLabels] 
         * @param {number} [areaMin] 
         * @param {number} [areaMax] 
         * @param {number} [numberOfRoomsMin] 
         * @param {number} [numberOfRoomsMax] 
         * @param {number} [yearBuiltFrom] 
         * @param {number} [yearBuiltTo] 
         * @param {number} [lotAreaMin] 
         * @param {number} [lotAreaMax] 
         * @param {number} [levelsMin] An address has at least this many levels - like a house in two levels.
         * @param {number} [levelsMax] An address has maximum this many levels - like a house in max one level.
         * @param {boolean} [presale] When enabled the result should only include pre-sale (projektsalg)
         * @param {number} [basementAreaMin] 
         * @param {number} [basementAreaMax] 
         * @param {boolean} [filterPriceDrop] 
         * @param {number} [lastPriceDropAtMax] Maximum number of days since the last price drop, if any
         * @param {number} [costMin] Lower bound on price
         * @param {number} [costMax] upper bound on price
         * @param {number} [timeOnMarketMin] minimum number of days on the market.
         * @param {number} [timeOnMarketMax] maximum number of days on the market.
         * @param {boolean} [filterBasement] Whether or not cases returned have a basement
         * @param {string} [yearSoldMin] 
         * @param {string} [yearSoldMax] 
         * @param {boolean} [highlighted] 
         * @param {boolean} [sortAscending] 
         * @param {string} [polygon] Polygon. Use like &#x60;api.dk/search?polygon&#x3D;12.552178,55.784813|12.497472,55.784813|12.304046,55.685812|12.302093,55.654960&#x60;. * Format polygon&#x3D;[lon,lat]|[lon,lat]|.... * A polygon can have a max of 500 points 
         * @param {string} [radius] Radius. Use like &#x60;api.dk/search?radius&#x3D;10350|11.852554,55.767563&#x60;. * Format: [meters]|[lon:centerPoint],[lat:centerPoint] * [meters] must be at least 1 
         * @param {string} [realtorBranchName] 
         * @param {string} [realtorID] 
         * @param {SearchCasesSortBy} [sortBy] 
         * @param {Set<number>} [sortByDistanceCenter] required when sortBy is set to distance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCases(floorMin?: number, floorMax?: number, provinces?: Set<string>, municipalities?: Set<string>, cities?: Set<string>, zipCodes?: Set<string>, roads?: Set<string>, placeNames?: Set<string>, customAreas?: Set<string>, addressTypes?: Set<AddressType>, priceMax?: number, priceMin?: number, monthlyExpenseMin?: number, monthlyExpenseMax?: number, freeText?: string, filterOpenHouse?: boolean, perPage?: number, page?: number, energyLabels?: Set<EnergyLabel>, areaMin?: number, areaMax?: number, numberOfRoomsMin?: number, numberOfRoomsMax?: number, yearBuiltFrom?: number, yearBuiltTo?: number, lotAreaMin?: number, lotAreaMax?: number, levelsMin?: number, levelsMax?: number, presale?: boolean, basementAreaMin?: number, basementAreaMax?: number, filterPriceDrop?: boolean, lastPriceDropAtMax?: number, costMin?: number, costMax?: number, timeOnMarketMin?: number, timeOnMarketMax?: number, filterBasement?: boolean, yearSoldMin?: string, yearSoldMax?: string, highlighted?: boolean, sortAscending?: boolean, polygon?: string, radius?: string, realtorBranchName?: string, realtorID?: string, sortBy?: SearchCasesSortBy, sortByDistanceCenter?: Set<number>, options?: any): AxiosPromise<HALCollection & SearchCasesResult> {
            return localVarFp.searchCases(floorMin, floorMax, provinces, municipalities, cities, zipCodes, roads, placeNames, customAreas, addressTypes, priceMax, priceMin, monthlyExpenseMin, monthlyExpenseMax, freeText, filterOpenHouse, perPage, page, energyLabels, areaMin, areaMax, numberOfRoomsMin, numberOfRoomsMax, yearBuiltFrom, yearBuiltTo, lotAreaMin, lotAreaMax, levelsMin, levelsMax, presale, basementAreaMin, basementAreaMax, filterPriceDrop, lastPriceDropAtMax, costMin, costMax, timeOnMarketMin, timeOnMarketMax, filterBasement, yearSoldMin, yearSoldMax, highlighted, sortAscending, polygon, radius, realtorBranchName, realtorID, sortBy, sortByDistanceCenter, options).then((request) => request(axios, basePath));
        },
        /**
         * Search for cases by locations.  The result is grouped into following location groups:  - `provinces` - `municipalities` - `cities` - `zipCodes` - `roads` - `places` - `addresses` - `customAreas` 
         * @summary Search case locations
         * @param {string} text Free form search string
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCasesByLocations(text: string, addressTypes?: Set<AddressType>, options?: any): AxiosPromise<SearchCaseLocationsResult> {
            return localVarFp.searchCasesByLocations(text, addressTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Map search cases
         * @param {Set<number>} bbox Bounding box. Use like &#x60;api.dk/search?bbox&#x3D;10.721,55.310,12.678,55.841&#x60;. * Index zero is minX * Index one is minY * Index two is maxX * Index three is maxY  X runs left to right (i.e. minX is left and maxX is right). Y runs bottom to top (i.e. minY is bottom and maxY is top).  If the &#x60;bbox&#x60; query parameter is sent, it must contain exactly four items. 
         * @param {number} [floorMin] the minimum floor where an address resides like minimum on the third floor.
         * @param {number} [floorMax] the maximum floor where an address (apartment) resides like at most the third floor.
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [cities] 
         * @param {Set<string>} [zipCodes] This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
         * @param {Set<string>} [roads] Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
         * @param {Set<string>} [placeNames] 
         * @param {Set<string>} [customAreas] 
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {number} [priceMax] 
         * @param {number} [priceMin] 
         * @param {number} [monthlyExpenseMin] Case minimum monthly expense.
         * @param {number} [monthlyExpenseMax] Case maximum monthly expense.
         * @param {string} [freeText] Free text search in the title and description of cases
         * @param {boolean} [filterOpenHouse] Whether or not cases returned have an upcoming open house event
         * @param {Set<EnergyLabel>} [energyLabels] 
         * @param {number} [areaMin] 
         * @param {number} [areaMax] 
         * @param {number} [numberOfRoomsMin] 
         * @param {number} [numberOfRoomsMax] 
         * @param {number} [yearBuiltFrom] 
         * @param {number} [yearBuiltTo] 
         * @param {number} [lotAreaMin] 
         * @param {number} [lotAreaMax] 
         * @param {number} [levelsMin] An address has at least this many levels - like a house in two levels.
         * @param {number} [levelsMax] An address has maximum this many levels - like a house in max one level.
         * @param {boolean} [presale] When enabled the result should only include pre-sale (projektsalg)
         * @param {number} [basementAreaMin] 
         * @param {number} [basementAreaMax] 
         * @param {boolean} [filterPriceDrop] 
         * @param {number} [lastPriceDropAtMax] Maximum number of days since the last price drop, if any
         * @param {number} [costMin] Lower bound on price
         * @param {number} [costMax] upper bound on price
         * @param {number} [timeOnMarketMin] minimum number of days on the market.
         * @param {number} [timeOnMarketMax] maximum number of days on the market.
         * @param {boolean} [filterBasement] Whether or not cases returned have a basement
         * @param {string} [yearSoldMin] 
         * @param {string} [yearSoldMax] 
         * @param {boolean} [highlighted] 
         * @param {string} [polygon] Polygon. Use like &#x60;api.dk/search?polygon&#x3D;12.552178,55.784813|12.497472,55.784813|12.304046,55.685812|12.302093,55.654960&#x60;. * Format polygon&#x3D;[lon,lat]|[lon,lat]|.... * A polygon can have a max of 500 points 
         * @param {string} [radius] Radius. Use like &#x60;api.dk/search?radius&#x3D;10350|11.852554,55.767563&#x60;. * Format: [meters]|[lon:centerPoint],[lat:centerPoint] * [meters] must be at least 1 
         * @param {SearchCasesSortBy} [sortBy] 
         * @param {boolean} [sortAscending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMapCases(bbox: Set<number>, floorMin?: number, floorMax?: number, provinces?: Set<string>, municipalities?: Set<string>, cities?: Set<string>, zipCodes?: Set<string>, roads?: Set<string>, placeNames?: Set<string>, customAreas?: Set<string>, addressTypes?: Set<AddressType>, priceMax?: number, priceMin?: number, monthlyExpenseMin?: number, monthlyExpenseMax?: number, freeText?: string, filterOpenHouse?: boolean, energyLabels?: Set<EnergyLabel>, areaMin?: number, areaMax?: number, numberOfRoomsMin?: number, numberOfRoomsMax?: number, yearBuiltFrom?: number, yearBuiltTo?: number, lotAreaMin?: number, lotAreaMax?: number, levelsMin?: number, levelsMax?: number, presale?: boolean, basementAreaMin?: number, basementAreaMax?: number, filterPriceDrop?: boolean, lastPriceDropAtMax?: number, costMin?: number, costMax?: number, timeOnMarketMin?: number, timeOnMarketMax?: number, filterBasement?: boolean, yearSoldMin?: string, yearSoldMax?: string, highlighted?: boolean, polygon?: string, radius?: string, sortBy?: SearchCasesSortBy, sortAscending?: boolean, options?: any): AxiosPromise<SearchMapCasesResult> {
            return localVarFp.searchMapCases(bbox, floorMin, floorMax, provinces, municipalities, cities, zipCodes, roads, placeNames, customAreas, addressTypes, priceMax, priceMin, monthlyExpenseMin, monthlyExpenseMax, freeText, filterOpenHouse, energyLabels, areaMin, areaMax, numberOfRoomsMin, numberOfRoomsMax, yearBuiltFrom, yearBuiltTo, lotAreaMin, lotAreaMax, levelsMin, levelsMax, presale, basementAreaMin, basementAreaMax, filterPriceDrop, lastPriceDropAtMax, costMin, costMax, timeOnMarketMin, timeOnMarketMax, filterBasement, yearSoldMin, yearSoldMax, highlighted, polygon, radius, sortBy, sortAscending, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for casesCaseID operation in CasesApi.
 * @export
 * @interface CasesApiCasesCaseIDRequest
 */
export interface CasesApiCasesCaseIDRequest {
    /**
     * Case uuid (UUIDv4 format).
     * @type {string}
     * @memberof CasesApiCasesCaseID
     */
    readonly caseID: string
}

/**
 * Request parameters for casesCaseIDInteractionPost operation in CasesApi.
 * @export
 * @interface CasesApiCasesCaseIDInteractionPostRequest
 */
export interface CasesApiCasesCaseIDInteractionPostRequest {
    /**
     * Case uuid (UUIDv4 format).
     * @type {string}
     * @memberof CasesApiCasesCaseIDInteractionPost
     */
    readonly caseID: string

    /**
     * User-case interaction data
     * @type {CaseInteraction}
     * @memberof CasesApiCasesCaseIDInteractionPost
     */
    readonly caseInteraction: CaseInteraction

    /**
     * user uuid
     * @type {string}
     * @memberof CasesApiCasesCaseIDInteractionPost
     */
    readonly userID?: string
}

/**
 * Request parameters for casesCaseIDTimeline operation in CasesApi.
 * @export
 * @interface CasesApiCasesCaseIDTimelineRequest
 */
export interface CasesApiCasesCaseIDTimelineRequest {
    /**
     * Case uuid (UUIDv4 format).
     * @type {string}
     * @memberof CasesApiCasesCaseIDTimeline
     */
    readonly caseID: string
}

/**
 * Request parameters for casesCaseIDTraffic operation in CasesApi.
 * @export
 * @interface CasesApiCasesCaseIDTrafficRequest
 */
export interface CasesApiCasesCaseIDTrafficRequest {
    /**
     * Case uuid (UUIDv4 format).
     * @type {string}
     * @memberof CasesApiCasesCaseIDTraffic
     */
    readonly caseID: string

    /**
     * This is the same as &#x60;type&#x60; in &#x60;POST /interactions&#x60; endpoint. However, only the following types are considered valid: * click_to_realtor * presentation_page_viewed * click_favorite 
     * @type {InteractionType}
     * @memberof CasesApiCasesCaseIDTraffic
     */
    readonly interactionType: InteractionType

    /**
     * This is the duration of time with which to group the data. Defaults to \&quot;day\&quot; 
     * @type {'day' | 'week' | 'month'}
     * @memberof CasesApiCasesCaseIDTraffic
     */
    readonly granularity?: 'day' | 'week' | 'month'
}

/**
 * Request parameters for getOblique operation in CasesApi.
 * @export
 * @interface CasesApiGetObliqueRequest
 */
export interface CasesApiGetObliqueRequest {
    /**
     * Case uuid (UUIDv4 format).
     * @type {string}
     * @memberof CasesApiGetOblique
     */
    readonly caseID: string

    /**
     * Chooses the image direction.
     * @type {DirectionType}
     * @memberof CasesApiGetOblique
     */
    readonly direction?: DirectionType
}

/**
 * Request parameters for postCasesStats operation in CasesApi.
 * @export
 * @interface CasesApiPostCasesStatsRequest
 */
export interface CasesApiPostCasesStatsRequest {
    /**
     * 
     * @type {Array<CasesStatsRequestBodyItem>}
     * @memberof CasesApiPostCasesStats
     */
    readonly casesStatsRequestBodyItem: Array<CasesStatsRequestBodyItem>
}

/**
 * Request parameters for searchCases operation in CasesApi.
 * @export
 * @interface CasesApiSearchCasesRequest
 */
export interface CasesApiSearchCasesRequest {
    /**
     * the minimum floor where an address resides like minimum on the third floor.
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly floorMin?: number

    /**
     * the maximum floor where an address (apartment) resides like at most the third floor.
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly floorMax?: number

    /**
     * 
     * @type {Set<string>}
     * @memberof CasesApiSearchCases
     */
    readonly provinces?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof CasesApiSearchCases
     */
    readonly municipalities?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof CasesApiSearchCases
     */
    readonly cities?: Set<string>

    /**
     * This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
     * @type {Set<string>}
     * @memberof CasesApiSearchCases
     */
    readonly zipCodes?: Set<string>

    /**
     * Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
     * @type {Set<string>}
     * @memberof CasesApiSearchCases
     */
    readonly roads?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof CasesApiSearchCases
     */
    readonly placeNames?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof CasesApiSearchCases
     */
    readonly customAreas?: Set<string>

    /**
     * The address type to filter by
     * @type {Set<AddressType>}
     * @memberof CasesApiSearchCases
     */
    readonly addressTypes?: Set<AddressType>

    /**
     * 
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly priceMax?: number

    /**
     * 
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly priceMin?: number

    /**
     * Case minimum monthly expense.
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly monthlyExpenseMin?: number

    /**
     * Case maximum monthly expense.
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly monthlyExpenseMax?: number

    /**
     * Free text search in the title and description of cases
     * @type {string}
     * @memberof CasesApiSearchCases
     */
    readonly freeText?: string

    /**
     * Whether or not cases returned have an upcoming open house event
     * @type {boolean}
     * @memberof CasesApiSearchCases
     */
    readonly filterOpenHouse?: boolean

    /**
     * Limits the number of results per page. 
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly perPage?: number

    /**
     * Page number 
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly page?: number

    /**
     * 
     * @type {Set<EnergyLabel>}
     * @memberof CasesApiSearchCases
     */
    readonly energyLabels?: Set<EnergyLabel>

    /**
     * 
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly areaMin?: number

    /**
     * 
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly areaMax?: number

    /**
     * 
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly numberOfRoomsMin?: number

    /**
     * 
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly numberOfRoomsMax?: number

    /**
     * 
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly yearBuiltFrom?: number

    /**
     * 
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly yearBuiltTo?: number

    /**
     * 
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly lotAreaMin?: number

    /**
     * 
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly lotAreaMax?: number

    /**
     * An address has at least this many levels - like a house in two levels.
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly levelsMin?: number

    /**
     * An address has maximum this many levels - like a house in max one level.
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly levelsMax?: number

    /**
     * When enabled the result should only include pre-sale (projektsalg)
     * @type {boolean}
     * @memberof CasesApiSearchCases
     */
    readonly presale?: boolean

    /**
     * 
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly basementAreaMin?: number

    /**
     * 
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly basementAreaMax?: number

    /**
     * 
     * @type {boolean}
     * @memberof CasesApiSearchCases
     */
    readonly filterPriceDrop?: boolean

    /**
     * Maximum number of days since the last price drop, if any
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly lastPriceDropAtMax?: number

    /**
     * Lower bound on price
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly costMin?: number

    /**
     * upper bound on price
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly costMax?: number

    /**
     * minimum number of days on the market.
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly timeOnMarketMin?: number

    /**
     * maximum number of days on the market.
     * @type {number}
     * @memberof CasesApiSearchCases
     */
    readonly timeOnMarketMax?: number

    /**
     * Whether or not cases returned have a basement
     * @type {boolean}
     * @memberof CasesApiSearchCases
     */
    readonly filterBasement?: boolean

    /**
     * 
     * @type {string}
     * @memberof CasesApiSearchCases
     */
    readonly yearSoldMin?: string

    /**
     * 
     * @type {string}
     * @memberof CasesApiSearchCases
     */
    readonly yearSoldMax?: string

    /**
     * 
     * @type {boolean}
     * @memberof CasesApiSearchCases
     */
    readonly highlighted?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CasesApiSearchCases
     */
    readonly sortAscending?: boolean

    /**
     * Polygon. Use like &#x60;api.dk/search?polygon&#x3D;12.552178,55.784813|12.497472,55.784813|12.304046,55.685812|12.302093,55.654960&#x60;. * Format polygon&#x3D;[lon,lat]|[lon,lat]|.... * A polygon can have a max of 500 points 
     * @type {string}
     * @memberof CasesApiSearchCases
     */
    readonly polygon?: string

    /**
     * Radius. Use like &#x60;api.dk/search?radius&#x3D;10350|11.852554,55.767563&#x60;. * Format: [meters]|[lon:centerPoint],[lat:centerPoint] * [meters] must be at least 1 
     * @type {string}
     * @memberof CasesApiSearchCases
     */
    readonly radius?: string

    /**
     * 
     * @type {string}
     * @memberof CasesApiSearchCases
     */
    readonly realtorBranchName?: string

    /**
     * 
     * @type {string}
     * @memberof CasesApiSearchCases
     */
    readonly realtorID?: string

    /**
     * 
     * @type {SearchCasesSortBy}
     * @memberof CasesApiSearchCases
     */
    readonly sortBy?: SearchCasesSortBy

    /**
     * required when sortBy is set to distance
     * @type {Set<number>}
     * @memberof CasesApiSearchCases
     */
    readonly sortByDistanceCenter?: Set<number>
}

/**
 * Request parameters for searchCasesByLocations operation in CasesApi.
 * @export
 * @interface CasesApiSearchCasesByLocationsRequest
 */
export interface CasesApiSearchCasesByLocationsRequest {
    /**
     * Free form search string
     * @type {string}
     * @memberof CasesApiSearchCasesByLocations
     */
    readonly text: string

    /**
     * The address type to filter by
     * @type {Set<AddressType>}
     * @memberof CasesApiSearchCasesByLocations
     */
    readonly addressTypes?: Set<AddressType>
}

/**
 * Request parameters for searchMapCases operation in CasesApi.
 * @export
 * @interface CasesApiSearchMapCasesRequest
 */
export interface CasesApiSearchMapCasesRequest {
    /**
     * Bounding box. Use like &#x60;api.dk/search?bbox&#x3D;10.721,55.310,12.678,55.841&#x60;. * Index zero is minX * Index one is minY * Index two is maxX * Index three is maxY  X runs left to right (i.e. minX is left and maxX is right). Y runs bottom to top (i.e. minY is bottom and maxY is top).  If the &#x60;bbox&#x60; query parameter is sent, it must contain exactly four items. 
     * @type {Set<number>}
     * @memberof CasesApiSearchMapCases
     */
    readonly bbox: Set<number>

    /**
     * the minimum floor where an address resides like minimum on the third floor.
     * @type {number}
     * @memberof CasesApiSearchMapCases
     */
    readonly floorMin?: number

    /**
     * the maximum floor where an address (apartment) resides like at most the third floor.
     * @type {number}
     * @memberof CasesApiSearchMapCases
     */
    readonly floorMax?: number

    /**
     * 
     * @type {Set<string>}
     * @memberof CasesApiSearchMapCases
     */
    readonly provinces?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof CasesApiSearchMapCases
     */
    readonly municipalities?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof CasesApiSearchMapCases
     */
    readonly cities?: Set<string>

    /**
     * This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
     * @type {Set<string>}
     * @memberof CasesApiSearchMapCases
     */
    readonly zipCodes?: Set<string>

    /**
     * Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
     * @type {Set<string>}
     * @memberof CasesApiSearchMapCases
     */
    readonly roads?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof CasesApiSearchMapCases
     */
    readonly placeNames?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof CasesApiSearchMapCases
     */
    readonly customAreas?: Set<string>

    /**
     * The address type to filter by
     * @type {Set<AddressType>}
     * @memberof CasesApiSearchMapCases
     */
    readonly addressTypes?: Set<AddressType>

    /**
     * 
     * @type {number}
     * @memberof CasesApiSearchMapCases
     */
    readonly priceMax?: number

    /**
     * 
     * @type {number}
     * @memberof CasesApiSearchMapCases
     */
    readonly priceMin?: number

    /**
     * Case minimum monthly expense.
     * @type {number}
     * @memberof CasesApiSearchMapCases
     */
    readonly monthlyExpenseMin?: number

    /**
     * Case maximum monthly expense.
     * @type {number}
     * @memberof CasesApiSearchMapCases
     */
    readonly monthlyExpenseMax?: number

    /**
     * Free text search in the title and description of cases
     * @type {string}
     * @memberof CasesApiSearchMapCases
     */
    readonly freeText?: string

    /**
     * Whether or not cases returned have an upcoming open house event
     * @type {boolean}
     * @memberof CasesApiSearchMapCases
     */
    readonly filterOpenHouse?: boolean

    /**
     * 
     * @type {Set<EnergyLabel>}
     * @memberof CasesApiSearchMapCases
     */
    readonly energyLabels?: Set<EnergyLabel>

    /**
     * 
     * @type {number}
     * @memberof CasesApiSearchMapCases
     */
    readonly areaMin?: number

    /**
     * 
     * @type {number}
     * @memberof CasesApiSearchMapCases
     */
    readonly areaMax?: number

    /**
     * 
     * @type {number}
     * @memberof CasesApiSearchMapCases
     */
    readonly numberOfRoomsMin?: number

    /**
     * 
     * @type {number}
     * @memberof CasesApiSearchMapCases
     */
    readonly numberOfRoomsMax?: number

    /**
     * 
     * @type {number}
     * @memberof CasesApiSearchMapCases
     */
    readonly yearBuiltFrom?: number

    /**
     * 
     * @type {number}
     * @memberof CasesApiSearchMapCases
     */
    readonly yearBuiltTo?: number

    /**
     * 
     * @type {number}
     * @memberof CasesApiSearchMapCases
     */
    readonly lotAreaMin?: number

    /**
     * 
     * @type {number}
     * @memberof CasesApiSearchMapCases
     */
    readonly lotAreaMax?: number

    /**
     * An address has at least this many levels - like a house in two levels.
     * @type {number}
     * @memberof CasesApiSearchMapCases
     */
    readonly levelsMin?: number

    /**
     * An address has maximum this many levels - like a house in max one level.
     * @type {number}
     * @memberof CasesApiSearchMapCases
     */
    readonly levelsMax?: number

    /**
     * When enabled the result should only include pre-sale (projektsalg)
     * @type {boolean}
     * @memberof CasesApiSearchMapCases
     */
    readonly presale?: boolean

    /**
     * 
     * @type {number}
     * @memberof CasesApiSearchMapCases
     */
    readonly basementAreaMin?: number

    /**
     * 
     * @type {number}
     * @memberof CasesApiSearchMapCases
     */
    readonly basementAreaMax?: number

    /**
     * 
     * @type {boolean}
     * @memberof CasesApiSearchMapCases
     */
    readonly filterPriceDrop?: boolean

    /**
     * Maximum number of days since the last price drop, if any
     * @type {number}
     * @memberof CasesApiSearchMapCases
     */
    readonly lastPriceDropAtMax?: number

    /**
     * Lower bound on price
     * @type {number}
     * @memberof CasesApiSearchMapCases
     */
    readonly costMin?: number

    /**
     * upper bound on price
     * @type {number}
     * @memberof CasesApiSearchMapCases
     */
    readonly costMax?: number

    /**
     * minimum number of days on the market.
     * @type {number}
     * @memberof CasesApiSearchMapCases
     */
    readonly timeOnMarketMin?: number

    /**
     * maximum number of days on the market.
     * @type {number}
     * @memberof CasesApiSearchMapCases
     */
    readonly timeOnMarketMax?: number

    /**
     * Whether or not cases returned have a basement
     * @type {boolean}
     * @memberof CasesApiSearchMapCases
     */
    readonly filterBasement?: boolean

    /**
     * 
     * @type {string}
     * @memberof CasesApiSearchMapCases
     */
    readonly yearSoldMin?: string

    /**
     * 
     * @type {string}
     * @memberof CasesApiSearchMapCases
     */
    readonly yearSoldMax?: string

    /**
     * 
     * @type {boolean}
     * @memberof CasesApiSearchMapCases
     */
    readonly highlighted?: boolean

    /**
     * Polygon. Use like &#x60;api.dk/search?polygon&#x3D;12.552178,55.784813|12.497472,55.784813|12.304046,55.685812|12.302093,55.654960&#x60;. * Format polygon&#x3D;[lon,lat]|[lon,lat]|.... * A polygon can have a max of 500 points 
     * @type {string}
     * @memberof CasesApiSearchMapCases
     */
    readonly polygon?: string

    /**
     * Radius. Use like &#x60;api.dk/search?radius&#x3D;10350|11.852554,55.767563&#x60;. * Format: [meters]|[lon:centerPoint],[lat:centerPoint] * [meters] must be at least 1 
     * @type {string}
     * @memberof CasesApiSearchMapCases
     */
    readonly radius?: string

    /**
     * 
     * @type {SearchCasesSortBy}
     * @memberof CasesApiSearchMapCases
     */
    readonly sortBy?: SearchCasesSortBy

    /**
     * 
     * @type {boolean}
     * @memberof CasesApiSearchMapCases
     */
    readonly sortAscending?: boolean
}

/**
 * CasesApi - object-oriented interface
 * @export
 * @class CasesApi
 * @extends {BaseAPI}
 */
export class CasesApi extends BaseAPI {
    /**
     * 
     * @summary Get a case by ID
     * @param {CasesApiCasesCaseIDRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CasesApi
     */
    public casesCaseID(requestParameters: CasesApiCasesCaseIDRequest, options?: any) {
        return CasesApiFp(this.configuration).casesCaseID(requestParameters.caseID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Register a case interaction of a given type 
     * @summary This endpoint is deprecated; prefer POST /interactions.
     * @param {CasesApiCasesCaseIDInteractionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CasesApi
     */
    public casesCaseIDInteractionPost(requestParameters: CasesApiCasesCaseIDInteractionPostRequest, options?: any) {
        return CasesApiFp(this.configuration).casesCaseIDInteractionPost(requestParameters.caseID, requestParameters.caseInteraction, requestParameters.userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get timeline of a case
     * @param {CasesApiCasesCaseIDTimelineRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CasesApi
     */
    public casesCaseIDTimeline(requestParameters: CasesApiCasesCaseIDTimelineRequest, options?: any) {
        return CasesApiFp(this.configuration).casesCaseIDTimeline(requestParameters.caseID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get traffic statistics of a case
     * @param {CasesApiCasesCaseIDTrafficRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CasesApi
     */
    public casesCaseIDTraffic(requestParameters: CasesApiCasesCaseIDTrafficRequest, options?: any) {
        return CasesApiFp(this.configuration).casesCaseIDTraffic(requestParameters.caseID, requestParameters.interactionType, requestParameters.granularity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This endpoint is deprecated; prefer the totalHits property of GET /search/cases.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CasesApi
     */
    public casesOpenCount(options?: any) {
        return CasesApiFp(this.configuration).casesOpenCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Case\'s oblique image. 
     * @summary Get oblique
     * @param {CasesApiGetObliqueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CasesApi
     */
    public getOblique(requestParameters: CasesApiGetObliqueRequest, options?: any) {
        return CasesApiFp(this.configuration).getOblique(requestParameters.caseID, requestParameters.direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get case statistics
     * @summary Get case statistics. The order of results is the same as the input order.
     * @param {CasesApiPostCasesStatsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CasesApi
     */
    public postCasesStats(requestParameters: CasesApiPostCasesStatsRequest, options?: any) {
        return CasesApiFp(this.configuration).postCasesStats(requestParameters.casesStatsRequestBodyItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search cases
     * @param {CasesApiSearchCasesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CasesApi
     */
    public searchCases(requestParameters: CasesApiSearchCasesRequest = {}, options?: any) {
        return CasesApiFp(this.configuration).searchCases(requestParameters.floorMin, requestParameters.floorMax, requestParameters.provinces, requestParameters.municipalities, requestParameters.cities, requestParameters.zipCodes, requestParameters.roads, requestParameters.placeNames, requestParameters.customAreas, requestParameters.addressTypes, requestParameters.priceMax, requestParameters.priceMin, requestParameters.monthlyExpenseMin, requestParameters.monthlyExpenseMax, requestParameters.freeText, requestParameters.filterOpenHouse, requestParameters.perPage, requestParameters.page, requestParameters.energyLabels, requestParameters.areaMin, requestParameters.areaMax, requestParameters.numberOfRoomsMin, requestParameters.numberOfRoomsMax, requestParameters.yearBuiltFrom, requestParameters.yearBuiltTo, requestParameters.lotAreaMin, requestParameters.lotAreaMax, requestParameters.levelsMin, requestParameters.levelsMax, requestParameters.presale, requestParameters.basementAreaMin, requestParameters.basementAreaMax, requestParameters.filterPriceDrop, requestParameters.lastPriceDropAtMax, requestParameters.costMin, requestParameters.costMax, requestParameters.timeOnMarketMin, requestParameters.timeOnMarketMax, requestParameters.filterBasement, requestParameters.yearSoldMin, requestParameters.yearSoldMax, requestParameters.highlighted, requestParameters.sortAscending, requestParameters.polygon, requestParameters.radius, requestParameters.realtorBranchName, requestParameters.realtorID, requestParameters.sortBy, requestParameters.sortByDistanceCenter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search for cases by locations.  The result is grouped into following location groups:  - `provinces` - `municipalities` - `cities` - `zipCodes` - `roads` - `places` - `addresses` - `customAreas` 
     * @summary Search case locations
     * @param {CasesApiSearchCasesByLocationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CasesApi
     */
    public searchCasesByLocations(requestParameters: CasesApiSearchCasesByLocationsRequest, options?: any) {
        return CasesApiFp(this.configuration).searchCasesByLocations(requestParameters.text, requestParameters.addressTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Map search cases
     * @param {CasesApiSearchMapCasesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CasesApi
     */
    public searchMapCases(requestParameters: CasesApiSearchMapCasesRequest, options?: any) {
        return CasesApiFp(this.configuration).searchMapCases(requestParameters.bbox, requestParameters.floorMin, requestParameters.floorMax, requestParameters.provinces, requestParameters.municipalities, requestParameters.cities, requestParameters.zipCodes, requestParameters.roads, requestParameters.placeNames, requestParameters.customAreas, requestParameters.addressTypes, requestParameters.priceMax, requestParameters.priceMin, requestParameters.monthlyExpenseMin, requestParameters.monthlyExpenseMax, requestParameters.freeText, requestParameters.filterOpenHouse, requestParameters.energyLabels, requestParameters.areaMin, requestParameters.areaMax, requestParameters.numberOfRoomsMin, requestParameters.numberOfRoomsMax, requestParameters.yearBuiltFrom, requestParameters.yearBuiltTo, requestParameters.lotAreaMin, requestParameters.lotAreaMax, requestParameters.levelsMin, requestParameters.levelsMax, requestParameters.presale, requestParameters.basementAreaMin, requestParameters.basementAreaMax, requestParameters.filterPriceDrop, requestParameters.lastPriceDropAtMax, requestParameters.costMin, requestParameters.costMax, requestParameters.timeOnMarketMin, requestParameters.timeOnMarketMax, requestParameters.filterBasement, requestParameters.yearSoldMin, requestParameters.yearSoldMax, requestParameters.highlighted, requestParameters.polygon, requestParameters.radius, requestParameters.sortBy, requestParameters.sortAscending, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InstitutionsApi - axios parameter creator
 * @export
 */
export const InstitutionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get municipality\'s institutions
         * @param {number} municipalityCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMunicipalityInstitutions: async (municipalityCode: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'municipalityCode' is not null or undefined
            assertParamExists('getMunicipalityInstitutions', 'municipalityCode', municipalityCode)
            const localVarPath = `/municipalities/{municipalityCode}/institutions`
                .replace(`{${"municipalityCode"}}`, encodeURIComponent(String(municipalityCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstitutionsApi - functional programming interface
 * @export
 */
export const InstitutionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InstitutionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get municipality\'s institutions
         * @param {number} municipalityCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMunicipalityInstitutions(municipalityCode: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Institution>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMunicipalityInstitutions(municipalityCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InstitutionsApi - factory interface
 * @export
 */
export const InstitutionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InstitutionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get municipality\'s institutions
         * @param {number} municipalityCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMunicipalityInstitutions(municipalityCode: number, options?: any): AxiosPromise<Array<Institution>> {
            return localVarFp.getMunicipalityInstitutions(municipalityCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getMunicipalityInstitutions operation in InstitutionsApi.
 * @export
 * @interface InstitutionsApiGetMunicipalityInstitutionsRequest
 */
export interface InstitutionsApiGetMunicipalityInstitutionsRequest {
    /**
     * 
     * @type {number}
     * @memberof InstitutionsApiGetMunicipalityInstitutions
     */
    readonly municipalityCode: number
}

/**
 * InstitutionsApi - object-oriented interface
 * @export
 * @class InstitutionsApi
 * @extends {BaseAPI}
 */
export class InstitutionsApi extends BaseAPI {
    /**
     * 
     * @summary Get municipality\'s institutions
     * @param {InstitutionsApiGetMunicipalityInstitutionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutionsApi
     */
    public getMunicipalityInstitutions(requestParameters: InstitutionsApiGetMunicipalityInstitutionsRequest, options?: any) {
        return InstitutionsApiFp(this.configuration).getMunicipalityInstitutions(requestParameters.municipalityCode, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MarketIndexApi - axios parameter creator
 * @export
 */
export const MarketIndexApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get market index file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketIndexFile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/market-index-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketIndexApi - functional programming interface
 * @export
 */
export const MarketIndexApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketIndexApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get market index file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketIndexFile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketIndexFile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketIndexApi - factory interface
 * @export
 */
export const MarketIndexApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketIndexApiFp(configuration)
    return {
        /**
         * 
         * @summary Get market index file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketIndexFile(options?: any): AxiosPromise<any> {
            return localVarFp.marketIndexFile(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarketIndexApi - object-oriented interface
 * @export
 * @class MarketIndexApi
 * @extends {BaseAPI}
 */
export class MarketIndexApi extends BaseAPI {
    /**
     * 
     * @summary Get market index file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketIndexApi
     */
    public marketIndexFile(options?: any) {
        return MarketIndexApiFp(this.configuration).marketIndexFile(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MiscellaneousApi - axios parameter creator
 * @export
 */
export const MiscellaneousApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint aggregates the bounding boxes of one or more geographical areas. That is, it returns the smallest possible rectangle which contains the bounding box of each geographical area provided.  Only zero or one of `provinces`, `municipalities`, `zipCodes`, `cities`, `roads`, `placesNames` and `customAreas` is supported. Sending two or more will return a HTTP error `400`.  If none of `provinces`, `municipalities`, `zipCodes`, `cities`, `roads`, `placesNames` and `customAreas` are sent, the bounding box of all of Denmark is returned.  While the overwhelming majority of geographical areas have a bounding box, there are a select few that don\'t. In the rare case that all geographical areas sent have no bounding box, the bounding box of all of Denmark is returned. 
         * @summary Aggregate the bounding boxes of one or more geographical areas
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [zipCodes] This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
         * @param {Set<string>} [cities] 
         * @param {Set<string>} [roads] Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60;. 
         * @param {Set<string>} [placeNames] 
         * @param {Set<string>} [customAreas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateBbox: async (provinces?: Set<string>, municipalities?: Set<string>, zipCodes?: Set<string>, cities?: Set<string>, roads?: Set<string>, placeNames?: Set<string>, customAreas?: Set<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/bbox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (provinces) {
                localVarQueryParameter['provinces'] = Array.from(provinces);
            }

            if (municipalities) {
                localVarQueryParameter['municipalities'] = Array.from(municipalities);
            }

            if (zipCodes) {
                localVarQueryParameter['zipCodes'] = Array.from(zipCodes);
            }

            if (cities) {
                localVarQueryParameter['cities'] = Array.from(cities);
            }

            if (roads) {
                localVarQueryParameter['roads'] = Array.from(roads);
            }

            if (placeNames) {
                localVarQueryParameter['placeNames'] = Array.from(placeNames);
            }

            if (customAreas) {
                localVarQueryParameter['customAreas'] = Array.from(customAreas);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of banks that provide mortgage calculation
         * @param {AddressType} [addressType] 
         * @param {MortgageSource} [mortgageSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateMortgageBanks: async (addressType?: AddressType, mortgageSource?: MortgageSource, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/calculate_mortgage/banks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (addressType !== undefined) {
                localVarQueryParameter['addressType'] = addressType;
            }

            if (mortgageSource !== undefined) {
                localVarQueryParameter['mortgageSource'] = mortgageSource;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary View API documentation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doc: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/doc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all custom areas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomAreas: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/custom_areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Used by deployment setup to probe the service instance for its health situation.
         * @summary Check service health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Different geography types (provinces, zip codes, cities etc.) on Boligsiden can be navigated to via a URL. This URL is typically a slugified version of the geography\'s name. For example, `København NV` becomes `koebenhavn-nv`. This endpoint can be used to exchange the slug of a geography for the real name of the geography. 
         * @summary Exchange the slug of one or more locations for the location\'s real name
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [cities] 
         * @param {Set<string>} [roads] 
         * @param {Set<string>} [placeNames] 
         * @param {Set<string>} [customAreas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsSlug: async (provinces?: Set<string>, municipalities?: Set<string>, cities?: Set<string>, roads?: Set<string>, placeNames?: Set<string>, customAreas?: Set<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/locations/slug`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (provinces) {
                localVarQueryParameter['provinces'] = Array.from(provinces);
            }

            if (municipalities) {
                localVarQueryParameter['municipalities'] = Array.from(municipalities);
            }

            if (cities) {
                localVarQueryParameter['cities'] = Array.from(cities);
            }

            if (roads) {
                localVarQueryParameter['roads'] = Array.from(roads);
            }

            if (placeNames) {
                localVarQueryParameter['placeNames'] = Array.from(placeNames);
            }

            if (customAreas) {
                localVarQueryParameter['customAreas'] = Array.from(customAreas);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ping GET endpoint to test the API.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ping POST endpoint to test the API.
         * @param {PingMessageBody} pingMessageBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingWithMessage: async (pingMessageBody: PingMessageBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pingMessageBody' is not null or undefined
            assertParamExists('pingWithMessage', 'pingMessageBody', pingMessageBody)
            const localVarPath = `/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pingMessageBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Register a new interaction. 
         * @summary Register a new interaction
         * @param {Interaction} interaction User interaction data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInteraction: async (interaction: Interaction, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'interaction' is not null or undefined
            assertParamExists('postInteraction', 'interaction', interaction)
            const localVarPath = `/interactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(interaction, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MiscellaneousApi - functional programming interface
 * @export
 */
export const MiscellaneousApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MiscellaneousApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint aggregates the bounding boxes of one or more geographical areas. That is, it returns the smallest possible rectangle which contains the bounding box of each geographical area provided.  Only zero or one of `provinces`, `municipalities`, `zipCodes`, `cities`, `roads`, `placesNames` and `customAreas` is supported. Sending two or more will return a HTTP error `400`.  If none of `provinces`, `municipalities`, `zipCodes`, `cities`, `roads`, `placesNames` and `customAreas` are sent, the bounding box of all of Denmark is returned.  While the overwhelming majority of geographical areas have a bounding box, there are a select few that don\'t. In the rare case that all geographical areas sent have no bounding box, the bounding box of all of Denmark is returned. 
         * @summary Aggregate the bounding boxes of one or more geographical areas
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [zipCodes] This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
         * @param {Set<string>} [cities] 
         * @param {Set<string>} [roads] Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60;. 
         * @param {Set<string>} [placeNames] 
         * @param {Set<string>} [customAreas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateBbox(provinces?: Set<string>, municipalities?: Set<string>, zipCodes?: Set<string>, cities?: Set<string>, roads?: Set<string>, placeNames?: Set<string>, customAreas?: Set<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Set<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateBbox(provinces, municipalities, zipCodes, cities, roads, placeNames, customAreas, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of banks that provide mortgage calculation
         * @param {AddressType} [addressType] 
         * @param {MortgageSource} [mortgageSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateMortgageBanks(addressType?: AddressType, mortgageSource?: MortgageSource, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Bank>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateMortgageBanks(addressType, mortgageSource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary View API documentation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doc(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doc(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all custom areas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCustomAreas(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomAreaResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCustomAreas(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Used by deployment setup to probe the service instance for its health situation.
         * @summary Check service health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Different geography types (provinces, zip codes, cities etc.) on Boligsiden can be navigated to via a URL. This URL is typically a slugified version of the geography\'s name. For example, `København NV` becomes `koebenhavn-nv`. This endpoint can be used to exchange the slug of a geography for the real name of the geography. 
         * @summary Exchange the slug of one or more locations for the location\'s real name
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [cities] 
         * @param {Set<string>} [roads] 
         * @param {Set<string>} [placeNames] 
         * @param {Set<string>} [customAreas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationsSlug(provinces?: Set<string>, municipalities?: Set<string>, cities?: Set<string>, roads?: Set<string>, placeNames?: Set<string>, customAreas?: Set<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationsSlugsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationsSlug(provinces, municipalities, cities, roads, placeNames, customAreas, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Ping GET endpoint to test the API.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ping(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ping(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Ping POST endpoint to test the API.
         * @param {PingMessageBody} pingMessageBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingWithMessage(pingMessageBody: PingMessageBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingWithMessage(pingMessageBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Register a new interaction. 
         * @summary Register a new interaction
         * @param {Interaction} interaction User interaction data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postInteraction(interaction: Interaction, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postInteraction(interaction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MiscellaneousApi - factory interface
 * @export
 */
export const MiscellaneousApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MiscellaneousApiFp(configuration)
    return {
        /**
         * This endpoint aggregates the bounding boxes of one or more geographical areas. That is, it returns the smallest possible rectangle which contains the bounding box of each geographical area provided.  Only zero or one of `provinces`, `municipalities`, `zipCodes`, `cities`, `roads`, `placesNames` and `customAreas` is supported. Sending two or more will return a HTTP error `400`.  If none of `provinces`, `municipalities`, `zipCodes`, `cities`, `roads`, `placesNames` and `customAreas` are sent, the bounding box of all of Denmark is returned.  While the overwhelming majority of geographical areas have a bounding box, there are a select few that don\'t. In the rare case that all geographical areas sent have no bounding box, the bounding box of all of Denmark is returned. 
         * @summary Aggregate the bounding boxes of one or more geographical areas
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [zipCodes] This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
         * @param {Set<string>} [cities] 
         * @param {Set<string>} [roads] Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60;. 
         * @param {Set<string>} [placeNames] 
         * @param {Set<string>} [customAreas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateBbox(provinces?: Set<string>, municipalities?: Set<string>, zipCodes?: Set<string>, cities?: Set<string>, roads?: Set<string>, placeNames?: Set<string>, customAreas?: Set<string>, options?: any): AxiosPromise<Set<number>> {
            return localVarFp.aggregateBbox(provinces, municipalities, zipCodes, cities, roads, placeNames, customAreas, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of banks that provide mortgage calculation
         * @param {AddressType} [addressType] 
         * @param {MortgageSource} [mortgageSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateMortgageBanks(addressType?: AddressType, mortgageSource?: MortgageSource, options?: any): AxiosPromise<Array<Bank>> {
            return localVarFp.calculateMortgageBanks(addressType, mortgageSource, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary View API documentation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doc(options?: any): AxiosPromise<string> {
            return localVarFp.doc(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all custom areas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomAreas(options?: any): AxiosPromise<CustomAreaResult> {
            return localVarFp.getAllCustomAreas(options).then((request) => request(axios, basePath));
        },
        /**
         * Used by deployment setup to probe the service instance for its health situation.
         * @summary Check service health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<object> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
        /**
         * Different geography types (provinces, zip codes, cities etc.) on Boligsiden can be navigated to via a URL. This URL is typically a slugified version of the geography\'s name. For example, `København NV` becomes `koebenhavn-nv`. This endpoint can be used to exchange the slug of a geography for the real name of the geography. 
         * @summary Exchange the slug of one or more locations for the location\'s real name
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [cities] 
         * @param {Set<string>} [roads] 
         * @param {Set<string>} [placeNames] 
         * @param {Set<string>} [customAreas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsSlug(provinces?: Set<string>, municipalities?: Set<string>, cities?: Set<string>, roads?: Set<string>, placeNames?: Set<string>, customAreas?: Set<string>, options?: any): AxiosPromise<LocationsSlugsResult> {
            return localVarFp.locationsSlug(provinces, municipalities, cities, roads, placeNames, customAreas, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ping GET endpoint to test the API.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.ping(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ping POST endpoint to test the API.
         * @param {PingMessageBody} pingMessageBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingWithMessage(pingMessageBody: PingMessageBody, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.pingWithMessage(pingMessageBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Register a new interaction. 
         * @summary Register a new interaction
         * @param {Interaction} interaction User interaction data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInteraction(interaction: Interaction, options?: any): AxiosPromise<void> {
            return localVarFp.postInteraction(interaction, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for aggregateBbox operation in MiscellaneousApi.
 * @export
 * @interface MiscellaneousApiAggregateBboxRequest
 */
export interface MiscellaneousApiAggregateBboxRequest {
    /**
     * 
     * @type {Set<string>}
     * @memberof MiscellaneousApiAggregateBbox
     */
    readonly provinces?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof MiscellaneousApiAggregateBbox
     */
    readonly municipalities?: Set<string>

    /**
     * This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
     * @type {Set<string>}
     * @memberof MiscellaneousApiAggregateBbox
     */
    readonly zipCodes?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof MiscellaneousApiAggregateBbox
     */
    readonly cities?: Set<string>

    /**
     * Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60;. 
     * @type {Set<string>}
     * @memberof MiscellaneousApiAggregateBbox
     */
    readonly roads?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof MiscellaneousApiAggregateBbox
     */
    readonly placeNames?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof MiscellaneousApiAggregateBbox
     */
    readonly customAreas?: Set<string>
}

/**
 * Request parameters for calculateMortgageBanks operation in MiscellaneousApi.
 * @export
 * @interface MiscellaneousApiCalculateMortgageBanksRequest
 */
export interface MiscellaneousApiCalculateMortgageBanksRequest {
    /**
     * 
     * @type {AddressType}
     * @memberof MiscellaneousApiCalculateMortgageBanks
     */
    readonly addressType?: AddressType

    /**
     * 
     * @type {MortgageSource}
     * @memberof MiscellaneousApiCalculateMortgageBanks
     */
    readonly mortgageSource?: MortgageSource
}

/**
 * Request parameters for locationsSlug operation in MiscellaneousApi.
 * @export
 * @interface MiscellaneousApiLocationsSlugRequest
 */
export interface MiscellaneousApiLocationsSlugRequest {
    /**
     * 
     * @type {Set<string>}
     * @memberof MiscellaneousApiLocationsSlug
     */
    readonly provinces?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof MiscellaneousApiLocationsSlug
     */
    readonly municipalities?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof MiscellaneousApiLocationsSlug
     */
    readonly cities?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof MiscellaneousApiLocationsSlug
     */
    readonly roads?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof MiscellaneousApiLocationsSlug
     */
    readonly placeNames?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof MiscellaneousApiLocationsSlug
     */
    readonly customAreas?: Set<string>
}

/**
 * Request parameters for pingWithMessage operation in MiscellaneousApi.
 * @export
 * @interface MiscellaneousApiPingWithMessageRequest
 */
export interface MiscellaneousApiPingWithMessageRequest {
    /**
     * 
     * @type {PingMessageBody}
     * @memberof MiscellaneousApiPingWithMessage
     */
    readonly pingMessageBody: PingMessageBody
}

/**
 * Request parameters for postInteraction operation in MiscellaneousApi.
 * @export
 * @interface MiscellaneousApiPostInteractionRequest
 */
export interface MiscellaneousApiPostInteractionRequest {
    /**
     * User interaction data
     * @type {Interaction}
     * @memberof MiscellaneousApiPostInteraction
     */
    readonly interaction: Interaction
}

/**
 * MiscellaneousApi - object-oriented interface
 * @export
 * @class MiscellaneousApi
 * @extends {BaseAPI}
 */
export class MiscellaneousApi extends BaseAPI {
    /**
     * This endpoint aggregates the bounding boxes of one or more geographical areas. That is, it returns the smallest possible rectangle which contains the bounding box of each geographical area provided.  Only zero or one of `provinces`, `municipalities`, `zipCodes`, `cities`, `roads`, `placesNames` and `customAreas` is supported. Sending two or more will return a HTTP error `400`.  If none of `provinces`, `municipalities`, `zipCodes`, `cities`, `roads`, `placesNames` and `customAreas` are sent, the bounding box of all of Denmark is returned.  While the overwhelming majority of geographical areas have a bounding box, there are a select few that don\'t. In the rare case that all geographical areas sent have no bounding box, the bounding box of all of Denmark is returned. 
     * @summary Aggregate the bounding boxes of one or more geographical areas
     * @param {MiscellaneousApiAggregateBboxRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MiscellaneousApi
     */
    public aggregateBbox(requestParameters: MiscellaneousApiAggregateBboxRequest = {}, options?: any) {
        return MiscellaneousApiFp(this.configuration).aggregateBbox(requestParameters.provinces, requestParameters.municipalities, requestParameters.zipCodes, requestParameters.cities, requestParameters.roads, requestParameters.placeNames, requestParameters.customAreas, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of banks that provide mortgage calculation
     * @param {MiscellaneousApiCalculateMortgageBanksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MiscellaneousApi
     */
    public calculateMortgageBanks(requestParameters: MiscellaneousApiCalculateMortgageBanksRequest = {}, options?: any) {
        return MiscellaneousApiFp(this.configuration).calculateMortgageBanks(requestParameters.addressType, requestParameters.mortgageSource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary View API documentation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MiscellaneousApi
     */
    public doc(options?: any) {
        return MiscellaneousApiFp(this.configuration).doc(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all custom areas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MiscellaneousApi
     */
    public getAllCustomAreas(options?: any) {
        return MiscellaneousApiFp(this.configuration).getAllCustomAreas(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Used by deployment setup to probe the service instance for its health situation.
     * @summary Check service health
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MiscellaneousApi
     */
    public health(options?: any) {
        return MiscellaneousApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Different geography types (provinces, zip codes, cities etc.) on Boligsiden can be navigated to via a URL. This URL is typically a slugified version of the geography\'s name. For example, `København NV` becomes `koebenhavn-nv`. This endpoint can be used to exchange the slug of a geography for the real name of the geography. 
     * @summary Exchange the slug of one or more locations for the location\'s real name
     * @param {MiscellaneousApiLocationsSlugRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MiscellaneousApi
     */
    public locationsSlug(requestParameters: MiscellaneousApiLocationsSlugRequest = {}, options?: any) {
        return MiscellaneousApiFp(this.configuration).locationsSlug(requestParameters.provinces, requestParameters.municipalities, requestParameters.cities, requestParameters.roads, requestParameters.placeNames, requestParameters.customAreas, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ping GET endpoint to test the API.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MiscellaneousApi
     */
    public ping(options?: any) {
        return MiscellaneousApiFp(this.configuration).ping(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ping POST endpoint to test the API.
     * @param {MiscellaneousApiPingWithMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MiscellaneousApi
     */
    public pingWithMessage(requestParameters: MiscellaneousApiPingWithMessageRequest, options?: any) {
        return MiscellaneousApiFp(this.configuration).pingWithMessage(requestParameters.pingMessageBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Register a new interaction. 
     * @summary Register a new interaction
     * @param {MiscellaneousApiPostInteractionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MiscellaneousApi
     */
    public postInteraction(requestParameters: MiscellaneousApiPostInteractionRequest, options?: any) {
        return MiscellaneousApiFp(this.configuration).postInteraction(requestParameters.interaction, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MunicipalitiesApi - axios parameter creator
 * @export
 */
export const MunicipalitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a list of all additional city names in a municipality
         * @param {number} municipalityCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMunicipalityAdditionalCityNames: async (municipalityCode: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'municipalityCode' is not null or undefined
            assertParamExists('getMunicipalityAdditionalCityNames', 'municipalityCode', municipalityCode)
            const localVarPath = `/municipalities/{municipalityCode}/additional_city_names`
                .replace(`{${"municipalityCode"}}`, encodeURIComponent(String(municipalityCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get municipality\'s institutions
         * @param {number} municipalityCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMunicipalityInstitutions: async (municipalityCode: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'municipalityCode' is not null or undefined
            assertParamExists('getMunicipalityInstitutions', 'municipalityCode', municipalityCode)
            const localVarPath = `/municipalities/{municipalityCode}/institutions`
                .replace(`{${"municipalityCode"}}`, encodeURIComponent(String(municipalityCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Municipalities are sorted alphabetically using Danish alphabet 
         * @summary Get all municipalities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        municipalities: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/municipalities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MunicipalitiesApi - functional programming interface
 * @export
 */
export const MunicipalitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MunicipalitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a list of all additional city names in a municipality
         * @param {number} municipalityCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMunicipalityAdditionalCityNames(municipalityCode: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdditionalCityName>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMunicipalityAdditionalCityNames(municipalityCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get municipality\'s institutions
         * @param {number} municipalityCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMunicipalityInstitutions(municipalityCode: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Institution>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMunicipalityInstitutions(municipalityCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Municipalities are sorted alphabetically using Danish alphabet 
         * @summary Get all municipalities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async municipalities(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MunicipalityCollectionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.municipalities(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MunicipalitiesApi - factory interface
 * @export
 */
export const MunicipalitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MunicipalitiesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a list of all additional city names in a municipality
         * @param {number} municipalityCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMunicipalityAdditionalCityNames(municipalityCode: number, options?: any): AxiosPromise<Array<AdditionalCityName>> {
            return localVarFp.getMunicipalityAdditionalCityNames(municipalityCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get municipality\'s institutions
         * @param {number} municipalityCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMunicipalityInstitutions(municipalityCode: number, options?: any): AxiosPromise<Array<Institution>> {
            return localVarFp.getMunicipalityInstitutions(municipalityCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Municipalities are sorted alphabetically using Danish alphabet 
         * @summary Get all municipalities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        municipalities(options?: any): AxiosPromise<MunicipalityCollectionResult> {
            return localVarFp.municipalities(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getMunicipalityAdditionalCityNames operation in MunicipalitiesApi.
 * @export
 * @interface MunicipalitiesApiGetMunicipalityAdditionalCityNamesRequest
 */
export interface MunicipalitiesApiGetMunicipalityAdditionalCityNamesRequest {
    /**
     * 
     * @type {number}
     * @memberof MunicipalitiesApiGetMunicipalityAdditionalCityNames
     */
    readonly municipalityCode: number
}

/**
 * Request parameters for getMunicipalityInstitutions operation in MunicipalitiesApi.
 * @export
 * @interface MunicipalitiesApiGetMunicipalityInstitutionsRequest
 */
export interface MunicipalitiesApiGetMunicipalityInstitutionsRequest {
    /**
     * 
     * @type {number}
     * @memberof MunicipalitiesApiGetMunicipalityInstitutions
     */
    readonly municipalityCode: number
}

/**
 * MunicipalitiesApi - object-oriented interface
 * @export
 * @class MunicipalitiesApi
 * @extends {BaseAPI}
 */
export class MunicipalitiesApi extends BaseAPI {
    /**
     * 
     * @summary Get a list of all additional city names in a municipality
     * @param {MunicipalitiesApiGetMunicipalityAdditionalCityNamesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MunicipalitiesApi
     */
    public getMunicipalityAdditionalCityNames(requestParameters: MunicipalitiesApiGetMunicipalityAdditionalCityNamesRequest, options?: any) {
        return MunicipalitiesApiFp(this.configuration).getMunicipalityAdditionalCityNames(requestParameters.municipalityCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get municipality\'s institutions
     * @param {MunicipalitiesApiGetMunicipalityInstitutionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MunicipalitiesApi
     */
    public getMunicipalityInstitutions(requestParameters: MunicipalitiesApiGetMunicipalityInstitutionsRequest, options?: any) {
        return MunicipalitiesApiFp(this.configuration).getMunicipalityInstitutions(requestParameters.municipalityCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Municipalities are sorted alphabetically using Danish alphabet 
     * @summary Get all municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MunicipalitiesApi
     */
    public municipalities(options?: any) {
        return MunicipalitiesApiFp(this.configuration).municipalities(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PointsOfInterestApi - axios parameter creator
 * @export
 */
export const PointsOfInterestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint is idempotent; attempting to delete a point of interest that does not exist returns `204`. 
         * @summary Delete a point of interest
         * @param {string} userPointOfInterestID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPointOfInterest: async (userPointOfInterestID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPointOfInterestID' is not null or undefined
            assertParamExists('deleteUserPointOfInterest', 'userPointOfInterestID', userPointOfInterestID)
            const localVarPath = `/user/points_of_interest/{userPointOfInterestID}`
                .replace(`{${"userPointOfInterestID"}}`, encodeURIComponent(String(userPointOfInterestID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a user\'s points of interest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPointsOfInterest: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/points_of_interest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new point of interest
         * @param {PostUserPointOfInterest} postUserPointOfInterest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserPointOfInterest: async (postUserPointOfInterest: PostUserPointOfInterest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postUserPointOfInterest' is not null or undefined
            assertParamExists('postUserPointOfInterest', 'postUserPointOfInterest', postUserPointOfInterest)
            const localVarPath = `/user/points_of_interest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postUserPointOfInterest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a point of interest
         * @param {string} userPointOfInterestID 
         * @param {PostUserPointOfInterest} postUserPointOfInterest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserPointOfInterest: async (userPointOfInterestID: string, postUserPointOfInterest: PostUserPointOfInterest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPointOfInterestID' is not null or undefined
            assertParamExists('putUserPointOfInterest', 'userPointOfInterestID', userPointOfInterestID)
            // verify required parameter 'postUserPointOfInterest' is not null or undefined
            assertParamExists('putUserPointOfInterest', 'postUserPointOfInterest', postUserPointOfInterest)
            const localVarPath = `/user/points_of_interest/{userPointOfInterestID}`
                .replace(`{${"userPointOfInterestID"}}`, encodeURIComponent(String(userPointOfInterestID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postUserPointOfInterest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PointsOfInterestApi - functional programming interface
 * @export
 */
export const PointsOfInterestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PointsOfInterestApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint is idempotent; attempting to delete a point of interest that does not exist returns `204`. 
         * @summary Delete a point of interest
         * @param {string} userPointOfInterestID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserPointOfInterest(userPointOfInterestID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserPointOfInterest(userPointOfInterestID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a user\'s points of interest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPointsOfInterest(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserPointsOfInterestResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPointsOfInterest(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new point of interest
         * @param {PostUserPointOfInterest} postUserPointOfInterest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserPointOfInterest(postUserPointOfInterest: PostUserPointOfInterest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostUserPointOfInterestResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserPointOfInterest(postUserPointOfInterest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a point of interest
         * @param {string} userPointOfInterestID 
         * @param {PostUserPointOfInterest} postUserPointOfInterest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUserPointOfInterest(userPointOfInterestID: string, postUserPointOfInterest: PostUserPointOfInterest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutUserPointOfInterestResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUserPointOfInterest(userPointOfInterestID, postUserPointOfInterest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PointsOfInterestApi - factory interface
 * @export
 */
export const PointsOfInterestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PointsOfInterestApiFp(configuration)
    return {
        /**
         * This endpoint is idempotent; attempting to delete a point of interest that does not exist returns `204`. 
         * @summary Delete a point of interest
         * @param {string} userPointOfInterestID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPointOfInterest(userPointOfInterestID: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserPointOfInterest(userPointOfInterestID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a user\'s points of interest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPointsOfInterest(options?: any): AxiosPromise<GetUserPointsOfInterestResult> {
            return localVarFp.getUserPointsOfInterest(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new point of interest
         * @param {PostUserPointOfInterest} postUserPointOfInterest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserPointOfInterest(postUserPointOfInterest: PostUserPointOfInterest, options?: any): AxiosPromise<PostUserPointOfInterestResult> {
            return localVarFp.postUserPointOfInterest(postUserPointOfInterest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a point of interest
         * @param {string} userPointOfInterestID 
         * @param {PostUserPointOfInterest} postUserPointOfInterest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserPointOfInterest(userPointOfInterestID: string, postUserPointOfInterest: PostUserPointOfInterest, options?: any): AxiosPromise<PutUserPointOfInterestResult> {
            return localVarFp.putUserPointOfInterest(userPointOfInterestID, postUserPointOfInterest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteUserPointOfInterest operation in PointsOfInterestApi.
 * @export
 * @interface PointsOfInterestApiDeleteUserPointOfInterestRequest
 */
export interface PointsOfInterestApiDeleteUserPointOfInterestRequest {
    /**
     * 
     * @type {string}
     * @memberof PointsOfInterestApiDeleteUserPointOfInterest
     */
    readonly userPointOfInterestID: string
}

/**
 * Request parameters for postUserPointOfInterest operation in PointsOfInterestApi.
 * @export
 * @interface PointsOfInterestApiPostUserPointOfInterestRequest
 */
export interface PointsOfInterestApiPostUserPointOfInterestRequest {
    /**
     * 
     * @type {PostUserPointOfInterest}
     * @memberof PointsOfInterestApiPostUserPointOfInterest
     */
    readonly postUserPointOfInterest: PostUserPointOfInterest
}

/**
 * Request parameters for putUserPointOfInterest operation in PointsOfInterestApi.
 * @export
 * @interface PointsOfInterestApiPutUserPointOfInterestRequest
 */
export interface PointsOfInterestApiPutUserPointOfInterestRequest {
    /**
     * 
     * @type {string}
     * @memberof PointsOfInterestApiPutUserPointOfInterest
     */
    readonly userPointOfInterestID: string

    /**
     * 
     * @type {PostUserPointOfInterest}
     * @memberof PointsOfInterestApiPutUserPointOfInterest
     */
    readonly postUserPointOfInterest: PostUserPointOfInterest
}

/**
 * PointsOfInterestApi - object-oriented interface
 * @export
 * @class PointsOfInterestApi
 * @extends {BaseAPI}
 */
export class PointsOfInterestApi extends BaseAPI {
    /**
     * This endpoint is idempotent; attempting to delete a point of interest that does not exist returns `204`. 
     * @summary Delete a point of interest
     * @param {PointsOfInterestApiDeleteUserPointOfInterestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsOfInterestApi
     */
    public deleteUserPointOfInterest(requestParameters: PointsOfInterestApiDeleteUserPointOfInterestRequest, options?: any) {
        return PointsOfInterestApiFp(this.configuration).deleteUserPointOfInterest(requestParameters.userPointOfInterestID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a user\'s points of interest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsOfInterestApi
     */
    public getUserPointsOfInterest(options?: any) {
        return PointsOfInterestApiFp(this.configuration).getUserPointsOfInterest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new point of interest
     * @param {PointsOfInterestApiPostUserPointOfInterestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsOfInterestApi
     */
    public postUserPointOfInterest(requestParameters: PointsOfInterestApiPostUserPointOfInterestRequest, options?: any) {
        return PointsOfInterestApiFp(this.configuration).postUserPointOfInterest(requestParameters.postUserPointOfInterest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a point of interest
     * @param {PointsOfInterestApiPutUserPointOfInterestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsOfInterestApi
     */
    public putUserPointOfInterest(requestParameters: PointsOfInterestApiPutUserPointOfInterestRequest, options?: any) {
        return PointsOfInterestApiFp(this.configuration).putUserPointOfInterest(requestParameters.userPointOfInterestID, requestParameters.postUserPointOfInterest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PollsApi - axios parameter creator
 * @export
 */
export const PollsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get Poll\'s details. Polls have settings, this endpoint is meant to display each poll\'s settings. 
         * @summary Get poll
         * @param {string} pollID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoll: async (pollID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pollID' is not null or undefined
            assertParamExists('getPoll', 'pollID', pollID)
            const localVarPath = `/poll/{pollID}`
                .replace(`{${"pollID"}}`, encodeURIComponent(String(pollID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a poll\'s possible answers. Each answer also contains result information for that answer. 
         * @summary Get a poll\'s answer options
         * @param {string} pollID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPollAnswers: async (pollID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pollID' is not null or undefined
            assertParamExists('getPollAnswers', 'pollID', pollID)
            const localVarPath = `/polls/{pollID}/answers`
                .replace(`{${"pollID"}}`, encodeURIComponent(String(pollID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get polls
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPolls: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/polls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns poll anwers _after_ factoring in the newly cast vote.  Note that casting a vote for an answer that does not exist does _not_ return an error; rather, the vote is silently discarded.  Attempting to cast a vote on a poll that does not exist returns `404`. 
         * @summary Cast a vote on a poll
         * @param {string} pollID 
         * @param {string} answerID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollVote: async (pollID: string, answerID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pollID' is not null or undefined
            assertParamExists('pollVote', 'pollID', pollID)
            // verify required parameter 'answerID' is not null or undefined
            assertParamExists('pollVote', 'answerID', answerID)
            const localVarPath = `/polls/{pollID}/answers/{answerID}/vote`
                .replace(`{${"pollID"}}`, encodeURIComponent(String(pollID)))
                .replace(`{${"answerID"}}`, encodeURIComponent(String(answerID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PollsApi - functional programming interface
 * @export
 */
export const PollsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PollsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get Poll\'s details. Polls have settings, this endpoint is meant to display each poll\'s settings. 
         * @summary Get poll
         * @param {string} pollID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPoll(pollID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPoll(pollID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a poll\'s possible answers. Each answer also contains result information for that answer. 
         * @summary Get a poll\'s answer options
         * @param {string} pollID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPollAnswers(pollID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPollAnswers(pollID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get polls
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPolls(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPolls(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns poll anwers _after_ factoring in the newly cast vote.  Note that casting a vote for an answer that does not exist does _not_ return an error; rather, the vote is silently discarded.  Attempting to cast a vote on a poll that does not exist returns `404`. 
         * @summary Cast a vote on a poll
         * @param {string} pollID 
         * @param {string} answerID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollVote(pollID: string, answerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pollVote(pollID, answerID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PollsApi - factory interface
 * @export
 */
export const PollsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PollsApiFp(configuration)
    return {
        /**
         * Get Poll\'s details. Polls have settings, this endpoint is meant to display each poll\'s settings. 
         * @summary Get poll
         * @param {string} pollID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoll(pollID: string, options?: any): AxiosPromise<InlineResponse2002> {
            return localVarFp.getPoll(pollID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a poll\'s possible answers. Each answer also contains result information for that answer. 
         * @summary Get a poll\'s answer options
         * @param {string} pollID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPollAnswers(pollID: string, options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.getPollAnswers(pollID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get polls
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPolls(options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.getPolls(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns poll anwers _after_ factoring in the newly cast vote.  Note that casting a vote for an answer that does not exist does _not_ return an error; rather, the vote is silently discarded.  Attempting to cast a vote on a poll that does not exist returns `404`. 
         * @summary Cast a vote on a poll
         * @param {string} pollID 
         * @param {string} answerID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollVote(pollID: string, answerID: string, options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.pollVote(pollID, answerID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getPoll operation in PollsApi.
 * @export
 * @interface PollsApiGetPollRequest
 */
export interface PollsApiGetPollRequest {
    /**
     * 
     * @type {string}
     * @memberof PollsApiGetPoll
     */
    readonly pollID: string
}

/**
 * Request parameters for getPollAnswers operation in PollsApi.
 * @export
 * @interface PollsApiGetPollAnswersRequest
 */
export interface PollsApiGetPollAnswersRequest {
    /**
     * 
     * @type {string}
     * @memberof PollsApiGetPollAnswers
     */
    readonly pollID: string
}

/**
 * Request parameters for pollVote operation in PollsApi.
 * @export
 * @interface PollsApiPollVoteRequest
 */
export interface PollsApiPollVoteRequest {
    /**
     * 
     * @type {string}
     * @memberof PollsApiPollVote
     */
    readonly pollID: string

    /**
     * 
     * @type {string}
     * @memberof PollsApiPollVote
     */
    readonly answerID: string
}

/**
 * PollsApi - object-oriented interface
 * @export
 * @class PollsApi
 * @extends {BaseAPI}
 */
export class PollsApi extends BaseAPI {
    /**
     * Get Poll\'s details. Polls have settings, this endpoint is meant to display each poll\'s settings. 
     * @summary Get poll
     * @param {PollsApiGetPollRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public getPoll(requestParameters: PollsApiGetPollRequest, options?: any) {
        return PollsApiFp(this.configuration).getPoll(requestParameters.pollID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a poll\'s possible answers. Each answer also contains result information for that answer. 
     * @summary Get a poll\'s answer options
     * @param {PollsApiGetPollAnswersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public getPollAnswers(requestParameters: PollsApiGetPollAnswersRequest, options?: any) {
        return PollsApiFp(this.configuration).getPollAnswers(requestParameters.pollID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get polls
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public getPolls(options?: any) {
        return PollsApiFp(this.configuration).getPolls(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns poll anwers _after_ factoring in the newly cast vote.  Note that casting a vote for an answer that does not exist does _not_ return an error; rather, the vote is silently discarded.  Attempting to cast a vote on a poll that does not exist returns `404`. 
     * @summary Cast a vote on a poll
     * @param {PollsApiPollVoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public pollVote(requestParameters: PollsApiPollVoteRequest, options?: any) {
        return PollsApiFp(this.configuration).pollVote(requestParameters.pollID, requestParameters.answerID, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PreviewsApi - axios parameter creator
 * @export
 */
export const PreviewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a user\'s favorite addresses and previews
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFavorites: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/favorites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This operation is idempotent; calling it for a preview that is not in the user\'s list of favorites has no side effects. 
         * @summary Remove a preview from the user\'s list of favorites
         * @param {string} previewID UUID of the Preview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoritePreviewDelete: async (previewID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'previewID' is not null or undefined
            assertParamExists('userFavoritePreviewDelete', 'previewID', previewID)
            const localVarPath = `/user/previews/{previewID}/favorite`
                .replace(`{${"previewID"}}`, encodeURIComponent(String(previewID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This operation is idempotent; calling it for a preview that is already in the user\'s list of favorites has no side effects. 
         * @summary Add a preview to the user\'s list of favorites
         * @param {string} previewID UUID of the Preview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoritePreviewPut: async (previewID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'previewID' is not null or undefined
            assertParamExists('userFavoritePreviewPut', 'previewID', previewID)
            const localVarPath = `/user/previews/{previewID}/favorite`
                .replace(`{${"previewID"}}`, encodeURIComponent(String(previewID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PreviewsApi - functional programming interface
 * @export
 */
export const PreviewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PreviewsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a user\'s favorite addresses and previews
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserFavorites(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FavoriteResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserFavorites(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This operation is idempotent; calling it for a preview that is not in the user\'s list of favorites has no side effects. 
         * @summary Remove a preview from the user\'s list of favorites
         * @param {string} previewID UUID of the Preview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userFavoritePreviewDelete(previewID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userFavoritePreviewDelete(previewID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This operation is idempotent; calling it for a preview that is already in the user\'s list of favorites has no side effects. 
         * @summary Add a preview to the user\'s list of favorites
         * @param {string} previewID UUID of the Preview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userFavoritePreviewPut(previewID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userFavoritePreviewPut(previewID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PreviewsApi - factory interface
 * @export
 */
export const PreviewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PreviewsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a user\'s favorite addresses and previews
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFavorites(options?: any): AxiosPromise<FavoriteResult> {
            return localVarFp.getUserFavorites(options).then((request) => request(axios, basePath));
        },
        /**
         * This operation is idempotent; calling it for a preview that is not in the user\'s list of favorites has no side effects. 
         * @summary Remove a preview from the user\'s list of favorites
         * @param {string} previewID UUID of the Preview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoritePreviewDelete(previewID: string, options?: any): AxiosPromise<void> {
            return localVarFp.userFavoritePreviewDelete(previewID, options).then((request) => request(axios, basePath));
        },
        /**
         * This operation is idempotent; calling it for a preview that is already in the user\'s list of favorites has no side effects. 
         * @summary Add a preview to the user\'s list of favorites
         * @param {string} previewID UUID of the Preview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoritePreviewPut(previewID: string, options?: any): AxiosPromise<void> {
            return localVarFp.userFavoritePreviewPut(previewID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for userFavoritePreviewDelete operation in PreviewsApi.
 * @export
 * @interface PreviewsApiUserFavoritePreviewDeleteRequest
 */
export interface PreviewsApiUserFavoritePreviewDeleteRequest {
    /**
     * UUID of the Preview
     * @type {string}
     * @memberof PreviewsApiUserFavoritePreviewDelete
     */
    readonly previewID: string
}

/**
 * Request parameters for userFavoritePreviewPut operation in PreviewsApi.
 * @export
 * @interface PreviewsApiUserFavoritePreviewPutRequest
 */
export interface PreviewsApiUserFavoritePreviewPutRequest {
    /**
     * UUID of the Preview
     * @type {string}
     * @memberof PreviewsApiUserFavoritePreviewPut
     */
    readonly previewID: string
}

/**
 * PreviewsApi - object-oriented interface
 * @export
 * @class PreviewsApi
 * @extends {BaseAPI}
 */
export class PreviewsApi extends BaseAPI {
    /**
     * 
     * @summary Get a user\'s favorite addresses and previews
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreviewsApi
     */
    public getUserFavorites(options?: any) {
        return PreviewsApiFp(this.configuration).getUserFavorites(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This operation is idempotent; calling it for a preview that is not in the user\'s list of favorites has no side effects. 
     * @summary Remove a preview from the user\'s list of favorites
     * @param {PreviewsApiUserFavoritePreviewDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreviewsApi
     */
    public userFavoritePreviewDelete(requestParameters: PreviewsApiUserFavoritePreviewDeleteRequest, options?: any) {
        return PreviewsApiFp(this.configuration).userFavoritePreviewDelete(requestParameters.previewID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This operation is idempotent; calling it for a preview that is already in the user\'s list of favorites has no side effects. 
     * @summary Add a preview to the user\'s list of favorites
     * @param {PreviewsApiUserFavoritePreviewPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreviewsApi
     */
    public userFavoritePreviewPut(requestParameters: PreviewsApiUserFavoritePreviewPutRequest, options?: any) {
        return PreviewsApiFp(this.configuration).userFavoritePreviewPut(requestParameters.previewID, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PromotionsApi - axios parameter creator
 * @export
 */
export const PromotionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get promotions
         * @param {number} limit The maximum amount of promotions to be returned
         * @param {number} [price] Get promotions in this price class
         * @param {number} [municipalityCode] Get promotions in this municipality
         * @param {AddressType} [addressType] Get promotions for this address type
         * @param {EnergyLabel} [energyLabel] 
         * @param {DisplayOn} [displayOn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotions: async (limit: number, price?: number, municipalityCode?: number, addressType?: AddressType, energyLabel?: EnergyLabel, displayOn?: DisplayOn, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getPromotions', 'limit', limit)
            const localVarPath = `/promotion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (price !== undefined) {
                localVarQueryParameter['price'] = price;
            }

            if (municipalityCode !== undefined) {
                localVarQueryParameter['municipalityCode'] = municipalityCode;
            }

            if (addressType !== undefined) {
                localVarQueryParameter['addressType'] = addressType;
            }

            if (energyLabel !== undefined) {
                localVarQueryParameter['energyLabel'] = energyLabel;
            }

            if (displayOn !== undefined) {
                localVarQueryParameter['displayOn'] = displayOn;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register a promotion interaction
         * @param {PostPromotionInteraction} postPromotionInteraction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPromotionInteraction: async (postPromotionInteraction: PostPromotionInteraction, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postPromotionInteraction' is not null or undefined
            assertParamExists('postPromotionInteraction', 'postPromotionInteraction', postPromotionInteraction)
            const localVarPath = `/promotion/interactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postPromotionInteraction, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromotionsApi - functional programming interface
 * @export
 */
export const PromotionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromotionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get promotions
         * @param {number} limit The maximum amount of promotions to be returned
         * @param {number} [price] Get promotions in this price class
         * @param {number} [municipalityCode] Get promotions in this municipality
         * @param {AddressType} [addressType] Get promotions for this address type
         * @param {EnergyLabel} [energyLabel] 
         * @param {DisplayOn} [displayOn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPromotions(limit: number, price?: number, municipalityCode?: number, addressType?: AddressType, energyLabel?: EnergyLabel, displayOn?: DisplayOn, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPromotionsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPromotions(limit, price, municipalityCode, addressType, energyLabel, displayOn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register a promotion interaction
         * @param {PostPromotionInteraction} postPromotionInteraction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPromotionInteraction(postPromotionInteraction: PostPromotionInteraction, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPromotionInteraction(postPromotionInteraction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromotionsApi - factory interface
 * @export
 */
export const PromotionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromotionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get promotions
         * @param {number} limit The maximum amount of promotions to be returned
         * @param {number} [price] Get promotions in this price class
         * @param {number} [municipalityCode] Get promotions in this municipality
         * @param {AddressType} [addressType] Get promotions for this address type
         * @param {EnergyLabel} [energyLabel] 
         * @param {DisplayOn} [displayOn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotions(limit: number, price?: number, municipalityCode?: number, addressType?: AddressType, energyLabel?: EnergyLabel, displayOn?: DisplayOn, options?: any): AxiosPromise<GetPromotionsResult> {
            return localVarFp.getPromotions(limit, price, municipalityCode, addressType, energyLabel, displayOn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register a promotion interaction
         * @param {PostPromotionInteraction} postPromotionInteraction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPromotionInteraction(postPromotionInteraction: PostPromotionInteraction, options?: any): AxiosPromise<void> {
            return localVarFp.postPromotionInteraction(postPromotionInteraction, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getPromotions operation in PromotionsApi.
 * @export
 * @interface PromotionsApiGetPromotionsRequest
 */
export interface PromotionsApiGetPromotionsRequest {
    /**
     * The maximum amount of promotions to be returned
     * @type {number}
     * @memberof PromotionsApiGetPromotions
     */
    readonly limit: number

    /**
     * Get promotions in this price class
     * @type {number}
     * @memberof PromotionsApiGetPromotions
     */
    readonly price?: number

    /**
     * Get promotions in this municipality
     * @type {number}
     * @memberof PromotionsApiGetPromotions
     */
    readonly municipalityCode?: number

    /**
     * Get promotions for this address type
     * @type {AddressType}
     * @memberof PromotionsApiGetPromotions
     */
    readonly addressType?: AddressType

    /**
     * 
     * @type {EnergyLabel}
     * @memberof PromotionsApiGetPromotions
     */
    readonly energyLabel?: EnergyLabel

    /**
     * 
     * @type {DisplayOn}
     * @memberof PromotionsApiGetPromotions
     */
    readonly displayOn?: DisplayOn
}

/**
 * Request parameters for postPromotionInteraction operation in PromotionsApi.
 * @export
 * @interface PromotionsApiPostPromotionInteractionRequest
 */
export interface PromotionsApiPostPromotionInteractionRequest {
    /**
     * 
     * @type {PostPromotionInteraction}
     * @memberof PromotionsApiPostPromotionInteraction
     */
    readonly postPromotionInteraction: PostPromotionInteraction
}

/**
 * PromotionsApi - object-oriented interface
 * @export
 * @class PromotionsApi
 * @extends {BaseAPI}
 */
export class PromotionsApi extends BaseAPI {
    /**
     * 
     * @summary Get promotions
     * @param {PromotionsApiGetPromotionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public getPromotions(requestParameters: PromotionsApiGetPromotionsRequest, options?: any) {
        return PromotionsApiFp(this.configuration).getPromotions(requestParameters.limit, requestParameters.price, requestParameters.municipalityCode, requestParameters.addressType, requestParameters.energyLabel, requestParameters.displayOn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register a promotion interaction
     * @param {PromotionsApiPostPromotionInteractionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public postPromotionInteraction(requestParameters: PromotionsApiPostPromotionInteractionRequest, options?: any) {
        return PromotionsApiFp(this.configuration).postPromotionInteraction(requestParameters.postPromotionInteraction, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProvincesApi - axios parameter creator
 * @export
 */
export const ProvincesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Provinces are sorted alphabetically using Danish alphabet 
         * @summary Get provinces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        provinces: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/provinces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProvincesApi - functional programming interface
 * @export
 */
export const ProvincesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProvincesApiAxiosParamCreator(configuration)
    return {
        /**
         * Provinces are sorted alphabetically using Danish alphabet 
         * @summary Get provinces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async provinces(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProvinceCollectionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.provinces(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProvincesApi - factory interface
 * @export
 */
export const ProvincesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProvincesApiFp(configuration)
    return {
        /**
         * Provinces are sorted alphabetically using Danish alphabet 
         * @summary Get provinces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        provinces(options?: any): AxiosPromise<ProvinceCollectionResult> {
            return localVarFp.provinces(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProvincesApi - object-oriented interface
 * @export
 * @class ProvincesApi
 * @extends {BaseAPI}
 */
export class ProvincesApi extends BaseAPI {
    /**
     * Provinces are sorted alphabetically using Danish alphabet 
     * @summary Get provinces
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvincesApi
     */
    public provinces(options?: any) {
        return ProvincesApiFp(this.configuration).provinces(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RealtorsApi - axios parameter creator
 * @export
 */
export const RealtorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get realtors by branch name
         * @param {string} realtorBranchName Realtor branch name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealtorsByBranch: async (realtorBranchName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'realtorBranchName' is not null or undefined
            assertParamExists('getRealtorsByBranch', 'realtorBranchName', realtorBranchName)
            const localVarPath = `/realtors/branch/{realtorBranchName}`
                .replace(`{${"realtorBranchName"}}`, encodeURIComponent(String(realtorBranchName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get number of realtor\'s cases by realtor ID
         * @param {string} realtorID Realtor uuid (UUIDv4 format).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        realtorsCaseCount: async (realtorID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'realtorID' is not null or undefined
            assertParamExists('realtorsCaseCount', 'realtorID', realtorID)
            const localVarPath = `/realtors/{realtorID}/count`
                .replace(`{${"realtorID"}}`, encodeURIComponent(String(realtorID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the amount of realtors on Boligsiden
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        realtorsCount: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/realtors/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a realtor by ID
         * @param {string} realtorID Realtor uuid (UUIDv4 format).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        realtorsRealtorID: async (realtorID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'realtorID' is not null or undefined
            assertParamExists('realtorsRealtorID', 'realtorID', realtorID)
            const localVarPath = `/realtors/{realtorID}`
                .replace(`{${"realtorID"}}`, encodeURIComponent(String(realtorID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns realtors with cases in the given location and with the given address type
         * @summary Search realtors
         * @param {string} locationType 
         * @param {string} locationName 
         * @param {AddressType} [addressType] 
         * @param {'rating' | 'amountSold' | 'saleTime' | 'priceChangePercentage' | 'perAreaPrice'} [sortBy] Realtors are split into two groups: those that are preferred, and those that are not (see &#x60;Realtor.preferred&#x60;). The sort specified here is used to sort the groups independently of each other.  For example, given the below realtors: | id | rating | preferred | | ----------- | ----------- | ----------- | | &#x60;126b9e88-f3a8-426e-ad12-2d4df6892511&#x60; | &#x60;5&#x60; | &#x60;true&#x60; | | &#x60;7d1cc3be-05c7-4a91-87dd-bdedfbb978ba&#x60; | &#x60;7&#x60; | &#x60;true&#x60; | | &#x60;ccd6d3b5-b894-4c10-b741-8e8244863287&#x60; | &#x60;3&#x60; | &#x60;false&#x60; | | &#x60;6619d170-335a-47af-aeda-da8d40cb024d&#x60; | &#x60;1&#x60; | &#x60;false&#x60; |  Searching for realtors with a sort of rating descending would return (in this order): * &#x60;7d1cc3be-05c7-4a91-87dd-bdedfbb978ba&#x60; * &#x60;126b9e88-f3a8-426e-ad12-2d4df6892511&#x60; * &#x60;ccd6d3b5-b894-4c10-b741-8e8244863287&#x60; * &#x60;6619d170-335a-47af-aeda-da8d40cb024d&#x60; 
         * @param {boolean} [sortAscending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRealtors: async (locationType: string, locationName: string, addressType?: AddressType, sortBy?: 'rating' | 'amountSold' | 'saleTime' | 'priceChangePercentage' | 'perAreaPrice', sortAscending?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationType' is not null or undefined
            assertParamExists('searchRealtors', 'locationType', locationType)
            // verify required parameter 'locationName' is not null or undefined
            assertParamExists('searchRealtors', 'locationName', locationName)
            const localVarPath = `/search/realtors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (locationType !== undefined) {
                localVarQueryParameter['locationType'] = locationType;
            }

            if (locationName !== undefined) {
                localVarQueryParameter['locationName'] = locationName;
            }

            if (addressType !== undefined) {
                localVarQueryParameter['addressType'] = addressType;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortAscending !== undefined) {
                localVarQueryParameter['sortAscending'] = sortAscending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search within realtors by locations to support the autocomplete for locations.
         * @summary Search for realtors by locations
         * @param {string} text Free form search string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRealtorsByLocations: async (text: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'text' is not null or undefined
            assertParamExists('searchRealtorsByLocations', 'text', text)
            const localVarPath = `/search/locations/realtors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (text !== undefined) {
                localVarQueryParameter['text'] = text;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RealtorsApi - functional programming interface
 * @export
 */
export const RealtorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RealtorsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get realtors by branch name
         * @param {string} realtorBranchName Realtor branch name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRealtorsByBranch(realtorBranchName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Realtor>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRealtorsByBranch(realtorBranchName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get number of realtor\'s cases by realtor ID
         * @param {string} realtorID Realtor uuid (UUIDv4 format).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async realtorsCaseCount(realtorID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Realtor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.realtorsCaseCount(realtorID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the amount of realtors on Boligsiden
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async realtorsCount(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealtorsCountResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.realtorsCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a realtor by ID
         * @param {string} realtorID Realtor uuid (UUIDv4 format).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async realtorsRealtorID(realtorID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Realtor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.realtorsRealtorID(realtorID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns realtors with cases in the given location and with the given address type
         * @summary Search realtors
         * @param {string} locationType 
         * @param {string} locationName 
         * @param {AddressType} [addressType] 
         * @param {'rating' | 'amountSold' | 'saleTime' | 'priceChangePercentage' | 'perAreaPrice'} [sortBy] Realtors are split into two groups: those that are preferred, and those that are not (see &#x60;Realtor.preferred&#x60;). The sort specified here is used to sort the groups independently of each other.  For example, given the below realtors: | id | rating | preferred | | ----------- | ----------- | ----------- | | &#x60;126b9e88-f3a8-426e-ad12-2d4df6892511&#x60; | &#x60;5&#x60; | &#x60;true&#x60; | | &#x60;7d1cc3be-05c7-4a91-87dd-bdedfbb978ba&#x60; | &#x60;7&#x60; | &#x60;true&#x60; | | &#x60;ccd6d3b5-b894-4c10-b741-8e8244863287&#x60; | &#x60;3&#x60; | &#x60;false&#x60; | | &#x60;6619d170-335a-47af-aeda-da8d40cb024d&#x60; | &#x60;1&#x60; | &#x60;false&#x60; |  Searching for realtors with a sort of rating descending would return (in this order): * &#x60;7d1cc3be-05c7-4a91-87dd-bdedfbb978ba&#x60; * &#x60;126b9e88-f3a8-426e-ad12-2d4df6892511&#x60; * &#x60;ccd6d3b5-b894-4c10-b741-8e8244863287&#x60; * &#x60;6619d170-335a-47af-aeda-da8d40cb024d&#x60; 
         * @param {boolean} [sortAscending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchRealtors(locationType: string, locationName: string, addressType?: AddressType, sortBy?: 'rating' | 'amountSold' | 'saleTime' | 'priceChangePercentage' | 'perAreaPrice', sortAscending?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HALCollection & SearchRealtorsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchRealtors(locationType, locationName, addressType, sortBy, sortAscending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Search within realtors by locations to support the autocomplete for locations.
         * @summary Search for realtors by locations
         * @param {string} text Free form search string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchRealtorsByLocations(text: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchRealtorLocationsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchRealtorsByLocations(text, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RealtorsApi - factory interface
 * @export
 */
export const RealtorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RealtorsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get realtors by branch name
         * @param {string} realtorBranchName Realtor branch name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealtorsByBranch(realtorBranchName: string, options?: any): AxiosPromise<Array<Realtor>> {
            return localVarFp.getRealtorsByBranch(realtorBranchName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get number of realtor\'s cases by realtor ID
         * @param {string} realtorID Realtor uuid (UUIDv4 format).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        realtorsCaseCount(realtorID: string, options?: any): AxiosPromise<Realtor> {
            return localVarFp.realtorsCaseCount(realtorID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the amount of realtors on Boligsiden
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        realtorsCount(options?: any): AxiosPromise<RealtorsCountResult> {
            return localVarFp.realtorsCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a realtor by ID
         * @param {string} realtorID Realtor uuid (UUIDv4 format).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        realtorsRealtorID(realtorID: string, options?: any): AxiosPromise<Realtor> {
            return localVarFp.realtorsRealtorID(realtorID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns realtors with cases in the given location and with the given address type
         * @summary Search realtors
         * @param {string} locationType 
         * @param {string} locationName 
         * @param {AddressType} [addressType] 
         * @param {'rating' | 'amountSold' | 'saleTime' | 'priceChangePercentage' | 'perAreaPrice'} [sortBy] Realtors are split into two groups: those that are preferred, and those that are not (see &#x60;Realtor.preferred&#x60;). The sort specified here is used to sort the groups independently of each other.  For example, given the below realtors: | id | rating | preferred | | ----------- | ----------- | ----------- | | &#x60;126b9e88-f3a8-426e-ad12-2d4df6892511&#x60; | &#x60;5&#x60; | &#x60;true&#x60; | | &#x60;7d1cc3be-05c7-4a91-87dd-bdedfbb978ba&#x60; | &#x60;7&#x60; | &#x60;true&#x60; | | &#x60;ccd6d3b5-b894-4c10-b741-8e8244863287&#x60; | &#x60;3&#x60; | &#x60;false&#x60; | | &#x60;6619d170-335a-47af-aeda-da8d40cb024d&#x60; | &#x60;1&#x60; | &#x60;false&#x60; |  Searching for realtors with a sort of rating descending would return (in this order): * &#x60;7d1cc3be-05c7-4a91-87dd-bdedfbb978ba&#x60; * &#x60;126b9e88-f3a8-426e-ad12-2d4df6892511&#x60; * &#x60;ccd6d3b5-b894-4c10-b741-8e8244863287&#x60; * &#x60;6619d170-335a-47af-aeda-da8d40cb024d&#x60; 
         * @param {boolean} [sortAscending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRealtors(locationType: string, locationName: string, addressType?: AddressType, sortBy?: 'rating' | 'amountSold' | 'saleTime' | 'priceChangePercentage' | 'perAreaPrice', sortAscending?: boolean, options?: any): AxiosPromise<HALCollection & SearchRealtorsResult> {
            return localVarFp.searchRealtors(locationType, locationName, addressType, sortBy, sortAscending, options).then((request) => request(axios, basePath));
        },
        /**
         * Search within realtors by locations to support the autocomplete for locations.
         * @summary Search for realtors by locations
         * @param {string} text Free form search string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRealtorsByLocations(text: string, options?: any): AxiosPromise<SearchRealtorLocationsResult> {
            return localVarFp.searchRealtorsByLocations(text, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getRealtorsByBranch operation in RealtorsApi.
 * @export
 * @interface RealtorsApiGetRealtorsByBranchRequest
 */
export interface RealtorsApiGetRealtorsByBranchRequest {
    /**
     * Realtor branch name
     * @type {string}
     * @memberof RealtorsApiGetRealtorsByBranch
     */
    readonly realtorBranchName: string
}

/**
 * Request parameters for realtorsCaseCount operation in RealtorsApi.
 * @export
 * @interface RealtorsApiRealtorsCaseCountRequest
 */
export interface RealtorsApiRealtorsCaseCountRequest {
    /**
     * Realtor uuid (UUIDv4 format).
     * @type {string}
     * @memberof RealtorsApiRealtorsCaseCount
     */
    readonly realtorID: string
}

/**
 * Request parameters for realtorsRealtorID operation in RealtorsApi.
 * @export
 * @interface RealtorsApiRealtorsRealtorIDRequest
 */
export interface RealtorsApiRealtorsRealtorIDRequest {
    /**
     * Realtor uuid (UUIDv4 format).
     * @type {string}
     * @memberof RealtorsApiRealtorsRealtorID
     */
    readonly realtorID: string
}

/**
 * Request parameters for searchRealtors operation in RealtorsApi.
 * @export
 * @interface RealtorsApiSearchRealtorsRequest
 */
export interface RealtorsApiSearchRealtorsRequest {
    /**
     * 
     * @type {string}
     * @memberof RealtorsApiSearchRealtors
     */
    readonly locationType: string

    /**
     * 
     * @type {string}
     * @memberof RealtorsApiSearchRealtors
     */
    readonly locationName: string

    /**
     * 
     * @type {AddressType}
     * @memberof RealtorsApiSearchRealtors
     */
    readonly addressType?: AddressType

    /**
     * Realtors are split into two groups: those that are preferred, and those that are not (see &#x60;Realtor.preferred&#x60;). The sort specified here is used to sort the groups independently of each other.  For example, given the below realtors: | id | rating | preferred | | ----------- | ----------- | ----------- | | &#x60;126b9e88-f3a8-426e-ad12-2d4df6892511&#x60; | &#x60;5&#x60; | &#x60;true&#x60; | | &#x60;7d1cc3be-05c7-4a91-87dd-bdedfbb978ba&#x60; | &#x60;7&#x60; | &#x60;true&#x60; | | &#x60;ccd6d3b5-b894-4c10-b741-8e8244863287&#x60; | &#x60;3&#x60; | &#x60;false&#x60; | | &#x60;6619d170-335a-47af-aeda-da8d40cb024d&#x60; | &#x60;1&#x60; | &#x60;false&#x60; |  Searching for realtors with a sort of rating descending would return (in this order): * &#x60;7d1cc3be-05c7-4a91-87dd-bdedfbb978ba&#x60; * &#x60;126b9e88-f3a8-426e-ad12-2d4df6892511&#x60; * &#x60;ccd6d3b5-b894-4c10-b741-8e8244863287&#x60; * &#x60;6619d170-335a-47af-aeda-da8d40cb024d&#x60; 
     * @type {'rating' | 'amountSold' | 'saleTime' | 'priceChangePercentage' | 'perAreaPrice'}
     * @memberof RealtorsApiSearchRealtors
     */
    readonly sortBy?: 'rating' | 'amountSold' | 'saleTime' | 'priceChangePercentage' | 'perAreaPrice'

    /**
     * 
     * @type {boolean}
     * @memberof RealtorsApiSearchRealtors
     */
    readonly sortAscending?: boolean
}

/**
 * Request parameters for searchRealtorsByLocations operation in RealtorsApi.
 * @export
 * @interface RealtorsApiSearchRealtorsByLocationsRequest
 */
export interface RealtorsApiSearchRealtorsByLocationsRequest {
    /**
     * Free form search string
     * @type {string}
     * @memberof RealtorsApiSearchRealtorsByLocations
     */
    readonly text: string
}

/**
 * RealtorsApi - object-oriented interface
 * @export
 * @class RealtorsApi
 * @extends {BaseAPI}
 */
export class RealtorsApi extends BaseAPI {
    /**
     * 
     * @summary Get realtors by branch name
     * @param {RealtorsApiGetRealtorsByBranchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealtorsApi
     */
    public getRealtorsByBranch(requestParameters: RealtorsApiGetRealtorsByBranchRequest, options?: any) {
        return RealtorsApiFp(this.configuration).getRealtorsByBranch(requestParameters.realtorBranchName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get number of realtor\'s cases by realtor ID
     * @param {RealtorsApiRealtorsCaseCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealtorsApi
     */
    public realtorsCaseCount(requestParameters: RealtorsApiRealtorsCaseCountRequest, options?: any) {
        return RealtorsApiFp(this.configuration).realtorsCaseCount(requestParameters.realtorID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the amount of realtors on Boligsiden
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealtorsApi
     */
    public realtorsCount(options?: any) {
        return RealtorsApiFp(this.configuration).realtorsCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a realtor by ID
     * @param {RealtorsApiRealtorsRealtorIDRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealtorsApi
     */
    public realtorsRealtorID(requestParameters: RealtorsApiRealtorsRealtorIDRequest, options?: any) {
        return RealtorsApiFp(this.configuration).realtorsRealtorID(requestParameters.realtorID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns realtors with cases in the given location and with the given address type
     * @summary Search realtors
     * @param {RealtorsApiSearchRealtorsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealtorsApi
     */
    public searchRealtors(requestParameters: RealtorsApiSearchRealtorsRequest, options?: any) {
        return RealtorsApiFp(this.configuration).searchRealtors(requestParameters.locationType, requestParameters.locationName, requestParameters.addressType, requestParameters.sortBy, requestParameters.sortAscending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search within realtors by locations to support the autocomplete for locations.
     * @summary Search for realtors by locations
     * @param {RealtorsApiSearchRealtorsByLocationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealtorsApi
     */
    public searchRealtorsByLocations(requestParameters: RealtorsApiSearchRealtorsByLocationsRequest, options?: any) {
        return RealtorsApiFp(this.configuration).searchRealtorsByLocations(requestParameters.text, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RentalsApi - axios parameter creator
 * @export
 */
export const RentalsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search rentals
         * @param {boolean} [sortAscending] 
         * @param {'address' | 'rent' | 'deposit' | 'livingArea' | 'numRooms' | 'durationMonths'} [sortBy] 
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [cities] 
         * @param {Set<string>} [zipCodes] This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
         * @param {Set<string>} [roads] Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
         * @param {Set<string>} [placeNames] 
         * @param {Set<string>} [customAreas] 
         * @param {number} [rentMin] 
         * @param {number} [rentMax] 
         * @param {number} [areaMin] 
         * @param {number} [areaMax] 
         * @param {number} [numberOfRoomsMin] 
         * @param {number} [numberOfRoomsMax] 
         * @param {number} [depositMin] 
         * @param {number} [depositMax] 
         * @param {number} [durationMonthsMin] 
         * @param {number} [durationMonthsMax] 
         * @param {boolean} [petsAllowed] 
         * @param {boolean} [rightOfFirstRefusal] 
         * @param {number} [levelsMin] An address has at least this many levels - like a house in two levels.
         * @param {number} [levelsMax] An address has maximum this many levels - like a house in max one level.
         * @param {number} [floorMin] the minimum floor where an address resides like minimum on the third floor.
         * @param {number} [floorMax] the maximum floor where an address (apartment) resides like at most the third floor.
         * @param {string} [realtorID] 
         * @param {number} [perPage] Limits the number of results per page. 
         * @param {number} [page] Page number 
         * @param {number} [lastPriceDropAtMax] Maximum number of days since the last price drop, if any
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRentals: async (sortAscending?: boolean, sortBy?: 'address' | 'rent' | 'deposit' | 'livingArea' | 'numRooms' | 'durationMonths', addressTypes?: Set<AddressType>, provinces?: Set<string>, municipalities?: Set<string>, cities?: Set<string>, zipCodes?: Set<string>, roads?: Set<string>, placeNames?: Set<string>, customAreas?: Set<string>, rentMin?: number, rentMax?: number, areaMin?: number, areaMax?: number, numberOfRoomsMin?: number, numberOfRoomsMax?: number, depositMin?: number, depositMax?: number, durationMonthsMin?: number, durationMonthsMax?: number, petsAllowed?: boolean, rightOfFirstRefusal?: boolean, levelsMin?: number, levelsMax?: number, floorMin?: number, floorMax?: number, realtorID?: string, perPage?: number, page?: number, lastPriceDropAtMax?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/search/rentals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sortAscending !== undefined) {
                localVarQueryParameter['sortAscending'] = sortAscending;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (addressTypes) {
                localVarQueryParameter['addressTypes'] = Array.from(addressTypes).join(COLLECTION_FORMATS.csv);
            }

            if (provinces) {
                localVarQueryParameter['provinces'] = Array.from(provinces);
            }

            if (municipalities) {
                localVarQueryParameter['municipalities'] = Array.from(municipalities);
            }

            if (cities) {
                localVarQueryParameter['cities'] = Array.from(cities);
            }

            if (zipCodes) {
                localVarQueryParameter['zipCodes'] = Array.from(zipCodes);
            }

            if (roads) {
                localVarQueryParameter['roads'] = Array.from(roads);
            }

            if (placeNames) {
                localVarQueryParameter['placeNames'] = Array.from(placeNames);
            }

            if (customAreas) {
                localVarQueryParameter['customAreas'] = Array.from(customAreas);
            }

            if (rentMin !== undefined) {
                localVarQueryParameter['rentMin'] = rentMin;
            }

            if (rentMax !== undefined) {
                localVarQueryParameter['rentMax'] = rentMax;
            }

            if (areaMin !== undefined) {
                localVarQueryParameter['areaMin'] = areaMin;
            }

            if (areaMax !== undefined) {
                localVarQueryParameter['areaMax'] = areaMax;
            }

            if (numberOfRoomsMin !== undefined) {
                localVarQueryParameter['numberOfRoomsMin'] = numberOfRoomsMin;
            }

            if (numberOfRoomsMax !== undefined) {
                localVarQueryParameter['numberOfRoomsMax'] = numberOfRoomsMax;
            }

            if (depositMin !== undefined) {
                localVarQueryParameter['depositMin'] = depositMin;
            }

            if (depositMax !== undefined) {
                localVarQueryParameter['depositMax'] = depositMax;
            }

            if (durationMonthsMin !== undefined) {
                localVarQueryParameter['durationMonthsMin'] = durationMonthsMin;
            }

            if (durationMonthsMax !== undefined) {
                localVarQueryParameter['durationMonthsMax'] = durationMonthsMax;
            }

            if (petsAllowed !== undefined) {
                localVarQueryParameter['petsAllowed'] = petsAllowed;
            }

            if (rightOfFirstRefusal !== undefined) {
                localVarQueryParameter['rightOfFirstRefusal'] = rightOfFirstRefusal;
            }

            if (levelsMin !== undefined) {
                localVarQueryParameter['levelsMin'] = levelsMin;
            }

            if (levelsMax !== undefined) {
                localVarQueryParameter['levelsMax'] = levelsMax;
            }

            if (floorMin !== undefined) {
                localVarQueryParameter['floorMin'] = floorMin;
            }

            if (floorMax !== undefined) {
                localVarQueryParameter['floorMax'] = floorMax;
            }

            if (realtorID !== undefined) {
                localVarQueryParameter['realtorID'] = realtorID;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (lastPriceDropAtMax !== undefined) {
                localVarQueryParameter['lastPriceDropAtMax'] = lastPriceDropAtMax;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search for rentals by locations. This endpoint is functionally equivalent to `/search/locations/cases`, with the crucial difference that this endpoint returns rentals only. 
         * @summary Search rental locations
         * @param {string} text Free form search string
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRentalsByLocations: async (text: string, addressTypes?: Set<AddressType>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'text' is not null or undefined
            assertParamExists('searchRentalsByLocations', 'text', text)
            const localVarPath = `/search/locations/rentals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (addressTypes) {
                localVarQueryParameter['addressTypes'] = Array.from(addressTypes).join(COLLECTION_FORMATS.csv);
            }

            if (text !== undefined) {
                localVarQueryParameter['text'] = text;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RentalsApi - functional programming interface
 * @export
 */
export const RentalsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RentalsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Search rentals
         * @param {boolean} [sortAscending] 
         * @param {'address' | 'rent' | 'deposit' | 'livingArea' | 'numRooms' | 'durationMonths'} [sortBy] 
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [cities] 
         * @param {Set<string>} [zipCodes] This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
         * @param {Set<string>} [roads] Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
         * @param {Set<string>} [placeNames] 
         * @param {Set<string>} [customAreas] 
         * @param {number} [rentMin] 
         * @param {number} [rentMax] 
         * @param {number} [areaMin] 
         * @param {number} [areaMax] 
         * @param {number} [numberOfRoomsMin] 
         * @param {number} [numberOfRoomsMax] 
         * @param {number} [depositMin] 
         * @param {number} [depositMax] 
         * @param {number} [durationMonthsMin] 
         * @param {number} [durationMonthsMax] 
         * @param {boolean} [petsAllowed] 
         * @param {boolean} [rightOfFirstRefusal] 
         * @param {number} [levelsMin] An address has at least this many levels - like a house in two levels.
         * @param {number} [levelsMax] An address has maximum this many levels - like a house in max one level.
         * @param {number} [floorMin] the minimum floor where an address resides like minimum on the third floor.
         * @param {number} [floorMax] the maximum floor where an address (apartment) resides like at most the third floor.
         * @param {string} [realtorID] 
         * @param {number} [perPage] Limits the number of results per page. 
         * @param {number} [page] Page number 
         * @param {number} [lastPriceDropAtMax] Maximum number of days since the last price drop, if any
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchRentals(sortAscending?: boolean, sortBy?: 'address' | 'rent' | 'deposit' | 'livingArea' | 'numRooms' | 'durationMonths', addressTypes?: Set<AddressType>, provinces?: Set<string>, municipalities?: Set<string>, cities?: Set<string>, zipCodes?: Set<string>, roads?: Set<string>, placeNames?: Set<string>, customAreas?: Set<string>, rentMin?: number, rentMax?: number, areaMin?: number, areaMax?: number, numberOfRoomsMin?: number, numberOfRoomsMax?: number, depositMin?: number, depositMax?: number, durationMonthsMin?: number, durationMonthsMax?: number, petsAllowed?: boolean, rightOfFirstRefusal?: boolean, levelsMin?: number, levelsMax?: number, floorMin?: number, floorMax?: number, realtorID?: string, perPage?: number, page?: number, lastPriceDropAtMax?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HALCollection & SearchCasesResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchRentals(sortAscending, sortBy, addressTypes, provinces, municipalities, cities, zipCodes, roads, placeNames, customAreas, rentMin, rentMax, areaMin, areaMax, numberOfRoomsMin, numberOfRoomsMax, depositMin, depositMax, durationMonthsMin, durationMonthsMax, petsAllowed, rightOfFirstRefusal, levelsMin, levelsMax, floorMin, floorMax, realtorID, perPage, page, lastPriceDropAtMax, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Search for rentals by locations. This endpoint is functionally equivalent to `/search/locations/cases`, with the crucial difference that this endpoint returns rentals only. 
         * @summary Search rental locations
         * @param {string} text Free form search string
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchRentalsByLocations(text: string, addressTypes?: Set<AddressType>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchCaseLocationsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchRentalsByLocations(text, addressTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RentalsApi - factory interface
 * @export
 */
export const RentalsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RentalsApiFp(configuration)
    return {
        /**
         * 
         * @summary Search rentals
         * @param {boolean} [sortAscending] 
         * @param {'address' | 'rent' | 'deposit' | 'livingArea' | 'numRooms' | 'durationMonths'} [sortBy] 
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [cities] 
         * @param {Set<string>} [zipCodes] This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
         * @param {Set<string>} [roads] Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
         * @param {Set<string>} [placeNames] 
         * @param {Set<string>} [customAreas] 
         * @param {number} [rentMin] 
         * @param {number} [rentMax] 
         * @param {number} [areaMin] 
         * @param {number} [areaMax] 
         * @param {number} [numberOfRoomsMin] 
         * @param {number} [numberOfRoomsMax] 
         * @param {number} [depositMin] 
         * @param {number} [depositMax] 
         * @param {number} [durationMonthsMin] 
         * @param {number} [durationMonthsMax] 
         * @param {boolean} [petsAllowed] 
         * @param {boolean} [rightOfFirstRefusal] 
         * @param {number} [levelsMin] An address has at least this many levels - like a house in two levels.
         * @param {number} [levelsMax] An address has maximum this many levels - like a house in max one level.
         * @param {number} [floorMin] the minimum floor where an address resides like minimum on the third floor.
         * @param {number} [floorMax] the maximum floor where an address (apartment) resides like at most the third floor.
         * @param {string} [realtorID] 
         * @param {number} [perPage] Limits the number of results per page. 
         * @param {number} [page] Page number 
         * @param {number} [lastPriceDropAtMax] Maximum number of days since the last price drop, if any
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRentals(sortAscending?: boolean, sortBy?: 'address' | 'rent' | 'deposit' | 'livingArea' | 'numRooms' | 'durationMonths', addressTypes?: Set<AddressType>, provinces?: Set<string>, municipalities?: Set<string>, cities?: Set<string>, zipCodes?: Set<string>, roads?: Set<string>, placeNames?: Set<string>, customAreas?: Set<string>, rentMin?: number, rentMax?: number, areaMin?: number, areaMax?: number, numberOfRoomsMin?: number, numberOfRoomsMax?: number, depositMin?: number, depositMax?: number, durationMonthsMin?: number, durationMonthsMax?: number, petsAllowed?: boolean, rightOfFirstRefusal?: boolean, levelsMin?: number, levelsMax?: number, floorMin?: number, floorMax?: number, realtorID?: string, perPage?: number, page?: number, lastPriceDropAtMax?: number, options?: any): AxiosPromise<HALCollection & SearchCasesResult> {
            return localVarFp.searchRentals(sortAscending, sortBy, addressTypes, provinces, municipalities, cities, zipCodes, roads, placeNames, customAreas, rentMin, rentMax, areaMin, areaMax, numberOfRoomsMin, numberOfRoomsMax, depositMin, depositMax, durationMonthsMin, durationMonthsMax, petsAllowed, rightOfFirstRefusal, levelsMin, levelsMax, floorMin, floorMax, realtorID, perPage, page, lastPriceDropAtMax, options).then((request) => request(axios, basePath));
        },
        /**
         * Search for rentals by locations. This endpoint is functionally equivalent to `/search/locations/cases`, with the crucial difference that this endpoint returns rentals only. 
         * @summary Search rental locations
         * @param {string} text Free form search string
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRentalsByLocations(text: string, addressTypes?: Set<AddressType>, options?: any): AxiosPromise<SearchCaseLocationsResult> {
            return localVarFp.searchRentalsByLocations(text, addressTypes, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for searchRentals operation in RentalsApi.
 * @export
 * @interface RentalsApiSearchRentalsRequest
 */
export interface RentalsApiSearchRentalsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof RentalsApiSearchRentals
     */
    readonly sortAscending?: boolean

    /**
     * 
     * @type {'address' | 'rent' | 'deposit' | 'livingArea' | 'numRooms' | 'durationMonths'}
     * @memberof RentalsApiSearchRentals
     */
    readonly sortBy?: 'address' | 'rent' | 'deposit' | 'livingArea' | 'numRooms' | 'durationMonths'

    /**
     * The address type to filter by
     * @type {Set<AddressType>}
     * @memberof RentalsApiSearchRentals
     */
    readonly addressTypes?: Set<AddressType>

    /**
     * 
     * @type {Set<string>}
     * @memberof RentalsApiSearchRentals
     */
    readonly provinces?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof RentalsApiSearchRentals
     */
    readonly municipalities?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof RentalsApiSearchRentals
     */
    readonly cities?: Set<string>

    /**
     * This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
     * @type {Set<string>}
     * @memberof RentalsApiSearchRentals
     */
    readonly zipCodes?: Set<string>

    /**
     * Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
     * @type {Set<string>}
     * @memberof RentalsApiSearchRentals
     */
    readonly roads?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof RentalsApiSearchRentals
     */
    readonly placeNames?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof RentalsApiSearchRentals
     */
    readonly customAreas?: Set<string>

    /**
     * 
     * @type {number}
     * @memberof RentalsApiSearchRentals
     */
    readonly rentMin?: number

    /**
     * 
     * @type {number}
     * @memberof RentalsApiSearchRentals
     */
    readonly rentMax?: number

    /**
     * 
     * @type {number}
     * @memberof RentalsApiSearchRentals
     */
    readonly areaMin?: number

    /**
     * 
     * @type {number}
     * @memberof RentalsApiSearchRentals
     */
    readonly areaMax?: number

    /**
     * 
     * @type {number}
     * @memberof RentalsApiSearchRentals
     */
    readonly numberOfRoomsMin?: number

    /**
     * 
     * @type {number}
     * @memberof RentalsApiSearchRentals
     */
    readonly numberOfRoomsMax?: number

    /**
     * 
     * @type {number}
     * @memberof RentalsApiSearchRentals
     */
    readonly depositMin?: number

    /**
     * 
     * @type {number}
     * @memberof RentalsApiSearchRentals
     */
    readonly depositMax?: number

    /**
     * 
     * @type {number}
     * @memberof RentalsApiSearchRentals
     */
    readonly durationMonthsMin?: number

    /**
     * 
     * @type {number}
     * @memberof RentalsApiSearchRentals
     */
    readonly durationMonthsMax?: number

    /**
     * 
     * @type {boolean}
     * @memberof RentalsApiSearchRentals
     */
    readonly petsAllowed?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof RentalsApiSearchRentals
     */
    readonly rightOfFirstRefusal?: boolean

    /**
     * An address has at least this many levels - like a house in two levels.
     * @type {number}
     * @memberof RentalsApiSearchRentals
     */
    readonly levelsMin?: number

    /**
     * An address has maximum this many levels - like a house in max one level.
     * @type {number}
     * @memberof RentalsApiSearchRentals
     */
    readonly levelsMax?: number

    /**
     * the minimum floor where an address resides like minimum on the third floor.
     * @type {number}
     * @memberof RentalsApiSearchRentals
     */
    readonly floorMin?: number

    /**
     * the maximum floor where an address (apartment) resides like at most the third floor.
     * @type {number}
     * @memberof RentalsApiSearchRentals
     */
    readonly floorMax?: number

    /**
     * 
     * @type {string}
     * @memberof RentalsApiSearchRentals
     */
    readonly realtorID?: string

    /**
     * Limits the number of results per page. 
     * @type {number}
     * @memberof RentalsApiSearchRentals
     */
    readonly perPage?: number

    /**
     * Page number 
     * @type {number}
     * @memberof RentalsApiSearchRentals
     */
    readonly page?: number

    /**
     * Maximum number of days since the last price drop, if any
     * @type {number}
     * @memberof RentalsApiSearchRentals
     */
    readonly lastPriceDropAtMax?: number
}

/**
 * Request parameters for searchRentalsByLocations operation in RentalsApi.
 * @export
 * @interface RentalsApiSearchRentalsByLocationsRequest
 */
export interface RentalsApiSearchRentalsByLocationsRequest {
    /**
     * Free form search string
     * @type {string}
     * @memberof RentalsApiSearchRentalsByLocations
     */
    readonly text: string

    /**
     * The address type to filter by
     * @type {Set<AddressType>}
     * @memberof RentalsApiSearchRentalsByLocations
     */
    readonly addressTypes?: Set<AddressType>
}

/**
 * RentalsApi - object-oriented interface
 * @export
 * @class RentalsApi
 * @extends {BaseAPI}
 */
export class RentalsApi extends BaseAPI {
    /**
     * 
     * @summary Search rentals
     * @param {RentalsApiSearchRentalsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public searchRentals(requestParameters: RentalsApiSearchRentalsRequest = {}, options?: any) {
        return RentalsApiFp(this.configuration).searchRentals(requestParameters.sortAscending, requestParameters.sortBy, requestParameters.addressTypes, requestParameters.provinces, requestParameters.municipalities, requestParameters.cities, requestParameters.zipCodes, requestParameters.roads, requestParameters.placeNames, requestParameters.customAreas, requestParameters.rentMin, requestParameters.rentMax, requestParameters.areaMin, requestParameters.areaMax, requestParameters.numberOfRoomsMin, requestParameters.numberOfRoomsMax, requestParameters.depositMin, requestParameters.depositMax, requestParameters.durationMonthsMin, requestParameters.durationMonthsMax, requestParameters.petsAllowed, requestParameters.rightOfFirstRefusal, requestParameters.levelsMin, requestParameters.levelsMax, requestParameters.floorMin, requestParameters.floorMax, requestParameters.realtorID, requestParameters.perPage, requestParameters.page, requestParameters.lastPriceDropAtMax, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search for rentals by locations. This endpoint is functionally equivalent to `/search/locations/cases`, with the crucial difference that this endpoint returns rentals only. 
     * @summary Search rental locations
     * @param {RentalsApiSearchRentalsByLocationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public searchRentalsByLocations(requestParameters: RentalsApiSearchRentalsByLocationsRequest, options?: any) {
        return RentalsApiFp(this.configuration).searchRentalsByLocations(requestParameters.text, requestParameters.addressTypes, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoadsApi - axios parameter creator
 * @export
 */
export const RoadsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Roads are sorted alphabetically using Danish alphabet
         * @summary Get all roads belonging to a zip code
         * @param {number} zipCode 
         * @param {boolean} [includeCustom] Include something from a custom realtor address, like a road name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roadsOfZipCode: async (zipCode: number, includeCustom?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'zipCode' is not null or undefined
            assertParamExists('roadsOfZipCode', 'zipCode', zipCode)
            const localVarPath = `/zip_codes/{zipCode}/roads`
                .replace(`{${"zipCode"}}`, encodeURIComponent(String(zipCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeCustom !== undefined) {
                localVarQueryParameter['includeCustom'] = includeCustom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoadsApi - functional programming interface
 * @export
 */
export const RoadsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoadsApiAxiosParamCreator(configuration)
    return {
        /**
         * Roads are sorted alphabetically using Danish alphabet
         * @summary Get all roads belonging to a zip code
         * @param {number} zipCode 
         * @param {boolean} [includeCustom] Include something from a custom realtor address, like a road name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roadsOfZipCode(zipCode: number, includeCustom?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoadCollectionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roadsOfZipCode(zipCode, includeCustom, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoadsApi - factory interface
 * @export
 */
export const RoadsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoadsApiFp(configuration)
    return {
        /**
         * Roads are sorted alphabetically using Danish alphabet
         * @summary Get all roads belonging to a zip code
         * @param {number} zipCode 
         * @param {boolean} [includeCustom] Include something from a custom realtor address, like a road name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roadsOfZipCode(zipCode: number, includeCustom?: boolean, options?: any): AxiosPromise<RoadCollectionResult> {
            return localVarFp.roadsOfZipCode(zipCode, includeCustom, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for roadsOfZipCode operation in RoadsApi.
 * @export
 * @interface RoadsApiRoadsOfZipCodeRequest
 */
export interface RoadsApiRoadsOfZipCodeRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadsApiRoadsOfZipCode
     */
    readonly zipCode: number

    /**
     * Include something from a custom realtor address, like a road name.
     * @type {boolean}
     * @memberof RoadsApiRoadsOfZipCode
     */
    readonly includeCustom?: boolean
}

/**
 * RoadsApi - object-oriented interface
 * @export
 * @class RoadsApi
 * @extends {BaseAPI}
 */
export class RoadsApi extends BaseAPI {
    /**
     * Roads are sorted alphabetically using Danish alphabet
     * @summary Get all roads belonging to a zip code
     * @param {RoadsApiRoadsOfZipCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadsApi
     */
    public roadsOfZipCode(requestParameters: RoadsApiRoadsOfZipCodeRequest, options?: any) {
        return RoadsApiFp(this.configuration).roadsOfZipCode(requestParameters.zipCode, requestParameters.includeCustom, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SavedSearchesApi - axios parameter creator
 * @export
 */
export const SavedSearchesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Build save search url and redirect user to main site
         * @param {string} searchID UUID of the saved search
         * @param {number} [timeOnMarketMin] minimum number of days on the market.
         * @param {number} [timeOnMarketMax] maximum number of days on the market.
         * @param {boolean} [filterPriceDrop] 
         * @param {number} [lastPriceDropAtMax] Maximum number of days since the last price drop, if any
         * @param {SearchCasesSortBy} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSavedSearchIdRedirect: async (searchID: string, timeOnMarketMin?: number, timeOnMarketMax?: number, filterPriceDrop?: boolean, lastPriceDropAtMax?: number, sortBy?: SearchCasesSortBy, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchID' is not null or undefined
            assertParamExists('userSavedSearchIdRedirect', 'searchID', searchID)
            const localVarPath = `/user/searches/{searchID}/redirect`
                .replace(`{${"searchID"}}`, encodeURIComponent(String(searchID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (timeOnMarketMin !== undefined) {
                localVarQueryParameter['timeOnMarketMin'] = timeOnMarketMin;
            }

            if (timeOnMarketMax !== undefined) {
                localVarQueryParameter['timeOnMarketMax'] = timeOnMarketMax;
            }

            if (filterPriceDrop !== undefined) {
                localVarQueryParameter['filterPriceDrop'] = filterPriceDrop;
            }

            if (lastPriceDropAtMax !== undefined) {
                localVarQueryParameter['lastPriceDropAtMax'] = lastPriceDropAtMax;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get searches that the user has saved
         * @param {number} [perPage] Limits the number of results per page. 
         * @param {number} [page] Page number 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSearches: async (perPage?: number, page?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/searches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a saved search on user
         * @param {string} searchID UUID of the saved search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSearchesDelete: async (searchID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchID' is not null or undefined
            assertParamExists('userSearchesDelete', 'searchID', searchID)
            const localVarPath = `/user/searches/{searchID}`
                .replace(`{${"searchID"}}`, encodeURIComponent(String(searchID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new saved search on user
         * @param {Search} search Full search query with metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSearchesPost: async (search: Search, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'search' is not null or undefined
            assertParamExists('userSearchesPost', 'search', search)
            const localVarPath = `/user/searches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(search, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This will replace the existing object if you want to patch the search query instead you should use the patch functionality. 
         * @summary Replace a saved search on user
         * @param {string} searchID UUID of the saved search
         * @param {Search} search Full search query with metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSearchesPut: async (searchID: string, search: Search, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchID' is not null or undefined
            assertParamExists('userSearchesPut', 'searchID', searchID)
            // verify required parameter 'search' is not null or undefined
            assertParamExists('userSearchesPut', 'search', search)
            const localVarPath = `/user/searches/{searchID}`
                .replace(`{${"searchID"}}`, encodeURIComponent(String(searchID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(search, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SavedSearchesApi - functional programming interface
 * @export
 */
export const SavedSearchesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SavedSearchesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Build save search url and redirect user to main site
         * @param {string} searchID UUID of the saved search
         * @param {number} [timeOnMarketMin] minimum number of days on the market.
         * @param {number} [timeOnMarketMax] maximum number of days on the market.
         * @param {boolean} [filterPriceDrop] 
         * @param {number} [lastPriceDropAtMax] Maximum number of days since the last price drop, if any
         * @param {SearchCasesSortBy} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSavedSearchIdRedirect(searchID: string, timeOnMarketMin?: number, timeOnMarketMax?: number, filterPriceDrop?: boolean, lastPriceDropAtMax?: number, sortBy?: SearchCasesSortBy, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSavedSearchIdRedirect(searchID, timeOnMarketMin, timeOnMarketMax, filterPriceDrop, lastPriceDropAtMax, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get searches that the user has saved
         * @param {number} [perPage] Limits the number of results per page. 
         * @param {number} [page] Page number 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSearches(perPage?: number, page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HALCollection & SearchQueryCollectionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSearches(perPage, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a saved search on user
         * @param {string} searchID UUID of the saved search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSearchesDelete(searchID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSearchesDelete(searchID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new saved search on user
         * @param {Search} search Full search query with metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSearchesPost(search: Search, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Search>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSearchesPost(search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This will replace the existing object if you want to patch the search query instead you should use the patch functionality. 
         * @summary Replace a saved search on user
         * @param {string} searchID UUID of the saved search
         * @param {Search} search Full search query with metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSearchesPut(searchID: string, search: Search, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Search>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSearchesPut(searchID, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SavedSearchesApi - factory interface
 * @export
 */
export const SavedSearchesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SavedSearchesApiFp(configuration)
    return {
        /**
         * 
         * @summary Build save search url and redirect user to main site
         * @param {string} searchID UUID of the saved search
         * @param {number} [timeOnMarketMin] minimum number of days on the market.
         * @param {number} [timeOnMarketMax] maximum number of days on the market.
         * @param {boolean} [filterPriceDrop] 
         * @param {number} [lastPriceDropAtMax] Maximum number of days since the last price drop, if any
         * @param {SearchCasesSortBy} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSavedSearchIdRedirect(searchID: string, timeOnMarketMin?: number, timeOnMarketMax?: number, filterPriceDrop?: boolean, lastPriceDropAtMax?: number, sortBy?: SearchCasesSortBy, options?: any): AxiosPromise<void> {
            return localVarFp.userSavedSearchIdRedirect(searchID, timeOnMarketMin, timeOnMarketMax, filterPriceDrop, lastPriceDropAtMax, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get searches that the user has saved
         * @param {number} [perPage] Limits the number of results per page. 
         * @param {number} [page] Page number 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSearches(perPage?: number, page?: number, options?: any): AxiosPromise<HALCollection & SearchQueryCollectionResult> {
            return localVarFp.userSearches(perPage, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a saved search on user
         * @param {string} searchID UUID of the saved search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSearchesDelete(searchID: string, options?: any): AxiosPromise<string> {
            return localVarFp.userSearchesDelete(searchID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new saved search on user
         * @param {Search} search Full search query with metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSearchesPost(search: Search, options?: any): AxiosPromise<Search> {
            return localVarFp.userSearchesPost(search, options).then((request) => request(axios, basePath));
        },
        /**
         * This will replace the existing object if you want to patch the search query instead you should use the patch functionality. 
         * @summary Replace a saved search on user
         * @param {string} searchID UUID of the saved search
         * @param {Search} search Full search query with metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSearchesPut(searchID: string, search: Search, options?: any): AxiosPromise<Search> {
            return localVarFp.userSearchesPut(searchID, search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for userSavedSearchIdRedirect operation in SavedSearchesApi.
 * @export
 * @interface SavedSearchesApiUserSavedSearchIdRedirectRequest
 */
export interface SavedSearchesApiUserSavedSearchIdRedirectRequest {
    /**
     * UUID of the saved search
     * @type {string}
     * @memberof SavedSearchesApiUserSavedSearchIdRedirect
     */
    readonly searchID: string

    /**
     * minimum number of days on the market.
     * @type {number}
     * @memberof SavedSearchesApiUserSavedSearchIdRedirect
     */
    readonly timeOnMarketMin?: number

    /**
     * maximum number of days on the market.
     * @type {number}
     * @memberof SavedSearchesApiUserSavedSearchIdRedirect
     */
    readonly timeOnMarketMax?: number

    /**
     * 
     * @type {boolean}
     * @memberof SavedSearchesApiUserSavedSearchIdRedirect
     */
    readonly filterPriceDrop?: boolean

    /**
     * Maximum number of days since the last price drop, if any
     * @type {number}
     * @memberof SavedSearchesApiUserSavedSearchIdRedirect
     */
    readonly lastPriceDropAtMax?: number

    /**
     * 
     * @type {SearchCasesSortBy}
     * @memberof SavedSearchesApiUserSavedSearchIdRedirect
     */
    readonly sortBy?: SearchCasesSortBy
}

/**
 * Request parameters for userSearches operation in SavedSearchesApi.
 * @export
 * @interface SavedSearchesApiUserSearchesRequest
 */
export interface SavedSearchesApiUserSearchesRequest {
    /**
     * Limits the number of results per page. 
     * @type {number}
     * @memberof SavedSearchesApiUserSearches
     */
    readonly perPage?: number

    /**
     * Page number 
     * @type {number}
     * @memberof SavedSearchesApiUserSearches
     */
    readonly page?: number
}

/**
 * Request parameters for userSearchesDelete operation in SavedSearchesApi.
 * @export
 * @interface SavedSearchesApiUserSearchesDeleteRequest
 */
export interface SavedSearchesApiUserSearchesDeleteRequest {
    /**
     * UUID of the saved search
     * @type {string}
     * @memberof SavedSearchesApiUserSearchesDelete
     */
    readonly searchID: string
}

/**
 * Request parameters for userSearchesPost operation in SavedSearchesApi.
 * @export
 * @interface SavedSearchesApiUserSearchesPostRequest
 */
export interface SavedSearchesApiUserSearchesPostRequest {
    /**
     * Full search query with metadata
     * @type {Search}
     * @memberof SavedSearchesApiUserSearchesPost
     */
    readonly search: Search
}

/**
 * Request parameters for userSearchesPut operation in SavedSearchesApi.
 * @export
 * @interface SavedSearchesApiUserSearchesPutRequest
 */
export interface SavedSearchesApiUserSearchesPutRequest {
    /**
     * UUID of the saved search
     * @type {string}
     * @memberof SavedSearchesApiUserSearchesPut
     */
    readonly searchID: string

    /**
     * Full search query with metadata
     * @type {Search}
     * @memberof SavedSearchesApiUserSearchesPut
     */
    readonly search: Search
}

/**
 * SavedSearchesApi - object-oriented interface
 * @export
 * @class SavedSearchesApi
 * @extends {BaseAPI}
 */
export class SavedSearchesApi extends BaseAPI {
    /**
     * 
     * @summary Build save search url and redirect user to main site
     * @param {SavedSearchesApiUserSavedSearchIdRedirectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedSearchesApi
     */
    public userSavedSearchIdRedirect(requestParameters: SavedSearchesApiUserSavedSearchIdRedirectRequest, options?: any) {
        return SavedSearchesApiFp(this.configuration).userSavedSearchIdRedirect(requestParameters.searchID, requestParameters.timeOnMarketMin, requestParameters.timeOnMarketMax, requestParameters.filterPriceDrop, requestParameters.lastPriceDropAtMax, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get searches that the user has saved
     * @param {SavedSearchesApiUserSearchesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedSearchesApi
     */
    public userSearches(requestParameters: SavedSearchesApiUserSearchesRequest = {}, options?: any) {
        return SavedSearchesApiFp(this.configuration).userSearches(requestParameters.perPage, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a saved search on user
     * @param {SavedSearchesApiUserSearchesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedSearchesApi
     */
    public userSearchesDelete(requestParameters: SavedSearchesApiUserSearchesDeleteRequest, options?: any) {
        return SavedSearchesApiFp(this.configuration).userSearchesDelete(requestParameters.searchID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new saved search on user
     * @param {SavedSearchesApiUserSearchesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedSearchesApi
     */
    public userSearchesPost(requestParameters: SavedSearchesApiUserSearchesPostRequest, options?: any) {
        return SavedSearchesApiFp(this.configuration).userSearchesPost(requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This will replace the existing object if you want to patch the search query instead you should use the patch functionality. 
     * @summary Replace a saved search on user
     * @param {SavedSearchesApiUserSearchesPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedSearchesApi
     */
    public userSearchesPut(requestParameters: SavedSearchesApiUserSearchesPutRequest, options?: any) {
        return SavedSearchesApiFp(this.configuration).userSearchesPut(requestParameters.searchID, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SitemapsApi - axios parameter creator
 * @export
 */
export const SitemapsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get sitemap file sitemap-{sitemapFileNumber}.xml.
         * @param {number} sitemapFileNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sitemapFile: async (sitemapFileNumber: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sitemapFileNumber' is not null or undefined
            assertParamExists('sitemapFile', 'sitemapFileNumber', sitemapFileNumber)
            const localVarPath = `/sitemap/{sitemapFileNumber}`
                .replace(`{${"sitemapFileNumber"}}`, encodeURIComponent(String(sitemapFileNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sitemap index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sitemapIndex: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sitemap/index`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SitemapsApi - functional programming interface
 * @export
 */
export const SitemapsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SitemapsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get sitemap file sitemap-{sitemapFileNumber}.xml.
         * @param {number} sitemapFileNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sitemapFile(sitemapFileNumber: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sitemapFile(sitemapFileNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get sitemap index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sitemapIndex(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sitemapIndex(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SitemapsApi - factory interface
 * @export
 */
export const SitemapsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SitemapsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get sitemap file sitemap-{sitemapFileNumber}.xml.
         * @param {number} sitemapFileNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sitemapFile(sitemapFileNumber: number, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.sitemapFile(sitemapFileNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get sitemap index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sitemapIndex(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.sitemapIndex(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sitemapFile operation in SitemapsApi.
 * @export
 * @interface SitemapsApiSitemapFileRequest
 */
export interface SitemapsApiSitemapFileRequest {
    /**
     * 
     * @type {number}
     * @memberof SitemapsApiSitemapFile
     */
    readonly sitemapFileNumber: number
}

/**
 * SitemapsApi - object-oriented interface
 * @export
 * @class SitemapsApi
 * @extends {BaseAPI}
 */
export class SitemapsApi extends BaseAPI {
    /**
     * 
     * @summary Get sitemap file sitemap-{sitemapFileNumber}.xml.
     * @param {SitemapsApiSitemapFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitemapsApi
     */
    public sitemapFile(requestParameters: SitemapsApiSitemapFileRequest, options?: any) {
        return SitemapsApiFp(this.configuration).sitemapFile(requestParameters.sitemapFileNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get sitemap index.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitemapsApi
     */
    public sitemapIndex(options?: any) {
        return SitemapsApiFp(this.configuration).sitemapIndex(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TilesApi - axios parameter creator
 * @export
 */
export const TilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate addresses tile
         * @param {number} z Map zoom level.
         * @param {number} tileX Tile X.
         * @param {number} tileY Tile Y.
         * @param {number} [scale] The scale of the tile.
         * @param {number} [dotType] The size of the dot which represents the property.
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [zipCodes] This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
         * @param {Set<string>} [roads] Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
         * @param {Set<string>} [cities] 
         * @param {boolean} [sold] An address is defined as sold if it has been sold _at least once_ during its existence
         * @param {number} [yearSoldFrom] yearSoldFrom is inclusive
         * @param {number} [yearSoldTo] yearSoldTo is inclusive
         * @param {number} [priceMax] 
         * @param {number} [priceMin] 
         * @param {Set<RegistrationType>} [registrationTypes] The registration type to filter by
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {number} [areaMin] 
         * @param {number} [areaMax] 
         * @param {string} [polygon] Polygon. Use like &#x60;api.dk/search?polygon&#x3D;12.552178,55.784813|12.497472,55.784813|12.304046,55.685812|12.302093,55.654960&#x60;. * Format polygon&#x3D;[lon,lat]|[lon,lat]|.... * A polygon can have a max of 500 points 
         * @param {string} [radius] Radius. Use like &#x60;api.dk/search?radius&#x3D;10350|11.852554,55.767563&#x60;. * Format: [meters]|[lon:centerPoint],[lat:centerPoint] * [meters] must be at least 1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressesTile: async (z: number, tileX: number, tileY: number, scale?: number, dotType?: number, provinces?: Set<string>, municipalities?: Set<string>, zipCodes?: Set<string>, roads?: Set<string>, cities?: Set<string>, sold?: boolean, yearSoldFrom?: number, yearSoldTo?: number, priceMax?: number, priceMin?: number, registrationTypes?: Set<RegistrationType>, addressTypes?: Set<AddressType>, areaMin?: number, areaMax?: number, polygon?: string, radius?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'z' is not null or undefined
            assertParamExists('addressesTile', 'z', z)
            // verify required parameter 'tileX' is not null or undefined
            assertParamExists('addressesTile', 'tileX', tileX)
            // verify required parameter 'tileY' is not null or undefined
            assertParamExists('addressesTile', 'tileY', tileY)
            const localVarPath = `/addresses/tile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (z !== undefined) {
                localVarQueryParameter['z'] = z;
            }

            if (tileX !== undefined) {
                localVarQueryParameter['tileX'] = tileX;
            }

            if (tileY !== undefined) {
                localVarQueryParameter['tileY'] = tileY;
            }

            if (scale !== undefined) {
                localVarQueryParameter['scale'] = scale;
            }

            if (dotType !== undefined) {
                localVarQueryParameter['dotType'] = dotType;
            }

            if (provinces) {
                localVarQueryParameter['provinces'] = Array.from(provinces);
            }

            if (municipalities) {
                localVarQueryParameter['municipalities'] = Array.from(municipalities);
            }

            if (zipCodes) {
                localVarQueryParameter['zipCodes'] = Array.from(zipCodes);
            }

            if (roads) {
                localVarQueryParameter['roads'] = Array.from(roads);
            }

            if (cities) {
                localVarQueryParameter['cities'] = Array.from(cities);
            }

            if (sold !== undefined) {
                localVarQueryParameter['sold'] = sold;
            }

            if (yearSoldFrom !== undefined) {
                localVarQueryParameter['yearSoldFrom'] = yearSoldFrom;
            }

            if (yearSoldTo !== undefined) {
                localVarQueryParameter['yearSoldTo'] = yearSoldTo;
            }

            if (priceMax !== undefined) {
                localVarQueryParameter['priceMax'] = priceMax;
            }

            if (priceMin !== undefined) {
                localVarQueryParameter['priceMin'] = priceMin;
            }

            if (registrationTypes) {
                localVarQueryParameter['registrationTypes'] = Array.from(registrationTypes);
            }

            if (addressTypes) {
                localVarQueryParameter['addressTypes'] = Array.from(addressTypes).join(COLLECTION_FORMATS.csv);
            }

            if (areaMin !== undefined) {
                localVarQueryParameter['areaMin'] = areaMin;
            }

            if (areaMax !== undefined) {
                localVarQueryParameter['areaMax'] = areaMax;
            }

            if (polygon !== undefined) {
                localVarQueryParameter['polygon'] = polygon;
            }

            if (radius !== undefined) {
                localVarQueryParameter['radius'] = radius;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Only real estate and cooperative cases are included.
         * @summary Generate a case tile
         * @param {number} z Map zoom level.
         * @param {number} tileX Tile X.
         * @param {number} tileY Tile Y.
         * @param {number} [floorMin] the minimum floor where an address resides like minimum on the third floor.
         * @param {number} [floorMax] the maximum floor where an address (apartment) resides like at most the third floor.
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [cities] 
         * @param {Set<string>} [zipCodes] This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
         * @param {Set<string>} [roads] Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
         * @param {Set<string>} [placeNames] 
         * @param {Set<string>} [customAreas] 
         * @param {number} [c] The color palette used to draw the properties on the map.
         * @param {number} [scale] The scale of the tile.
         * @param {number} [dotType] The size of the dot which represents the property.
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {number} [priceMin] 
         * @param {number} [priceMax] 
         * @param {number} [monthlyExpenseMin] Case minimum monthly expense.
         * @param {number} [monthlyExpenseMax] Case maximum monthly expense.
         * @param {string} [freeText] Free text search in the title and description of cases
         * @param {Set<EnergyLabel>} [energyLabels] 
         * @param {boolean} [filterOpenHouse] Whether or not cases returned have an upcoming open house event
         * @param {number} [areaMin] 
         * @param {number} [areaMax] 
         * @param {number} [numberOfRoomsMin] 
         * @param {number} [numberOfRoomsMax] 
         * @param {number} [yearBuiltFrom] 
         * @param {number} [yearBuiltTo] 
         * @param {number} [lotAreaMin] 
         * @param {number} [lotAreaMax] 
         * @param {number} [levelsMin] An address has at least this many levels - like a house in two levels.
         * @param {number} [levelsMax] An address has maximum this many levels - like a house in max one level.
         * @param {boolean} [presale] When enabled the result should only include pre-sale (projektsalg)
         * @param {number} [basementAreaMin] 
         * @param {number} [basementAreaMax] 
         * @param {boolean} [filterBasement] Whether or not cases returned have a basement
         * @param {boolean} [filterPriceDrop] 
         * @param {string} [polygon] Polygon. Use like &#x60;api.dk/search?polygon&#x3D;12.552178,55.784813|12.497472,55.784813|12.304046,55.685812|12.302093,55.654960&#x60;. * Format polygon&#x3D;[lon,lat]|[lon,lat]|.... * A polygon can have a max of 500 points 
         * @param {string} [radius] Radius. Use like &#x60;api.dk/search?radius&#x3D;10350|11.852554,55.767563&#x60;. * Format: [meters]|[lon:centerPoint],[lat:centerPoint] * [meters] must be at least 1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseTile: async (z: number, tileX: number, tileY: number, floorMin?: number, floorMax?: number, provinces?: Set<string>, municipalities?: Set<string>, cities?: Set<string>, zipCodes?: Set<string>, roads?: Set<string>, placeNames?: Set<string>, customAreas?: Set<string>, c?: number, scale?: number, dotType?: number, addressTypes?: Set<AddressType>, priceMin?: number, priceMax?: number, monthlyExpenseMin?: number, monthlyExpenseMax?: number, freeText?: string, energyLabels?: Set<EnergyLabel>, filterOpenHouse?: boolean, areaMin?: number, areaMax?: number, numberOfRoomsMin?: number, numberOfRoomsMax?: number, yearBuiltFrom?: number, yearBuiltTo?: number, lotAreaMin?: number, lotAreaMax?: number, levelsMin?: number, levelsMax?: number, presale?: boolean, basementAreaMin?: number, basementAreaMax?: number, filterBasement?: boolean, filterPriceDrop?: boolean, polygon?: string, radius?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'z' is not null or undefined
            assertParamExists('caseTile', 'z', z)
            // verify required parameter 'tileX' is not null or undefined
            assertParamExists('caseTile', 'tileX', tileX)
            // verify required parameter 'tileY' is not null or undefined
            assertParamExists('caseTile', 'tileY', tileY)
            const localVarPath = `/tile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (floorMin !== undefined) {
                localVarQueryParameter['floorMin'] = floorMin;
            }

            if (floorMax !== undefined) {
                localVarQueryParameter['floorMax'] = floorMax;
            }

            if (provinces) {
                localVarQueryParameter['provinces'] = Array.from(provinces);
            }

            if (municipalities) {
                localVarQueryParameter['municipalities'] = Array.from(municipalities);
            }

            if (cities) {
                localVarQueryParameter['cities'] = Array.from(cities);
            }

            if (zipCodes) {
                localVarQueryParameter['zipCodes'] = Array.from(zipCodes);
            }

            if (roads) {
                localVarQueryParameter['roads'] = Array.from(roads);
            }

            if (placeNames) {
                localVarQueryParameter['placeNames'] = Array.from(placeNames);
            }

            if (customAreas) {
                localVarQueryParameter['customAreas'] = Array.from(customAreas);
            }

            if (c !== undefined) {
                localVarQueryParameter['c'] = c;
            }

            if (z !== undefined) {
                localVarQueryParameter['z'] = z;
            }

            if (tileX !== undefined) {
                localVarQueryParameter['tileX'] = tileX;
            }

            if (tileY !== undefined) {
                localVarQueryParameter['tileY'] = tileY;
            }

            if (scale !== undefined) {
                localVarQueryParameter['scale'] = scale;
            }

            if (dotType !== undefined) {
                localVarQueryParameter['dotType'] = dotType;
            }

            if (addressTypes) {
                localVarQueryParameter['addressTypes'] = Array.from(addressTypes).join(COLLECTION_FORMATS.csv);
            }

            if (priceMin !== undefined) {
                localVarQueryParameter['priceMin'] = priceMin;
            }

            if (priceMax !== undefined) {
                localVarQueryParameter['priceMax'] = priceMax;
            }

            if (monthlyExpenseMin !== undefined) {
                localVarQueryParameter['monthlyExpenseMin'] = monthlyExpenseMin;
            }

            if (monthlyExpenseMax !== undefined) {
                localVarQueryParameter['monthlyExpenseMax'] = monthlyExpenseMax;
            }

            if (freeText !== undefined) {
                localVarQueryParameter['freeText'] = freeText;
            }

            if (energyLabels) {
                localVarQueryParameter['energyLabels'] = Array.from(energyLabels).join(COLLECTION_FORMATS.csv);
            }

            if (filterOpenHouse !== undefined) {
                localVarQueryParameter['filterOpenHouse'] = filterOpenHouse;
            }

            if (areaMin !== undefined) {
                localVarQueryParameter['areaMin'] = areaMin;
            }

            if (areaMax !== undefined) {
                localVarQueryParameter['areaMax'] = areaMax;
            }

            if (numberOfRoomsMin !== undefined) {
                localVarQueryParameter['numberOfRoomsMin'] = numberOfRoomsMin;
            }

            if (numberOfRoomsMax !== undefined) {
                localVarQueryParameter['numberOfRoomsMax'] = numberOfRoomsMax;
            }

            if (yearBuiltFrom !== undefined) {
                localVarQueryParameter['yearBuiltFrom'] = yearBuiltFrom;
            }

            if (yearBuiltTo !== undefined) {
                localVarQueryParameter['yearBuiltTo'] = yearBuiltTo;
            }

            if (lotAreaMin !== undefined) {
                localVarQueryParameter['lotAreaMin'] = lotAreaMin;
            }

            if (lotAreaMax !== undefined) {
                localVarQueryParameter['lotAreaMax'] = lotAreaMax;
            }

            if (levelsMin !== undefined) {
                localVarQueryParameter['levelsMin'] = levelsMin;
            }

            if (levelsMax !== undefined) {
                localVarQueryParameter['levelsMax'] = levelsMax;
            }

            if (presale !== undefined) {
                localVarQueryParameter['presale'] = presale;
            }

            if (basementAreaMin !== undefined) {
                localVarQueryParameter['basementAreaMin'] = basementAreaMin;
            }

            if (basementAreaMax !== undefined) {
                localVarQueryParameter['basementAreaMax'] = basementAreaMax;
            }

            if (filterBasement !== undefined) {
                localVarQueryParameter['filterBasement'] = filterBasement;
            }

            if (filterPriceDrop !== undefined) {
                localVarQueryParameter['filterPriceDrop'] = filterPriceDrop;
            }

            if (polygon !== undefined) {
                localVarQueryParameter['polygon'] = polygon;
            }

            if (radius !== undefined) {
                localVarQueryParameter['radius'] = radius;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TilesApi - functional programming interface
 * @export
 */
export const TilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generate addresses tile
         * @param {number} z Map zoom level.
         * @param {number} tileX Tile X.
         * @param {number} tileY Tile Y.
         * @param {number} [scale] The scale of the tile.
         * @param {number} [dotType] The size of the dot which represents the property.
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [zipCodes] This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
         * @param {Set<string>} [roads] Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
         * @param {Set<string>} [cities] 
         * @param {boolean} [sold] An address is defined as sold if it has been sold _at least once_ during its existence
         * @param {number} [yearSoldFrom] yearSoldFrom is inclusive
         * @param {number} [yearSoldTo] yearSoldTo is inclusive
         * @param {number} [priceMax] 
         * @param {number} [priceMin] 
         * @param {Set<RegistrationType>} [registrationTypes] The registration type to filter by
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {number} [areaMin] 
         * @param {number} [areaMax] 
         * @param {string} [polygon] Polygon. Use like &#x60;api.dk/search?polygon&#x3D;12.552178,55.784813|12.497472,55.784813|12.304046,55.685812|12.302093,55.654960&#x60;. * Format polygon&#x3D;[lon,lat]|[lon,lat]|.... * A polygon can have a max of 500 points 
         * @param {string} [radius] Radius. Use like &#x60;api.dk/search?radius&#x3D;10350|11.852554,55.767563&#x60;. * Format: [meters]|[lon:centerPoint],[lat:centerPoint] * [meters] must be at least 1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressesTile(z: number, tileX: number, tileY: number, scale?: number, dotType?: number, provinces?: Set<string>, municipalities?: Set<string>, zipCodes?: Set<string>, roads?: Set<string>, cities?: Set<string>, sold?: boolean, yearSoldFrom?: number, yearSoldTo?: number, priceMax?: number, priceMin?: number, registrationTypes?: Set<RegistrationType>, addressTypes?: Set<AddressType>, areaMin?: number, areaMax?: number, polygon?: string, radius?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressesTile(z, tileX, tileY, scale, dotType, provinces, municipalities, zipCodes, roads, cities, sold, yearSoldFrom, yearSoldTo, priceMax, priceMin, registrationTypes, addressTypes, areaMin, areaMax, polygon, radius, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Only real estate and cooperative cases are included.
         * @summary Generate a case tile
         * @param {number} z Map zoom level.
         * @param {number} tileX Tile X.
         * @param {number} tileY Tile Y.
         * @param {number} [floorMin] the minimum floor where an address resides like minimum on the third floor.
         * @param {number} [floorMax] the maximum floor where an address (apartment) resides like at most the third floor.
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [cities] 
         * @param {Set<string>} [zipCodes] This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
         * @param {Set<string>} [roads] Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
         * @param {Set<string>} [placeNames] 
         * @param {Set<string>} [customAreas] 
         * @param {number} [c] The color palette used to draw the properties on the map.
         * @param {number} [scale] The scale of the tile.
         * @param {number} [dotType] The size of the dot which represents the property.
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {number} [priceMin] 
         * @param {number} [priceMax] 
         * @param {number} [monthlyExpenseMin] Case minimum monthly expense.
         * @param {number} [monthlyExpenseMax] Case maximum monthly expense.
         * @param {string} [freeText] Free text search in the title and description of cases
         * @param {Set<EnergyLabel>} [energyLabels] 
         * @param {boolean} [filterOpenHouse] Whether or not cases returned have an upcoming open house event
         * @param {number} [areaMin] 
         * @param {number} [areaMax] 
         * @param {number} [numberOfRoomsMin] 
         * @param {number} [numberOfRoomsMax] 
         * @param {number} [yearBuiltFrom] 
         * @param {number} [yearBuiltTo] 
         * @param {number} [lotAreaMin] 
         * @param {number} [lotAreaMax] 
         * @param {number} [levelsMin] An address has at least this many levels - like a house in two levels.
         * @param {number} [levelsMax] An address has maximum this many levels - like a house in max one level.
         * @param {boolean} [presale] When enabled the result should only include pre-sale (projektsalg)
         * @param {number} [basementAreaMin] 
         * @param {number} [basementAreaMax] 
         * @param {boolean} [filterBasement] Whether or not cases returned have a basement
         * @param {boolean} [filterPriceDrop] 
         * @param {string} [polygon] Polygon. Use like &#x60;api.dk/search?polygon&#x3D;12.552178,55.784813|12.497472,55.784813|12.304046,55.685812|12.302093,55.654960&#x60;. * Format polygon&#x3D;[lon,lat]|[lon,lat]|.... * A polygon can have a max of 500 points 
         * @param {string} [radius] Radius. Use like &#x60;api.dk/search?radius&#x3D;10350|11.852554,55.767563&#x60;. * Format: [meters]|[lon:centerPoint],[lat:centerPoint] * [meters] must be at least 1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseTile(z: number, tileX: number, tileY: number, floorMin?: number, floorMax?: number, provinces?: Set<string>, municipalities?: Set<string>, cities?: Set<string>, zipCodes?: Set<string>, roads?: Set<string>, placeNames?: Set<string>, customAreas?: Set<string>, c?: number, scale?: number, dotType?: number, addressTypes?: Set<AddressType>, priceMin?: number, priceMax?: number, monthlyExpenseMin?: number, monthlyExpenseMax?: number, freeText?: string, energyLabels?: Set<EnergyLabel>, filterOpenHouse?: boolean, areaMin?: number, areaMax?: number, numberOfRoomsMin?: number, numberOfRoomsMax?: number, yearBuiltFrom?: number, yearBuiltTo?: number, lotAreaMin?: number, lotAreaMax?: number, levelsMin?: number, levelsMax?: number, presale?: boolean, basementAreaMin?: number, basementAreaMax?: number, filterBasement?: boolean, filterPriceDrop?: boolean, polygon?: string, radius?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseTile(z, tileX, tileY, floorMin, floorMax, provinces, municipalities, cities, zipCodes, roads, placeNames, customAreas, c, scale, dotType, addressTypes, priceMin, priceMax, monthlyExpenseMin, monthlyExpenseMax, freeText, energyLabels, filterOpenHouse, areaMin, areaMax, numberOfRoomsMin, numberOfRoomsMax, yearBuiltFrom, yearBuiltTo, lotAreaMin, lotAreaMax, levelsMin, levelsMax, presale, basementAreaMin, basementAreaMax, filterBasement, filterPriceDrop, polygon, radius, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TilesApi - factory interface
 * @export
 */
export const TilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TilesApiFp(configuration)
    return {
        /**
         * 
         * @summary Generate addresses tile
         * @param {number} z Map zoom level.
         * @param {number} tileX Tile X.
         * @param {number} tileY Tile Y.
         * @param {number} [scale] The scale of the tile.
         * @param {number} [dotType] The size of the dot which represents the property.
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [zipCodes] This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
         * @param {Set<string>} [roads] Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
         * @param {Set<string>} [cities] 
         * @param {boolean} [sold] An address is defined as sold if it has been sold _at least once_ during its existence
         * @param {number} [yearSoldFrom] yearSoldFrom is inclusive
         * @param {number} [yearSoldTo] yearSoldTo is inclusive
         * @param {number} [priceMax] 
         * @param {number} [priceMin] 
         * @param {Set<RegistrationType>} [registrationTypes] The registration type to filter by
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {number} [areaMin] 
         * @param {number} [areaMax] 
         * @param {string} [polygon] Polygon. Use like &#x60;api.dk/search?polygon&#x3D;12.552178,55.784813|12.497472,55.784813|12.304046,55.685812|12.302093,55.654960&#x60;. * Format polygon&#x3D;[lon,lat]|[lon,lat]|.... * A polygon can have a max of 500 points 
         * @param {string} [radius] Radius. Use like &#x60;api.dk/search?radius&#x3D;10350|11.852554,55.767563&#x60;. * Format: [meters]|[lon:centerPoint],[lat:centerPoint] * [meters] must be at least 1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressesTile(z: number, tileX: number, tileY: number, scale?: number, dotType?: number, provinces?: Set<string>, municipalities?: Set<string>, zipCodes?: Set<string>, roads?: Set<string>, cities?: Set<string>, sold?: boolean, yearSoldFrom?: number, yearSoldTo?: number, priceMax?: number, priceMin?: number, registrationTypes?: Set<RegistrationType>, addressTypes?: Set<AddressType>, areaMin?: number, areaMax?: number, polygon?: string, radius?: string, options?: any): AxiosPromise<any> {
            return localVarFp.addressesTile(z, tileX, tileY, scale, dotType, provinces, municipalities, zipCodes, roads, cities, sold, yearSoldFrom, yearSoldTo, priceMax, priceMin, registrationTypes, addressTypes, areaMin, areaMax, polygon, radius, options).then((request) => request(axios, basePath));
        },
        /**
         * Only real estate and cooperative cases are included.
         * @summary Generate a case tile
         * @param {number} z Map zoom level.
         * @param {number} tileX Tile X.
         * @param {number} tileY Tile Y.
         * @param {number} [floorMin] the minimum floor where an address resides like minimum on the third floor.
         * @param {number} [floorMax] the maximum floor where an address (apartment) resides like at most the third floor.
         * @param {Set<string>} [provinces] 
         * @param {Set<string>} [municipalities] 
         * @param {Set<string>} [cities] 
         * @param {Set<string>} [zipCodes] This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
         * @param {Set<string>} [roads] Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
         * @param {Set<string>} [placeNames] 
         * @param {Set<string>} [customAreas] 
         * @param {number} [c] The color palette used to draw the properties on the map.
         * @param {number} [scale] The scale of the tile.
         * @param {number} [dotType] The size of the dot which represents the property.
         * @param {Set<AddressType>} [addressTypes] The address type to filter by
         * @param {number} [priceMin] 
         * @param {number} [priceMax] 
         * @param {number} [monthlyExpenseMin] Case minimum monthly expense.
         * @param {number} [monthlyExpenseMax] Case maximum monthly expense.
         * @param {string} [freeText] Free text search in the title and description of cases
         * @param {Set<EnergyLabel>} [energyLabels] 
         * @param {boolean} [filterOpenHouse] Whether or not cases returned have an upcoming open house event
         * @param {number} [areaMin] 
         * @param {number} [areaMax] 
         * @param {number} [numberOfRoomsMin] 
         * @param {number} [numberOfRoomsMax] 
         * @param {number} [yearBuiltFrom] 
         * @param {number} [yearBuiltTo] 
         * @param {number} [lotAreaMin] 
         * @param {number} [lotAreaMax] 
         * @param {number} [levelsMin] An address has at least this many levels - like a house in two levels.
         * @param {number} [levelsMax] An address has maximum this many levels - like a house in max one level.
         * @param {boolean} [presale] When enabled the result should only include pre-sale (projektsalg)
         * @param {number} [basementAreaMin] 
         * @param {number} [basementAreaMax] 
         * @param {boolean} [filterBasement] Whether or not cases returned have a basement
         * @param {boolean} [filterPriceDrop] 
         * @param {string} [polygon] Polygon. Use like &#x60;api.dk/search?polygon&#x3D;12.552178,55.784813|12.497472,55.784813|12.304046,55.685812|12.302093,55.654960&#x60;. * Format polygon&#x3D;[lon,lat]|[lon,lat]|.... * A polygon can have a max of 500 points 
         * @param {string} [radius] Radius. Use like &#x60;api.dk/search?radius&#x3D;10350|11.852554,55.767563&#x60;. * Format: [meters]|[lon:centerPoint],[lat:centerPoint] * [meters] must be at least 1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseTile(z: number, tileX: number, tileY: number, floorMin?: number, floorMax?: number, provinces?: Set<string>, municipalities?: Set<string>, cities?: Set<string>, zipCodes?: Set<string>, roads?: Set<string>, placeNames?: Set<string>, customAreas?: Set<string>, c?: number, scale?: number, dotType?: number, addressTypes?: Set<AddressType>, priceMin?: number, priceMax?: number, monthlyExpenseMin?: number, monthlyExpenseMax?: number, freeText?: string, energyLabels?: Set<EnergyLabel>, filterOpenHouse?: boolean, areaMin?: number, areaMax?: number, numberOfRoomsMin?: number, numberOfRoomsMax?: number, yearBuiltFrom?: number, yearBuiltTo?: number, lotAreaMin?: number, lotAreaMax?: number, levelsMin?: number, levelsMax?: number, presale?: boolean, basementAreaMin?: number, basementAreaMax?: number, filterBasement?: boolean, filterPriceDrop?: boolean, polygon?: string, radius?: string, options?: any): AxiosPromise<any> {
            return localVarFp.caseTile(z, tileX, tileY, floorMin, floorMax, provinces, municipalities, cities, zipCodes, roads, placeNames, customAreas, c, scale, dotType, addressTypes, priceMin, priceMax, monthlyExpenseMin, monthlyExpenseMax, freeText, energyLabels, filterOpenHouse, areaMin, areaMax, numberOfRoomsMin, numberOfRoomsMax, yearBuiltFrom, yearBuiltTo, lotAreaMin, lotAreaMax, levelsMin, levelsMax, presale, basementAreaMin, basementAreaMax, filterBasement, filterPriceDrop, polygon, radius, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addressesTile operation in TilesApi.
 * @export
 * @interface TilesApiAddressesTileRequest
 */
export interface TilesApiAddressesTileRequest {
    /**
     * Map zoom level.
     * @type {number}
     * @memberof TilesApiAddressesTile
     */
    readonly z: number

    /**
     * Tile X.
     * @type {number}
     * @memberof TilesApiAddressesTile
     */
    readonly tileX: number

    /**
     * Tile Y.
     * @type {number}
     * @memberof TilesApiAddressesTile
     */
    readonly tileY: number

    /**
     * The scale of the tile.
     * @type {number}
     * @memberof TilesApiAddressesTile
     */
    readonly scale?: number

    /**
     * The size of the dot which represents the property.
     * @type {number}
     * @memberof TilesApiAddressesTile
     */
    readonly dotType?: number

    /**
     * 
     * @type {Set<string>}
     * @memberof TilesApiAddressesTile
     */
    readonly provinces?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof TilesApiAddressesTile
     */
    readonly municipalities?: Set<string>

    /**
     * This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
     * @type {Set<string>}
     * @memberof TilesApiAddressesTile
     */
    readonly zipCodes?: Set<string>

    /**
     * Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
     * @type {Set<string>}
     * @memberof TilesApiAddressesTile
     */
    readonly roads?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof TilesApiAddressesTile
     */
    readonly cities?: Set<string>

    /**
     * An address is defined as sold if it has been sold _at least once_ during its existence
     * @type {boolean}
     * @memberof TilesApiAddressesTile
     */
    readonly sold?: boolean

    /**
     * yearSoldFrom is inclusive
     * @type {number}
     * @memberof TilesApiAddressesTile
     */
    readonly yearSoldFrom?: number

    /**
     * yearSoldTo is inclusive
     * @type {number}
     * @memberof TilesApiAddressesTile
     */
    readonly yearSoldTo?: number

    /**
     * 
     * @type {number}
     * @memberof TilesApiAddressesTile
     */
    readonly priceMax?: number

    /**
     * 
     * @type {number}
     * @memberof TilesApiAddressesTile
     */
    readonly priceMin?: number

    /**
     * The registration type to filter by
     * @type {Set<RegistrationType>}
     * @memberof TilesApiAddressesTile
     */
    readonly registrationTypes?: Set<RegistrationType>

    /**
     * The address type to filter by
     * @type {Set<AddressType>}
     * @memberof TilesApiAddressesTile
     */
    readonly addressTypes?: Set<AddressType>

    /**
     * 
     * @type {number}
     * @memberof TilesApiAddressesTile
     */
    readonly areaMin?: number

    /**
     * 
     * @type {number}
     * @memberof TilesApiAddressesTile
     */
    readonly areaMax?: number

    /**
     * Polygon. Use like &#x60;api.dk/search?polygon&#x3D;12.552178,55.784813|12.497472,55.784813|12.304046,55.685812|12.302093,55.654960&#x60;. * Format polygon&#x3D;[lon,lat]|[lon,lat]|.... * A polygon can have a max of 500 points 
     * @type {string}
     * @memberof TilesApiAddressesTile
     */
    readonly polygon?: string

    /**
     * Radius. Use like &#x60;api.dk/search?radius&#x3D;10350|11.852554,55.767563&#x60;. * Format: [meters]|[lon:centerPoint],[lat:centerPoint] * [meters] must be at least 1 
     * @type {string}
     * @memberof TilesApiAddressesTile
     */
    readonly radius?: string
}

/**
 * Request parameters for caseTile operation in TilesApi.
 * @export
 * @interface TilesApiCaseTileRequest
 */
export interface TilesApiCaseTileRequest {
    /**
     * Map zoom level.
     * @type {number}
     * @memberof TilesApiCaseTile
     */
    readonly z: number

    /**
     * Tile X.
     * @type {number}
     * @memberof TilesApiCaseTile
     */
    readonly tileX: number

    /**
     * Tile Y.
     * @type {number}
     * @memberof TilesApiCaseTile
     */
    readonly tileY: number

    /**
     * the minimum floor where an address resides like minimum on the third floor.
     * @type {number}
     * @memberof TilesApiCaseTile
     */
    readonly floorMin?: number

    /**
     * the maximum floor where an address (apartment) resides like at most the third floor.
     * @type {number}
     * @memberof TilesApiCaseTile
     */
    readonly floorMax?: number

    /**
     * 
     * @type {Set<string>}
     * @memberof TilesApiCaseTile
     */
    readonly provinces?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof TilesApiCaseTile
     */
    readonly municipalities?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof TilesApiCaseTile
     */
    readonly cities?: Set<string>

    /**
     * This parameter supports two formats: just zip code (like &#x60;5000&#x60;) and zip code + zip code name (like &#x60;5000 Odense C&#x60;). Formats are interchangeable, meaning &#x60;?zipCodes&#x3D;3200,5000&#x60;, &#x60;?zipCodes&#x3D;3200 Helsinge,5000&#x60; and &#x60;?zipCodes&#x3D;3200 Helsinge,5000 Odense C&#x60; are all functionally equivalent. 
     * @type {Set<string>}
     * @memberof TilesApiCaseTile
     */
    readonly zipCodes?: Set<string>

    /**
     * Road names are not unique across Denmark. For example, the road &#x60;Birkedommervej&#x60; exists in both 2400 København NV and 8500 Grenaa. In order to ensure uniqueness, road names passed via this parameter should be suffixed with the road\&#39;s zip code and city. For example, instead of sending &#x60;Birkedommervej&#x60;, send &#x60;Birkedommervej, 2400 København NV&#x60; or &#x60;Birkedommervej, København NV 2400&#x60;. Prefix search is used, so &#x60;Birkedommervej, 2400&#x60; or &#x60;Birkedommervej, København&#x60; are valid search terms 
     * @type {Set<string>}
     * @memberof TilesApiCaseTile
     */
    readonly roads?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof TilesApiCaseTile
     */
    readonly placeNames?: Set<string>

    /**
     * 
     * @type {Set<string>}
     * @memberof TilesApiCaseTile
     */
    readonly customAreas?: Set<string>

    /**
     * The color palette used to draw the properties on the map.
     * @type {number}
     * @memberof TilesApiCaseTile
     */
    readonly c?: number

    /**
     * The scale of the tile.
     * @type {number}
     * @memberof TilesApiCaseTile
     */
    readonly scale?: number

    /**
     * The size of the dot which represents the property.
     * @type {number}
     * @memberof TilesApiCaseTile
     */
    readonly dotType?: number

    /**
     * The address type to filter by
     * @type {Set<AddressType>}
     * @memberof TilesApiCaseTile
     */
    readonly addressTypes?: Set<AddressType>

    /**
     * 
     * @type {number}
     * @memberof TilesApiCaseTile
     */
    readonly priceMin?: number

    /**
     * 
     * @type {number}
     * @memberof TilesApiCaseTile
     */
    readonly priceMax?: number

    /**
     * Case minimum monthly expense.
     * @type {number}
     * @memberof TilesApiCaseTile
     */
    readonly monthlyExpenseMin?: number

    /**
     * Case maximum monthly expense.
     * @type {number}
     * @memberof TilesApiCaseTile
     */
    readonly monthlyExpenseMax?: number

    /**
     * Free text search in the title and description of cases
     * @type {string}
     * @memberof TilesApiCaseTile
     */
    readonly freeText?: string

    /**
     * 
     * @type {Set<EnergyLabel>}
     * @memberof TilesApiCaseTile
     */
    readonly energyLabels?: Set<EnergyLabel>

    /**
     * Whether or not cases returned have an upcoming open house event
     * @type {boolean}
     * @memberof TilesApiCaseTile
     */
    readonly filterOpenHouse?: boolean

    /**
     * 
     * @type {number}
     * @memberof TilesApiCaseTile
     */
    readonly areaMin?: number

    /**
     * 
     * @type {number}
     * @memberof TilesApiCaseTile
     */
    readonly areaMax?: number

    /**
     * 
     * @type {number}
     * @memberof TilesApiCaseTile
     */
    readonly numberOfRoomsMin?: number

    /**
     * 
     * @type {number}
     * @memberof TilesApiCaseTile
     */
    readonly numberOfRoomsMax?: number

    /**
     * 
     * @type {number}
     * @memberof TilesApiCaseTile
     */
    readonly yearBuiltFrom?: number

    /**
     * 
     * @type {number}
     * @memberof TilesApiCaseTile
     */
    readonly yearBuiltTo?: number

    /**
     * 
     * @type {number}
     * @memberof TilesApiCaseTile
     */
    readonly lotAreaMin?: number

    /**
     * 
     * @type {number}
     * @memberof TilesApiCaseTile
     */
    readonly lotAreaMax?: number

    /**
     * An address has at least this many levels - like a house in two levels.
     * @type {number}
     * @memberof TilesApiCaseTile
     */
    readonly levelsMin?: number

    /**
     * An address has maximum this many levels - like a house in max one level.
     * @type {number}
     * @memberof TilesApiCaseTile
     */
    readonly levelsMax?: number

    /**
     * When enabled the result should only include pre-sale (projektsalg)
     * @type {boolean}
     * @memberof TilesApiCaseTile
     */
    readonly presale?: boolean

    /**
     * 
     * @type {number}
     * @memberof TilesApiCaseTile
     */
    readonly basementAreaMin?: number

    /**
     * 
     * @type {number}
     * @memberof TilesApiCaseTile
     */
    readonly basementAreaMax?: number

    /**
     * Whether or not cases returned have a basement
     * @type {boolean}
     * @memberof TilesApiCaseTile
     */
    readonly filterBasement?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TilesApiCaseTile
     */
    readonly filterPriceDrop?: boolean

    /**
     * Polygon. Use like &#x60;api.dk/search?polygon&#x3D;12.552178,55.784813|12.497472,55.784813|12.304046,55.685812|12.302093,55.654960&#x60;. * Format polygon&#x3D;[lon,lat]|[lon,lat]|.... * A polygon can have a max of 500 points 
     * @type {string}
     * @memberof TilesApiCaseTile
     */
    readonly polygon?: string

    /**
     * Radius. Use like &#x60;api.dk/search?radius&#x3D;10350|11.852554,55.767563&#x60;. * Format: [meters]|[lon:centerPoint],[lat:centerPoint] * [meters] must be at least 1 
     * @type {string}
     * @memberof TilesApiCaseTile
     */
    readonly radius?: string
}

/**
 * TilesApi - object-oriented interface
 * @export
 * @class TilesApi
 * @extends {BaseAPI}
 */
export class TilesApi extends BaseAPI {
    /**
     * 
     * @summary Generate addresses tile
     * @param {TilesApiAddressesTileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TilesApi
     */
    public addressesTile(requestParameters: TilesApiAddressesTileRequest, options?: any) {
        return TilesApiFp(this.configuration).addressesTile(requestParameters.z, requestParameters.tileX, requestParameters.tileY, requestParameters.scale, requestParameters.dotType, requestParameters.provinces, requestParameters.municipalities, requestParameters.zipCodes, requestParameters.roads, requestParameters.cities, requestParameters.sold, requestParameters.yearSoldFrom, requestParameters.yearSoldTo, requestParameters.priceMax, requestParameters.priceMin, requestParameters.registrationTypes, requestParameters.addressTypes, requestParameters.areaMin, requestParameters.areaMax, requestParameters.polygon, requestParameters.radius, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Only real estate and cooperative cases are included.
     * @summary Generate a case tile
     * @param {TilesApiCaseTileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TilesApi
     */
    public caseTile(requestParameters: TilesApiCaseTileRequest, options?: any) {
        return TilesApiFp(this.configuration).caseTile(requestParameters.z, requestParameters.tileX, requestParameters.tileY, requestParameters.floorMin, requestParameters.floorMax, requestParameters.provinces, requestParameters.municipalities, requestParameters.cities, requestParameters.zipCodes, requestParameters.roads, requestParameters.placeNames, requestParameters.customAreas, requestParameters.c, requestParameters.scale, requestParameters.dotType, requestParameters.addressTypes, requestParameters.priceMin, requestParameters.priceMax, requestParameters.monthlyExpenseMin, requestParameters.monthlyExpenseMax, requestParameters.freeText, requestParameters.energyLabels, requestParameters.filterOpenHouse, requestParameters.areaMin, requestParameters.areaMax, requestParameters.numberOfRoomsMin, requestParameters.numberOfRoomsMax, requestParameters.yearBuiltFrom, requestParameters.yearBuiltTo, requestParameters.lotAreaMin, requestParameters.lotAreaMax, requestParameters.levelsMin, requestParameters.levelsMax, requestParameters.presale, requestParameters.basementAreaMin, requestParameters.basementAreaMax, requestParameters.filterBasement, requestParameters.filterPriceDrop, requestParameters.polygon, requestParameters.radius, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint is idempotent; attempting to delete a point of interest that does not exist returns `204`. 
         * @summary Delete a point of interest
         * @param {string} userPointOfInterestID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPointOfInterest: async (userPointOfInterestID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPointOfInterestID' is not null or undefined
            assertParamExists('deleteUserPointOfInterest', 'userPointOfInterestID', userPointOfInterestID)
            const localVarPath = `/user/points_of_interest/{userPointOfInterestID}`
                .replace(`{${"userPointOfInterestID"}}`, encodeURIComponent(String(userPointOfInterestID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint is idempotent; attempting to delete a device token that does not exist is a no-op. 
         * @summary Delete a token from the user\'s device tokens
         * @param {UserTokenBody1} userTokenBody1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserToken: async (userTokenBody1: UserTokenBody1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userTokenBody1' is not null or undefined
            assertParamExists('deleteUserToken', 'userTokenBody1', userTokenBody1)
            const localVarPath = `/user/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userTokenBody1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a user\'s favorite addresses and previews
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFavorites: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/favorites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a user\'s points of interest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPointsOfInterest: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/points_of_interest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new point of interest
         * @param {PostUserPointOfInterest} postUserPointOfInterest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserPointOfInterest: async (postUserPointOfInterest: PostUserPointOfInterest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postUserPointOfInterest' is not null or undefined
            assertParamExists('postUserPointOfInterest', 'postUserPointOfInterest', postUserPointOfInterest)
            const localVarPath = `/user/points_of_interest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postUserPointOfInterest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a point of interest
         * @param {string} userPointOfInterestID 
         * @param {PostUserPointOfInterest} postUserPointOfInterest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserPointOfInterest: async (userPointOfInterestID: string, postUserPointOfInterest: PostUserPointOfInterest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPointOfInterestID' is not null or undefined
            assertParamExists('putUserPointOfInterest', 'userPointOfInterestID', userPointOfInterestID)
            // verify required parameter 'postUserPointOfInterest' is not null or undefined
            assertParamExists('putUserPointOfInterest', 'postUserPointOfInterest', postUserPointOfInterest)
            const localVarPath = `/user/points_of_interest/{userPointOfInterestID}`
                .replace(`{${"userPointOfInterestID"}}`, encodeURIComponent(String(userPointOfInterestID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postUserPointOfInterest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint is idempotent; attempting to add a device token that a user already owns is a no-op. Adding a device token to a user removes the token from any other users that may have had it. 
         * @summary Add a token to the user\'s device tokens
         * @param {UserTokenBody} userTokenBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserToken: async (userTokenBody: UserTokenBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userTokenBody' is not null or undefined
            assertParamExists('putUserToken', 'userTokenBody', userTokenBody)
            const localVarPath = `/user/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userTokenBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates user data
         * @param {UserUpdate} userUpdate The user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userUpdate: UserUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUpdate' is not null or undefined
            assertParamExists('updateUser', 'userUpdate', userUpdate)
            const localVarPath = `/user/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a user\'s favorite addresses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAddressesFavorites: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/addresses/favorites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This operation is idempotent; calling it for an address that is not in the user\'s list of favorites has no side effects. Note that this endpoint is only compatible with public addresses. Attempting to use an address that is not public in this endpoint will result in a status code `404`. 
         * @summary Remove an address from the user\'s list of favorites
         * @param {string} addressID UUID of the Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoriteAddressDelete: async (addressID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressID' is not null or undefined
            assertParamExists('userFavoriteAddressDelete', 'addressID', addressID)
            const localVarPath = `/user/addresses/{addressID}/favorite`
                .replace(`{${"addressID"}}`, encodeURIComponent(String(addressID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This operation is idempotent; calling it for an address that is already in the user\'s list of favorites has no side effects. Note that this endpoint is only compatible with public addresses. Attempting to use an address that is not public in this endpoint will result in a status code `404`. 
         * @summary Add an address to the user\'s list of favorites
         * @param {string} addressID UUID of the Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoriteAddressPut: async (addressID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressID' is not null or undefined
            assertParamExists('userFavoriteAddressPut', 'addressID', addressID)
            const localVarPath = `/user/addresses/{addressID}/favorite`
                .replace(`{${"addressID"}}`, encodeURIComponent(String(addressID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This operation is idempotent; calling it for a preview that is not in the user\'s list of favorites has no side effects. 
         * @summary Remove a preview from the user\'s list of favorites
         * @param {string} previewID UUID of the Preview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoritePreviewDelete: async (previewID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'previewID' is not null or undefined
            assertParamExists('userFavoritePreviewDelete', 'previewID', previewID)
            const localVarPath = `/user/previews/{previewID}/favorite`
                .replace(`{${"previewID"}}`, encodeURIComponent(String(previewID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This operation is idempotent; calling it for a preview that is already in the user\'s list of favorites has no side effects. 
         * @summary Add a preview to the user\'s list of favorites
         * @param {string} previewID UUID of the Preview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoritePreviewPut: async (previewID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'previewID' is not null or undefined
            assertParamExists('userFavoritePreviewPut', 'previewID', previewID)
            const localVarPath = `/user/previews/{previewID}/favorite`
                .replace(`{${"previewID"}}`, encodeURIComponent(String(previewID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get information about a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProfile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Build save search url and redirect user to main site
         * @param {string} searchID UUID of the saved search
         * @param {number} [timeOnMarketMin] minimum number of days on the market.
         * @param {number} [timeOnMarketMax] maximum number of days on the market.
         * @param {boolean} [filterPriceDrop] 
         * @param {number} [lastPriceDropAtMax] Maximum number of days since the last price drop, if any
         * @param {SearchCasesSortBy} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSavedSearchIdRedirect: async (searchID: string, timeOnMarketMin?: number, timeOnMarketMax?: number, filterPriceDrop?: boolean, lastPriceDropAtMax?: number, sortBy?: SearchCasesSortBy, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchID' is not null or undefined
            assertParamExists('userSavedSearchIdRedirect', 'searchID', searchID)
            const localVarPath = `/user/searches/{searchID}/redirect`
                .replace(`{${"searchID"}}`, encodeURIComponent(String(searchID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (timeOnMarketMin !== undefined) {
                localVarQueryParameter['timeOnMarketMin'] = timeOnMarketMin;
            }

            if (timeOnMarketMax !== undefined) {
                localVarQueryParameter['timeOnMarketMax'] = timeOnMarketMax;
            }

            if (filterPriceDrop !== undefined) {
                localVarQueryParameter['filterPriceDrop'] = filterPriceDrop;
            }

            if (lastPriceDropAtMax !== undefined) {
                localVarQueryParameter['lastPriceDropAtMax'] = lastPriceDropAtMax;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get searches that the user has saved
         * @param {number} [perPage] Limits the number of results per page. 
         * @param {number} [page] Page number 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSearches: async (perPage?: number, page?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/searches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a saved search on user
         * @param {string} searchID UUID of the saved search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSearchesDelete: async (searchID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchID' is not null or undefined
            assertParamExists('userSearchesDelete', 'searchID', searchID)
            const localVarPath = `/user/searches/{searchID}`
                .replace(`{${"searchID"}}`, encodeURIComponent(String(searchID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new saved search on user
         * @param {Search} search Full search query with metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSearchesPost: async (search: Search, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'search' is not null or undefined
            assertParamExists('userSearchesPost', 'search', search)
            const localVarPath = `/user/searches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(search, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This will replace the existing object if you want to patch the search query instead you should use the patch functionality. 
         * @summary Replace a saved search on user
         * @param {string} searchID UUID of the saved search
         * @param {Search} search Full search query with metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSearchesPut: async (searchID: string, search: Search, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchID' is not null or undefined
            assertParamExists('userSearchesPut', 'searchID', searchID)
            // verify required parameter 'search' is not null or undefined
            assertParamExists('userSearchesPut', 'search', search)
            const localVarPath = `/user/searches/{searchID}`
                .replace(`{${"searchID"}}`, encodeURIComponent(String(searchID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(search, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint is idempotent; attempting to delete a point of interest that does not exist returns `204`. 
         * @summary Delete a point of interest
         * @param {string} userPointOfInterestID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserPointOfInterest(userPointOfInterestID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserPointOfInterest(userPointOfInterestID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint is idempotent; attempting to delete a device token that does not exist is a no-op. 
         * @summary Delete a token from the user\'s device tokens
         * @param {UserTokenBody1} userTokenBody1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserToken(userTokenBody1: UserTokenBody1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserToken(userTokenBody1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a user\'s favorite addresses and previews
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserFavorites(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FavoriteResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserFavorites(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a user\'s points of interest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPointsOfInterest(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserPointsOfInterestResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPointsOfInterest(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new point of interest
         * @param {PostUserPointOfInterest} postUserPointOfInterest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserPointOfInterest(postUserPointOfInterest: PostUserPointOfInterest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostUserPointOfInterestResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserPointOfInterest(postUserPointOfInterest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a point of interest
         * @param {string} userPointOfInterestID 
         * @param {PostUserPointOfInterest} postUserPointOfInterest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUserPointOfInterest(userPointOfInterestID: string, postUserPointOfInterest: PostUserPointOfInterest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutUserPointOfInterestResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUserPointOfInterest(userPointOfInterestID, postUserPointOfInterest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint is idempotent; attempting to add a device token that a user already owns is a no-op. Adding a device token to a user removes the token from any other users that may have had it. 
         * @summary Add a token to the user\'s device tokens
         * @param {UserTokenBody} userTokenBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUserToken(userTokenBody: UserTokenBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUserToken(userTokenBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates user data
         * @param {UserUpdate} userUpdate The user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userUpdate: UserUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a user\'s favorite addresses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAddressesFavorites(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FavoriteAddressesResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAddressesFavorites(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This operation is idempotent; calling it for an address that is not in the user\'s list of favorites has no side effects. Note that this endpoint is only compatible with public addresses. Attempting to use an address that is not public in this endpoint will result in a status code `404`. 
         * @summary Remove an address from the user\'s list of favorites
         * @param {string} addressID UUID of the Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userFavoriteAddressDelete(addressID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userFavoriteAddressDelete(addressID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This operation is idempotent; calling it for an address that is already in the user\'s list of favorites has no side effects. Note that this endpoint is only compatible with public addresses. Attempting to use an address that is not public in this endpoint will result in a status code `404`. 
         * @summary Add an address to the user\'s list of favorites
         * @param {string} addressID UUID of the Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userFavoriteAddressPut(addressID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userFavoriteAddressPut(addressID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This operation is idempotent; calling it for a preview that is not in the user\'s list of favorites has no side effects. 
         * @summary Remove a preview from the user\'s list of favorites
         * @param {string} previewID UUID of the Preview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userFavoritePreviewDelete(previewID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userFavoritePreviewDelete(previewID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This operation is idempotent; calling it for a preview that is already in the user\'s list of favorites has no side effects. 
         * @summary Add a preview to the user\'s list of favorites
         * @param {string} previewID UUID of the Preview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userFavoritePreviewPut(previewID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userFavoritePreviewPut(previewID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get information about a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Build save search url and redirect user to main site
         * @param {string} searchID UUID of the saved search
         * @param {number} [timeOnMarketMin] minimum number of days on the market.
         * @param {number} [timeOnMarketMax] maximum number of days on the market.
         * @param {boolean} [filterPriceDrop] 
         * @param {number} [lastPriceDropAtMax] Maximum number of days since the last price drop, if any
         * @param {SearchCasesSortBy} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSavedSearchIdRedirect(searchID: string, timeOnMarketMin?: number, timeOnMarketMax?: number, filterPriceDrop?: boolean, lastPriceDropAtMax?: number, sortBy?: SearchCasesSortBy, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSavedSearchIdRedirect(searchID, timeOnMarketMin, timeOnMarketMax, filterPriceDrop, lastPriceDropAtMax, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get searches that the user has saved
         * @param {number} [perPage] Limits the number of results per page. 
         * @param {number} [page] Page number 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSearches(perPage?: number, page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HALCollection & SearchQueryCollectionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSearches(perPage, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a saved search on user
         * @param {string} searchID UUID of the saved search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSearchesDelete(searchID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSearchesDelete(searchID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new saved search on user
         * @param {Search} search Full search query with metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSearchesPost(search: Search, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Search>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSearchesPost(search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This will replace the existing object if you want to patch the search query instead you should use the patch functionality. 
         * @summary Replace a saved search on user
         * @param {string} searchID UUID of the saved search
         * @param {Search} search Full search query with metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSearchesPut(searchID: string, search: Search, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Search>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSearchesPut(searchID, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint is idempotent; attempting to delete a point of interest that does not exist returns `204`. 
         * @summary Delete a point of interest
         * @param {string} userPointOfInterestID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPointOfInterest(userPointOfInterestID: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserPointOfInterest(userPointOfInterestID, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint is idempotent; attempting to delete a device token that does not exist is a no-op. 
         * @summary Delete a token from the user\'s device tokens
         * @param {UserTokenBody1} userTokenBody1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserToken(userTokenBody1: UserTokenBody1, options?: any): AxiosPromise<string> {
            return localVarFp.deleteUserToken(userTokenBody1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a user\'s favorite addresses and previews
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFavorites(options?: any): AxiosPromise<FavoriteResult> {
            return localVarFp.getUserFavorites(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a user\'s points of interest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPointsOfInterest(options?: any): AxiosPromise<GetUserPointsOfInterestResult> {
            return localVarFp.getUserPointsOfInterest(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new point of interest
         * @param {PostUserPointOfInterest} postUserPointOfInterest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserPointOfInterest(postUserPointOfInterest: PostUserPointOfInterest, options?: any): AxiosPromise<PostUserPointOfInterestResult> {
            return localVarFp.postUserPointOfInterest(postUserPointOfInterest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a point of interest
         * @param {string} userPointOfInterestID 
         * @param {PostUserPointOfInterest} postUserPointOfInterest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserPointOfInterest(userPointOfInterestID: string, postUserPointOfInterest: PostUserPointOfInterest, options?: any): AxiosPromise<PutUserPointOfInterestResult> {
            return localVarFp.putUserPointOfInterest(userPointOfInterestID, postUserPointOfInterest, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint is idempotent; attempting to add a device token that a user already owns is a no-op. Adding a device token to a user removes the token from any other users that may have had it. 
         * @summary Add a token to the user\'s device tokens
         * @param {UserTokenBody} userTokenBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserToken(userTokenBody: UserTokenBody, options?: any): AxiosPromise<void> {
            return localVarFp.putUserToken(userTokenBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates user data
         * @param {UserUpdate} userUpdate The user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userUpdate: UserUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updateUser(userUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a user\'s favorite addresses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAddressesFavorites(options?: any): AxiosPromise<FavoriteAddressesResult> {
            return localVarFp.userAddressesFavorites(options).then((request) => request(axios, basePath));
        },
        /**
         * This operation is idempotent; calling it for an address that is not in the user\'s list of favorites has no side effects. Note that this endpoint is only compatible with public addresses. Attempting to use an address that is not public in this endpoint will result in a status code `404`. 
         * @summary Remove an address from the user\'s list of favorites
         * @param {string} addressID UUID of the Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoriteAddressDelete(addressID: string, options?: any): AxiosPromise<void> {
            return localVarFp.userFavoriteAddressDelete(addressID, options).then((request) => request(axios, basePath));
        },
        /**
         * This operation is idempotent; calling it for an address that is already in the user\'s list of favorites has no side effects. Note that this endpoint is only compatible with public addresses. Attempting to use an address that is not public in this endpoint will result in a status code `404`. 
         * @summary Add an address to the user\'s list of favorites
         * @param {string} addressID UUID of the Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoriteAddressPut(addressID: string, options?: any): AxiosPromise<void> {
            return localVarFp.userFavoriteAddressPut(addressID, options).then((request) => request(axios, basePath));
        },
        /**
         * This operation is idempotent; calling it for a preview that is not in the user\'s list of favorites has no side effects. 
         * @summary Remove a preview from the user\'s list of favorites
         * @param {string} previewID UUID of the Preview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoritePreviewDelete(previewID: string, options?: any): AxiosPromise<void> {
            return localVarFp.userFavoritePreviewDelete(previewID, options).then((request) => request(axios, basePath));
        },
        /**
         * This operation is idempotent; calling it for a preview that is already in the user\'s list of favorites has no side effects. 
         * @summary Add a preview to the user\'s list of favorites
         * @param {string} previewID UUID of the Preview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoritePreviewPut(previewID: string, options?: any): AxiosPromise<void> {
            return localVarFp.userFavoritePreviewPut(previewID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get information about a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProfile(options?: any): AxiosPromise<User> {
            return localVarFp.userProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Build save search url and redirect user to main site
         * @param {string} searchID UUID of the saved search
         * @param {number} [timeOnMarketMin] minimum number of days on the market.
         * @param {number} [timeOnMarketMax] maximum number of days on the market.
         * @param {boolean} [filterPriceDrop] 
         * @param {number} [lastPriceDropAtMax] Maximum number of days since the last price drop, if any
         * @param {SearchCasesSortBy} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSavedSearchIdRedirect(searchID: string, timeOnMarketMin?: number, timeOnMarketMax?: number, filterPriceDrop?: boolean, lastPriceDropAtMax?: number, sortBy?: SearchCasesSortBy, options?: any): AxiosPromise<void> {
            return localVarFp.userSavedSearchIdRedirect(searchID, timeOnMarketMin, timeOnMarketMax, filterPriceDrop, lastPriceDropAtMax, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get searches that the user has saved
         * @param {number} [perPage] Limits the number of results per page. 
         * @param {number} [page] Page number 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSearches(perPage?: number, page?: number, options?: any): AxiosPromise<HALCollection & SearchQueryCollectionResult> {
            return localVarFp.userSearches(perPage, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a saved search on user
         * @param {string} searchID UUID of the saved search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSearchesDelete(searchID: string, options?: any): AxiosPromise<string> {
            return localVarFp.userSearchesDelete(searchID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new saved search on user
         * @param {Search} search Full search query with metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSearchesPost(search: Search, options?: any): AxiosPromise<Search> {
            return localVarFp.userSearchesPost(search, options).then((request) => request(axios, basePath));
        },
        /**
         * This will replace the existing object if you want to patch the search query instead you should use the patch functionality. 
         * @summary Replace a saved search on user
         * @param {string} searchID UUID of the saved search
         * @param {Search} search Full search query with metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSearchesPut(searchID: string, search: Search, options?: any): AxiosPromise<Search> {
            return localVarFp.userSearchesPut(searchID, search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteUserPointOfInterest operation in UsersApi.
 * @export
 * @interface UsersApiDeleteUserPointOfInterestRequest
 */
export interface UsersApiDeleteUserPointOfInterestRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiDeleteUserPointOfInterest
     */
    readonly userPointOfInterestID: string
}

/**
 * Request parameters for deleteUserToken operation in UsersApi.
 * @export
 * @interface UsersApiDeleteUserTokenRequest
 */
export interface UsersApiDeleteUserTokenRequest {
    /**
     * 
     * @type {UserTokenBody1}
     * @memberof UsersApiDeleteUserToken
     */
    readonly userTokenBody1: UserTokenBody1
}

/**
 * Request parameters for postUserPointOfInterest operation in UsersApi.
 * @export
 * @interface UsersApiPostUserPointOfInterestRequest
 */
export interface UsersApiPostUserPointOfInterestRequest {
    /**
     * 
     * @type {PostUserPointOfInterest}
     * @memberof UsersApiPostUserPointOfInterest
     */
    readonly postUserPointOfInterest: PostUserPointOfInterest
}

/**
 * Request parameters for putUserPointOfInterest operation in UsersApi.
 * @export
 * @interface UsersApiPutUserPointOfInterestRequest
 */
export interface UsersApiPutUserPointOfInterestRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiPutUserPointOfInterest
     */
    readonly userPointOfInterestID: string

    /**
     * 
     * @type {PostUserPointOfInterest}
     * @memberof UsersApiPutUserPointOfInterest
     */
    readonly postUserPointOfInterest: PostUserPointOfInterest
}

/**
 * Request parameters for putUserToken operation in UsersApi.
 * @export
 * @interface UsersApiPutUserTokenRequest
 */
export interface UsersApiPutUserTokenRequest {
    /**
     * 
     * @type {UserTokenBody}
     * @memberof UsersApiPutUserToken
     */
    readonly userTokenBody: UserTokenBody
}

/**
 * Request parameters for updateUser operation in UsersApi.
 * @export
 * @interface UsersApiUpdateUserRequest
 */
export interface UsersApiUpdateUserRequest {
    /**
     * The user
     * @type {UserUpdate}
     * @memberof UsersApiUpdateUser
     */
    readonly userUpdate: UserUpdate
}

/**
 * Request parameters for userFavoriteAddressDelete operation in UsersApi.
 * @export
 * @interface UsersApiUserFavoriteAddressDeleteRequest
 */
export interface UsersApiUserFavoriteAddressDeleteRequest {
    /**
     * UUID of the Address
     * @type {string}
     * @memberof UsersApiUserFavoriteAddressDelete
     */
    readonly addressID: string
}

/**
 * Request parameters for userFavoriteAddressPut operation in UsersApi.
 * @export
 * @interface UsersApiUserFavoriteAddressPutRequest
 */
export interface UsersApiUserFavoriteAddressPutRequest {
    /**
     * UUID of the Address
     * @type {string}
     * @memberof UsersApiUserFavoriteAddressPut
     */
    readonly addressID: string
}

/**
 * Request parameters for userFavoritePreviewDelete operation in UsersApi.
 * @export
 * @interface UsersApiUserFavoritePreviewDeleteRequest
 */
export interface UsersApiUserFavoritePreviewDeleteRequest {
    /**
     * UUID of the Preview
     * @type {string}
     * @memberof UsersApiUserFavoritePreviewDelete
     */
    readonly previewID: string
}

/**
 * Request parameters for userFavoritePreviewPut operation in UsersApi.
 * @export
 * @interface UsersApiUserFavoritePreviewPutRequest
 */
export interface UsersApiUserFavoritePreviewPutRequest {
    /**
     * UUID of the Preview
     * @type {string}
     * @memberof UsersApiUserFavoritePreviewPut
     */
    readonly previewID: string
}

/**
 * Request parameters for userSavedSearchIdRedirect operation in UsersApi.
 * @export
 * @interface UsersApiUserSavedSearchIdRedirectRequest
 */
export interface UsersApiUserSavedSearchIdRedirectRequest {
    /**
     * UUID of the saved search
     * @type {string}
     * @memberof UsersApiUserSavedSearchIdRedirect
     */
    readonly searchID: string

    /**
     * minimum number of days on the market.
     * @type {number}
     * @memberof UsersApiUserSavedSearchIdRedirect
     */
    readonly timeOnMarketMin?: number

    /**
     * maximum number of days on the market.
     * @type {number}
     * @memberof UsersApiUserSavedSearchIdRedirect
     */
    readonly timeOnMarketMax?: number

    /**
     * 
     * @type {boolean}
     * @memberof UsersApiUserSavedSearchIdRedirect
     */
    readonly filterPriceDrop?: boolean

    /**
     * Maximum number of days since the last price drop, if any
     * @type {number}
     * @memberof UsersApiUserSavedSearchIdRedirect
     */
    readonly lastPriceDropAtMax?: number

    /**
     * 
     * @type {SearchCasesSortBy}
     * @memberof UsersApiUserSavedSearchIdRedirect
     */
    readonly sortBy?: SearchCasesSortBy
}

/**
 * Request parameters for userSearches operation in UsersApi.
 * @export
 * @interface UsersApiUserSearchesRequest
 */
export interface UsersApiUserSearchesRequest {
    /**
     * Limits the number of results per page. 
     * @type {number}
     * @memberof UsersApiUserSearches
     */
    readonly perPage?: number

    /**
     * Page number 
     * @type {number}
     * @memberof UsersApiUserSearches
     */
    readonly page?: number
}

/**
 * Request parameters for userSearchesDelete operation in UsersApi.
 * @export
 * @interface UsersApiUserSearchesDeleteRequest
 */
export interface UsersApiUserSearchesDeleteRequest {
    /**
     * UUID of the saved search
     * @type {string}
     * @memberof UsersApiUserSearchesDelete
     */
    readonly searchID: string
}

/**
 * Request parameters for userSearchesPost operation in UsersApi.
 * @export
 * @interface UsersApiUserSearchesPostRequest
 */
export interface UsersApiUserSearchesPostRequest {
    /**
     * Full search query with metadata
     * @type {Search}
     * @memberof UsersApiUserSearchesPost
     */
    readonly search: Search
}

/**
 * Request parameters for userSearchesPut operation in UsersApi.
 * @export
 * @interface UsersApiUserSearchesPutRequest
 */
export interface UsersApiUserSearchesPutRequest {
    /**
     * UUID of the saved search
     * @type {string}
     * @memberof UsersApiUserSearchesPut
     */
    readonly searchID: string

    /**
     * Full search query with metadata
     * @type {Search}
     * @memberof UsersApiUserSearchesPut
     */
    readonly search: Search
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Deletes user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUser(options?: any) {
        return UsersApiFp(this.configuration).deleteUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint is idempotent; attempting to delete a point of interest that does not exist returns `204`. 
     * @summary Delete a point of interest
     * @param {UsersApiDeleteUserPointOfInterestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUserPointOfInterest(requestParameters: UsersApiDeleteUserPointOfInterestRequest, options?: any) {
        return UsersApiFp(this.configuration).deleteUserPointOfInterest(requestParameters.userPointOfInterestID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint is idempotent; attempting to delete a device token that does not exist is a no-op. 
     * @summary Delete a token from the user\'s device tokens
     * @param {UsersApiDeleteUserTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUserToken(requestParameters: UsersApiDeleteUserTokenRequest, options?: any) {
        return UsersApiFp(this.configuration).deleteUserToken(requestParameters.userTokenBody1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a user\'s favorite addresses and previews
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserFavorites(options?: any) {
        return UsersApiFp(this.configuration).getUserFavorites(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a user\'s points of interest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserPointsOfInterest(options?: any) {
        return UsersApiFp(this.configuration).getUserPointsOfInterest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new point of interest
     * @param {UsersApiPostUserPointOfInterestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public postUserPointOfInterest(requestParameters: UsersApiPostUserPointOfInterestRequest, options?: any) {
        return UsersApiFp(this.configuration).postUserPointOfInterest(requestParameters.postUserPointOfInterest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a point of interest
     * @param {UsersApiPutUserPointOfInterestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public putUserPointOfInterest(requestParameters: UsersApiPutUserPointOfInterestRequest, options?: any) {
        return UsersApiFp(this.configuration).putUserPointOfInterest(requestParameters.userPointOfInterestID, requestParameters.postUserPointOfInterest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint is idempotent; attempting to add a device token that a user already owns is a no-op. Adding a device token to a user removes the token from any other users that may have had it. 
     * @summary Add a token to the user\'s device tokens
     * @param {UsersApiPutUserTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public putUserToken(requestParameters: UsersApiPutUserTokenRequest, options?: any) {
        return UsersApiFp(this.configuration).putUserToken(requestParameters.userTokenBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates user data
     * @param {UsersApiUpdateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUser(requestParameters: UsersApiUpdateUserRequest, options?: any) {
        return UsersApiFp(this.configuration).updateUser(requestParameters.userUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a user\'s favorite addresses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userAddressesFavorites(options?: any) {
        return UsersApiFp(this.configuration).userAddressesFavorites(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This operation is idempotent; calling it for an address that is not in the user\'s list of favorites has no side effects. Note that this endpoint is only compatible with public addresses. Attempting to use an address that is not public in this endpoint will result in a status code `404`. 
     * @summary Remove an address from the user\'s list of favorites
     * @param {UsersApiUserFavoriteAddressDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userFavoriteAddressDelete(requestParameters: UsersApiUserFavoriteAddressDeleteRequest, options?: any) {
        return UsersApiFp(this.configuration).userFavoriteAddressDelete(requestParameters.addressID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This operation is idempotent; calling it for an address that is already in the user\'s list of favorites has no side effects. Note that this endpoint is only compatible with public addresses. Attempting to use an address that is not public in this endpoint will result in a status code `404`. 
     * @summary Add an address to the user\'s list of favorites
     * @param {UsersApiUserFavoriteAddressPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userFavoriteAddressPut(requestParameters: UsersApiUserFavoriteAddressPutRequest, options?: any) {
        return UsersApiFp(this.configuration).userFavoriteAddressPut(requestParameters.addressID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This operation is idempotent; calling it for a preview that is not in the user\'s list of favorites has no side effects. 
     * @summary Remove a preview from the user\'s list of favorites
     * @param {UsersApiUserFavoritePreviewDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userFavoritePreviewDelete(requestParameters: UsersApiUserFavoritePreviewDeleteRequest, options?: any) {
        return UsersApiFp(this.configuration).userFavoritePreviewDelete(requestParameters.previewID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This operation is idempotent; calling it for a preview that is already in the user\'s list of favorites has no side effects. 
     * @summary Add a preview to the user\'s list of favorites
     * @param {UsersApiUserFavoritePreviewPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userFavoritePreviewPut(requestParameters: UsersApiUserFavoritePreviewPutRequest, options?: any) {
        return UsersApiFp(this.configuration).userFavoritePreviewPut(requestParameters.previewID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get information about a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userProfile(options?: any) {
        return UsersApiFp(this.configuration).userProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Build save search url and redirect user to main site
     * @param {UsersApiUserSavedSearchIdRedirectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userSavedSearchIdRedirect(requestParameters: UsersApiUserSavedSearchIdRedirectRequest, options?: any) {
        return UsersApiFp(this.configuration).userSavedSearchIdRedirect(requestParameters.searchID, requestParameters.timeOnMarketMin, requestParameters.timeOnMarketMax, requestParameters.filterPriceDrop, requestParameters.lastPriceDropAtMax, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get searches that the user has saved
     * @param {UsersApiUserSearchesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userSearches(requestParameters: UsersApiUserSearchesRequest = {}, options?: any) {
        return UsersApiFp(this.configuration).userSearches(requestParameters.perPage, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a saved search on user
     * @param {UsersApiUserSearchesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userSearchesDelete(requestParameters: UsersApiUserSearchesDeleteRequest, options?: any) {
        return UsersApiFp(this.configuration).userSearchesDelete(requestParameters.searchID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new saved search on user
     * @param {UsersApiUserSearchesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userSearchesPost(requestParameters: UsersApiUserSearchesPostRequest, options?: any) {
        return UsersApiFp(this.configuration).userSearchesPost(requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This will replace the existing object if you want to patch the search query instead you should use the patch functionality. 
     * @summary Replace a saved search on user
     * @param {UsersApiUserSearchesPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userSearchesPut(requestParameters: UsersApiUserSearchesPutRequest, options?: any) {
        return UsersApiFp(this.configuration).userSearchesPut(requestParameters.searchID, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ZipCodesApi - axios parameter creator
 * @export
 */
export const ZipCodesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Roads are sorted alphabetically using Danish alphabet
         * @summary Get all roads belonging to a zip code
         * @param {number} zipCode 
         * @param {boolean} [includeCustom] Include something from a custom realtor address, like a road name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roadsOfZipCode: async (zipCode: number, includeCustom?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'zipCode' is not null or undefined
            assertParamExists('roadsOfZipCode', 'zipCode', zipCode)
            const localVarPath = `/zip_codes/{zipCode}/roads`
                .replace(`{${"zipCode"}}`, encodeURIComponent(String(zipCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeCustom !== undefined) {
                localVarQueryParameter['includeCustom'] = includeCustom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Zip codes are sorted alphabetically using Danish alphabet 
         * @summary Get all zip codes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zipCodes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/zip_codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ZipCodesApi - functional programming interface
 * @export
 */
export const ZipCodesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ZipCodesApiAxiosParamCreator(configuration)
    return {
        /**
         * Roads are sorted alphabetically using Danish alphabet
         * @summary Get all roads belonging to a zip code
         * @param {number} zipCode 
         * @param {boolean} [includeCustom] Include something from a custom realtor address, like a road name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roadsOfZipCode(zipCode: number, includeCustom?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoadCollectionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roadsOfZipCode(zipCode, includeCustom, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Zip codes are sorted alphabetically using Danish alphabet 
         * @summary Get all zip codes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zipCodes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZipCodeCollectionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zipCodes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ZipCodesApi - factory interface
 * @export
 */
export const ZipCodesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ZipCodesApiFp(configuration)
    return {
        /**
         * Roads are sorted alphabetically using Danish alphabet
         * @summary Get all roads belonging to a zip code
         * @param {number} zipCode 
         * @param {boolean} [includeCustom] Include something from a custom realtor address, like a road name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roadsOfZipCode(zipCode: number, includeCustom?: boolean, options?: any): AxiosPromise<RoadCollectionResult> {
            return localVarFp.roadsOfZipCode(zipCode, includeCustom, options).then((request) => request(axios, basePath));
        },
        /**
         * Zip codes are sorted alphabetically using Danish alphabet 
         * @summary Get all zip codes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zipCodes(options?: any): AxiosPromise<ZipCodeCollectionResult> {
            return localVarFp.zipCodes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for roadsOfZipCode operation in ZipCodesApi.
 * @export
 * @interface ZipCodesApiRoadsOfZipCodeRequest
 */
export interface ZipCodesApiRoadsOfZipCodeRequest {
    /**
     * 
     * @type {number}
     * @memberof ZipCodesApiRoadsOfZipCode
     */
    readonly zipCode: number

    /**
     * Include something from a custom realtor address, like a road name.
     * @type {boolean}
     * @memberof ZipCodesApiRoadsOfZipCode
     */
    readonly includeCustom?: boolean
}

/**
 * ZipCodesApi - object-oriented interface
 * @export
 * @class ZipCodesApi
 * @extends {BaseAPI}
 */
export class ZipCodesApi extends BaseAPI {
    /**
     * Roads are sorted alphabetically using Danish alphabet
     * @summary Get all roads belonging to a zip code
     * @param {ZipCodesApiRoadsOfZipCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZipCodesApi
     */
    public roadsOfZipCode(requestParameters: ZipCodesApiRoadsOfZipCodeRequest, options?: any) {
        return ZipCodesApiFp(this.configuration).roadsOfZipCode(requestParameters.zipCode, requestParameters.includeCustom, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Zip codes are sorted alphabetically using Danish alphabet 
     * @summary Get all zip codes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZipCodesApi
     */
    public zipCodes(options?: any) {
        return ZipCodesApiFp(this.configuration).zipCodes(options).then((request) => request(this.axios, this.basePath));
    }
}


