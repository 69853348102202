/**
 * NOTE: an exact copy of this function exist as `slugify()`
 * in "libs/data-access/src/lib/users/user.utils.ts". When removing
 * this function please also remove `slugify()`.
 */
export function toURLSafe(input: string): string {
  let result = input.toLocaleLowerCase();

  // Fold non-ASCII chars
  result = [
    ['æ', 'ae'],
    ['ø', 'oe'],
    ['å', 'aa'],
    ['è', 'e'],
    ['é', 'e'],
    ['ä', 'a'],
    ['ü', 'u'],
    ['ö', 'o'],
  ].reduce((acc, [from, to]) => acc.replace(new RegExp(from, 'g'), to), result);

  // Replace spaces (and other whitespace chars) with dashes
  result = result.replace(/\s+/g, '-');

  return encodeURIComponent(result);
}

export function capitalize(input: string): string {
  return (input ?? '').replace(/^./, (c) => c.toLocaleUpperCase());
}
