export interface Address {
  roadName: string;
  houseNumber?: string;
  floor?: string;
  door?: string;
  cityName: string;
  placeName?: string;
  zipCode: number | string;
}

export function formatAddress({
  roadName,
  houseNumber,
  floor,
  door,
  cityName,
  zipCode,
}: Address): string {
  return [
    roadName,
    formatHouseNumber(houseNumber),
    formatFloorAndDoor(floor, door),
    formatZipCodeAndCity(zipCode, cityName),
  ].join('');
}

export function formatAddressNoCity({
  roadName,
  houseNumber,
  floor,
  door,
}: Address): string {
  return [
    roadName,
    formatHouseNumber(houseNumber),
    formatFloorAndDoor(floor, door),
  ].join('');
}

export function formatAddressMultiLine({
  roadName,
  houseNumber,
  floor,
  door,
  cityName,
  zipCode,
  placeName,
}: Address): string[] {
  return [
    [
      `${roadName}${formatHouseNumber(houseNumber)}`,
      formatFloorAndDoor(floor, door, ''),
    ]
      .filter((line) => !!line)
      .join(', '),
    placeName
      ? formatPlaceNameZipCodeAndCity(placeName, zipCode, cityName)
      : formatZipCodeAndCity(zipCode, cityName, ''),
  ];
}

function formatHouseNumber(houseNumber?: string): string {
  return houseNumber ? ` ${houseNumber}` : '';
}

export function formatFloorAndDoor(
  floor?: string,
  door?: string,
  prefix = ', '
): string {
  const floorParsed =
    floor && (floor === '-1' ? 'kldr.' : floor === '0' ? 'st.' : `${floor}.`);

  return floorParsed && door
    ? `${prefix}${floorParsed} ${door}.` // e.g. ", 1. tv."
    : floor
    ? `${prefix}${floorParsed}` // e.g. ", 1."
    : door
    ? `${prefix}${door}.` // e.g. ", 5."
    : '';
}

function formatZipCodeAndCity(
  zipCode: number | string,
  cityName: string,
  prefix = ', '
): string {
  return `${prefix}${zipCode} ${cityName}`;
}

function formatPlaceNameZipCodeAndCity(
  placeName: string,
  zipCode: number | string,
  cityName: string,
  divider = ', '
): string {
  return `${placeName}${divider}${zipCode} ${cityName}`;
}

/**
 *
 * @param phoneNumber string
 * @returns a spaced version of current number ie. "12345678" -> "12 34 56 78"
 */
export function formatPhoneNumber(phoneNumber: string) {
  // In case of +45 ... numbers
  if (phoneNumber.includes('+')) return phoneNumber;

  const number = phoneNumber.replace(/.{1,2}(?=(.{2})+$)/g, '$& ');
  return number;
}
